

.block-page-top{

    & + * > .container > .inner{
        margin-top: rem(64);
        @include laptop{
            margin-top: rem(48);
        }
        @include tablet{
            margin-top: rem(32);
        }
        @include mobile{
            margin-top: rem(24);
        }
    }

    &:has(+ .block-banner) {
        & + .block-banner {
            .inner {
                margin-top: 0;
                margin-bottom: 0;
            }
        }
    }

    &:has(+ .block-text){
        .block-page-top__inner{
            margin: rem(32) 0 rem(64) 0;
            @include laptop{
                margin: rem(24) 0 rem(48) 0;
            }
            @include tablet{
                margin: rem(24) 0 rem(48) 0;
            }
            @include mobile{
                margin: rem(24) 0 rem(24) 0;
            }
        }
    }
    &:has(+ .block-text_special){
        .block-page-top__inner{
            margin: rem(64) 0 rem(40) 0;
            @include laptop{
                margin: rem(40) 0 rem(48) 0;
            }
            @include tablet{
                margin: rem(40) 0 rem(32) 0;
            }
            @include mobile{
                margin: rem(24) 0 rem(16) 0;
            }
        }
        & + * > .container > .inner{
            margin-top: 0;
            @include laptop{
                margin-top: 0;
            }
            @include tablet{
                margin-top: 0;
            }
            @include mobile{
                margin-top: 0;
            }
        }
    }

    &__inner{
        margin: rem(64) 0;
        display: flex;
        flex-direction: column;
        row-gap: rem(16);
        align-items: center;
        position: relative;

        @include laptop{
            margin: rem(40) 0 rem(48) 0;
        }
        @include tablet{
            margin: rem(40) 0 rem(32) 0;
        }
        @include mobile{
            margin: rem(24) 0 rem(24) 0;
            align-items: flex-start;
        }
    }

    &__title{
        font-size: rem(48);
        max-width: rem(900);

        @include laptop{
            font-size: rem(32);
            max-width: rem(580);
        }
        @include tablet{
            font-size: rem(28);
            max-width: rem(520);
        }
        @include mobile{
            font-size: rem(22);
            text-align: left;
            max-width: unset;
        }
        
        &_short{
            @include mobile{
                max-width: rem(220);
            }
        }
    }

    &__title-link{
        font-size: rem(14);
        color: var(--gray40);
        border-color: var(--gray40);
        text-transform: uppercase;
        position: absolute;
        right: 0;
        bottom: rem(2);
        display: none;

        @include mobile{
            display: block;
            font-size: rem(12);
        }
    }

    &__date{
        font-size: rem(12);
        text-align: center;
        color: var(--gray80);
        margin-top: rem(8);

        @include mobile{
            font-size: rem(10);
        }
    }

    &__path{
        display: flex;
        flex-wrap: wrap;
        gap: rem(8) rem(16);

        @include mobile{
            gap: rem(4) rem(8);
        }
    }

    &__link{
        color: var(--gray40);
        text-transform: uppercase;
        text-decoration: none;
        white-space: nowrap;
        
        &_active{
            color: var(--black);
        }
    }

    &__category{
        width: 100%;
        @include mobile{
            width: calc(100% + rem(18));
            margin-left: rem(-9);
            padding: 0 rem(3) 0 rem(9);
        }
    }

    &__category-wrap{
        margin-top: rem(24);
        display: flex;
        flex-wrap: wrap;
        justify-content: center;
        gap: rem(12);

        @include laptop{
            margin-top: rem(16);
        }
        @include tablet{
            gap: rem(10);
        }
        @include mobile{
            margin-top: rem(8);
            flex-wrap: unset;
            justify-content: unset;
            gap: unset;
        }
    }

    &__category-card{
        display: flex;
        flex-direction: column;
        row-gap: rem(12);
        text-decoration: none;
        width: rem(214);
        @include laptop{
            width: rem(128);
        }
        @include tablet{
            width: rem(80);
        }
        @include mobile{
            width: rem(82);
            margin-right: rem(6);   
        }
    }

    &__category-cover{
        width: rem(214);
        height: rem(214);
        border-radius: rem(16);
        overflow: hidden;

        @include laptop{
            width: rem(128);
            height: rem(128);
        }
        @include tablet{
            border-radius: rem(12);
            width: rem(80);
            height: rem(80);
        }
        @include mobile{
            width: rem(82);
            height: rem(82);
            border-radius: rem(8);
        }

        img{
            width: 100%;
            height: 100%;
        }
    }

    &__category-title{
        text-align: center;
        font-size: rem(14);
        text-transform: uppercase;
        line-height: 1.4;

        @include laptop{
            font-size: rem(12);
        }
        @include tablet{
            font-size: rem(10);
        }
    }

}