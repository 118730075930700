
.franchise-banner{

    &__box{
        min-height: rem(600);
        border-radius: rem(20);
        border: rem(1) solid var(--black);
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        row-gap: rem(32);

        @include laptop{
            min-height: 360px;
            row-gap: rem(16);
        }
    }

    &__title{
        max-width: rem(1040);

        @include laptop{
            max-width: rem(670);
        }
        @include tablet{
            max-width: rem(660);
        }
        @include mobile{
            max-width: rem(320);
        }

        span{
            color: var(--sageDark);
        }
    }

    &__text{
        max-width: rem(500);
        text-align: center;
        color: var(--gray80);

        @include tablet{
            max-width: rem(400);
        }
        @include mobile{
            max-width: rem(350);
        }
    }

    &__btn{
        min-width: rem(300);

        @include laptop{
            min-width: rem(208);
        }
    }

}


.franchise-img{
    max-width: rem(682);
    @include laptop{
        max-width: rem(416);
    }
    @include tablet{
        max-width: 100%;
    }
}
.franchise-img-wide{
    max-width: rem(916);
    @include laptop{
        max-width: rem(560);
    }
    @include tablet{
        max-width: 100%;
    }
}
.franchise-img-map{
    max-width: rem(1184);
    @include laptop{
        max-width: rem(704);
    }
    @include tablet{
        max-width: 100%;
    }
    img{
        object-fit: contain;
    }
}