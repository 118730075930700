

.lookbook-page{

    &__box{
        display: flex;
        flex-direction: column;
        row-gap: rem(64);

        @include laptop{
            row-gap: rem(48);
        }
        @include tablet{
            row-gap: rem(32);
        }
    }

    &__section{
        position: relative;
        display: flex;
        flex-wrap: wrap;
        gap: rem(64) rem(20);

        @include laptop{
            gap: rem(48) rem(16);
        }
        @include tablet{
            gap: rem(32) rem(10);
        }
        @include mobile{
            gap: rem(10);
        }

        &_third{
            @include mobile{
                //flex-wrap: wrap-reverse;
                justify-content: center;
                .lookbook-page__frame{
                    &:nth-of-type(1) {
                        width: 50%;
                    }
                }
            }
        }
    }

    &__frame{
        width: calc(50% - rem(10));

        @include laptop{
            width: calc(50% - rem(8));
        }
        @include tablet{
            width: calc(50% - rem(5));
        }
        @include mobile{
            width: 100%;
        }

        &_third{
            width: calc(33.3333% - rem(14));
            @include laptop{
                width: calc(33.3333% - rem(11));
            }
            @include tablet{
                width: calc(33.3333% - rem(7));
            }
            @include mobile{
                width: calc(50% - rem(5));
            }
        }

        &_wide{
            width: calc(100% + rem(68));
            margin-left: rem(-34);
            margin-right: rem(-34);

            @include tablet{
                width: calc(100% + rem(34));
                margin-left: rem(-17);
                margin-right: rem(-17);
            }
            @include mobile{
                width: calc(100% + rem(18));
                margin-left: rem(-9);
                margin-right: rem(-9);
            }

            .lookbook-page__card{
                max-height: rem(1110);
            }
            .lookbook-page__image{
                //height: fit-content;
                border-radius: 0;
                max-height: rem(1110);
            }
        }

        &_center{
            width: 100%;

            .lookbook-page__card{
                width: fit-content;
                margin: 0 auto;
                @include laptop{
                    max-width: rem(944);
                }
            }
        }

        &_fix{
            .lookbook-page__card{
                max-width: rem(916);
                @include laptop{
                    max-width: rem(560);
                }
                @include tablet{
                    max-width: rem(390);
                }
            }
        }

        &_text{
            .lookbook-page__card{
                max-width: rem(604);
                flex-direction: column;
                row-gap: rem(16);
                margin: 0 auto;
                @include laptop{
                    max-width: rem(464);
                    margin-right: 0;
                }
                @include tablet{
                    max-width: rem(290);
                    row-gap: rem(12);
                }
                @include mobile{
                    max-width: rem(266);
                    margin-bottom: rem(24);
                }
            }
            .lookbook-page__image{
                margin-bottom: rem(16);
                max-height: rem(815);
                @include laptop{
                    max-height: rem(608);
                }
                @include tablet{
                    max-height: rem(342);
                    margin-bottom: rem(4);
                }
            }
        }

        &_right{
            .lookbook-page__image{
                width: auto;
                margin-left: auto;
                max-height: rem(854);
                @include laptop{
                    max-height: rem(690);
                    max-width: rem(464);
                }
                @include tablet{
                    max-height: rem(430);
                    max-width: rem(250);
                }
                @include mobile{
                    max-width: rem(266);
                    max-height: rem(390);
                }
            }
        }

        &_left{
            .lookbook-page__image{
                width: auto;
                margin-right: auto;
                max-height: rem(854);
                @include laptop{
                    max-height: rem(690);
                    max-width: rem(464);
                }
                @include tablet{
                    max-height: rem(430);
                    max-width: rem(250);
                }
                @include mobile{
                    max-width: rem(266);
                    max-height: rem(390);
                }
            }
        }

        &_small{
            
            @include mobile{
                width: calc(50% - rem(5));
            }

            .lookbook-page__image{
                @include mobile{
                    max-width: rem(174);
                    max-height: rem(274);
                }
            }
        }


    }

    &__card{
        width: 100%;
        display: flex;
    }

    &__image{
        border-radius: rem(12);
        overflow: hidden;
        width: 100%;
        max-height: rem(1270);

        img{
            width: 100%;
            height: 100%;
            display: block;
        }
    }

    &__buttons{
        display: flex;
        justify-content: center;
        width: 100%;
    }

    &__btn{
        max-width: rem(300);
        @include tablet{
            max-width: unset;
        }
    }

    &__title{
        @include laptop{
            font-size: rem(28);
        }
        @include tablet{
            font-size: rem(26);
        }
        @include mobile{
            font-size: rem(20);
        }
    }

}