

.profile-gift{


    &__tab{
        margin-top: rem(48);
        display: flex;
        flex-direction: column;
        //row-gap: rem(48);
        //padding-top: rem(48);
        //border-top: rem(1) solid var(--gray20);

        @include laptop{
            margin-top: rem(32);
        }
        @include tablet{
            margin-top: rem(24);
        }
    }

    &__box{
        display: flex;
        flex-direction: column;
        row-gap: rem(32);
        padding: rem(48) 0;
        border-top: rem(1) solid var(--gray20);
        border-bottom: rem(1) solid var(--gray20);

        @include laptop{
            row-gap: rem(24);
            padding: rem(32) 0;
        }
        @include tablet{
            row-gap: rem(16);
            padding: rem(24) 0;
        }

        & + .profile-gift__box{
            border-top: none;
        }

        & + .profile-gift__note{
            margin-top: rem(32);

            @include laptop{
                margin-top: rem(24);
            }
            @include tablet{
                margin-top: rem(16);
            }
        }

        &_none{
            padding: 0;
            border: none;
        }

    }

    &__flex{
        display: flex;
        justify-content: space-between;
    }

    &__block{
        display: flex;
        flex-direction: column;
        row-gap: rem(16);
        justify-content: flex-end;
    }

    &__note{
        max-width: rem(360);
        margin-top: rem(-8);

        @include laptop{
            max-width: rem(320);
            margin-bottom: rem(8);
        }
        @include tablet{
            margin-top: 0;
            margin-bottom: rem(14);
        }
        @include mobile{
            max-width: rem(240);
        }
    }

    &__title{
        max-width: rem(400);
        @include mobile{
            max-width: rem(260);
        }
    }

}