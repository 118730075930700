.profile-loyalty {
  @include tablet {
    max-width: rem(350);
  }

  @include mobile {
    max-width: none;
  }

  &__card {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    padding: rem(32);
    width: 100%;
    height: rem(340);
    border-radius: rem(20);
    background: linear-gradient(
      96deg,
      #98b8ad 0%,
      #b3cdbf 50.65%,
      #a9bfb3 101.3%
    );

    @include laptop {
      height: rem(260);
      padding: rem(24);
      border-radius: rem(14);
    }

    @include tablet {
      height: rem(196);
    }

    @include mobile {
      height: rem(200);
    }
  }

  &__date {
    font-size: rem(16);
    font-style: normal;
    font-weight: 500;
    line-height: 140%;
    color: var(--black);

    span {
      display: block;
    }

    @include laptop {
      font-size: rem(14);
    }

    @include tablet {
      font-size: rem(12);
    }
  }

  &__info {
    display: flex;
    flex-direction: column;
    gap: rem(16);

    @include laptop {
      gap: rem(8);
    }
  }

  &__discount {
    font-family: "Stapel Expanded", serif;
    font-size: rem(72);
    font-style: normal;
    font-weight: 400;
    line-height: 80%;
    color: var(--white);

    @include laptop {
      font-size: rem(48);
    }

    @include tablet {
      font-size: rem(32);
    }
  }

  &__remains {
    font-size: rem(16);
    font-style: normal;
    font-weight: 500;
    line-height: 140%;
    color: var(--white);

    @include laptop {
      font-size: rem(14);
    }

    @include tablet {
      font-size: rem(12);
    }
  }

  &__grid {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    gap: rem(20);
  }

  &__description {
    margin-block: rem(40);
    font-size: rem(16);
    font-style: normal;
    font-weight: 400;
    line-height: 140%;
    color: var(--gray60);

    @include laptop {
      margin-block: rem(24);
      grid-template-columns: rem(310);
      gap: rem(8);

      font-size: rem(14);
    }

    @include tablet {
      grid-template-columns: rem(260);
      font-size: rem(12);
    }

    @include mobile {
      grid-template-columns: rem(310);
      gap: rem(16);
    }
  }

  &__btns {
    @include tablet {
      grid-template-columns: 1fr;
      gap: rem(8);
    }
  }
}
