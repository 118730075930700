

.contacts{

    &__title{
        @include mobile{
            font-size: rem(16);
        }
    }

    &__top{
        display: flex;
        flex-direction: column;
        row-gap: rem(20);
        margin-bottom: rem(56);

        @include laptop{
            margin-bottom: rem(40);
            row-gap: rem(16);
        }
        @include mobile{
            margin-bottom: rem(32);
            margin-top: rem(40);
        }
    }

    &__rows{
        display: flex;
        flex-direction: column;
        row-gap: rem(8);
    }

    &__text{
        color: var(--gray70);
        max-width: rem(560);

        @include laptop{
            max-width: rem(500);
        }

        a{
            color: var(--gray70);
        }

        span{
            color: var(--black);
        }
    }

    &__box{
        display: flex;
        justify-content: space-between;
        column-gap: rem(20);

        @include laptop{
            column-gap: rem(16);
        }
        @include tablet{
            column-gap: rem(10);
        }
        @include mobile{
            flex-wrap: wrap;
            row-gap: rem(40);
        }
        
        &_mt{
            margin-top: rem(64);
            @include laptop{
                margin-top: rem(48);
            }
            @include tablet{
                margin-top: rem(40);
            }
            @include mobile{
                margin-top: rem(32);
                row-gap: rem(24);
            }
        }
    }

    &__left{
        width: 50%;
        display: flex;
        flex-direction: column;
        row-gap: rem(32);
        @include laptop{
            row-gap: rem(24);
        }
        @include mobile{
            width: 100%;
            row-gap: rem(16);
        }

        &_row{
            flex-direction: row;
            flex-wrap: wrap;
            @include mobile{
                row-gap: rem(24);
            }
        }
    }

    &__right{
        width: 50%;
        display: flex;
        flex-direction: column;
        row-gap: rem(32);
        @include laptop{
            row-gap: rem(24);
        }
        @include mobile{
            width: 100%;
            row-gap: rem(16);
        }

        &_row{
            flex-direction: row;
            flex-wrap: wrap;
        }
    }

    &__block{
        display: flex;
        flex-direction: column;
        row-gap: rem(16);
        min-width: 50%;
        @include laptop{
            width: 100%;
            row-gap: rem(8);
        }

        &_head{
            @include laptop{
                row-gap: rem(16);
                margin-bottom: rem(-8);
            }       
            @include mobile{
                margin-bottom: 0;
                row-gap: rem(8);
            }
        }
    }

    &__block-title{
        font-size: rem(24);
        @include laptop{
            font-size: rem(18);
        }
        @include tablet{
            font-size: rem(16);
        }
        @include mobile{
            font-size: rem(16);
        }
    }

    &__map{
        margin-top: auto;
        //margin-bottom: rem(64);
        background: #eee;
        height: rem(571);
        width: 100%;
        border-radius: rem(12);
        overflow: hidden;

        @include laptop{
            height: rem(348);
        }
        @include tablet{
            height: rem(232);
        }
    }

}