.main-first{
    //margin-top: rem(100);

    @include laptop{
        //margin-top: rem(90);
    }
    @include tablet{
        //margin-top: rem(76);
    }
    @include mobile{
        //margin-top: rem(90);
    }

    &__title{
        max-width: rem(1200);
        @include mobile{
            font-size: rem(20);
        }
    }

    &__swiper{
        width: 100%;
        height: calc(100svh);
        max-height: rem(960);
        position: relative;
        z-index: 1;

        @include laptop{
            max-height: rem(600);
            height: calc(100svh);
        }
        @include tablet{
            max-height: rem(800);
            height: calc(100svh);
        }
        @include mobile{
            max-height: rem(500);
            height: calc(100svh);
        }

        .swiper-pagination{
            height: rem(20);
            bottom: rem(22);
            .swiper-pagination-bullet{
                width: rem(72);
                height: rem(4);
                border-radius: rem(90);
                margin: rem(8) rem(6);
                background: var(--black);
                transition-duration: 0.3s;
                @include tablet{
                    width: rem(35);
                    margin: rem(8) rem(2.5);
                    height: rem(2);
                }
            }
            .swiper-pagination-bullet-active{
                background: var(--white);
            }
        }

    }

    &__swiper-slide{
        display: flex;

        &::before{
            content: '';
            position: absolute;
            width: 100%;
            height: 100%;
            background: linear-gradient(180deg, rgba(0, 0, 0, 0) 0%, rgba(0, 0, 0, 0.3) 100%);
            top: 0;
            left: 0;
            z-index: 2;
        }

        img{
            width: rem(1920);
        }
    }

    &__swiper-half{
        width: 50%;

        @include tablet{
            width: 100%;
        }

        img{
            width: 100%;
            height: 100%;
        }

        video{
            height: 100%;
            width: auto;
        }

        &_left{
            @include tablet{
                display: none;
            }
        }

    }

    &__swiper-content{
        position: absolute;
        width: 100%;
        height: 100%;
        top: 0;
        left: 0;
        display: flex;
        align-items: center;
        justify-content: center;
        flex-direction: column;
        z-index: 2;
        //pointer-events: none;
        row-gap: rem(32);

        @include laptop{
            row-gap: rem(24);
        }
    }

}