.profile-favourite {

    @include tablet{
        max-width: rem(350);
        margin-left: auto;
    }

    &__top{
        display: flex;
        flex-direction: column;
        row-gap: rem(8);
        
        //padding-bottom: rem(24);
        margin-bottom: rem(24);

        @include laptop{
            margin-bottom: rem(16);
        }
        @include tablet{
            //margin-bottom: rem(24);
        }
        @include mobile{
            //margin-top: rem(8);
        }
        
    }

    &__tabs {
        display: flex;
        gap: rem(16);
        
        padding-top: rem(32);
        margin-bottom: rem(40);
        border-top: rem(1) solid var(--gray20);

        @include laptop {
            gap: rem(10);
            margin-bottom: rem(32);
        }

        @include tablet {
            //justify-content: space-between;
            overflow-x: auto;
            padding-bottom: rem(10);
            margin-bottom: rem(12);
            //width: rem(487);
            margin-right: rem(-17);
            padding-right: rem(17);
            gap: rem(8);
            padding-top: rem(24);
        }

        @include mobile {
            //width: 100vw;
            gap: rem(6);
            margin-bottom: rem(22);
            padding-top: rem(20);
        }
    }

    &__tab {
        user-select: none;
        color: var(--gray50);
        font-size: rem(14);
        font-style: normal;
        font-weight: 400;
        line-height: 140%;
        cursor: pointer;

        padding: rem(8) rem(12);
        border-radius: rem(8);
        border: rem(1) solid var(--gray00);
        transition: all 0.3s ease;

        &:has(.profile-favourite__tab-radio:checked) {
            background: var(--gray10);
            border-color: transparent;
            color: var(--black);
        }

        @include tablet {
            padding: rem(6) rem(10);
            flex: 0 0 auto;
            font-size: rem(12);
        }
    }

    &__tab-radio {
        display: none;
    }



    &__banner{
        display: none !important;
        position: relative;
        display: flex;
        align-items: center;
        margin-bottom: rem(48);
        background: var(--white);
        padding: rem(32);
        border-radius: rem(20);
        border: rem(1) solid var(--gray10);
        overflow: hidden;
        height: rem(89);
        transition-duration: 0.3s;

        @include laptop{
            margin-bottom: rem(32);
            border-radius: rem(18);
        }
        @include tablet{
            padding: rem(20);
            height: rem(80);
            border-radius: rem(14);
        }
        @include mobile{
            padding: rem(20) rem(12);
            margin-bottom: rem(24);
            margin-top: rem(16);
            height: rem(57);
            border-radius: rem(12);
        }
        
        &_open{
            height: rem(119);

            .profile-favourite__banner-inner{
                row-gap: rem(8);
            }
            .profile-favourite__banner-image{
                opacity: 1;
            }
            .profile-favourite__banner-text{
                opacity: 1;
                height: auto;
            }
            .profile-favourite__banner-link{
                span{
                    margin-top: 0;
                    &:nth-last-of-type(1) {
                        display: none;
                    }
                    &:nth-last-of-type(2) {
                        display: block;
                    }
                }
            }
            .profile-favourite__banner-link img{
                transform: scaleY(-1);
            }
        }

    }

    &__banner-inner{
        display: flex;
        flex-direction: column;
        row-gap: rem(0);

        @include laptop{
            max-width: rem(330);
        }
        @include tablet{
            max-width: rem(170);
        }
        @include mobile{
            max-width: rem(252);
        }
    }

    &__banner-title{
        display: flex;
        align-items: center;
        column-gap: rem(4);

        @include tablet{
            display: block;
        }
        

        img{
            width: rem(24);
            height: rem(24);
            @include tablet{
                display: inline-block;
                width: rem(20);
                height: rem(20);
                margin-bottom: rem(-4);
            }
        }
    }

    &__banner-text{
        opacity: 0;
        height: 0;
        transition-duration: 0.3s;

        @include mobile{
            max-width: rem(210);
        }
    }

    &__title{
        @include laptop{
            font-size: rem(20);
        }
        @include tablet{
            font-size: rem(18);
        }
        @include mobile{
            font-size: rem(18);
        }
    }

    &__banner-image{
        position: absolute;
        right: rem(120);
        top: rem(5);
        transform: rotate(10deg);
        width: rem(322);
        height: rem(210);
        transition-delay: 0.2s;
        transition-duration: 0.3s;
        opacity: 0;

        @include laptop{
            width: rem(280);
            height: rem(190);
            right: rem(80);
        }
        @include tablet{
            width: rem(200);
            height: rem(120);
            right: rem(-50);
            top: rem(70);
        }
        @include mobile{
            width: rem(160);
            height: rem(90);
            top: rem(70);
            right: rem(-20);
        }
    }

    &__banner-link{
        display: flex;
        align-items: center;
        column-gap: rem(4);
        text-transform: uppercase;
        position: absolute;
        top: rem(32);
        right: rem(32);

        @include laptop{
            top: rem(34);
        }
        @include tablet{
            top: rem(20);
            right: rem(20);
        }
        @include mobile{
            top: rem(20);
            right: rem(12);
        }

        span{
            margin-top: rem(2);
            &:nth-last-of-type(1) {
                display: block;
            }
            &:nth-last-of-type(2) {
                display: none;
            }
        }

        img{
            width: rem(20);
            height: rem(20);
            opacity: 0.6;
            transition-duration: 0.3s;
            transform: scaleY(1);
        }
    }

    &__box{
        margin-top: rem(32);
        display: flex;
        gap: rem(20);
        flex-wrap: wrap;

        @include laptop{
            gap: rem(16);
            margin-top: rem(24);
        }
        @include tablet{
            gap: rem(20) rem(10);
            margin-top: rem(24);
        }
        @include mobile{
            gap: rem(16) rem(10);
            margin-top: rem(16);
        }

        .block-product__card{
            width: rem(292);
            position: relative;
            @include laptop{
                width: rem(176);
            }
            @include tablet{
                width: rem(170);
            }
            @include mobile{
                width: rem(167);
            }
        }
        .block-product__card-cover{
            width: rem(292);
            @include laptop{
                width: rem(176);
            }
            @include tablet{
                width: rem(170);
            }
            @include mobile{
                width: rem(167);
            }
        }
        .block-product__card-favourite{
            position: absolute;
            top: rem(16);
            right: rem(16);
            width: rem(24);
            height: rem(24);

            @include laptop{
                top: rem(12);
                right: rem(12);
            }

            img{
                width: 100%;
                height: 100%;
            }
        }
    }


}