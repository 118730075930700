

.modal{
    position: fixed;
    width: 100%;
    height: 100%;
    left: 0;
    top: 0;
    background: var(--gray50);
    //z-index: 60;
    //display: none;
    display: flex;
    z-index: 60;
    pointer-events: none;
    opacity: 0;
    transition-duration: 0.3s;
    transition-delay: 0.25s;

    *{
        pointer-events: none;
    }

    &_open{
        z-index: 60;
        pointer-events: all;
        opacity: 1;

        *{
            pointer-events: all;
        }

        .modal__inner{
            right: 0;
        }

        &[data-modal='cookies'] {
            z-index: 70;
        }

        &[data-modal='askCity'] {
            @include laptop{
                display: none;
            }
        }

    }

    &_dark{
        //background: var(--black);
    }

    &__inner{
        transition-duration: 0.3s;
        background: var(--grayBg);
        position: absolute;
        width: 100%;
        height: 100%;
        top: 0;
        right: -100%;
        //padding: rem(56) rem(58);
        max-width: rem(658);
        overflow: hidden;

        /* @include mobile{
            padding: rem(40) rem(9);
        } */

        @include laptop{
            max-width: rem(420);
        }
        @include tablet{
            max-width: rem(420);
        }
        @include mobile{
            max-width: rem(420);
        }

        &_hello{
            padding: rem(24);
            display: flex;
            align-items: center;
            justify-content: space-between;
            height: auto;
            text-transform: uppercase;
            top: rem(20);
            margin-right: rem(20);
            font-weight: 500;
            max-width: rem(604);
            border-radius: rem(6);

            @include laptop{
                top: rem(15);
                max-width: rem(384);
            }
            @include tablet{
                padding: rem(20) rem(16);
                top: rem(10);
                margin-right: rem(10);
            }
            @include mobile{
                top: rem(20);
                width: calc(100% - rem(20));
                margin-right: rem(10);
            }

            .modal__close{
                top: rem(12);
                border: none;
                @include laptop{
                    top: rem(10);
                }
                @include tablet{
                    right: rem(2);
                    top: rem(6);

                    img{
                        width: rem(20);
                        height: rem(20);
                    }
                }
                @include mobile{
                    top: rem(4);
                }
            }
        }

        &_search{
            max-width: unset;
            width: 100%;

            .modal__close{
                right: 50%;
                transform: translateX(50%);
            }

        }

        &_more{
            max-width: unset;
            width: 100%;

            .modal__title{
                max-width: rem(546);
                margin-right: auto;
                @include laptop{
                    max-width: rem(422);
                }
                @include tablet{
                    max-width: rem(372);
                }
                @include mobile{
                    max-width: rem(302);
                }
            }
            .modal__box{
                padding: rem(128) rem(34);

                @include laptop{
                    padding: rem(102) rem(32);
                }
                @include tablet{
                    padding: rem(80) rem(17) rem(48) rem(17);
                    row-gap: rem(16);
                }
                @include mobile{
                    padding: rem(80) rem(9);
                    row-gap: rem(12);
                }
            }
        }

        &_ask-city{
            border-radius: rem(6);
            padding: rem(20) rem(16);
            max-width: rem(380);
            height: auto;

            row-gap: rem(16);
            display: flex;
            align-items: center;
            overflow: visible;
            
            
            top: rem(120);
            left: rem(170);

            @include laptop{
                left: rem(40);
                top: rem(100);
            }
            @include tablet{
                max-width: 100%;
                left: 0;
                top: rem(124);
                padding: rem(16);
                flex-wrap: wrap;
                justify-content: center;
                row-gap: rem(10);
            }
            @include mobile{
                top: rem(134);
                padding: rem(17) rem(16);
            }
            
            &::before{
                content: '';
                background: #000;
                position: absolute;
                width: rem(20);
                height: rem(20);
                top: 0;
                left: 50%;
                transform: translateY(-50%) translateX(-50%) rotate(45deg);
                z-index: -1;
                background: var(--grayBg);
                @include tablet{
                    display: none;
                }
            }
            
        }

        &_cookies{
            border-radius: rem(12);
            padding: rem(32);
            max-width: rem(440);
            height: auto;
            row-gap: rem(16);
            display: flex;
            flex-direction: column;
            align-items: center;
            top: unset;
            bottom: rem(32);
            margin-right: rem(32);

            @include laptop{
                max-width: rem(420);
                .modal__text{
                    max-width: rem(320);
                }
            }
            @include tablet{
                margin-right: unset;
                right: unset !important;
                left: calc(50% - rem(210));
                .modal__text{
                    max-width: rem(270);
                }
            }
            @include mobile{
                padding: rem(24);
                max-width: rem(358);
                bottom: rem(8);
                left: calc(50% - rem(179));
            }
        }

        &_banner{
            width: 100%;
            height: auto;
            max-width: rem(916);
            min-height: rem(480);
            right: unset;
            left: 50%;
            top: 50%;
            transform: translate(-50%,-50%);
            padding: rem(95) rem(156);
            display: flex;
            flex-direction: column;
            justify-content: center;
            align-items: center;
            row-gap: rem(32);
            border-radius: rem(12);
            overflow: hidden;
            background: transparent;

            @include laptop{
                padding: rem(115) rem(120);
                max-width: rem(848);
            }
            @include tablet{
                max-width: rem(590);
                padding: rem(90) rem(60);
                min-height: rem(410);
            }
            @include mobile{
                max-width: calc(100% - rem(18));
                min-height: rem(396);
                padding: rem(40) rem(9);
                display: flex;
                flex-direction: column;
                justify-content: center;
                row-gap: rem(24);
            }
        }

        &_city{
            width: 100%;
            height: auto;
            max-width: rem(916);
            min-height: rem(480);
            right: unset;
            left: 50%;
            top: 50%;
            transform: translate(-50%,-50%);
            //padding: rem(95) rem(156);
            //padding: 0;
            //padding: rem(45) 0;
            display: flex;
            flex-direction: column;
            justify-content: center;
            align-items: center;
            row-gap: rem(24);
            border-radius: rem(12);
            overflow: visible;

            .modal__title{
                transition: all 0.3s;
            }

            &:has( .select_open ) {
                //padding-bottom: 0;
                //justify-content: flex-start;

                .modal__title{
                    transform: translateY(rem(-140));
                    @include tablet{
                        transform: translateY(rem(-120));
                    }
                }
                .select{
                    transform: translateY(rem(-140));
                    @include tablet{
                        transform: translateY(rem(-120));
                    }
                }
            }

            .select{
                transition: all 0.3s;
                margin-top: rem(8);
                max-width: rem(511);
                @include laptop{
                    max-width: rem(440);
                }
            }

            .select__list{
                position: absolute;
            }

            @include laptop{
                //padding: rem(115) rem(120);
                max-width: rem(848);
                row-gap: rem(16);
            }
            @include tablet{
                max-width: rem(590);
                //padding: rem(90) rem(60);
                min-height: rem(410);
                .select__list{
                    max-height: rem(230);
                }
            }
            @include mobile{
                width: 100%;
                height: 100%;
                border-radius: 0;
                padding: rem(40) rem(9);
                display: flex;
                flex-direction: column;
                justify-content: center;

                .select{
                    margin-top: unset;
                }
                .select__list{
                    max-height: rem(217);
                }
            }
        }

        &_thanks{
            width: 100%;
            height: auto;
            max-width: rem(916);
            min-height: rem(480);
            right: unset;
            left: 50%;
            top: 50%;
            transform: translate(-50%,-50%);
            padding: rem(95) rem(156);
            display: flex;
            flex-direction: column;
            justify-content: center;
            align-items: center;
            row-gap: rem(24);
            border-radius: rem(12);
            overflow: hidden;

            .btn{
                margin-top: rem(8);
            }

            @include laptop{
                padding: rem(115) rem(120);
                max-width: rem(848);
                row-gap: rem(16);
            }
            @include tablet{
                max-width: rem(590);
                padding: rem(90) rem(60);
                min-height: rem(410);
            }
            @include mobile{
                width: 100%;
                height: 100%;
                border-radius: 0;
                padding: rem(40) rem(9);
                display: flex;
                flex-direction: column;
                justify-content: center;

                .btn{
                    margin-top: unset;
                }
            }
        }

        &_center{
            width: 100%;
            height: auto;
            max-width: rem(916);
            right: unset;
            left: 50%;
            top: 50%;
            transform: translate(-50%,-50%);
            padding: rem(95) rem(156);
            border-radius: rem(12);
            @include laptop{
                padding: rem(115) rem(120);
                max-width: rem(848);
            }
            @include tablet{
                max-width: rem(590);
                padding: rem(90) rem(60);
            }
            @include mobile{
                height: 100%;
                padding: rem(40) rem(9);
                display: flex;
                flex-direction: column;
                justify-content: center;
                border-radius: 0;
            }
        }

        &_filters{
            max-width: rem(420);
            padding: rem(49) rem(50);
            display: flex;
            flex-direction: column;
            overflow-y: auto;

            @include mobile{
                padding: rem(40) rem(9);
            }

            .modal__title{
                font-size: rem(22);
                color: var(--black);
                text-align: left;
        
                @include mobile{
                    font-size: rem(20);
                }
            }

            .modal__buttons{
                margin-top: auto;
                column-gap: rem(8);
                @include mobile{
                    max-width: unset;
                }
            }
            .btn{
                padding-left: 0;
                padding-right: 0;
            }
            .select__value{
                @include tablet{
                    max-height: rem(35);
                }
                @include mobile{
                    max-height: rem(34);
                }
            }

        }

        &_sizes{
            position: absolute;
            bottom: 0;
            top: unset;
            //width: 100%;
            max-width: unset;
            height: auto;
            
            padding: rem(32) rem(17);
            border-radius: rem(12) rem(12) 0 0;

            @include mobile{
                padding: rem(16) rem(9);
                padding-bottom: rem(8);
            }
        }
    }

    &__close{
        position: absolute;
        display: flex;
        justify-content: center;
        align-items: center;
        border-radius: rem(6);
        border: rem(1) solid var(--gray10);
        width: rem(40);
        height: rem(40);
        top: rem(16);
        right: rem(16);
        z-index: 70;
        background: var(--grayBg);
        cursor: pointer;
        transition-duration: 0.3s;

        @include mobile{
            top: rem(8);
            right: rem(8);
        }
        
        img{
            width: rem(24);
            height: rem(24);
            pointer-events: none;
        }

        &:hover{
            background: var(--white);
        }
    }

    &__buttons{
        display: flex;
        justify-content: space-between;
        column-gap: rem(16);
        max-width: rem(476);
        width: 100%;
        margin-top: rem(16);

        @include laptop{
            max-width: rem(384);
        }
        @include tablet{
            max-width: rem(384);
        }
        @include mobile{
            max-width: rem(236);
            margin-top: rem(8);
        }

    }

    &__cover{
        position: absolute;
        width: 100%;
        height: 100%;
        top: 0;
        left: 0;
        z-index: -1;

        img{
            width: 100%;
            height: 100%;
        }

        &::after{
            content: '';
            background: #000;
            opacity: 0.3;
            width: 110%;
            height: 110%;
            position: absolute;
            top: -5%;
            left: -5%;
        }
    }

    &__title{
        color: var(--black);
        text-align: left;
        @include laptop{
            font-size: rem(28);
        }
        @include tablet{
            font-size: rem(26);
        }
        @include mobile{
            font-size: rem(20);
            max-width: rem(325);
        }
        &_center{
            text-align: center;
            @include mobile{
                max-width: unset;
            }
        }
        &_fix{
            max-width: rem(330);
        }
        &_white{
            color: var(--white);
        }
    }

    &__subtitle{
        color: var(--black);
        text-align: left;
        @include laptop{
            font-size: rem(18);
        }
        @include tablet{
            font-size: rem(16);
            margin-bottom: rem(-8);
        }
        @include mobile{
            font-size: rem(16);
        }
    }

    &__box{
        padding: rem(56) rem(58);
        overflow-y: auto;
        position: absolute;
        width: 100%;
        height: 100%;
        display: flex;
        flex-direction: column;
        row-gap: rem(24);

        @include laptop{
            padding: rem(40) rem(50);
        }
        @include mobile{
            padding: rem(40) rem(8);
        }

        &_stock{
            
            .modal__card{
                margin-bottom: rem(24);
                @include laptop{
                    padding-bottom: rem(60);
                    column-gap: rem(16);
                    margin-bottom: rem(8);
                }
                @include tablet{
                    margin-bottom: 0;
                }
            }
            .modal__card-flex{
                @include laptop{
                    margin-top: auto;
                }
            }
            .modal__card-cover{
                width: rem(128);
                height: rem(170);
                @include laptop{
                    width: rem(96);
                    height: rem(122);
                }
                @include tablet{
                    width: rem(76);
                    height: rem(100);
                }
            }
            .modal__card-title{
                margin-bottom: rem(8);
                @include laptop{
                    font-size: rem(18);
                }
                @include tablet{
                    font-size: rem(16);
                }
            }
        }

        &_certificate{

            .certificate__explain{
                max-width: unset;
            }
            .certificate__explain_fix{
                max-width: rem(300);
            }
            
            @include laptop{


                .certificate__explain{
                    max-width: rem(250);
                }
                .variants{
                    gap: rem(8);
                }
                .variants__label{
                    padding: rem(7.5);
                    border-radius: rem(8);
                }
            }
            @include mobile{
                .certificate__explain{
                    max-width: unset;
                    margin-top: rem(-4);
                }
                .certificate__explain_fix{
                    max-width: rem(220);
                }
                .variants{
                    gap: rem(12);
                }
            }
        }

        &_search{
            
            @include laptop{
                padding: rem(40) rem(32);
            }
            @include tablet{
                padding: rem(40) rem(17);
            }

            @include mobile{
                padding: rem(40) rem(8);
            }
        }

        &_gap{
            row-gap: rem(56);
            @include laptop{
                row-gap: rem(40);
            }
            @include mobile{
                row-gap: rem(24);
            }
        }

        &_gift{
            row-gap: rem(32);

            @include laptop{
                row-gap: rem(24);
            }

            .modal__title{
                max-width: rem(404);
                @include tablet{
                    max-width: rem(304);
                }
                @include mobile{
                    max-width: rem(234);
                }
            }
        }

        &_product-info{
            .modal__subtitle{
                max-width: rem(300);
                @include laptop{
                    max-width: rem(200);
                }
            }
        }

        &_picksize{
            row-gap: rem(56);
            @include laptop{
                row-gap: rem(36);
            }
            @include tablet{
                row-gap: rem(36);
            }
            @include mobile{
                row-gap: rem(24);
            }
        }

    }

    &__form{
        display: flex;
        flex-direction: column;
        row-gap: rem(24);
        @include laptop{
            row-gap: rem(16);
        }
    }

    &__form-title{
        
    }

    &__form-text{
        text-align: center;
        max-width: rem(480);
        margin: 0 auto;
        @include laptop{
            max-width: rem(400);
        }
        @include tablet{
            max-width: rem(356);
        }
        @include mobile{
            max-width: rem(300);
        }

        &_fix{
            max-width: rem(420);
            @include laptop{
                max-width: rem(370);
            }
            @include tablet{
                max-width: rem(320);
            }
            @include mobile{
                max-width: rem(270);
            }
        }
    }

    &__form-fields{
        margin-top: rem(8);
        display: flex;
        flex-wrap: wrap;
        gap: rem(12) rem(20);
        @include tablet{
            gap: rem(12) rem(10);
        }
        @include mobile{
            margin-top: 0;
        }
    }

    &__form-block{
        width: calc(50% - rem(10));
        @include tablet{
            width: calc(50% - rem(5));
        }
        @include mobile{
            width: 100%;
        }

        &_wide{
            width: 100%;
            
        }

        textarea{
            height: rem(110);
            min-height: rem(56);
            max-height: rem(134);
            @include laptop{
                height: rem(100);
                min-height: rem(43);
                max-height: rem(124);
            }
            @include tablet{
                height: rem(72);
                min-height: rem(45);
                max-height: rem(152);
            }
            @include mobile{
                height: rem(72);
                min-height: rem(45);
                max-height: rem(136);
            }
        }

    }

    &__form-note{
        color: var(--gray50);
        margin-top: rem(-16);

        @include laptop{
            margin-top: rem(-8);
        }

        a{
            color: var(--gray50);
        }
    }

    &__information{
        display: flex;
        flex-direction: column;
        row-gap: rem(24);
        @include laptop{
            row-gap: rem(16);
        }
        .modal__subtitle{
            @include laptop{
                margin-bottom: rem(8);
            }
            @include mobile{
                margin-bottom: 0;
            }
        }
    }

    &__information-block{
        display: flex;
        flex-direction: column;
        row-gap: rem(8);
    }

    &__information-link{
        color: var(--gray60);
    }

    &__information-gray{
        color: var(--gray60);
    }

    &__information-flex{
        display: flex;
        justify-content: space-between;
        align-items: flex-end;
    }

    &__information-sale{
        text-align-last: left;
        font-size: rem(36);
        @include laptop{
            font-size: rem(28);
        }
        @include tablet{
            font-size: rem(26);
        }
        @include mobile{
            font-size: rem(20);
        }
    }

    &__information-limit{
        color: var(--gray60);
        @include mobile{
            font-size: rem(14);
        }
    }



    &__product{
        display: flex;
        flex-wrap: wrap;
        row-gap: rem(16);
    }
    
    &__product-column{
        width: 50%;
        display: flex;
        flex-direction: column;
        row-gap: rem(16);
        
        &_wide{
            width: 100%;
        }
    }

    &__product-block{
        max-width: rem(500);
    }

    &__product-label{
        color: var(--gray40);
    }

    &__product-text{
        
    }
    
    &__product-image{
        margin: 0;
        margin-left: auto;
        width: 100%;
        max-width: rem(242);
        max-height: rem(320);

        @include laptop{
            max-width: rem(118);
            max-height: rem(155);
        }
        @include mobile{
            max-width: rem(127);
        }
        
        //overflow: hidden;

        img{
            display: block;
            border-radius: rem(16);
            width: 100%;
            height: 100%;
        }
    }

    &__product-caption{
        text-align: center;
        text-transform: uppercase;
        padding: rem(10);
        font-weight: 500;
        color: var(--gray60);
        @include laptop{
            padding: rem(7) rem(10);
        }
        @include laptop{
            padding: rem(7) rem(23);
        }
    }




    &__sizes{
        display: flex;
        flex-direction: column;
        row-gap: rem(32);
        @include mobile{
            row-gap: rem(24);
        }
    }

    &__sizes-close{
        right: rem(17);
        top: rem(28);
        border: none;
        width: rem(24);
        height: rem(24);
        @include mobile{
            right: rem(8);
            top: rem(16);
        }
    }

    &__sizes-title{
        @include tablet{
            font-size: rem(18);
        }
        @include mobile{
            font-size: rem(16);
        }
    }

    &__sizes-table{
        display: flex;
        flex-wrap: wrap;
        gap: rem(8);
        @include mobile{
            gap: rem(8) rem(8);
        }
    }

    &__sizes-label{
        cursor: pointer;
        text-align: center;
        width: rem(81);
        height: rem(38);
        display: flex;
        justify-content: center;
        align-items: center;
        border-radius: rem(10);
        user-select: none;
        border: rem(1) solid var(--gray00);
        color: var(--gray60);
        font-weight: 400;
        font-size: rem(12);

        @include mobile{
            width: rem(83);
            height: rem(38);
            border-radius: rem(8);
        }

        input{
            position: absolute;
            width: rem(16);
            height: rem(16);
            opacity: 0;
            pointer-events: none;
        }

        &:has(input:checked){
            background: var(--gray10);
            border: rem(1) solid transparent;
        }

    }



    &__price{
        margin-top: rem(24);
    }

    &__price-top{
        display: flex;
        justify-content: space-between;
    }

    &__price-title{
        font-size: rem(12);
    }

    &__price-clear{
        font-size: rem(12);
        margin-right: unset;
        color: var(--gray50);
        border-bottom: rem(1) solid var(--gray50);
    }

    &__price-slider{
        height: rem(18);
        display: flex;
        align-items: center;
        margin-top: rem(24);
        margin-bottom: rem(20);
        
        /* .ui-widget.ui-widget-content{
            height: rem(4) !important;
            border: none !important;
            background: var(--gray10) !important;
            width: 100%;
        }
        
        .ui-slider {
            //width: calc(100% - 8px);
            //left: 4px;
            //bottom: -1px;           
        }
        
        .ui-slider-range {
            background: var(--black) !important;
        }
        
        .ui-slider-handle {
            border-radius: 30px !important;
            background: var(--black) !important;
            border: rem(5) solid var(--white) !important;
            width: rem(18) !important;
            height: rem(18) !important;
            outline: none !important;
            margin-top: -3px !important;
            cursor: pointer !important;
            box-shadow: 0 3px 10px 0px rgba(0, 0, 0, 0.1);
        } */

    }

    &__price-box{
        display: flex;
        justify-content: space-between;
        column-gap: rem(10);
    }

    &__price-half{
        width: calc(50% - rem(5));
        position: relative;
    }

    &__price-label{
        font-size: rem(10);
        line-height: 1.4;
        color: var(--gray30);
        text-transform: uppercase;
    }

    &__price-input{
        width: 100%;
        margin-top: rem(6);
        height: rem(44);
        font-weight: 500;
        color: var(--black);
        padding: 0 rem(20);
        font-size: rem(12);
        outline: none;
        border: rem(1) solid var(--gray30);
        background: var(--grayBg);
        border-radius: rem(8);
    }


    &__selects{
        margin-top: rem(24);
        margin-bottom: rem(24);
        display: flex;
        flex-direction: column;
        row-gap: rem(8);

        .select__list{
            //position: relative;
        }
        .select_drop::after{
            top: rem(7);
            transform: translateY(0);
        }
        .select_open{
            &::after{
                transform: translateY(0) scaleY(-1);
            }
        }

    }


    &__tabs{
        margin-top: rem(-22);
        @include laptop{
            margin-top: 0;
        }
    }

    &__map{
        width: 100%;
        background: var(--black);
        height: rem(538);
        border-radius: rem(12);
        margin: rem(56) 0;

        @include laptop{
            height: rem(270);
            margin-bottom: rem(24);
        }
        @include mobile{
            height: rem(358);
        }
    }

    &__shop{
        display: flex;
        flex-direction: column;
        row-gap: rem(16);
        //margin-top: rem(32);
    }

    &__shop-title{
        @include laptop{
            max-width: rem(340);
        }
        @include mobile{
            font-size: rem(16);
        }
    }

    &__shop-block{
        display: flex;
        flex-direction: column;
        row-gap: rem(8);
    }

    &__text{
        &_gray{
            color: var(--gray70);
        }
    }

    &__shop-image{
        width: 100%;
        height: rem(320);
        border-radius: rem(20);
        overflow: hidden;
        
        img{
            width: 100%;
            height: 100%;
        }

        @include laptop{
            height: rem(186);
            border-radius: rem(14);
        }
        @include mobile{
            height: rem(232);
        }
    }

    &__shop-btn{
        margin-top: rem(24);
    }


    &__list{
        display: flex;
        flex-direction: column;
        row-gap: rem(16);
        width: 100%;
        //max-height: rem(520);
        //overflow-y: scroll;
        //width: calc(100% + rem(15));
        //padding-right: rem(5);

        @include mobile{
            //padding-right: rem(5);
            //width: calc(100% + rem(0));
        }
        @include tablet{
            //max-height: rem(420);
        }
    }

    &__list-item{
        padding: rem(24);
        border-radius: rem(16);
        border: rem(1) solid var(--gray20);
        display: flex;
        justify-content: space-between;
        align-items: center;

        @include laptop{
            padding: rem(16);
            flex-wrap: wrap;
            row-gap: rem(8);
        }
    }

    &__list-text{
        max-width: rem(320);

        &:has(+ &){
            margin-bottom: rem(-8);
        }

    }

    &__list-link{
        margin-right: unset;
        cursor: pointer;
    }



    &__addition{
        position: relative;
        width: 100%;
        overflow: unset;
        overflow-x: clip;
        margin-bottom: rem(24);
        margin-top: rem(56);

        @include laptop{
            margin-top: rem(16);
            margin-bottom: 0;
        }
        @include mobile{
            margin-top: 0;
        }

        .block-product__card{
            margin-right: rem(12);
            
            @include laptop{
                margin-right: rem(8);
            }
            
            &:nth-last-of-type(1) {
                margin-right: 0;
            }
        }

        .block-product__card-cover{
            width: 100%;
        }

        .block-product__card{
            width: rem(259);
            @include laptop{
                width: rem(155);
            }
            @include mobile{
                width: rem(174);
            }
        }

        .select__value{
            @include mobile{
                padding: rem(8) rem(16);
            }
        }

        .block-product__card-cart{
            @include mobile{
                width: rem(34);
                height: rem(34);

                img{
                    width: rem(18);
                    height: rem(18);
                }
            }
        }

    }

    &__addition-top{
        display: flex;
        justify-content: space-between;
        align-items: center;
        margin-bottom: rem(16);
    }

    &__addition-title{
        text-align: left;
        @include laptop{
            font-size: rem(18);
        }
        @include mobile{
            font-size: rem(16);
        }
    }

    &__addition-controls{
        display: flex;
    }

    &__addition-next,
    &__addition-prev{
        width: rem(24);
        height: rem(24);
        cursor: pointer;

        &.swiper-button-disabled{
            opacity: 0.5;
        }

        img{
            width: 100%;
            height: 100%;
        }
    }

    &__addition-filters{
        display: flex;
        //flex-wrap: wrap;
        gap: rem(8);
        margin-bottom: rem(32);
        @include laptop{
            gap: rem(4);
            margin-bottom: rem(24);
        }
  
    }

    &__addition-check{
        width: auto;
        height: rem(36);
        display: flex;
        align-items: center;
        justify-content: center;
        font-size: rem(14);
        padding: 0 rem(16);
        cursor: pointer;
        color: var(--gray60);
        font-weight: 400;
        background: var(--grayBg);
        border: rem(1) solid var(--gray30);
        border-radius: rem(8);
        outline: none;
        user-select: none;

        @include laptop{
            font-size: rem(12);
            padding: 0 rem(12);
            height: rem(32);
            width: 100%;
        }
        @include mobile{
            width: auto;
        }

        &:has(input:checked){
            color: var(--black);
            font-weight: 500;
            background: var(--gray00);
            border: rem(1) solid var(--gray00);
        }

        input{
            pointer-events: none;
            opacity: 0;
            position: absolute;
        }
    }


    &__cart{
        display: flex;
        flex-direction: column;
        row-gap: rem(32);

        @include laptop{
            row-gap: rem(24);
        }

    }

    &__card{
        display: flex;
        column-gap: rem(24);
        position: relative;
        @include laptop{
            column-gap: rem(28);
        }

        &_disabled{
            .modal__card-cover{
                opacity: 0.4;
            }
        }
    }

    &__card-cover{
        width: rem(120);
        height: rem(160);
        border-radius: rem(10);
        overflow: hidden;
        flex-shrink: 0;

        @include laptop{
            width: rem(50);
            height: rem(67);
        }

        img{
            width: 100%;
            height: 100%;
        }
    }

    &__card-content{
        width: 100%;
        display: flex;
        flex-direction: column;
    }

    &__card-title{
        max-width: rem(300);
        margin-bottom: rem(4);
        @include laptop{
            font-size: rem(20);
        }
        @include tablet{
            font-size: rem(18);
        }
        @include mobile{
            font-size: rem(16);
            max-width: rem(220);
        }
    }

    &__card-flex{
        display: flex;
        justify-content: space-between;
        margin-top: auto;
        @include laptop{
            margin-top: rem(10);
        }
    }

    &__card-block{
        display: flex;
        flex-direction: column;
        justify-content: flex-end;

        @include laptop{
            row-gap: rem(4);
        }

        &_quantity{
            row-gap: rem(10);

            @include tablet{
                row-gap: rem(4);
            }
            
            .quantity{
                margin-left: auto;
            }
        }

        &_select{
            width: rem(195);
            @include laptop{
                position: absolute;
                width: 100%;
                bottom: 0;
                left: 0;
            }
        }

        &_price{
            @include laptop{
                flex-direction: row;
                width: 100%;
                margin-top: auto;
                justify-content: flex-start;
                column-gap: rem(4);
                align-items: center;
            }
        }

    }

    &__card-price{
        margin-top: rem(10);
        @include laptop{
            margin-top: rem(6);
        }
    }
    


    &__picksize{
        display: flex;
        flex-direction: column;
        row-gap: rem(32);
        position: relative;
        z-index: 1;
        
        @include tablet{
            font-size: rem(24);
        }
    }

    &__picksize-block{
        display: flex;
        flex-direction: column;
        row-gap: rem(16);
        position: relative;
        max-width: rem(372);

        @include laptop{
            max-width: rem(255);
        }
        @include mobile{
            max-width: rem(265);
        }

        .input-block{
            margin-top: rem(12);
            @include laptop{
                max-width: rem(190);
            }
            @include tablet{
                margin-top: 0;
            }
        }

        &_wide{
            max-width: unset;
        }

    }

    &__picksize-step{
        padding-left: rem(52);
        min-height: rem(36);
    }

    &__picksize-number{
        width: rem(36);
        height: rem(36);
        background: var(--gray00);
        display: flex;
        justify-content: center;
        align-items: center;
        font-size: rem(24);
        border-radius: rem(8);
        position: absolute;
        left: 0;
        top: 0;
        
        @include laptop{
            font-size: rem(18);
        }
        @include tablet{
            font-size: rem(16);
        }
        @include mobile{
            font-size: rem(16);
        }
    }

    &__picksize-text{
        max-width: rem(246);
    }

    &__picksize-value{
        width: rem(80);
        @include laptop{
            width: rem(60);
        }
    }
    
    &__picksize-line{
        display: flex;
        justify-content: space-between;
        max-width: rem(289);
    }

    &__picksize-image{
        width: rem(292);
        height: rem(386);
        position: absolute;
        right: rem(-48);
        top: rem(16);
        z-index: -1;

        @include laptop{
            width: rem(142);
            height: rem(187);
        }
        @include mobile{
            right: rem(10);
            top: rem(56);
        }

        
        img{
            width: 100%;
            height: 100%;
        }
    }
    

    &__notify{
        display: flex;
        flex-direction: column;
        row-gap: rem(8);
    }

    &__notify-title{
        margin-bottom: rem(24);
        @include laptop{
            margin-bottom: rem(8);
        }
    }

    &__notify-btn{
        margin-top: rem(8);
    }

    &__notify-note{
        @include laptop{
            max-width: rem(232);
        }
        @include mobile{
            max-width: unset;
        }
    }


    &__repeat{
        
        display: flex;
        flex-direction: column;
        row-gap: rem(16);
        
    }

    &__repeat-list{
        display: flex;
        flex-direction: column;
        row-gap: rem(24);
        margin-top: rem(12);

        .modal__card{
            padding-bottom: rem(24);
            border-bottom: rem(1) solid var(--gray20);

            &:nth-last-of-type(1){
                border: none;
                padding-bottom: rem(8);
            }
        }
    }

    &__repeat-btn{
        margin-bottom: rem(24);
        @include laptop{
            margin-bottom: rem(16);
        }
    }

    &__back{
        display: flex;
        align-items: center;
        column-gap: rem(12);
        font-weight: 500;
        text-transform: uppercase;
        position: absolute;
        top: rem(32);
        left: rem(24);
        z-index: 70;

        @include tablet{
            top: rem(24);
            left: rem(17);
        }
        @include mobile{
            left: rem(9);
            column-gap: rem(8);
        }
    }

    &__more{
        display: flex;
        justify-content: space-between;
        column-gap: rem(36);
        position: relative;
        
        @include laptop{
            flex-wrap: wrap;
            row-gap: rem(24);
        }
    }

    &__more-left{
        flex-shrink: 0;
        position: relative;
        
        @include tablet{
            width: 100%;
        }
    }

    &__more-right{
        width: rem(1228);

        @include laptop{
            width: rem(752);
        }
        @include tablet{
            width: 100%;
        }

        .modal__addition-controls{
            @include tablet{
                display: none;
            }
        }

        .modal__addition{
            margin-top: 0;
            @include tablet{
                width: calc(100% + rem(17));
                padding-right: rem(17);
            }
            @include mobile{
                width: calc(100% + rem(9));
                padding-right: rem(9);
            }
        }
        .block-product__card{
            width: rem(292);
            margin-right: rem(20);
            @include laptop{
                width: rem(176);
                margin-right: rem(16);
            }
            @include tablet{
                width: rem(170);
                margin-right: rem(10);
            }
            @include mobile{
                width: rem(160);
            }
        }
        .modal__addition-top{
            margin-bottom: rem(24);
            @include laptop{
                margin-bottom: rem(19);
            }
            @include tablet{
                margin-bottom: rem(12);
            }
        }
        .modal__addition-filters{
            margin-bottom: rem(24);
            @include tablet{
                margin-bottom: rem(16);
            }
        }
        .modal__addition-check{
            @include laptop{
                width: auto;
            }
        }
    }

    &__more-title{
        text-align: left;
    }

    &__more-swiper{
        width: rem(482);
        height: rem(639);

        @include laptop{
            width: rem(347);
            height: rem(460);
        }
        @include tablet{
            width: calc(100% + rem(17));
            padding-right: rem(17);
            height: auto;
            position: relative;
        }
        @include mobile{
            width: calc(100% + rem(9));
            padding-right: rem(9);
        }

        .swiper-pagination{
            height: rem(20);
            bottom: rem(22);

            @include laptop{
                bottom: rem(10);
            }
            @include tablet{
                display: none;
            }

            .swiper-pagination-bullet{
                width: rem(72);
                height: rem(4);
                border-radius: rem(90);
                margin: rem(8) rem(6);
                background: var(--black);
                transition-duration: 0.3s;
                @include laptop{
                    width: rem(35);
                    height: rem(2);
                    margin: rem(8) rem(3);
                }
            }
            .swiper-pagination-bullet-active{
                background: var(--white);
            }
        }
    }

    &__more-slide{
        border-radius: rem(24);
        overflow: hidden;

        @include laptop{
            border-radius: rem(20);
        }
        @include tablet{
            border-radius: rem(16);
            width: rem(290);
            height: rem(386);
            margin-right: rem(10);
        }
        @include mobile{
            border-radius: rem(12);
            width: rem(220);
            height: rem(292);
        }

        &::nth-last-of-type(1) {
            margin-right: 0 !important;
        }
    }

    &__alert{
        padding: rem(24);
        background: var(--gray00);
        border-radius: rem(16);
        display: flex;
        column-gap: rem(70);

        @include laptop{
            flex-wrap: wrap;
            row-gap: rem(16);
        }
    }

    &__alert-text{
        @include laptop{
            max-width: rem(240);
        }
        @include mobile{
            max-width: unset;
        }
    }

    &__alert-btn{
        flex-shrink: 0;
        min-width: rem(186);

        @include laptop{
            width: 100%;
        }
    }

    &__order{
        display: flex;
        flex-direction: column;
        row-gap: rem(56);
        margin-top: rem(24);

        @include laptop{
            row-gap: rem(40);
        }
        
        .input-block{
            width: 100%;
        }
    }

    &__order-text{
        max-width: rem(410);

        @include mobile{
            max-width: rem(325);
            font-size: rem(14);
        }

        &_fix{
            max-width: rem(370);
            @include laptop{
                max-width: rem(295);
            }
        }
    }

    &__order-block{
        display: flex;
        flex-direction: column;
        row-gap: rem(16);

        @include laptop{
            row-gap: rem(8);
        }

        &_gap{
            row-gap: rem(32);

            @include laptop{
                row-gap: rem(24);
            }
        }
    }

    &__order-buttons{
        display: flex;
        justify-content: space-between;
        column-gap: rem(16);

        @include laptop{
            flex-wrap: wrap;
            row-gap: rem(8);
        }
    }


    &__certificate{
        display: flex;
        flex-direction: column;
        row-gap: rem(24);
        margin-top: rem(16);

        @include laptop{
            margin-top: rem(0);
            row-gap: rem(16);
        }
    }

    &__certificate-response{
        display: flex;
        flex-direction: column;
        row-gap: rem(16);
        margin-top: rem(16);
        //margin-bottom: rem(16);


    }

    &__certificate-text{
        @include laptop{
            max-width: rem(251);
        }
        @include mobile{
            max-width: rem(231);
        }
    }

    &__certificate-btn{
        margin-top: rem(16);
    }

    &__search{
        margin: 0 auto;
        margin-top: rem(28);
        max-width: rem(1386);
        width: 100%;
    }

    &__search-form{
        display: flex;
        column-gap: rem(24); 

        @include mobile{
            flex-wrap: wrap;
            row-gap: rem(16);
        }
        
        .input-block{
            width: 100%;
        }
    }

    &__search-btn{
        flex-shrink: 0;
        min-width: rem(212);
        height: rem(56);

        @include laptop{
            height: rem(44);
        }
        @include tablet{
            min-width: rem(170);
        }
        @include mobile{
            width: 100%;
        }
    }

    &__search-line{
        display: flex;
        justify-content: space-between;
        align-items: center;
        width: 100%;
        margin-top: rem(24);
        margin-bottom: rem(36);

        @include tablet{
            margin-top: rem(16);
            margin-bottom: rem(24);
        }
    }

    &__search-link{
        margin-right: unset;
    }

    &__search-results{
        display: flex;
        flex-wrap: wrap;
        gap: rem(40) rem(20);

        @include laptop{
            gap: rem(24) rem(16);
        }
        @include tablet{
            gap: rem(32) rem(10);
        }

        .block-catalog__card{
            @include mobile{
                width: rem(174);
                height: rem(330);
                margin-right: 0;
            }
        }
        .block-catalog__card-image{
            @include mobile{
                height: rem(232);
            }
        }

    }

    &__search-preview{
        display: flex;
        justify-content: space-between;
        margin-top: rem(48);

        @include laptop{
            margin-top: rem(40);
        }
        @include tablet{
            margin-top: rem(32);
            flex-wrap: wrap;
            row-gap: rem(32);
        }
        @include mobile{
            margin-top: rem(24);
        }
    }

    &__search-suggest{
        width: rem(448);
        display: flex;
        flex-direction: column;
        row-gap: rem(32);

        @include laptop{
            width: rem(317);
            row-gap: rem(24);
        }
        @include tablet{
            width: rem(300);
            row-gap: rem(16);
        }
        @include mobile{
            width: 100%;
        }
    }

    &__search-categories{
        width: rem(856);
        
        overflow-y: clip;
        overflow-x: scroll;

        @include laptop{
            width: rem(560);
        }
        @include tablet{
            width: 100%;
        }

        .main-categories__card{
            width: rem(272);
            gap: rem(16);
            margin-right: 0;
            @include mobile{
                gap: rem(8);
            }
        }
        .main-categories__image{
            height: rem(272);
        }
        .main-categories__card-title{
            font-size: rem(20);
            @include tablet{
                font-size: rem(18);
            }
            @include mobile{
                font-size: rem(16);
            }
        }
    }

    &__search-scroll{
        width: rem(856);
        display: flex;
        gap: rem(20);
        padding-bottom: rem(30);
        @include laptop{
            gap: rem(16);
            width: rem(835);
        }
        @include tablet{
            gap: rem(9);
            width: rem(855);
        }
    }

    &__search-title{
        text-align: left;
        border-bottom: rem(1) solid var(--gray60);
        padding-bottom: rem(12);

        @include laptop{
            margin-bottom: rem(8);
            font-size: rem(18);
        }
        @include tablet{
            font-size: rem(16);
        }
        @include mobile{
            font-size: rem(16);
        }
    }

    &__search-description{
        max-width: 50%;
    }

    &__search-pagination{
        width: 100%;
        display: flex;
        justify-content: center;
        margin-top: rem(32);

        @include tablet{
            margin-top: rem(8);
        }
        @include mobile{
            margin-top: rem(-16);
        }

    }

    &__change{
        display: flex;
        flex-direction: column;
        row-gap: rem(8);
        margin-top: rem(8);

        &_row{
            flex-direction: row;
            flex-wrap: wrap;
            gap: rem(40) rem(10);

            @include laptop{
                gap: rem(32) rem(8);
            }

            .modal__change-btn{
                margin-top: unset;
            }
        }
    }

    &__change-text{
        max-width: rem(365);
        @include tablet{
            max-width: rem(240);
        }
    }

    &__change-btn{
        margin-top: rem(32);
        @include laptop{
            margin-top: rem(24);
        }
    }

    &__change-block{
        width: calc(50% - rem(5));
        display: flex;
        flex-direction: column;
        row-gap: rem(8);

        @include laptop{
            width: calc(50% - rem(4));
        }
        
        &_wide{
            width: 100%;
        }

        &_third{
            width: calc(33.3333% - rem(7));
            @include laptop{
                width: calc(33.3333% - rem(6));
            }
        }

        &_check{
            justify-content: flex-end;
            max-height: rem(66);

            @include laptop{
                max-height: rem(60);
            }
            @include mobile{
                max-height: rem(56);
            }

            .input-block{
                height: 100%;
            }
        }

        .input-close{
            @include laptop{
                top: rem(10);
                right: rem(8);
            }
        }
    }

    &__change-label{
        display: flex;
        align-items: center;
        column-gap: rem(8);

        input {
            position: absolute;
            opacity: 0;
            pointer-events: none;
        }

        &::before {
            content: "";
            display: block;
            width: rem(24);
            height: rem(24);
            background: url(/img/svg/checkbox-empty.svg) no-repeat;
            background-size: cover;
            flex-shrink: 0;
            pointer-events: none;

            /* @include mobile{
                width: rem(20);
                height: rem(20);
            } */
        }

        &:has(input:checked) {
            &::before {
                background: url(/img/svg/checkbox-full.svg) no-repeat;
                background-size: cover;
            }
        }
    }

    &__return{
        display: flex;
        align-items: center;
        column-gap: rem(8);
        position: fixed;
        width: 100%;
        background: var(--grayBg);
        text-transform: uppercase;
        font-weight: 500;
        cursor: pointer;

        &::before{
            content: '';
            position: absolute;
            display: block;
            width: 100%;
            height: rem(140);
            bottom: rem(-30);
            left: rem(-5);
            background: var(--grayBg);
            z-index: -1;
        }
    }

    &__ask-city-text{
        text-transform: uppercase;
        margin-right: rem(10);
        font-weight: 500;
        @include tablet{
            width: 100%;
            text-align: center;
            margin-right: 0;
            font-size: rem(12);
            line-height: 1;
        }
    }

    &__ask-city-buttons{
        display: flex;
        column-gap: rem(8);
        @include tablet{
            width: 100%;
        }
    }

    &__ask-city-btn{
        width: rem(100);
        height: rem(40);
        padding: 0;
        border-radius: rem(8);
        @include tablet{
            width: 100%;
        }
    }


    &__login{
        display: flex;
        flex-direction: column;
        row-gap: rem(40);

        @include mobile{
            row-gap: rem(32);
        }

        .variants{
            @include laptop{
                gap: rem(8);
            }
        }
        
    }

    &__login-form{
        display: flex;
        flex-direction: column;
        row-gap: rem(16);


        @include tablet{
            row-gap: rem(10);
        }
        @include mobile{
            row-gap: rem(8);
        }


        .input-block.error-field-response {
            & + .modal__login-text_tohide{
                display: none;
            }
        }

        .input-block.error-field:not(.error-field-response){
            .input__error{
                &:nth-of-type(2) {
                    display: none;
                }
            }
        }
        .input-block.error-field-response{
            .input__error{
                &:nth-of-type(1) {
                    display: none;
                }
            }
        }

        &[data-login-form='resetPassword']{
            margin-top: rem(16);
        }

    }

    &__login-text{
        margin-bottom: rem(8);
        max-width: rem(530);
        
        &_fix{
            max-width: rem(437);

            @include mobile{
                max-width: rem(250);
            }
        }

        &_confirm{
            max-width: rem(300);
        }

        &_note{
            margin-top: rem(-8);
            @include tablet{
                margin-top: rem(-4);
            }
            @include mobile{
                margin-top: rem(-2);
            }
        }
    }

    &__login-capcha{
        width: rem(300);
        height: rem(72);
        background: #ddd;
        margin-top: rem(8);
        margin-bottom: rem(-8);

        img{
            width: 100%;
            height: 100%;
        }

        @include laptop{
            width: rem(234);
            height: rem(56);
        }

    }

    &__login-btn{
        margin-top: rem(16);
    }

    &__double-input{
        display: flex;
        column-gap: rem(8);

        .input-block:nth-of-type(1){
            width: 100%;
        }
        .input-block:nth-of-type(2){
            max-width: rem(125);
            flex-shrink: 0;
        }
    }

}