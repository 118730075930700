.error{

    &__inner{
        margin: 0 0 rem(100) 0;

        @include tablet{
            margin: 0 0 rem(80) 0;
        }
        @include mobile{
            margin: 0 0 rem(56) 0;
        }
    }

    &__box{
        width: 100%;
        height: calc(100svh);
        max-height: rem(800);
        position: relative;
        z-index: 1;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        row-gap: rem(32);

        @include laptop{
            max-height: rem(600);
            height: calc(100svh);
            row-gap: rem(24);
        }
        @include tablet{
            max-height: rem(800);
            height: calc(100svh);
            row-gap: rem(16);
        }
        @include mobile{
            max-height: rem(500);
            height: calc(100svh);
        }
    }
    
    &__cover{
        position: absolute;
        width: 100%;
        height: 100%;
        z-index: -1;

        img{
            width: 100%;
            height: 100%;
        }

        &::after{
            content: '';
            background: linear-gradient(180deg, rgba(0, 0, 0, 0.00) 0%, rgba(0, 0, 0, 0.30) 100%);
            position: absolute;
            width: 100%;
            height: 100%;
            top: 0;
            left: 0;
            z-index: 1;
        }

    }

    &__title{
        text-align: center;
    }

    &__text{
        text-align: center;
        margin-bottom: rem(8);
        @include mobile{
            margin-bottom: 0;
        }
    }

}