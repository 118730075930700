.drop-video {
  margin-bottom: rem(16);

  @include tablet {
    margin-bottom: rem(24);
  }

  &__description {
    font-weight: 400;
    font-size: rem(14);
    line-height: 120%;
    letter-spacing: -0.01em;
    color: var(--black);
    margin-bottom: rem(8);

    @include laptop {
      font-size: rem(12);
    }

    @include mobile {
      font-size: rem(10);
    }
  }
}

.drag-drop {
  position: relative;
  overflow: hidden;
  height: rem(127);
  width: 100%;

  transition: border-style 0.3s ease;

  &__error {
    position: absolute;
    bottom: rem(10);
    left: rem(10);
  }

  &__field {
    position: absolute;
    left: 0;
    top: 0;

    z-index: 100;
    width: 100%;
    height: 100%;
    transform: scale(20);
    letter-spacing: 10em;
    opacity: 0;
    cursor: pointer;

    &:user-invalid + .drag-drop__wrapper {
      border: rem(1) solid var(--red);
      border-radius: rem(10);
    }

    &:user-invalid + .drag-drop__wrapper > .input__error {
      display: block;
    }
  }

  &__wrapper {
    height: 100%;
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    gap: rem(15);
    border: rem(1) dashed var(--black);
    border-radius: rem(12);
  }

  &__icon {
    width: rem(24);
    height: rem(24);
  }

  &__text {
    font-weight: 500;
    font-size: rem(14);
    line-height: 140%;
    letter-spacing: -0.01em;
    text-align: center;
    color: var(--gray70);
    max-width: rem(380);

    @include laptop {
      max-width: rem(220);
      font-size: rem(12);
    }

    @include mobile {
      font-size: rem(10);
    }

    &_desktop {
      @media (max-width: 650px) {
        display: none;
      }
    }

    &_mobile {
      @media (min-width: 651px) {
        display: none;
      }
    }

    span {
      display: block;

      @media (max-width: 1440px) {
        display: inline-block;
      }

      @media (max-width: 650px) {
        display: block;
      }
    }
  }
}

.drop-file {
  display: block;

  // &.active {
  //   display: block;
  // }

  position: relative;
  margin-top: rem(8);
  padding: 0 rem(40);
  height: rem(130);
  display: flex;
  flex-direction: column;
  justify-content: center;
  flex: 0 0 auto;

  border-radius: rem(12);
  background: #fff;

  @include laptop {
    margin-top: rem(16);
  }

  @include tablet {
    margin-top: rem(8);
  }

  @include mobile {
    height: rem(135);
    padding: 0 rem(23);
  }

  &__info {
    display: flex;
    align-items: start;
    gap: rem(16);
    margin-bottom: rem(10);
    padding-right: rem(70);
  }

  &__ext {
    position: relative;
    width: rem(24);
    height: rem(24);
    flex: 0 0 auto;

    &-img {
      width: rem(24);
      height: rem(24);
    }

    &-text {
      position: absolute;
      top: rem(12);
      left: rem(2);
      color: #fff;
      font-size: rem(6);
      text-transform: uppercase;

      @include laptop {
        top: rem(12);
      }
    }
  }

  &__name-block {
    flex: 1 1 auto;
    width: 100%;
  }

  &__name {
    font-weight: 500;
    font-size: rem(16);
    line-height: 140%;
    letter-spacing: -0.01em;
    color: var(--gray70);
    margin-bottom: rem(4);
    display: flex;

    @include laptop {
      font-size: rem(14);
    }

    @include tablet {
      font-size: rem(16);
    }

    @include mobile {
      font-size: rem(12);
    }

    span {
      display: inline-block;
      text-wrap: nowrap;
      text-overflow: ellipsis;
      overflow: hidden;

      &:first-child {
        // flex: 1 1 auto;
      }

      &:last-child {
        flex: 0 0 auto;
      }
    }
  }

  &__size {
    font-weight: 400;
    line-height: 120%;
    letter-spacing: -0.04em;
    color: var(--gray60);
    font-size: rem(14);

    @include laptop {
      font-size: rem(12);
    }

    @include tablet {
      font-size: rem(14);
    }

    @include mobile {
      font-size: rem(10);
    }
  }

  &__close-block {
    cursor: pointer;
    position: absolute;
    right: rem(40);
    width: rem(24);
    height: rem(24);

    @include mobile {
      right: rem(23);
    }
  }

  &__close {
    width: 100%;
    height: 100%;
  }

  &__line-block {
    display: flex;
    align-items: center;
    gap: rem(12);
    width: 100%;
  }

  &__line {
    position: relative;
    --width: 40%;
    flex: 1 1 auto;
    height: rem(4);
    border-radius: rem(4);
    background: #f8f8ed;
    overflow: hidden;

    &::after {
      content: "";
      position: absolute;
      left: 0;
      height: 100%;
      width: var(--width);
      background: #a5c2b6;
      border-radius: rem(4);
      transition: width 0.2s linear;
    }
  }

  &__line-count {
    font-weight: 500;
    font-size: rem(12);
    line-height: 110%;
    color: var(--black);
    width: rem(35);
    text-align: right;

    @include laptop {
      font-size: rem(14);
      width: rem(40);
    }

    @include tablet {
      font-size: rem(12);
      width: rem(35);
    }
  }

  &__watch {
    text-decoration: none;
    margin-top: rem(10);
    font-weight: 500;
    font-size: rem(14);
    line-height: 120%;
    letter-spacing: -0.01em;
    color: var(--grey60);
    display: none;

    @include laptop {
      margin-top: rem(8);
      font-size: rem(12);
    }

    @include mobile {
      font-size: rem(10);
    }
  }

  &__another {
    margin-top: rem(16);
    font-weight: 400;
    font-size: rem(14);
    line-height: 140%;
    letter-spacing: -0.01em;
    text-transform: uppercase;
    color: var(--black);

    @include laptop {
      font-size: rem(12);
    }
  }

  .js-file-count,
  .js-file-now {
    font-variant-numeric: tabular-nums;
  }
}

.disabled_drop {
  pointer-events: none;
  user-select: none;
  opacity: 0.4;
}

.drop-file-error {
  .drop-file__line-block {
    visibility: collapse;
    display: none;
  }

  .drop-file__name {
    color: var(--red);
  }

  .drop-file__size {
    color: var(--red);
  }

  .drop-file__another {
    color: var(--red);
  }
}

.drop-file-ex {
  .drop-file__watch {
    display: block;
  }

  .drop-file__close {
    opacity: 0.9;
  }

  .drop-file__line {
    &::after {
      background: #50c878;
    }
  }

  .drop-file__name {
    color: var(--black);
  }

  .drop-file__size {
    color: var(--gray90);
  }

  .drop-file__another {
    color: var(--red);
  }
}
