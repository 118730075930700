
.block-banner{

    &_tablet{
        .block-banner__box{
            @include tablet{
                height: rem(800);
            }
            @include mobile{
                height: rem(500);
            }
        }
    }

    &__box{
        width: 100%;
        height: rem(800);
        position: relative;
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        row-gap: rem(32);

        @include laptop{
            height: rem(600);
        }
        @include tablet{
            height: rem(400);
            row-gap: rem(24);
        }
        @include mobile{
            height: rem(320);
            row-gap: rem(16);
        }
    }

    &__cover{
        position: absolute;
        width: 100%;
        height: 100%;
        top: 0;
        left: 0;
        z-index: -1;

        img{
            width: 100%;
            height: 100%;
        }

        &::before{
            content: '';
            position: absolute;
            width: 100%;
            height: 100%;
            background: linear-gradient(180deg, rgba(0, 0, 0, 0) 0%, rgba(0, 0, 0, 0.3) 100%);
            top: 0;
            left: 0;
            z-index: 2;
        }

        &_overlay{
            &::before{
                background: var(--black);
                opacity: 0.3;
            }
        }

    }


    &__img{
        display: block;
        @include tablet{
            display: none;
        }
    }


    &__img-mobile{
        display: none;
        @include tablet{
            display: block;
        }
    }

    &__text{
        max-width: rem(470);
        text-align: center;

        @include laptop{
            max-width: rem(420);
        }
        @include tablet{
            
        }
        @include mobile{
            
        }

        &_loyalty{
            max-width: rem(540);
            @include tablet{
                max-width: rem(484);
            }
            @include mobile{
                max-width: rem(290);
            }
        }
    }

    &__title {
        &_vacancy {
            max-width: rem(1307);
            @include laptop{
                max-width: rem(880);
            }
            @include tablet{
                max-width: rem(580);
            }
            @include mobile{
                max-width: rem(336);
            }
        }
        &_certificate{
            max-width: rem(1200);
            @include laptop{
                max-width: rem(768);
            }
            @include tablet{
                max-width: rem(574);
            }
            @include mobile{
                max-width: rem(330);
            }
        }
        &_loyalty{
            max-width: rem(680);
        }
        &_sizes{
            max-width: rem(480);
            @include laptop{
                max-width: rem(360);
            }
            @include tablet{
                max-width: rem(360);
            }
            @include mobile{
                max-width: rem(290);
            }
        }
        
    }
}