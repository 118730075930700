

.franchise-line{

    &__title{
        max-width: rem(600);
        margin: 0 auto;
        @include laptop{
            font-size: rem(28);
        }
        @include tablet{
            font-size: rem(26);
        }
        @include mobile{
            font-size: rem(20);
        }
    }

    &__box{
        margin-top: rem(64);
        border-top: rem(1) solid var(--black);
        border-bottom: rem(1) solid var(--black);
        padding: rem(72) 0;
        display: flex;
        justify-content: space-around;

        @include laptop{
            margin-top: rem(32);
            padding: rem(48) 0;
        }
        @include mobile{
            margin-top: rem(24);
            padding: rem(24) 0;
            flex-wrap: wrap;
            row-gap: rem(24);
        }

        &_special{
            justify-content: flex-start;
            column-gap: rem(150);
            @include laptop{
                column-gap: rem(30);
            }
            @include tablet{
                flex-wrap: wrap;
                column-gap: 0;
                row-gap: rem(28);
            }
            @include mobile{
                row-gap: rem(16);
            }

            .franchise-line__block{
                @include laptop{
                    max-width: rem(270);
                }
                @include tablet{
                    min-width: 50%;
                    max-width: 50%;
                }
                @include mobile{
                    max-width: 100%;
                }
            }
        }
    }

    &__block{
        max-width: rem(340);

        @include tablet{
            max-width: rem(168);
        }
        @include mobile{
            max-width: unset;
            width: 100%;
        }
        
        &_half{
            min-width: calc(50% - rem(150));

            @include laptop{
                min-width: calc(50% - rem(30));
            }
            @include tablet{
                max-width: rem(416) !important;
            }
            
            .franchise-line__title{
                text-align: left;
                margin: 0;
            }
        }
    }

    &__block-title{
        color: var(--gray80);
        text-align: center;
        margin-bottom: rem(16);

        @include laptop{
            font-size: rem(20);
            margin-bottom: rem(8);
        }
        @include tablet{
            font-size: rem(18);
            margin-bottom: rem(4);
        }
        @include mobile{
            font-size: rem(16);
        }

        &_black{
            color: var(--black);
        }

        &_sage{
            color: var(--sageDark);
            text-align: left;
            font-size: rem(80);
            margin-bottom: rem(0);

            @include laptop{
                font-size: rem(56);
            }
            @include mobile{
                font-size: rem(48);
            }

            span{
                font-size: rem(36);
                margin-left: rem(8);
                @include laptop{
                    font-size: rem(28);
                }
                @include tablet{
                    font-size: rem(26);
                }
                @include mobile{
                    font-size: rem(20);
                }
            }
        }

        &_height{
            @include tablet{
                min-height: rem(40);
            }
            @include mobile{
                min-height: unset;
            }
        }
    
    }

    &__block-text{
        color: var(--gray80);
        text-align: center;
        letter-spacing: -0.02vw;

        @include laptop{
            font-size: rem(14);
        }
        @include tablet{
            font-size: rem(12);
        }
        @include mobile{
            font-size: rem(12);
        }

        &_left{
            text-align: left;
            @include tablet{
                max-width: rem(300);
            }
        }
    }

}