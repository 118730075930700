.profile-main {
  &__tabs {
    margin-bottom: rem(40);
    @include laptop {
      margin-bottom: rem(35);
    }

    @include tablet {
      margin-bottom: rem(32);
    }
  }

  &__body {
    &:not(.tab-box-active) {
      display: none;
    }
  }

  &__body-info {
    display: flex;
    flex-direction: column;
    gap: rem(32);

    @include laptop {
      gap: rem(24);
    }
  }

  &__field {
    @include laptop {
      .input-sub {
        font-size: rem(12);
      }

      .input {
        font-size: rem(12);
      }
    }

    @include tablet {
      .input-title_big {
        font-size: rem(12);
      }
    }
  }

  &__delete {
    font-size: rem(18);
    font-style: normal;
    font-weight: 400;
    line-height: 140%;
    text-decoration: underline;
    text-decoration-skip-ink: none;
    color: var(--gray40);
    text-align: left;
    max-width: max-content;

    @include laptop {
      font-size: rem(14);
    }

    @include mobile {
      font-size: rem(12);
    }
  }

  &__addresses {
    display: flex;
    flex-direction: column;
    gap: rem(24);

    @include laptop {
      gap: rem(16);
    }

    @include mobile {
      gap: rem(24);
    }
  }

  &__address {
    display: grid;
    grid-template-columns: 1fr rem(140);
    gap: rem(65);
    align-items: start;
    border-radius: rem(12);
    padding: rem(16) rem(20);
    border: rem(1) solid var(--gray30);

    @include laptop {
      grid-template-columns: 1fr rem(120);
      gap: rem(15);
    }

    @include tablet {
      gap: rem(25);
    }

    @include mobile {
      grid-template-columns: 1fr rem(60);
      gap: rem(22);
    }
  }

  &__address-left {
    display: flex;
    flex-direction: column;
    gap: rem(16);
  }

  &__address-text {
    font-size: rem(16);
    font-style: normal;
    font-weight: 500;
    line-height: 140%;
    color: var(--black);

    @include laptop {
      font-size: rem(14);
    }

    @include tablet {
      font-size: rem(12);
    }
  }

  &__address-city {
    font-size: rem(14);
    font-style: normal;
    font-weight: 400;
    line-height: 140%;
    color: var(--gray60);

    @include laptop {
      font-size: rem(12);
    }

    @include mobile {
      font-size: rem(10);
    }
  }

  &__address-changer {
    font-size: rem(12);
    font-style: normal;
    font-weight: 500;
    line-height: 130%;
    color: var(--gray40);
    text-transform: uppercase;
    text-decoration: underline;
    text-decoration-skip-ink: none;
    @include laptop {
      font-size: rem(10);
    }
    span {
      @include mobile {
        display: none;
      }
    }

    svg {
      display: none;

      @include mobile {
        width: rem(20);
        height: rem(20);
        display: block;
      }
    }
  }

  &__address-right {
    display: flex;
    gap: rem(16);

    @include laptop {
      justify-content: space-between;
    }

    @include mobile {
      gap: rem(20);
    }
  }

  &__address-add {
    margin-top: rem(40);
    width: 100%;

    @include laptop {
      margin-top: rem(24);
    }

    @include tablet {
    }

    @include mobile {
      margin-top: rem(32);
    }
  }

  &__address-empty {
    color: var(--black);
    font-size: rem(18);
    font-style: normal;
    font-weight: 500;
    line-height: 140%;

    @include laptop {
      font-size: rem(16);
    }

    @include tablet {
      font-size: rem(14);
    }

    @include mobile {
      font-size: rem(12);
    }
  }
}
