.headline-1,
.headline-2,
.headline-3,
.headline-4,
.headline-5{
    font-family: "Stapel Expanded", serif !important;
    font-weight: 500;
    color: var(--black);
    text-align: center;
    margin: 0;
    text-decoration: none;
}
.headline-1{
    font-size: rem(56);
    letter-spacing: -0.02vw;
    //letter-spacing: -0.05vw;
    line-height: 0.9;
    //color: #000 !important;
    @include laptop{
        font-size: rem(36);
    }
    @include tablet{
        font-size: rem(36);
    }
    @include mobile{
        font-size: rem(24);
    }
}
.headline-2{
    font-size: rem(48);
    letter-spacing: -0.01vw;
    line-height: 0.9;
    @include laptop{
        font-size: rem(32);
    }
    @include tablet{
        font-size: rem(28);
    }
    @include mobile{
        font-size: rem(20);
    }
}
.headline-3{
    font-size: rem(36);
    letter-spacing: -0.01vw;
    line-height: 1;
    @include laptop{
        font-size: rem(24);
    }
    @include tablet{
        font-size: rem(22);
    }
    @include mobile{
        font-size: rem(16);
    }
}
.headline-4{
    font-size: rem(28);
    letter-spacing: -0.01vw;
    line-height: 1.1;
    text-align: left;
    @include laptop{
        font-size: rem(18);
    }
    @include tablet{
        font-size: rem(16);
    }
    @include mobile{
        font-size: rem(14);
    }
}
.headline-5{
    font-size: rem(24);
    letter-spacing: -0.01vw;
    line-height: 1.1;
    @include laptop{
        font-size: rem(16);
    }
    @include tablet{
        font-size: rem(12);
    }
    @include mobile{
        font-size: rem(10);
    }
}

a{
    text-decoration: underline;
}

.t-primary-l,
.t-primary-l-accent,
.t-primary-m,
.t-primary-m-accent,
.t-primary-s,
.t-secodary-s-line,
.t-secodary-s,
.t-caption,
.t-navigation
{
    color: var(--black);
    text-decoration: none;
}






.t-primary-l{
    line-height: 1.4;
    font-size: rem(18);
    @include laptop{
        font-size: rem(16);
    }
    @include tablet{
        font-size: rem(14);
    }
    @include mobile{
        font-size: rem(12);
    }
}
.t-primary-l-accent{
    line-height: 1.2;
    font-weight: 500;
    letter-spacing: -0.01vw;
    font-size: rem(18);
    @include laptop{
        font-size: rem(16);
    }
    @include tablet{
        font-size: rem(14);
        line-height: 1.4;
    }
    @include mobile{
        font-size: rem(12);
    }
}
.t-primary-m{
    font-size: rem(16);
    line-height: 1.2;
    letter-spacing: -0.03vw;
    @include laptop{
        font-size: rem(14);
        line-height: 1.4;
    }
    @include tablet{
        font-size: rem(12);
        line-height: 1.4;
    }
    @include mobile{
        line-height: 1.2;
    }
}
.t-primary-m-accent{
    font-weight: 500;
    font-size: rem(16);
    line-height: 1.4;
    letter-spacing: -0.03vw;
    @include laptop{
        font-size: rem(14);
    }
    @include tablet{
        font-size: rem(12);
    }
    @include mobile{
        font-size: rem(12);
        line-height: 1.2;
    }
}
.t-primary-s{
    font-size: rem(14);
    letter-spacing: -0.01vw;
    line-height: 1.4;
    @include laptop{
        font-size: rem(12);
        line-height: 1.1;
        letter-spacing: -0.03vw;
    }
    @include tablet{
        line-height: 1.1;
    }
    @include mobile{
        font-size: rem(10);
        line-height: 1.3;
    }
}
.t-secodary-s-line{
    line-height: 1.4;
    font-size: rem(16);
    letter-spacing: -0.01vw;
    text-decoration: line-through;
    //color: var(--gray30);
    @include laptop{
        font-size: rem(14);
    }
    @include tablet{
        font-size: rem(12);
    }
    @include mobile{
        font-size: rem(10);
        line-height: 1.4;
    }
}
.t-secodary-s{
    line-height: 1.4;
    font-size: rem(14);
    //text-decoration: line-through;
    @include laptop{
        font-size: rem(12);
    }
    @include tablet{
        
    }
    @include mobile{
        font-size: rem(10);
    }
}
.t-caption{
    color: var(--gray80);
    font-size: rem(12);
    line-height: 1.3;
    @include laptop{
        font-size: rem(10);
    }
    @include tablet{
        
    }
    @include mobile{
        font-size: rem(8);
    }
    a{
        color: var(--gray80);
    }
}
.t-navigation{
    font-weight: 500;
    font-size: rem(14);
    letter-spacing: -0.01vw;
    line-height: 1.1;
    text-transform: uppercase;
    @include laptop{
        font-size: rem(12);
    }
    @include tablet{
        font-size: rem(12);
    }
    @include mobile{
        font-size: rem(10);
    }
}



.t-center{
    text-align: center;
}
.t-left{
    text-align: left;
}
.t-right{
    text-align: right;
}



.t-white{
    color: var(--white);
}
.t-gray-00{
    color: var(--gray00);
}
.t-gray-10{
    color: var(--gray10);
}
.t-gray-20{
    color: var(--gray20);
}
.t-gray-30{
    color: var(--gray30);
}
.t-gray-40{
    color: var(--gray40);
}
.t-gray-50{
    color: var(--gray50);
}
.t-gray-60{
    color: var(--gray60);
}
.t-gray-70{
    color: var(--gray70);
}
.t-gray-80{
    color: var(--gray80);
}
.t-sage{
    color: var(--sageDark);
}