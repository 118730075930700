.inner {
    margin: rem(100) 0;

    @include tablet {
        margin: rem(80) 0;
    }

    @include mobile {
        margin: rem(56) 0;
    }

    &_big {
        margin: rem(160) 0;

        @include laptop {
            margin: rem(120) 0;
        }

        @include tablet {
            margin: rem(80) 0;
        }

        @include mobile {
            margin: rem(56) 0;
        }
    }
}

.cart-fly {
    position: fixed;
    right: rem(15);
    width: rem(48);
    height: rem(48);
    background: var(--white);
    box-shadow: 0px 0px 20px 8px #0000000f;
    border-radius: rem(100);
    top: calc(100dvh - rem(140));
    text-decoration: none;
    z-index: 50;
    cursor: pointer;
    display: none;

    @include mobile {
        display: flex;
        justify-content: center;
        align-items: center;
    }

    img {
        width: rem(24);
        height: rem(24);
    }

    &__label {
        position: absolute;
        bottom: calc(50% - rem(6));
        left: calc(50% + rem(3));
        background: var(--sageDark);
        width: rem(16);
        height: rem(16);
        display: flex;
        align-items: center;
        justify-content: center;
        border-radius: rem(100);
        color: var(--white);
    }
}

.btn {
    display: block;
    font-weight: 400 !important;
    color: var(--black);
    text-decoration: none;
    text-transform: uppercase;
    border-radius: rem(12);
    padding: rem(20) rem(24);
    pointer-events: all;
    text-align: center;
    box-sizing: border-box;
    transition-duration: 0.3s;
    line-height: 1;
    font-size: rem(14);
    border: rem(1) solid transparent;
    z-index: 3;

    @include laptop {
        padding: rem(16) rem(24);
        border-radius: rem(8);
        font-size: rem(12);
    }

    @include tablet {
        padding: rem(13) rem(16);
    }

    @include mobile {
        font-size: rem(12);
    }

    &_small {
        padding: rem(13) rem(24);
        border-radius: rem(10);

        @include laptop {
            padding: rem(14) rem(24);
        }

        @include tablet {
            padding: rem(13) rem(16);
            border-radius: rem(8);
        }
    }

    &_fix {
        margin-right: auto;
    }

    &_wide {
        width: 100%;
    }

    &_border {
        border: rem(1) solid var(--black);

        &:hover {
            background: var(--white);
        }
    }

    &_icon {
        display: flex;
        align-items: center;
        justify-content: center;
        column-gap: rem(4);
        padding: rem(15) rem(24);

        img {
            width: rem(24);
            height: rem(24);
            pointer-events: none;
        }

        @include laptop {
            padding: rem(11) rem(16);

            img {
                width: rem(20);
                height: rem(20);
            }
        }
    }

    &_gray {
        background: var(--grayBg);

        &:hover {
            background: var(--white);
        }
    }

    &_white {
        background: var(--white);

        &:disabled {
            color: var(--gray30);
            background: var(--grayBg);
            border-color: var(--gray20);
            user-select: none;
            pointer-events: none;
        }

        &:hover {
            background: var(--grayBg);
        }
    }

    &_accent {
        color: var(--white);
        background: var(--black);
        font-size: rem(14);
        border: rem(1) solid var(--black);

        &:hover {
            background: var(--gray80);
        }

        &:active {
            background: var(--gray60);
        }

        &:disabled {
            background: var(--gray20);
            border-color: var(--gray20);
            color: var(--gray00);
            user-select: none;
            pointer-events: none;
        }

        @include laptop {
            font-size: rem(12);
            padding: rem(16) rem(20);
            border-radius: rem(8);
        }
    }
}

.link {
    transition-duration: 0.3s;
    border-bottom: rem(1) solid var(--black);
    display: inline-block;
    margin-right: auto;
    text-decoration: none;

    &:hover {
        color: var(--gray60);
        border-bottom: rem(1) solid var(--gray60);
    }
}

.select {
    position: relative;
    display: inline-block;
    z-index: 10;
    width: auto;
    box-sizing: border-box;

    &::after {
        content: "";
        position: absolute;
        z-index: 1;
        right: rem(20);
        top: 50%;
        transform: translateY(-50%);
        width: rem(24);
        height: rem(24);
        background: url(/img/svg/arrow-down.svg) no-repeat;
        background-size: cover;
        font-size: 12px;
        opacity: 0.6;
        pointer-events: none;
    }

    &:has(input:disabled) {
        &::after {
            opacity: 0.2;
        }
    }

    &_wide {
        width: 100%;
    }

    &_city {
        max-width: rem(542);

        .select__list {
            position: relative;
        }

        .select__value {
            white-space: nowrap;
            display: block;
            line-height: rem(50);
            height: rem(52);
            padding-top: 0;
            padding-bottom: 0;

            @include laptop {
                min-height: rem(44);
                max-height: rem(44);
                line-height: rem(42);
            }
        }

        @include tablet {
            max-width: rem(440);
        }

        &::after {
            content: '';
            top: rem(14);
            background: url(/img/svg/search.svg) no-repeat;
            background-size: cover;
            transform: translateY(0%) !important;

            @include laptop {
                top: rem(10);
            }
        }
    }

    &__price {
        padding: rem(16) rem(8) rem(8);
        width: 100%;
    }

    &__price-top{
        align-items: center;
        display: flex;
        justify-content: space-between;
        margin-bottom: rem(22);

        .link{
            font-size: rem(12);
            margin: 0;
            color: var(--gray50);
            border-color: var(--gray50);
        }
    }

    &__price-title{
        font-size: rem(16);
        @include laptop{
            font-size: rem(16);
        }
    }

    &__price-btn {
        margin-top: rem(20);
        height: rem(44);
        padding: 0;
    }

    &__value {
        width: auto;
        height: 100%;
        display: flex;
        align-items: center;
        font-size: rem(16);
        padding: rem(17) rem(20);
        padding-right: calc(rem(20) + rem(30));
        cursor: pointer;
        //color: var(--black);
        color: var(--gray60);
        font-weight: 400;
        background: var(--grayBg);
        border: rem(1) solid var(--gray30);
        border-radius: rem(12);
        outline: none;
        user-select: none;


        &[data-full] {
            font-weight: 500;
            color: var(--black);
        }

        &[data-full-hard] {
            font-weight: 500;
            color: var(--black);
        }

        &:disabled {
            opacity: 0.3;
        }

        @include laptop {
            font-size: rem(12);
            padding: rem(12) rem(16);
            padding-right: calc(rem(16) + rem(30));
            border-radius: rem(8);
        }

        @include tablet {
            padding: rem(13) rem(16);
        }

        @include mobile {
            padding: rem(14) rem(16);
        }
    }

    &__list {
        margin-top: rem(8);
        position: absolute;
        background: var(--white);
        box-shadow: 0px 4px 24px 0px #0000001f;
        border-radius: rem(12);
        width: 100%;
        min-width: rem(280);
        max-height: rem(260);
        overflow-y: auto;
        overflow-x: clip;
        display: none;

        &:has(> .select__price){
            width: rem(374);
            @include laptop{
                width: rem(332);
            }
        }

        &_check {
            min-width: rem(372);
            height: rem(366);
            max-height: unset;

            @include tablet {
                min-width: unset;
                height: rem(278);
            }

        }

        @include mobile {
            min-width: rem(160);
        }

        &:has(.select__scroll) {
            overflow-y: hidden;
        }
    }

    &__scroll {
        overflow-y: auto;
        height: calc(rem(366) - rem(47));

        @include tablet {
            height: calc(rem(278) - rem(38));
        }
    }

    &__clear {
        position: absolute;
        bottom: 0;
        left: 0;
        background: var(--white);
        color: var(--black);
        width: 100%;
        height: rem(47);
        font-size: rem(14);

        @include tablet {
            font-size: rem(12);
            height: rem(38);
        }
    }

    &__label {
        display: block;
        padding: rem(14) rem(16);
        color: var(--gray60);
        white-space: nowrap;
        display: flex;
        align-items: center;
        column-gap: rem(8);
        user-select: none;
        font-size: rem(16);
        cursor: pointer;

        input {
            position: absolute;
            opacity: 0;
            pointer-events: none;
        }

        @include tablet {
            font-size: rem(12);
            padding: rem(12) rem(12);
        }

        &::before {
            content: "";
            width: rem(24);
            height: rem(24);
            background: url(/img/svg/checkbox-empty.svg) no-repeat;
            background-size: cover;
            flex-shrink: 0;
        }

        &:has(input:checked) {
            color: var(--black);

            &::before {
                background: url(/img/svg/checkbox-full.svg) no-repeat;
                background-size: cover;
            }
        }
    }

    &__option {
        padding: rem(15) rem(20);
        color: var(--black);
        text-decoration: none;
        display: block;

        &:hover {
            background: var(--grayBg);
            cursor: pointer;
        }

        &_selected {
            color: var(--sageDark);
        }

        input {
            position: absolute;
            opacity: 0;
            pointer-events: none;
        }

        &::before {
            content: "";
            width: rem(24);
            height: rem(24);
            background: url(/img/svg/checkbox-empty.svg) no-repeat;
            background-size: cover;
            flex-shrink: 0;
        }

        &:has(input:checked) {
            color: var(--black);

            &::before {
                background: url(/img/svg/checkbox-full.svg) no-repeat;
                background-size: cover;
            }
        }
    }

    &_open {
        z-index: 11;

        &::after {
            transform: translateY(-50%) scaleY(-1);
        }

        .select__list {
            display: block;
        }
    }

    &_drop {
        //width: rem(190);

        &::after {
            right: rem(16);

            @include laptop {
                width: rem(20);
                height: rem(20);
                right: rem(12);
            }
        }

        .select__value {
            padding: rem(10) rem(16);
            padding-right: calc(rem(16) + rem(30));
            border-radius: rem(8);
            background: var(--grayBg);
            border: rem(1) solid var(--gray30);

            @include laptop {
                font-size: rem(14);
                padding: rem(7) rem(12);
                padding-right: calc(rem(12) + rem(30));
            }

            @include mobile {
                font-size: rem(12);
                padding: rem(9) rem(12);
                padding-right: calc(rem(12) + rem(30));
            }

            /* &:not([value=""]){
                        background: var(--gray00);
                        border: rem(1) solid var(--gray00);
                    } */

            &[data-full] {
                background: var(--gray00);
                border: rem(1) solid var(--gray00);
            }

            &[data-full-hard] {
                background: var(--gray00);
                border: rem(1) solid var(--gray00);
            }

            &[data-selected] {
                background: var(--grayBg);
                border: rem(1) solid var(--gray30);
                font-weight: 500;
                color: var(--black);
            }
        }
    }

    &_double {
        .select__value {
            padding: rem(18) rem(20);
            flex-direction: column;
            align-items: flex-start;
            //row-gap: rem(4);
            border: rem(1) solid var(--black);

            @include laptop {
                padding: rem(14) rem(20);
            }

            @include mobile {
                row-gap: rem(4);

                div:nth-of-type(1) {
                    font-size: rem(12);
                }
            }
        }

        .select__sub-value {
            color: var(--gray60);
        }

        .select__double-option {
            padding: rem(16) rem(20);
            color: var(--black);
            text-decoration: none;
            display: block;

            &:hover {
                background: var(--grayBg);
                cursor: pointer;
            }

            &_selected {
                color: var(--sageDark);
            }
        }
    }

    &:hover {
        .select__value:not(:disabled) {
            background: var(--white);
            border: rem(1) solid var(--gray60);
        }
    }
}

.range-slider {
    /* position: relative;
            width: 95vmin;
            background-color: #ffffff;
            padding: 50px 40px 20px 40px;
            border-radius: 10px; */

    &__box {
        position: relative;
        width: 100%;
        //height: 100px;
        //margin-top: 30px;
    }

    &__track {
        width: 100%;
        height: rem(2);
        position: absolute;
        margin: auto;
        top: 0;
        bottom: 0;
        border-radius: rem(20);
        overflow-x: hidden;
    }

    input[type="range"] {
        -webkit-appearance: none;
        -moz-appearance: none;
        appearance: none;
        width: 100%;
        outline: none;
        position: absolute;
        margin: auto;
        top: 0;
        bottom: 0;
        background-color: transparent;
        pointer-events: none;
    }

    input[type="range"]::-webkit-slider-runnable-track {
        -webkit-appearance: none;
        height: rem(2);
    }

    input[type="range"]::-moz-range-track {
        -moz-appearance: none;
        height: rem(2);
    }

    input[type="range"]::-ms-track {
        appearance: none;
        height: rem(2);
    }

    input[type="range"]::-webkit-slider-thumb {
        -webkit-appearance: none;
        height: rem(18);
        width: rem(18);
        background-color: var(--black);
        border: rem(5) solid var(--white);
        cursor: pointer;
        margin-top: rem(-8.5);
        pointer-events: auto;
        border-radius: 50%;
        box-shadow: 0px 0px 15px 0px #0000000d, 0px 4px 4px 0px #0000000d;
        flex-shrink: 0;
    }

    input[type="range"]::-moz-range-thumb {
        -webkit-appearance: none;
        height: rem(18);
        width: rem(18);
        background-color: var(--black);
        border: rem(5) solid var(--white);
        cursor: pointer;
        margin-top: rem(-8.5);
        pointer-events: auto;
        border-radius: 50%;
        box-shadow: 0px 0px 15px 0px #0000000d, 0px 4px 4px 0px #0000000d;
        flex-shrink: 0;
    }

    input[type="range"]::-ms-thumb {
        -webkit-appearance: none;
        height: rem(18);
        width: rem(18);
        background-color: var(--black);
        border: rem(5) solid var(--white);
        cursor: pointer;
        margin-top: rem(-8.5);
        pointer-events: auto;
        border-radius: 50%;
        box-shadow: 0px 0px 15px 0px #0000000d, 0px 4px 4px 0px #0000000d;
        flex-shrink: 0;
    }

    /* input[type="range"]:active::-webkit-slider-thumb{
                background-color: #ffffff;
                border: 3px solid #e25922;
            } */

    .values {
        background-color: #3264fe;
        width: 32%;
        position: absolute;
        margin: auto;
        padding: 10px 0;
        border-radius: 5px;
        text-align: center;
        font-weight: 500;
        font-size: 25px;
        color: #ffffff;
        top: 50vh;
        left: 40vw;
        display: none;
    }

    .values:before {
        content: "";
        position: absolute;
        height: 0;
        width: 0;
        border-top: 15px solid #3264fe;
        border-left: 15px solid transparent;
        border-right: 15px solid transparent;
        margin: auto;
        bottom: -14px;
        left: 0;
        right: 0;
    }
}

.pagination {
    display: flex;
    flex-direction: column;
    align-items: center;
    row-gap: rem(16);
    margin-top: rem(40);

    @include tablet {
        margin-top: rem(32);
    }

    @include mobile {
        margin-top: rem(32);
    }

    &__count {
        color: var(--gray50);
        font-size: rem(14);

        @include laptop {
            font-size: rem(12);
        }

        @include tablet {
            font-size: rem(12);
        }

        @include mobile {
            font-size: rem(10);
        }
    }

    &__box {
        display: flex;
        column-gap: rem(40);
    }

    &__btn {
        width: rem(40);
        height: rem(40);
        border-radius: rem(8);
        display: flex;
        justify-content: center;
        align-items: center;
        cursor: pointer;
        border: rem(1) solid var(--black);

        @include tablet {
            width: rem(36);
            height: rem(36);
        }

        img {
            width: rem(24);
            height: rem(24);

            @include tablet {
                width: rem(20);
                height: rem(20);
            }
        }

        &:hover {
            background: var(--white);
        }

        &_disabled {
            border: rem(1) solid var(--gray20);

            img {
                opacity: 0.2;
            }

            &:hover {
                background: unset;
            }
        }
    }

    &__navigation {
        display: flex;
    }

    &__page {
        width: rem(40);
        height: rem(40);
        border-radius: rem(8);
        display: flex;
        justify-content: center;
        align-items: center;
        cursor: pointer;
        text-decoration: none;
        color: var(--gray50);
        font-size: rem(14);

        @include laptop {
            font-size: rem(12);
        }

        @include tablet {
            font-size: rem(12);
        }

        @include mobile {
            font-size: rem(12);
        }

        &:hover {
            background: var(--white);
            color: var(--black);
        }

        &_active {
            color: var(--black);
            background: var(--gray00);
        }

        &_disabled {
            &:hover {
                color: var(--gray50);
                background: var(--grayBg);
            }
        }
    }
}

.search {
    width: 100%;
    max-width: rem(600);
    z-index: 1;
    position: relative;
    border: rem(1) solid var(--gray30);
    border-radius: rem(12);
    overflow: hidden;

    &:has(+ .modal__shop) {
        margin-bottom: rem(40);
        @include laptop{
            margin-bottom: rem(24);
        }
    }

    @include laptop {
        max-width: rem(368);
        border-radius: rem(8);
    }

    @include tablet {
        max-width: unset;
    }

    &__input {
        width: 100%;
        height: 100%;
        padding: rem(18) rem(20);
        padding-right: rem(50);
        line-height: 1;
        outline: none;
        background: transparent;

        @include laptop {
            padding: rem(13) rem(20);
        }
    }

    &__icon {
        width: rem(24);
        height: rem(24);
        position: absolute;
        top: 50%;
        transform: translateY(-50%);
        right: rem(20);
        z-index: 2;
        cursor: pointer;

        img {
            width: 100%;
            height: 100%;
        }
    }
}

.input {
    width: 100%;
    padding: rem(17) rem(20);
    border-radius: rem(12);
    border: rem(1) solid var(--gray30);
    background: none;

    font-size: rem(16);
    font-weight: 400;
    line-height: 120%;
    letter-spacing: -0.03em;
    color: var(--black);
    transition: all 0.3s ease;

    &::-webkit-contacts-auto-fill-button {
        opacity: 0;
        pointer-events: none;
    }

    &:disabled {
        pointer-events: none;
        opacity: 0.6;
    }

    @include laptop {
        padding: rem(12) rem(20);
        border-radius: rem(8);
        font-size: rem(14);
    }

    @include tablet {
        padding: rem(14) rem(20);
        font-size: rem(12);
    }

    &-block {
        position: relative;

        &:has(.input:disabled) {
            .input-close{
                opacity: 0.5;
                pointer-events: none;
                img{
                    pointer-events: none;
                }
            }
        }

        &:has(.input_day:focus:invalid),
        &:has(.input_day.value-show) {
            &::before {
                display: none;
            }
        }

        &:has(.input_day:invalid) {
            &::before {
                content: "Дата рождения*";
                position: absolute;
                top: rem(2);
                left: rem(2);
                pointer-events: none;

                height: rem(35.1875);
                display: flex;
                align-items: end;
                padding-left: rem(18);
                border-radius: rem(12);

                background: var(--grayBg);
                z-index: 10;

                font-size: rem(16);
                font-weight: 400;
                line-height: 120%;
                letter-spacing: -0.03em;
                color: var(--gray60);
                // transition: all 0.3s ease;

                @include laptop {
                    border-radius: rem(8);
                    font-size: rem(14);
                    height: rem(27.7969);
                }

                @include tablet {
                    font-size: rem(12);
                    height: rem(28.3906);
                }
            }
        }

        &.clear-field {
            .input-close {
                visibility: visible;
                opacity: 1;
            }
        }

        &.error-field {
            .input {
                border: rem(1) solid var(--red);
            }

            .input__error {
                display: block;
            }
        }
    }

    &-title {
        font-weight: 400;
        font-size: rem(14);
        line-height: 120%;
        letter-spacing: -0.01em;
        color: var(--black);
        margin-bottom: rem(8);

        @include laptop {
            font-size: rem(12);
        }

        @include mobile {
            font-size: rem(10);
        }

        &:has(.input-title-change) {
            display: flex;
            align-items: center;
            justify-content: space-between;
            gap: rem(24);

            @include laptop {
                gap: rem(16);
            }
        }

        &-change {
            font-size: rem(12);
            font-style: normal;
            font-weight: 500;
            line-height: 130%;
            text-decoration: underline;
            text-decoration-skip-ink: none;
            text-transform: uppercase;
            color: var(--gray40);
            cursor: pointer;

            @include laptop {
                font-size: rem(10);
            }

            @include mobile {
                font-size: rem(8);
            }
        }

        &_big {
            font-size: rem(16);

            @include laptop {
                font-size: rem(14);
            }

            @include tablet {
                font-size: rem(12);
            }

            @include mobile {
                font-size: rem(10);
            }
        }
    }

    &-close {
        background: var(--grayBg);
        position: absolute;
        right: rem(20);
        top: rem(16);
        width: rem(24);
        height: rem(24);
        cursor: pointer;
        opacity: 0;
        visibility: hidden;
        transition: all 0.3s ease;

        @include laptop {
            top: rem(10);
        }

        &__img {
            width: rem(24);
            height: rem(24);
        }
    }

    &_text {
        border-radius: rem(8);
        height: rem(72);
        resize: vertical;
    }

    &_border {
        border-color: var(--black);
    }

    &::placeholder {
        color: var(--gray60);
    }

    &:hover {
        border-color: var(--gray60);
    }

    &:focus {
        outline: none;
        border-color: var(--black);
    }

    &:user-invalid,
    &:not(:placeholder-shown):user-invalid {
        border: rem(1) solid var(--red);

        &+.input__error {
            display: block;
        }
    }

    &__error {
        display: none;
        margin-top: rem(4);
        color: var(--red);
        font-weight: 500;
        font-size: rem(14);
        line-height: 120%;
        //text-transform: lowercase;

        a {
            color: var(--red);
        }

        @include laptop {
            font-size: rem(12);
        }
    }

    &_day {
        position: relative;
        overflow: hidden;
        height: rem(55.1875);

        @include laptop {
            height: rem(42.7969);
        }

        @include tablet {
            height: rem(44.3906);
        }

        &::-webkit-inner-spin-button,
        &::-webkit-calendar-picker-indicator {
            position: absolute;
            inset: 0;
            height: 100vh;
            width: 100vw;
            opacity: 0;
            cursor: pointer;
        }

        &:invalid {}
    }

    &-sub {
        margin-top: rem(8);
        color: var(--gray50);
        font-size: rem(14);
        font-style: normal;
        font-weight: 400;
        line-height: 140%;
    }
}

.tab-box {
    &:not(.tab-box-active) {
        display: none;
    }
}

.tabs {
    &__panel {
        border-radius: rem(8);
        border: rem(1) solid var(--black);
        display: flex;
        justify-content: space-between;
        column-gap: rem(20);

        @include mobile {
            display: none;
        }
    }

    &__select {
        display: none;

        @include mobile {
            display: block;
            margin-top: rem(24);
        }
    }

    &__btn {
        cursor: pointer;
        width: 100%;
        padding: rem(12) rem(16);
        color: var(--gray50);
        text-align: center;
        user-select: none;

        @include laptop {
            padding: rem(14);
        }

        @include tablet {
            padding: rem(16);
        }

        &.tab-btn-active {
            color: var(--black);
        }
    }
}

.variants {
    display: flex;
    gap: rem(12);
    width: 100%;

    @include tablet {
        gap: rem(8);
    }

    &_wrap {
        flex-wrap: wrap;
    }

    &__label {
        color: var(--gray50);
        padding: rem(9.5) rem(12);
        border-radius: rem(8);
        border: rem(1) solid var(--gray00);
        cursor: pointer;
        display: flex;
        justify-content: center;
        text-align: center;
        align-items: center;
        user-select: none;
        line-height: 1;

        @include mobile {
            font-size: rem(12);
        }

        &_wide {
            width: 100%;
        }

        input {
            display: none;
        }

        &:has(input:checked) {
            background: var(--gray10);
            border: rem(1) solid transparent;
            color: var(--black);
        }
    }
}

.checkradio {
    display: flex;
    align-items: center;
    gap: rem(16);
    user-select: none;

    &__input {
        display: none;
    }

    &__mask {
        position: relative;
        cursor: pointer;
        width: rem(51);
        height: rem(31);
        border-radius: rem(100);
        background: var(--gray30);
        padding: rem(3);
        transition: all 0.3s ease;

        &::after {
            content: "";
            position: absolute;
            left: rem(3);
            width: rem(25);
            height: rem(25);
            box-shadow: 0 rem(3) rem(1) 0 rgba(0, 0, 0, 0.06),
                0 rem(3) rem(7) 0 rgba(0, 0, 0, 0.15), 0 0 0 rem(1) rgba(0, 0, 0, 0.04);
            background: #fff;
            border-radius: 100%;
            transition: all 0.3s ease;
        }
    }

    &__text {
        font-weight: 400;
        font-size: rem(16);
        line-height: 140%;
        letter-spacing: -0.01em;
        color: var(--gray50);
        cursor: pointer;

        @include laptop {
            font-size: rem(14);
        }

        @include tablet {
            font-size: rem(12);
        }
    }

    &__input:checked~&__mask {
        background: var(--black);

        &::after {
            left: rem(23);
        }
    }

    &__input:checked~&__text {
        color: var(--black);
    }
}

.quantity {
    display: flex;

    @include mobile {
        margin-left: auto;
    }

    input {
        width: rem(54);
        text-align: center;
        background: transparent;
        text-align: center;
        border: none;
        outline: none;

        @include mobile {
            width: rem(37);
        }
    }

    &__button {
        width: rem(24);
        height: rem(24);
        transition-duration: 0.3s;
        user-select: none;
        cursor: pointer;

        img {
            width: 100%;
            height: 100%;
            pointer-events: none;
        }

        &:hover {
            opacity: 0.6;
        }

        @include laptop {
            width: rem(20);
            height: rem(20);
        }
    }
}