.footer{
    padding-bottom: rem(1);

    @include tablet{
        display: flex;
        flex-direction: column;
    }

    &__section{
        border-top: rem(1) solid var(--black);

        @include tablet{
            &:has(.footer__inner_short){
                order: 10;
                @include mobile{
                    order: unset;
                }
            }
        }

        &_auth{
            @include mobile{
                display: none;
            }
        }

        &__rights{
            
        }

        /* &_rights-mobile{
            display: none;
            @include mobile{
                display: block;
            }
        } */

        &_bottom{
            display: flex;
            flex-direction: column;
            position: relative;
            @include tablet{
                flex-direction: column-reverse;
            }
            @include mobile{
                border-top: none;
                .container{
                    padding: 0;
                }
            }
        }

    }

    &__inner{
        margin: rem(80) 0;

        @include laptop{
            margin: rem(60) 0;
        }
        @include tablet{
            margin: rem(40) 0;
        }
        @include mobile{
            margin: rem(20) 0;
        }

        &_tall{
            margin-bottom: rem(200);

            @include laptop{
                margin-top: rem(80);
                margin-bottom: rem(180);
            }
            @include tablet{
                margin-top: rem(40);
                margin-bottom: rem(40);
                min-height: rem(360);
            }
            @include mobile{
                margin-bottom: rem(8);
                min-height: unset;
            }
        }

        &_short{
            margin: rem(20) 0;

            @include tablet{
                margin: rem(16) 0;
            }
            @include mobile{
                margin: rem(24) 0;
            }

        }

        &_auth{
            margin: 0;
            padding: rem(11) 0 rem(9) 0;
            border-top: rem(1) solid var(--black);

            @include tablet{
                //margin: rem(12) 0;
                //order: -1;
                border-top: none;
                border-bottom: rem(1) solid var(--black);
            }
            @include mobile{
                display: none;
            }

        }

        &_rights{
            margin: rem(28) 0;
            .footer__box{
                display: flex;
                justify-content: center;
            }
        }

    }

    &__box{
        display: flex;
        column-gap: rem(216);
        width: 100%;

        @include laptop{
            column-gap: rem(112);
        }
        @include tablet{
            column-gap: rem(130);
        }
        @include mobile{
            flex-wrap: wrap;
        }

        &_bottom{
            align-items: center;
            justify-content: space-between;
            column-gap: 0;
        }

        &_info{
            column-gap: rem(485);

            @include laptop{
                column-gap: rem(125);
            }
            @include tablet{
                column-gap: rem(60);
            }
        }

    }

    &__left{
        
    }

    &__phone{
        color: var(--sageDark);
        @include laptop{
            font-size: rem(20);
        }
        @include tablet{
            font-size: rem(18);
        }
        @include mobile{
            font-size: rem(16);
        }
    }

    &__time{
        margin-top: rem(8);
        color: var(--gray40);
        @include mobile{
            font-size: rem(12);
        }
    }

    &__contacts{
        margin-top: rem(32);
        display: flex;
        column-gap: rem(12);
        flex-shrink: 0;

        @include laptop{
            margin-top: rem(22);
        }
        @include tablet{
            margin-top: rem(20);
        }
        @include mobile{
            margin-top: rem(32);
        }
    }

    &__contacts-link{
        text-decoration: none;
        text-transform: uppercase;
        font-weight: 400;
        @include laptop{
            font-size: rem(12);
        }
        @include tablet{
            font-size: rem(12);
        }
        @include mobile{
            font-size: rem(12);
        }
    }

    &__subscribe{
        margin-top: rem(110);

        @include laptop{
            margin-top: rem(40);
        }
        @include mobile{
            margin-bottom: rem(40);
            margin-top: rem(32);
        }
    }

    &__subscribe-title{
        @include mobile{
            font-size: rem(12);
        }
    }

    &__subscribe-form{
        margin-top: rem(20);
        min-width: rem(448);
        position: relative;

        @include laptop{
            margin-top: rem(16);
            min-width: rem(272);
        }
        @include tablet{
            min-width: rem(230);
            margin-top: rem(4);
        }
    }

    &__subscribe-input{
        padding: rem(18) 0;
        background: transparent;
        border: none;
        border-bottom: rem(1) solid var(--gray60);
        outline: none;
        width: 100%;

        @include tablet{
            padding: rem(17) 0;
        }
    }

    &__subscribe-btn{
        width: rem(24);
        height: rem(24);
        opacity: 0.6;
        position: absolute;
        right: 0;
        top: 50%;
        transform: translateY(-50%);

        img{
            width: 100%;
            height: 100%;
        }
    }

    &__nav-mobile{
        display: none;
        width: 100%;
        
        @include tablet{
            display: flex;
            flex-direction: column;
            row-gap: rem(16);
        }

        ul{
            margin: 0;
            padding: 0;
            display: flex;
            flex-direction: column;
            row-gap: rem(20);
        }

        li{
            list-style: none;
        }
    }

    &__nav{
        display: flex;
        column-gap: rem(120);
        width: 100%;

        @include laptop{
            column-gap: rem(50);
        }
        @include tablet{
            display: none;
        }

        ul{
            margin: 0;
            padding: 0;
            display: flex;
            flex-direction: column;
            row-gap: rem(24);
            max-width: rem(300);

            @include laptop{
                max-width: rem(155);
                row-gap: rem(16);

                &:nth-of-type(1) {
                    margin-right: rem(10);
                }
            }
        }

        li{
            list-style: none;
        }

    }

    &__nav-link{
        text-transform: uppercase;
        text-decoration: none;
        display: block;
        max-width: rem(200);
        white-space: nowrap;

        @include laptop{
            max-width: unset;
            white-space: unset;
        }

        &_title{
            color: var(--gray40);
            margin-bottom: rem(8);
        }
    }

    &__rights{
        color: var(--gray40);

        @include tablet{
            max-width: rem(125);
        }
        @include mobile{
            max-width: 100%;
            //display: none;
            order: 3;
            text-align: center;
            width: 100%;
            padding-top: rem(24);
            border-top: rem(1) solid var(--black);
        }

        /* &_mobile{
            display: block;
        } */

    }

    &__social{
        display: flex;
        column-gap: rem(24);

        @include mobile{
            //display: none;
            order: -1;
            margin: rem(16) 0 0 0;
            padding-bottom: rem(32);
            width: 100%;
            justify-content: center;
            border-bottom: rem(1) solid var(--black);
        }

        /* &_mobile{
            display: none;
            @include mobile{
                display: flex;
                justify-content: center;
                width: 100%;
                margin: rem(40) 0 rem(24) 0;
            }
        } */
    }

    &__social-icon{
        width: rem(24);
        height: rem(24);
        display: block;
        img{
            width: 100%;
            height: 100%;
        }
    }

    &__partners{
        display: flex;
        column-gap: rem(24);
        position: absolute;
        left: 50%;
        transform: translateX(-50%);

        @include tablet{
            column-gap: rem(12);
        }
        @include mobile{
            column-gap: rem(24);
            width: 100%;
            justify-content: center;
            position: relative;
            margin: rem(24) 0;
        }
    }

    &__partners-icon{
        position: relative;
        height: rem(11);
        display: flex;
        justify-content: center;
        align-items: center;
        img{
            width: auto;
            height: 100%;
            display: block;
            object-fit: contain;
        }
        @include tablet{
            height: rem(8);
        }
        @include mobile{
            height: rem(11);
        }
    }
    
    &__auth{
        text-transform: uppercase;
        text-align: center;
        width: 100%;



        a{
            line-height: 1;
        }
    }

    &__block{
        width: rem(450);
        display: flex;
        flex-direction: column;
        row-gap: rem(8);

        @include tablet{
            width: rem(300);
        }
        @include mobile{
            width: 100%;
            row-gap: rem(4);
            position: relative;

            &:nth-of-type(2){
                margin-top: rem(40);
                &::before{
                    content: '';
                    width: calc(100% + rem(20));
                    top: rem(-19);
                    left: rem(-10);
                    position: absolute;
                    border-top: rem(1) solid var(--black);
                }
            }
            
        }


        //УБРАТЬ
        .t-primary-m{
            color: var(--gray80);
        }
        .t-navigation{
            text-transform: uppercase;
            margin-top: rem(8);
        }

    }

    &__block-title{
        @include laptop{
            font-size: rem(20);
        }
        @include tablet{
            font-size: rem(18);
        }
        @include mobile{
            font-size: rem(16);
        }
    }

    &__block-link{
        @include laptop{
            font-size: rem(12);
        }
        @include tablet{
            font-size: rem(12);
        }
        @include mobile{
            font-size: rem(12);
        }

        &_mobile{
            display: none;
            @include mobile{
                display: block;
                position: absolute;
                right: 0;
                bottom: calc(50% - rem(5));
                transform: translateY(50%);
            }
        }

    }

}