

.cart{

    &_success{
        .certificate-buy__info{
            position: sticky;
            top: rem(120);
        }

        @include tablet{
            .cart__top{
                justify-content: flex-start;
            }
            .certificate-buy__info{
                margin-top: rem(56);
            }
        }
        @include mobile{
            .cart__card{
                padding-left: 0;
            }
            .cart__card-title{
                max-width: rem(200);
            }
            .cart__card-block_auto{
                position: relative;
                bottom: 0;
            }
            .cart__card-image{
                margin-bottom: 0;
            }
            .certificate-buy__info{
                margin-top: rem(40);
            }
        }
    }

    .inner{
        margin-top: rem(64);
        @include tablet{
            margin-top: rem(48);
        }
        @include mobile{
            margin-top: rem(24);
        }
    }

    &__box{
        display: flex;
        justify-content: space-between;
        flex-wrap: wrap;
    }

    &__empty{
        width: 100%;
        display: flex;
        flex-direction: column;
        align-items: center;
        row-gap: rem(32);
        margin-top: rem(8);

        @include laptop{
            row-gap: rem(24);
        }@include mobile{
            margin-top: 0;
        }
    }

    &__empty-title{
        
    }

    &__empty-btn{
        width: 100%;
        max-width: rem(292);
    }

    &__top{
        width: 100%;
        max-width: rem(1072);
        display: flex;
        justify-content: flex-end;
        align-items: center;
        margin-bottom: rem(28);
        column-gap: rem(173);
        position: relative;

        @include laptop{
            max-width: rem(608);
            column-gap: rem(60);
            margin-bottom: rem(24);
        }
        @include tablet{
            max-width: 100%;
            column-gap: rem(102);
        }
        @include mobile{
            flex-wrap: wrap;
            margin-bottom: rem(24);
            row-gap: rem(24);
            column-gap: rem(24);
            justify-content: flex-start;
        }
    }

    &__top-title{
        @include laptop{
            font-size: rem(20);
        }
        @include tablet{
            font-size: rem(18);
        }
        @include mobile{
            font-size: rem(22);
        }
    }

    &__top-block{
        margin-right: auto;
        display: flex;
        flex-direction: column;
        row-gap: rem(4);

        @include tablet{
            margin-right: rem(-20);
        }
        @include mobile{
            width: 100%;
        }
    }

    &__top-link{
        text-decoration: none;
        white-space: nowrap;

        &_share{
            @include mobile{
                display: none;
            }
        }

        &_mobile{
            display: none;
            @include mobile{
                display: block;
                margin-left: auto;

                img{
                    width: rem(20);
                    height: rem(20);
                }
            }
        }

        @include mobile{
            font-size: rem(12);
        }
    }

    &__top-amount{
        @include mobile{
            font-size: rem(10);
        }
    }

    &__top-check{
        display: flex;
        align-items: center;
        column-gap: rem(20);
        cursor: pointer;
        flex-shrink: 0;
        user-select: none;

        input {
            position: absolute;
            opacity: 0;
            pointer-events: none;
        }

        &::before {
            content: "";
            display: block;
            width: rem(24);
            height: rem(24);
            background: url(/img/svg/checkbox-empty.svg) no-repeat;
            background-size: cover;
            flex-shrink: 0;
            pointer-events: none;

            @include mobile{
                width: rem(20);
                height: rem(20);
            }
        }

        &:has(input:checked) {
            &::before {
                background: url(/img/svg/checkbox-full.svg) no-repeat;
                background-size: cover;
            }
        }

        @include mobile{
            font-size: rem(12);
        }
    }


    &__content{
        width: 100%;
        max-width: rem(1072);
        display: flex;
        flex-direction: column;
        row-gap: rem(100);

        @include laptop{
            max-width: rem(608);
            row-gap: rem(80);
        }
        @include tablet{
            max-width: 100%;
            row-gap: rem(64);
        }
    }

    &__sticky{
        @include tablet{
            width: 100%;
        }
    }

    &__info{
        
        top: rem(120);
        position: sticky;
        width: 100%;
        max-width: rem(604);
        border-radius: rem(24);
        padding: rem(64) rem(78);
        border: rem(1) solid var(--gray20);
        display: flex;
        flex-direction: column;
        row-gap: rem(32);

        @include laptop{
            padding: rem(40);
            max-width: rem(416);
            row-gap: rem(24);
        }
        @include tablet{
            position: relative;
            top: unset;
            margin-top: rem(64);
            max-width: unset;
            padding: rem(24);
            border-radius: rem(18);
        }
        @include mobile{
            padding: 0;
            border-radius: 0;
            border: none;
        }

    }

    &__info-title{
        @include laptop{
            font-size: rem(20);
        }
    }

    &__info-list{
        display: flex;
        flex-direction: column;
        row-gap: rem(5);
    }

    &__info-line{
        display: flex;
        justify-content: space-between;
    }

    &__info-alert{
        border-radius: rem(12);
        border: rem(1) solid var(--gray20);
        display: flex;
        align-items: center;
        padding: rem(16) rem(24);
        column-gap: rem(12);
        margin-top: rem(-8);
        margin-bottom: rem(-8);
        @include laptop{
            padding: rem(11) rem(20);
        }
        @include tablet{
            margin-top: 0;
            margin-bottom: 0;
        }
    }

    &__info-result{
        display: flex;
        justify-content: space-between;

        @include mobile{
            *{
                font-size: rem(16);
            }
        }
    }

    &__info-payment{
        @include tablet{
            margin-top: rem(-88);
            max-width: 48%;
            padding: rem(20) rem(18) !important;
            font-size: rem(14) !important;
            
            .block-product__payment-arrow{
                display: none;
            }
        }
        @include mobile{
            max-width: 100%;
            position: relative;
            order: 20 !important;
            padding: rem(10) rem(20) !important;
            padding-right: rem(36) !important;
            margin-top: unset !important;

            .block-product__payment-arrow{
                display: block;
            }
        }
    }

    &__info-text{
        &_gray{
            color: var(--gray40);
        }
        &_sage{
            color: var(--sageDark);
        }
    }

    &__info-additional{
        display: flex;
        align-items: center;
        column-gap: rem(16);
        position: relative;

        &_second{
            @include tablet{
                margin-top: rem(-16);
            }
            @include mobile{
                margin-top: unset;
            }
        }

        @include tablet{
            margin-left: 54%;
            column-gap: rem(12);
        }
        @include mobile{
            margin-left: unset;

        }
    }

    &__info-icon{
        width: rem(36);
        height: rem(36);
        display: flex;
        justify-content: center;
        align-items: center;
        border: rem(1) solid var(--gray20);
        border-radius: rem(8);
        flex-shrink: 0;

        @include laptop{
            width: rem(24);
            height: rem(24);
            border-radius: rem(6);
        }

        img{
            width: rem(24);
            height: rem(24);

            @include laptop{
                width: rem(18);
                height: rem(18);
            }
        }
    }

    &__info-link{
        font-size: rem(14);
        max-width: rem(276);
        color: var(--black);

        a{
            font-weight: 500;
            color: var(--black);
        }

        span{
            cursor: pointer;
            font-weight: 500;
        }

        @include mobile{
            font-size: rem(10);
            max-width: unset;
        }
    }

    &__info-close{
        position: absolute;
        right: 0;
        top: 50%;
        transform: translateY(-50%);
        cursor: pointer;


        img{
            width: rem(24);
            height: rem(24);
        }

        @include laptop{
            img {
                width: rem(20);
                height: rem(20);
            }
        }

    }
    
    &__info-btn{
        @include tablet{
            order: 19;
        }

        &_pay{
            margin-top: rem(-8);
        }
    }

    &__info-note{
        color: var(--gray60);
        margin-top: rem(-24);

        @include laptop{
            margin-top: rem(-12);
        }
        @include tablet{
            order: 20;
        }

        a{
            color: var(--gray60);
        }
    }



    &__list{
        border-top: rem(1) solid var(--gray20);
    }

    &__list-result{
        display: none;
        @include mobile{
            display: block;
            margin-top: rem(24);
            font-size: rem(16);
        }
    }

    &__card{
        border-bottom: rem(1) solid var(--gray20);
        padding: rem(40) 0;
        display: flex;
        column-gap: rem(170);

        @include laptop{
            column-gap: rem(64);
            padding: rem(32) 0;
        }
        @include tablet{
            padding: rem(20) 0;
            column-gap: rem(82);
        }
        @include mobile{
            column-gap: rem(10);
            padding: rem(16) 0;
            padding-left: rem(36);
            position: relative;
        }
    }

    

    &__card-title{
        width: 100%;
        text-align: left;

        @include laptop{
            max-width: rem(260);
            margin-right: auto;
            font-size: rem(18);
        }
        @include tablet{
            font-size: rem(16);
            max-width: rem(240);
        }
        @include mobile{
            max-width: rem(154);
            display: -webkit-box;
            overflow: hidden;
            -webkit-box-orient: vertical;
            -webkit-line-clamp: 2;
            padding-top: rem(24);
        }
    }

    &__card-image{
        width: rem(214);
        height: rem(296);
        overflow: hidden;
        border-radius: rem(16);
        flex-shrink: 0;

        @include laptop{
            width: rem(128);
            height: rem(177);
        }
        @include mobile{
            width: rem(92);
            height: rem(124);
            margin-bottom: rem(33);
            border-radius: rem(8);
        }

        img{
            width: 100%;
            height: 100%;
        }
    }

    &__card-content{
        width: 100%;
        display: flex;
        flex-direction: column;
    }

    &__card-top{
        display: flex;
        justify-content: flex-end;
        column-gap: rem(24);
        width: 100%;
        position: relative;

        @include mobile{
            column-gap: rem(16);
        }
    }

    &__card-check{
        display: block;
        cursor: pointer;
        width: rem(24);
        height: rem(24);
        flex-shrink: 0;
        position: absolute;
        top: 0;
        left: rem(-44);

        input {
            position: absolute;
            opacity: 0;
            pointer-events: none;
        }

        &::before {
            content: "";
            display: block;
            width: rem(24);
            height: rem(24);
            background: url(/img/svg/checkbox-empty.svg) no-repeat;
            background-size: cover;
            flex-shrink: 0;
            pointer-events: none;
            @include mobile{
                width: rem(20);
                height: rem(20);
            }
        }

        &:has(input:checked) {
            &::before {
                background: url(/img/svg/checkbox-full.svg) no-repeat;
                background-size: cover;
            }
        }

        @include mobile{
            width: rem(20);
            height: rem(20);
            left: rem(-140);
        }
    }

    &__card-favourite{
        flex-shrink: 0;
        display: block;
        width: rem(24);
        height: rem(24);
        background: url(/img/svg/favourite.svg) no-repeat;
        background-size: cover;
        cursor: pointer;

        input{
            position: absolute;
            pointer-events: none;
            opacity: 0;
        }

        &:has(input:checked){
            background: url(/img/svg/favourite-fill.svg) no-repeat;
            background-size: cover;
        }

        @include mobile{
            width: rem(20);
            height: rem(20);
        }
    }

    &__card-del{
        flex-shrink: 0;
        width: rem(24);
        height: rem(24);
        display: block;
        
        img{
            width: 100%;
            height: 100%;
            pointer-events: none;
        }

        @include mobile{
            width: rem(20);
            height: rem(20);
        }
    }

    &__card-info{
        display: flex;
        column-gap: rem(20);
        margin-top: auto;
        margin-bottom: rem(72);

        @include laptop{
            column-gap: rem(16);
            margin-bottom: 0;
        }
        @include mobile{
            flex-wrap: wrap;
            column-gap: rem(9);
            margin-top: rem(20);
        }
    }

    &__card-block{
        display: flex;
        flex-direction: column;
        justify-content: flex-end;
        row-gap: rem(12);
        width: rem(214);

        @include laptop{
            width: rem(111);
            row-gap: rem(8);
        }
        @include tablet{
            width: rem(134);
        }
        @include mobile{
            width: rem(105);
            row-gap: rem(2);
        }

        &_auto{
            width: auto;
            margin-left: auto;
            @include mobile{
                position: absolute;
                bottom: rem(16);
            }
        }

        &_quantity{
            @include mobile{
                position: absolute;
                bottom: rem(16);
                right: 0;
            }
        }

    }

    &__card-number{
        color: var(--gray40);
        margin-bottom: rem(12);

        @include mobile{
            position: absolute;
            top: 0;
            margin-top: rem(16);
        }
    }

    &__card-price{
        display: flex;
        column-gap: rem(16);
        white-space: nowrap;

        @include laptop{
            column-gap: rem(12);
        }
    }

    &__card-text{
        
        &_sage{
            color: var(--sageDark);
            @include mobile{
                text-align: right;
            }
        }

        &_old{
            color: var(--gray40);
            text-decoration: line-through;
            @include mobile{
                display: none;
            }
        }
    }




    &__form{
        display: flex;
        flex-direction: column;
        row-gap: rem(100);
        
        @include laptop{
            row-gap: rem(80);
        }
        
        @include tablet{
            row-gap: rem(64);
        }
        @include mobile{
            row-gap: rem(48);
        }
    }

    &__form-part{
        


    }
    
    &__form-top{
        display: flex;
        justify-content: space-between;
        margin-bottom: rem(24);

 
    }

    &__form-title{
        @include laptop{
            font-size: rem(20);
        }
        @include tablet{
            font-size: rem(18);
        }
        @include laptop{
            font-size: rem(22);
        }
    }

    &__form-link{
        align-self: center;
        margin-right: unset;
        color: var(--gray50);
        border-color: var(--gray50);

        @include mobile{
            margin-top: auto;
            font-size: rem(12);
        }
    }

    &__form-box{
        
        &_grid{
            display: flex;
            flex-wrap: wrap;
            gap: rem(8) rem(20);
            @include laptop{
                gap: rem(12);
            }
            @include mobile{
                flex-wrap: wrap;
                row-gap: rem(8);
            }
        }

        &_grid-delivery{
            display: flex;
            flex-wrap: wrap;
            gap: rem(20);
            @include laptop{
                gap: rem(12);
            }
            @include mobile{
                flex-wrap: wrap;
                gap: rem(8);

                .cart__form-field{
                    width: calc(50% - rem(4));
                }
                .cart__form-field_wide{
                    width: 100%;
                }
            }
        }

        &_delivery{
            display: flex;
            flex-wrap: wrap;
            gap: rem(40) rem(20);
            @include laptop{
                gap: rem(32) rem(12);
            }
            @include mobile{
                row-gap: rem(24);
            }
        }

        &_pay{
            display: flex;
            flex-wrap: wrap;
            row-gap: rem(24);
            @include tablet{
                row-gap: rem(16);
            }
        }

    }

    &__form-subtitle{
        width: 100%;
    }

    &__form-select{
        margin-bottom: rem(40);
        @include laptop{
            margin-bottom: rem(32);
        }
        @include tablet{
            margin-bottom: rem(32);
        }
        @include mobile{
            margin-bottom: rem(24);
        }
    }

    &__form-field{
        display: flex;
        flex-direction: column;
        row-gap: rem(16);
        width: calc(50% - rem(10));

        @include laptop{
            row-gap: rem(8);
            width: calc(50% - rem(6));
        }
        @include mobile{
            width: 100%;
        }

        textarea{
            height: rem(90);
            min-height: rem(56);
            //border: rem(1) solid var(--gray30);
            @include laptop{
                font-size: rem(12);
                height: rem(65);
                min-height: rem(40);
            }
            @include tablet{
                min-height: rem(44);
            }
        }

        .input__error{
            margin-top: rem(-12);
            @include laptop{
                margin-top: rem(-4);
            }
        }

        &_wide{
            width: 100%;
            margin-top: rem(8);
        }

        &.select_city {

            &::after{
                content: '';
                display: none;
            }

            .select__list{
                position: absolute;
                margin-top: rem(66);
                @include laptop{
                    margin-top: rem(50);
                }
            }
            .select__value{
                height: rem(58);
                line-height: rem(56);
                overflow: hidden;

                @include laptop{
                    line-height: rem(42);
                }
            }
        }

    }

    &__form-info{
        flex-direction: row;
        column-gap: rem(12);
        max-width: rem(388);
        margin-top: auto;
        margin-bottom: auto;
        img{
            width: rem(24);
            height: rem(24);
        }

        @include laptop{
            
            img {
                width: rem(16);
                height: rem(16);
            }
        }
        @include tablet{
            max-width: rem(295);
            img {
                width: rem(16);
                height: rem(16);
            }
        }
        @include mobile{
            max-width: rem(267);
            img {
                width: rem(14);
                height: rem(14);
            }
        }

    }

    &__form-about{
        max-width: rem(270);
        display: flex;
        column-gap: rem(12);
        margin-left: rem(26);

        img{
            width: rem(24);
            height: rem(24);
            flex-shrink: 0;
        }
        
        @include laptop{
            max-width: rem(250);
            column-gap: rem(8);
            

            img{
                width: rem(16);
                height: rem(16);
            }
        }
        @include mobile{
            max-width: unset;
            margin-left: 0;
            align-items: center;
        }
    }

    &__form-text{

        font-size: rem(16);
        

        &_gray{
            color: var(--gray80);
        }

        a{
            font-weight: 500;
            color: var(--black);
        }

        @include laptop{
            font-size: rem(14);
        }
        @include mobile{
            font-size: rem(12);
        }
        @include tablet{
            font-size: rem(12);
        }

    }

    &__form-additional{
        display: flex;
        align-items: center;
        column-gap: rem(16);
        width: 100%;

        &_label{
            margin-top: rem(-16);

            input {
                position: absolute;
                opacity: 0;
                pointer-events: none;
            }
    
            &::before {
                content: "";
                display: block;
                width: rem(24);
                height: rem(24);
                background: url(/img/svg/checkbox-empty.svg) no-repeat;
                background-size: cover;
                flex-shrink: 0;
                pointer-events: none;
    
                /* @include mobile{
                    width: rem(20);
                    height: rem(20);
                } */
            }
    
            &:has(input:checked) {
                &::before {
                    background: url(/img/svg/checkbox-full.svg) no-repeat;
                    background-size: cover;
                }
            }

            @include tablet{
                margin-top: rem(-8);
            }
        }
    }

    &__form-icon{
        width: rem(24);
        height: rem(24);
        display: flex;
        justify-content: center;
        align-items: center;
        flex-shrink: 0;

        img{
            width: rem(24);
            height: rem(24);
        }
    }

}