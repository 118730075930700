
.list-card__list {
    display: flex;
    flex-wrap: wrap;
    justify-content: flex-start;
    margin: -1rem;
    row-gap: 1.8rem;
    @include laptop{
        margin: -0.8rem; 
    }    
    @include tablet{
        margin: -0.5rem; 
    }
}



.list-card{

    &__link{
        font-size: rem(14);
        color: var(--gray40);
        border-color: var(--gray40);
        text-transform: uppercase;
        position: absolute;
        right: 0;
        bottom: 0;
        @include laptop{
            font-size: rem(12);
        }
        @include tablet{
            font-size: rem(12);
        }
        @include mobile{
            display: none;
            font-size: rem(12);
        }
    }
    
    &__filters-form{
        position: relative;
        display: flex;
        flex-wrap: wrap;
        justify-content: center;
        gap: rem(10);
        width: 100%;
        margin-top: rem(-32);
        margin-bottom: rem(32);
        padding: 0 rem(110);
    
        @include laptop{
            gap: rem(8);
            margin-top: rem(-24);
            margin-bottom: rem(24);
        }
        @include tablet{
            margin-top: rem(-16);
        }
        @include mobile{
            flex-wrap: nowrap;
            justify-content: flex-start;
            margin-top: 0;
            margin-bottom: rem(8);
            padding-bottom: rem(8);
            padding-left: rem(9);
            padding-right: rem(9);
            overflow-x: auto;
            margin-left: rem(-9);
            width: calc(100% + rem(9) + rem(9));
        }
    }

    &__filters-check{
        flex-shrink: 0;
        width: auto;
        display: flex;
        align-items: center;
        font-size: rem(14);
        padding: rem(9) rem(12);
        cursor: pointer;
        color: var(--gray60);
        font-weight: 400;
        background: var(--grayBg);
        border: rem(1) solid var(--gray30);
        border-radius: rem(8);
        outline: none;
        user-select: none;

        @include laptop{
            padding: rem(7.5) rem(12);
            line-height: 1.1;
            font-size: rem(14);
        }
        @include tablet{
            font-size: rem(12);
        }
        @include mobile{
            font-size: rem(10);
            padding: rem(9.5) rem(12);
        }

        &:has(input:checked){
            color: var(--black);
            font-weight: 500;
            background: var(--gray00);
            border: rem(1) solid var(--gray00);
        }

        input{
            pointer-events: none;
            opacity: 0;
            position: absolute;
        }

    }
}



.list-card__list--grid{
    .list-card__list {
        display: flex;
        flex-wrap: wrap;
        justify-content: flex-start;
        margin: -1rem;
        row-gap: 1.8rem;
        @include laptop{
            margin: -0.8rem; 
        }    
        @include tablet{
            margin: -0.5rem; 
        }
    }
}


/* .list-card__inner{
    padding-bottom: 10rem;
    @include laptop{
        padding-bottom: 10rem;
    }    
    @include tablet{
        padding-bottom: 8rem;
    }
    @include mobile{
        padding-bottom: 5.6rem;
    }
} */

.preview-card {
    flex: 0 1 calc(33.33% - 2rem);
    margin: 1rem;

    @include laptop{
        margin: 0.8rem;
        flex: 0 1 calc(33.33% - 1.6rem);
    }

    @include tablet{
        margin: 0.5rem; 
        flex: 0 1 calc(50% - 1rem);
    }

    @include mobile{
        flex: 0 1 100%;
    }


    &__block-text {
        width: 85%;
        margin-top: rem(8);
        @include laptop{
            width: 90%;
        }
    }

    &__img {
        width: 100%;
        height: 100%;
    }

    &__block-title, &__block-season {
        margin: 2rem 0 0rem 0;
        font-size: 2.4rem;
        color: var(--black);
        font-weight: 400;
        //letter-spacing: 0.18rem;
        margin-bottom: 0;
        text-align: left;

        @include laptop{
            font-size: 1.8rem;
            margin: 1.6rem 0 0rem 0;
            //letter-spacing: 0.11rem;
        }
        @include tablet{
            font-size: 1.6rem;
            //letter-spacing: 0.11rem;
            br{
                display: none;
            }
        }
    }

    &__block-date{
        color: var(--gray80);
        font-size: rem(16);
        margin: 0;
        @include laptop{
            font-size: rem(14);
        }
        @include tablet{
            font-size: rem(12);
        }
        @include mobile{
            font-size: rem(12);
        }
    }

    &__block-season {
        margin-top: 0;
        margin-bottom: 1.6rem;

        @include laptop{
            margin-bottom: 1rem;
        }
        @include tablet{
            margin-bottom: 0.8rem;
        }

    }

    &__block-link{
        margin-top: rem(16);
        @include mobile{
            font-size: rem(12);
        }
    }

    &__block-desc {
        margin: 0;
        font-size: 1.6rem;
        color: var(--gray60);
        line-height: 1.4;
        font-weight: 500;
        letter-spacing: -0.04rem;
        margin-top: rem(8);

        @include laptop{
            font-size: 1.4rem;
            letter-spacing: -0.035rem;
        }
        @include mobile{
            font-size: rem(12);
            //margin: rem(8) 0 rem(16) 0;
        }

        &_short{
            display: -webkit-box;
            overflow: hidden;
            -webkit-box-orient: vertical;
            -webkit-line-clamp: 2;
        }

    }
    
   

    &__link {
        width: 100%;
        height: 100%;
        display: flex;
        align-items: center;
    }
  
}


.preview-card__img-wrap {
    height: 68rem;

    @include laptop{
        height: 40rem;
    }

    @include tablet{
        height: 37rem;
    }

    @include mobile{
        height: 36.8rem;
    }

}



.list-card__list--grid{
    .preview-card__img-wrap {
        height: 91rem;
        @include laptop{
            height: 69.6rem;
        }
    }
}

.list-card__list--grid-2, .list-card__list--grid-4, .list-card__list--grid-6, .list-card__list--grid-8{ 
    .preview-card {
        flex: 0 1 calc(50% - 2rem);

        @include laptop{
            flex: 0 1 calc(50% - 1.6rem);
            .preview-card__img-wrap {
                height: 61rem;
            }
        }

        @include tablet{
            flex: 0 1 calc(50% - 1rem);
            .preview-card__img-wrap {
                height: 38.1rem;
            }
        }

        @include mobile{
            flex: 0 1 100%;
            .preview-card__img-wrap {
                height: 38.8rem;
            }
        }
    }
}

.list-card__list--grid-3{ 
    .preview-card {
        flex: 0 1 calc(33.33% - 2rem);
        .preview-card__img-wrap {
            height: 60rem;
        }
        @include laptop{
            flex: 0 1 calc(33.33% - 1.6rem);
            .preview-card__img-wrap {
                height: 40rem;
            }
        }

        @include tablet{
            flex: 0 1 calc(50% - 1rem);
            .preview-card__img-wrap {
                height: 38.1rem;
            }
        }
        @include mobile{
            flex: 0 1 100%;
            .preview-card__img-wrap {
                height: 38.8rem;
            }
        }

    }
}

.list-card__list--grid-5{ 
    .preview-card:nth-child(1),.preview-card:nth-child(2) {
        flex: 0 1 calc(50% - 2rem);

        @include laptop{
            flex: 0 1 calc(50% - 1.6rem);
            .preview-card__img-wrap {
                height: 61rem;
            }
        }

        @include tablet{
            flex: 0 1 calc(50% - 1rem);
            .preview-card__img-wrap {
                height: 38.1rem;
            }
        }
        @include mobile{
            flex: 0 1 100%;
            .preview-card__img-wrap {
                height: 38.8rem;
            }
        }
    }

    .preview-card:nth-child(3),.preview-card:nth-child(4), .preview-card:nth-child(5) {
        flex: 0 1 calc(33.33% - 2rem);
        .preview-card__img-wrap {
            height: 60rem;
        }

        @include laptop{
            flex: 0 1 calc(33.33% - 1.6rem);
            .preview-card__img-wrap {
                height: 40rem;
            }
        }

        @include tablet{
            flex: 0 1 calc(50% - 1rem);
            .preview-card__img-wrap {
                height: 38.1rem;
            }
        }
        @include mobile{
            flex: 0 1 100%;
            .preview-card__img-wrap {
                height: 38.8rem;
            }
        }
    }

}


.list-card__list--grid-7{ 
    .preview-card:nth-child(1),.preview-card:nth-child(2),.preview-card:nth-child(3),.preview-card:nth-child(4)  {
        flex: 0 1 calc(50% - 2rem);
          @include laptop{
            flex: 0 1 calc(50% - 1.6rem);
            .preview-card__img-wrap {
                height: 61rem;
            }
        }
        @include tablet{
            flex: 0 1 calc(50% - 1rem);
            .preview-card__img-wrap {
                height: 38.1rem;
            }
        }
        @include mobile{
            flex: 0 1 100%;
            .preview-card__img-wrap {
                height: 38.8rem;
            }
        }
    }

    .preview-card:nth-child(5),.preview-card:nth-child(6), .preview-card:nth-child(7) {
        flex: 0 1 calc(33.33% - 2rem);
        .preview-card__img-wrap {
            height: 60rem;
        }
          @include laptop{
            flex: 0 1 calc(33.33% - 1.6rem);
            .preview-card__img-wrap {
                height: 40rem;
            }
        }
        @include tablet{
            flex: 0 1 calc(50% - 1rem);
            .preview-card__img-wrap {
                height: 38.1rem;
            }
        }
        @include mobile{
            flex: 0 1 100%;
            .preview-card__img-wrap {
                height: 38.8rem;
            }
        }
    }

}

.list-card__list--grid-9{ 
    .preview-card {
        flex: 0 1 calc(33.33% - 2rem);
        .preview-card__img-wrap {
            height: 60rem;
        }
        @include laptop{
            flex: 0 1 calc(33.33% - 1.6rem);
            .preview-card__img-wrap {
                height: 40rem;
            }
        }
        @include tablet{
            flex: 0 1 calc(50% - 1rem);
            .preview-card__img-wrap {
                height: 38.1rem;
            }
        }
        @include mobile{
            flex: 0 1 100%;
            .preview-card__img-wrap {
                height: 38.8rem;
            }
        }
    }

}








