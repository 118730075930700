.profile {
  &__inner {
    display: grid;
    grid-template-columns: rem(370) rem(605);
    gap: rem(254);

    @include laptop {
      grid-template-columns: rem(270) rem(426);
      gap: rem(114);
    }

    @include tablet {
      grid-template-columns: rem(270) 1fr;
      gap: rem(10);
    }

    @include mobile {
      display: flex;
      flex-direction: column;
    }

    .care-menu__title {
      @include laptop {
        font-size: rem(20);
      }

      @include tablet {
        font-size: rem(18);
      }

      @include mobile {
        font-size: rem(22);
      }
    }

    .care-menu {
      width: rem(370);

      @include laptop {
        width: rem(270);
      }

      @include tablet {
        width: rem(270);
      }

      @include mobile {
        width: 100%;
        display: block;
        margin-bottom: rem(-10);
        z-index: 10;
      }
    }

    .care-menu__nav {
      @include mobile {
        display: none;
      }
    }

    .care-menu__sticky {
      @include mobile {
        position: static;
      }
    }

    &_full-screen {
      grid-template-columns: rem(370) rem(1232);
      justify-content: space-between;
      gap: rem(20);

      @include laptop {
        grid-template-columns: rem(270) rem(752);
        gap: rem(20);
      }

      @include tablet {
        grid-template-columns: rem(207) 1fr;
        gap: rem(33);

        .care-menu {
          width: rem(217);
        }
      }

      @include mobile {
        display: flex;
        flex-direction: column;
        justify-content: start;
        gap: 0;

        .care-menu {
          width: 100%;
        }
      }
    }
  }
}
