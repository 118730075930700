

.sizes{

    &__box{
        margin-top: rem(80);
        display: flex;
        flex-wrap: wrap;
        gap: rem(72) rem(20);
        width: 100%;
        @include laptop{
            gap: rem(48) rem(16);
            margin-top: rem(48);
        }
        @include tablet{
            gap: rem(48) rem(30);
        }
        @include mobile{
            margin-top: rem(32);
            gap: rem(32) rem(0);
        }
    }

    &__part{
        width: 100%;
        max-width: rem(604);
        display: flex;
        flex-direction: column;

        @include laptop{
            max-width: rem(368);
        }
        @include tablet{
            max-width: rem(325);
        }
        @include mobile{
            max-width: 100%;
        }
        
        &_wide{
            max-width: 100%;
        }

        &_end{
            margin-top: auto;
        }

        &_title{
            row-gap: rem(32);
            @include laptop{
                row-gap: rem(24);
            }
        }

        &_bottom{
            row-gap: rem(16);
            @include laptop{
                max-width: rem(506);
                margin-right: rem(64);
            }
            @include tablet{
                margin-right: 0;
            }
        }

        &_2fr{
            max-width: rem(1228);
            @include laptop{
                max-width: rem(736);
            }
            @include tablet{
                max-width: rem(355);
            }
        }

        &_images{
            display: flex;
            column-gap: rem(20);
            flex-direction: row;
            @include laptop{
                column-gap: rem(16);
            }
            @include tablet{
                column-gap: rem(10);
            }
        }

        &_form{
            row-gap: rem(68);
            @include laptop{
                row-gap: rem(32);
            }
        }

        &_result{
            @include tablet{
                max-width: rem(504);
                margin-top: rem(-16);
                .sizes__block{
                    max-width: 100%;
                }
            }
            @include mobile{
                margin-top: rem(-8);
                max-width: rem(355);
            }
        }
        
        &_note{
            @include mobile{
                margin-top: rem(-24);
            }
        }

    }

    &__step{
        display: flex;
        flex-direction: column;
        row-gap: rem(24);
        max-width: rem(390);
        @include laptop{
            row-gap: rem(16);
            max-width: rem(332);
        }
        @include tablet{
            max-width: rem(280);
        }
        @include mobile{
            max-width: unset;
        }
    }

    &__title{
        text-align: left;

        @include laptop{
            font-size: rem(28);
        }
        @include tablet{
            font-size: rem(26);
        }
        @include mobile{
            font-size: rem(20);
        }

        &_step{
            color: var(--gray80);
            @include laptop{
                font-size: rem(20);
            }
            @include tablet{
                font-size: rem(18);
            }
            @include mobile{
                font-size: rem(16);
            }
        }
    }

    &__text{
        color: var(--gray80);
        max-width: rem(410);

        &_note{
            color: var(--gray60);
            @include tablet{
                width: 120%;
            }
            @include mobile{
                width: 100%;
                max-width: rem(300);
            }
        }
    }

    &__block{
        display: flex;
        flex-direction: column;
        row-gap: rem(8);
        max-width: rem(370);
        
        @include laptop{
            max-width: rem(335);
        }

        @include mobile{
            max-width: unset;
        }
    }

    &__line{
        display: flex;
        justify-content: space-between;
        align-items: center;
    }

    &__image{
        border-radius: rem(24);
        overflow: hidden;
        position: relative;
        width: rem(604);
        height: rem(680);

        @include laptop{
            border-radius: rem(14);
            width: rem(368);
            height: rem(415);
        }
        @include tablet{
            border-radius: rem(8);
            width: rem(170);
            height: rem(192);
        }
        @include mobile{
            border-radius: rem(12);
            width: rem(174);
        }

        img{
            width: 100%;
            display: block;
        }
    }

    &__number{
        font-size: rem(28);
        position: absolute;
        border: rem(12);
        background: var(--white);
        width: rem(48);
        height: rem(48);
        border-radius: rem(12);
        top: rem(32);
        left: rem(32);
        display: flex;
        justify-content: center;
        align-items: center;
        line-height: 1;

        @include tablet{
            width: rem(30);
            height: rem(30);
            border-radius: rem(8);
            font-size: rem(18);
            top: rem(8);
            left: rem(8);
        }
    }

    &__table{
        margin-top: rem(24);
        display: flex;
        flex-wrap: wrap;
        gap: rem(8);
        max-width: rem(504);
        @include laptop{
            margin-top: rem(8);
        }
        @include mobile{
            width: 100%;
            max-width: unset;
        }
    }

    &__table-label{
        cursor: pointer;
        text-align: center;
        width: rem(56);
        height: rem(36);
        display: flex;
        justify-content: center;
        align-items: center;
        border-radius: rem(10);
        user-select: none;
        border: rem(1) solid var(--gray00);
        color: var(--gray60);
        font-weight: 400;
        font-size: rem(12);

        @include mobile{
            width: rem(83);
            height: rem(38);
        }

        input{
            position: absolute;
            opacity: 0;
            pointer-events: none;
        }

        &:has(input:checked){
            background: var(--gray10);
            border: rem(1) solid transparent;
        }

    }

}