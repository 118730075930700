.profile-order {
  &__wrapper {
    padding: rem(78);
    border-radius: rem(24);
    border: rem(1) solid var(--gray20);

    @include laptop {
      padding: rem(48);
    }

    @include tablet {
      padding: rem(30);
      border-radius: rem(18);
    }

    @include mobile {
      padding: 0;
      border-radius: 0;
      border: none;
    }
  }

  &__item {
    padding-bottom: rem(56);
    border-bottom: rem(1) solid var(--gray20);
    margin-bottom: rem(56);

    @include laptop {
      padding-bottom: rem(32);
      margin-bottom: rem(32);
    }

    @include tablet {
      padding-bottom: rem(24);
      margin-bottom: rem(24);
    }

    @include mobile {
      padding-bottom: rem(31);
      margin-bottom: rem(31);
    }
  }

  &__headline {
    margin-bottom: rem(40);

    @include laptop {
      font-size: rem(20);
      margin-bottom: rem(24);
    }

    @include tablet {
      font-size: rem(18);
      margin-bottom: rem(16);
    }

    @include mobile {
      font-size: rem(16);
    }
  }

  &__info-list {
    display: flex;
    flex-direction: column;
    gap: rem(24);

    @include laptop {
      gap: rem(16);
    }

    @include tablet {
      gap: rem(8);
    }
  }

  &__info-item {
    display: grid;
    grid-template-columns: 1fr minmax(auto, rem(513));
    gap: rem(10);

    @include laptop {
      grid-template-columns: 1fr minmax(auto, rem(358));
    }

    @include tablet {
      grid-template-columns: 1fr minmax(auto, rem(236));
    }
  }

  &__info-name,
  &__info-text {
    @include tablet {
      font-size: rem(14);
    }

    @include mobile {
      font-size: rem(12);
    }
  }

  &__info-name {
    color: var(--gray60);
  }

  &__info-text {
    text-align: right;

    &_balance {
      text-wrap: balance;
    }
  }
  //
  //
  &__structure {
  }

  &__structure-item {
    display: flex;
    justify-content: space-between;
    align-items: start;
    gap: rem(20);

    padding-bottom: rem(32);
    border-bottom: rem(1) solid var(--gray10);
    margin-bottom: rem(32);

    @include laptop {
      padding-bottom: rem(24);
      margin-bottom: rem(24);
    }

    @include mobile {
      padding-bottom: rem(16);
      margin-bottom: rem(16);
    }

    @include mobile {
      position: relative;
    }
  }

  &__structure-left {
    display: flex;
    align-items: stretch;
    gap: rem(20);

    @include laptop {
      gap: rem(16);
    }

    @include tablet {
      gap: rem(10);
    }

    @include mobile {
      justify-content: space-between;
    }
  }

  &__structure-img-wrapper {
    width: rem(136);
    height: rem(183);
    border-radius: rem(10);
    overflow: hidden;
    flex: 0 0 auto;

    @include laptop {
      width: rem(128);
      height: rem(170);
    }

    @include tablet {
      width: rem(80);
      height: rem(107);
    }

    @include mobile {
      width: rem(76);
      height: rem(100);
    }
  }

  &__structure-img {
    width: rem(136);
    height: rem(183);
    border-radius: rem(10);
    overflow: hidden;

    @include laptop {
      width: rem(128);
      height: rem(170);
    }

    @include tablet {
      width: rem(80);
      height: rem(107);
    }

    @include mobile {
      width: rem(76);
      height: rem(100);
    }
  }

  &__structure-info {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    gap: rem(20);
    max-width: rem(321);

    @include laptop {
      max-width: rem(218);
    }

    @include tablet {
      max-width: rem(176);
    }

    @include mobile {
      max-width: rem(200);
    }
  }

  &__structure-top {
    display: flex;
    flex-direction: column;
    gap: rem(12);

    @include laptop {
      gap: rem(5);
    }

    @include tablet {
    }

    @include mobile {
      gap: rem(2);
    }
  }

  &__structure-article {
    color: var(--gray40);
  }

  &__structure-name {
    text-align: left;

    @include laptop {
      font-size: rem(18);
    }

    @include tablet {
      font-size: rem(16);
    }
  }

  //
  &__structure-right {
    padding-top: rem(28);

    @include laptop {
      padding-top: rem(18);
    }

    @include mobile {
      position: absolute;
      right: 0;
      bottom: rem(16);
      padding-top: 0;
      width: rem(121);
      flex: 0 0 auto;
      text-align: right;

      display: flex;
      flex-direction: column-reverse;
    }
  }

  &__structure-price {
  }

  &__structure-price-old {
    text-align: right;
    color: var(--gray40);
  }

  &__structure-btns {
    margin-top: rem(56);
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    gap: rem(20);

    @include laptop {
      margin-top: 0;
    }

    @include tablet {
      grid-template-columns: 1fr;
      gap: rem(12);
      .btn:not(.profile-order__structure-gone) {
        display: grid;
        place-content: center;
      }
    }

    @include mobile {
      margin-top: rem(32);
      gap: rem(8);
    }
  }

  &__structure-gone-block {
    display: flex;
    flex-direction: column;
    gap: rem(8);

    & + .btn {
      height: rem(58);

      @include laptop {
        height: rem(46);
      }
    }
  }

  &__structure-gone {
    width: 100%;
    text-transform: none;
    font-size: rem(16);
    font-weight: 500;
    height: rem(58);

    display: flex;
    align-items: center;
    justify-content: center;
    gap: rem(8);

    @include laptop {
      gap: rem(7);
      font-size: rem(14);
      padding: rem(11) rem(20);
      height: rem(46);
    }

    @include tablet {
    }

    @include mobile {
      font-size: rem(13);
    }

    &_img {
      width: rem(90);
      height: rem(25);

      @include laptop {
        width: rem(75);
        height: rem(21);
      }

      @include mobile {
        width: rem(62);
        height: auto;
      }
    }

    &_more {
      font-size: rem(14);
      font-style: normal;
      font-weight: 400;
      line-height: 140%;
      text-decoration-line: underline;
      text-decoration-style: solid;
      text-decoration-skip-ink: none;
      color: var(--gray50);

      @include laptop {
        font-size: rem(12);
      }

      @include tablet {
        display: none;
      }
    }
  }
}

.profile-order-head {
  display: flex;
  justify-content: space-between;
  align-items: center;
  gap: rem(20);
  margin-bottom: rem(56);

  @include laptop {
    margin-bottom: rem(40);
  }

  @include tablet {
    margin-bottom: rem(32);
    align-items: end;
  }

  &__left {
    display: flex;
    align-items: center;
    gap: rem(50);

    @include laptop {
      gap: rem(28);
    }

    @include tablet {
      flex-direction: column;
      align-items: start;
      gap: rem(20);
    }
  }

  &__back {
    display: flex;
    align-items: center;

    color: var(--black);
    font-size: rem(12);
    font-style: normal;
    font-weight: 500;
    line-height: normal;
    text-transform: uppercase;
    text-decoration: none;

    @include laptop {
      font-size: rem(10);
    }

    @include mobile {
      display: none;
    }
  }

  &__back-arrow {
    width: rem(24);
    height: rem(24);

    @include laptop {
      width: rem(20);
      height: rem(20);
    }
  }

  &__num {
    color: var(--black);

    font-family: "Stapel";
    font-size: rem(28);
    font-style: normal;
    font-weight: 400;
    line-height: 110%;

    @include laptop {
      font-size: rem(20);
    }

    @include tablet {
      font-size: rem(18);
    }

    @include mobile {
      font-size: rem(16);
    }
  }

  &__right {
    display: flex;
    align-items: center;
    gap: rem(34);

    @include laptop {
      gap: rem(62);
    }

    @include tablet {
      flex-direction: column;
      justify-content: space-between;
      align-items: end;
      gap: rem(15);
    }
  }

  &__cancel-btn {
    display: flex;
    align-items: center;
    gap: rem(8);

    font-size: rem(12);
    font-style: normal;
    font-weight: 500;
    text-transform: uppercase;
    line-height: normal;
    color: var(--black);

    @include laptop {
      font-size: rem(10);
      gap: rem(4);
    }

    @include mobile {
      display: none;
    }
  }

  &__cancel-img {
    width: rem(24);
    height: rem(24);

    @include laptop {
      width: rem(20);
      height: rem(20);
    }
  }

  &__status {
    color: var(--white);
    font-size: rem(14);
    font-style: normal;
    font-weight: 400;
    line-height: 140%;

    padding: rem(4) rem(16);
    border-radius: rem(90);
    background: var(--sageDark);

    @include laptop {
      font-size: rem(12);
    }

    @include tablet {
    }

    @include mobile {
      font-size: rem(10);
    }
  }
}
