

.main-news{

    &__title{
        @include laptop{
            font-size: rem(28);
        }
        @include tablet{
            font-size: rem(16);
        }
        @include mobile{
            font-size: rem(20);
        }
    }

    &__swiper{
        position: relative;
        margin: 0 auto;
        margin-top: rem(40);
        max-width: rem(1236);
        overflow: visible;

        @include laptop{
            max-width: rem(800);
            margin-top: rem(24);
        }
        @include tablet{
            max-width: rem(500);
        }
        @include mobile{
            max-width: calc(100% - rem(18));
            margin-top: rem(16);
            .swiper-slide{
                width: rem(348);
            }
        }


    }

    &__swiper-slide{
        margin: 0 rem(160);
        display: flex;
        flex-direction: column;
        row-gap: rem(40);

        @include laptop{
            margin: 0 rem(100);
            row-gap: rem(16);
        }
        @include tablet{
            margin: 0 rem(75);
        }
        @include mobile{
            margin: 0;
            margin-right: rem(12);
        }

    }

    &__swiper-image{
        display: flex;
        justify-content: center;

        img{
            width: 100%;
            height: 100%;
        }
    }

    &__swiper-prev,
    &__swiper-next{
        width: rem(32);
        height: rem(32);
        left: 0;
        top: rem(305);
        position: absolute;
        cursor: pointer;
        z-index: 10;

        @include laptop{
            top: rem(160);
        }
        @include tablet{
            width: rem(22);
            height: rem(22);
            top: rem(100);
            left: rem(30);
        }
        @include mobile{
            display: none;
        }
    }

    &__swiper-next{
        right: 0;
        left: unset;
        @include tablet{
            right: rem(30);
        }
    }

    &__swiper-title{
        max-width: rem(600);
        color: var(--gray80);
        span{
            color: var(--sageDark);
        }
        @include laptop{
            font-size: rem(20);
        }
        @include tablet{
            font-size: rem(16);
        }
        @include mobile{
            font-size: rem(16);
        }
    }

    &__swiper-link{
        margin-top: rem(-16);
        text-transform: uppercase;

        @include laptop{
            margin-top: rem(-8);
        }
        @include tablet{
            font-size: rem(12);
        }
        @include mobile{
            font-size: rem(12);
        }
    }

}