

.franchise-first{

    &__box{
        display: flex;
        column-gap: rem(130);
        width: 100%;
        position: relative;

        @include laptop{
            column-gap: rem(40);
        }
        @include tablet{
            flex-wrap: wrap;
        }
    }

    &__left{
        display: flex;
        flex-direction: column;
        min-height: 100%;
    }

    &__description{
        text-transform: uppercase;
        margin-top: rem(124);
        margin-bottom: rem(223);
        color: var(--gray60);

        @include laptop{
            margin-top: rem(60);
            margin-bottom: rem(114);
        }
        @include tablet{
            margin-top: 0;
            margin-bottom: rem(64);
        }
        @include mobile{
            margin-bottom: rem(32);
        }
    }

    &__title{
        text-align: left;
        max-width: rem(810);
        margin-bottom: rem(40);
        line-height: 1.1;

        span{
            color: var(--sageDark);
        }

        @include tablet{
            margin-bottom: rem(48);
            max-width: rem(470);
        }
        @include mobile{
            margin-bottom: rem(32);
            font-size: rem(22);
            max-width: rem(290);
        }
    }

    &__btn{
        margin-right: auto;
        min-width: rem(300);
        @include laptop{
            min-width: rem(176);
        }
        @include tablet{
            display: none;
        }

        &_mobile{
            display: none;
            @include tablet{
                display: block;
                margin-top: rem(40);
                width: 100%;
            }
            @include mobile{
                margin-top: rem(32);
            }
        }
    }

    &__image{
        width: rem(916);
        height: rem(924);

        @include laptop{
            width: rem(560);
            height: rem(560);
            flex-shrink: 0;
        }
        @include tablet{
            width: 100%;
            height: rem(456);
            margin-top: rem(40);
        }
        @include mobile{
            height: rem(230);
            margin-top: rem(32);
        }

        img{
            width: 100%;
            height: 100%;
        }
    }

    &__info{
        margin-top: auto;
        display: flex;
        justify-content: space-between;

        @include mobile{
            flex-wrap: wrap;
            row-gap: rem(24);
        }
    }

    &__info-block{
        max-width: rem(190);
        @include mobile{
            width: 100%;
            max-width: unset;
        }
    }

    &__info-title{
        color: var(--gray80);
        margin-bottom: rem(4);
        @include laptop{
            font-size: rem(20);
        }
        @include tablet{
            font-size: rem(18);
        }
        @include mobile{
            font-size: rem(16);
        }
    }

    &__info-text{
        color: var(--gray60);
    }


}