
.block-catalog{

    &__top{
        position: relative;
        text-decoration: none;
        display: block;

        .block-catalog__arrow{
            display: none;
        }

        @include mobile{
            display: flex;
            justify-content: space-between;
            align-items: center;

            .block-catalog__arrow{
                display: block;
            }
        }

        &:has(> .block-catalog__filters_aside){
            @include mobile{
                display: flex;
                flex-wrap: wrap;
            }
        }
    }

    &__title{
        @include laptop{
            font-size: rem(28);
        }
        @include tablet{
            font-size: rem(26);
        }
        @include mobile{
            font-size: rem(20);
        }
    }

    &__box{
        //margin-top: rem(40);
        margin-bottom: rem(32);
        display: flex;
        flex-wrap: wrap;
        gap: rem(40) rem(20);

        @include laptop{
            gap: rem(32) rem(16);
            //margin-top: rem(24);
        }
        @include tablet{
            gap: rem(24) rem(10);
        }
        @include mobile{
            gap: unset;
            flex-wrap: unset;
            margin-bottom: unset;
        }

        &_wrap{
            @include mobile{
                flex-wrap: wrap;
                gap: rem(32) rem(10);
            }
        }

    }

    &__card{
        width: rem(448);
        height: rem(740);
        //background: #ddd;
        display: flex;
        flex-direction: column;
        row-gap: rem(24);

        @include laptop{
            width: rem(272);
            height: rem(469);
            row-gap: rem(20);
        }
        @include tablet{
            width: rem(230);
            height: rem(386);
            row-gap: rem(10);
        }
        @include mobile{
            margin-right: rem(8);
        }

        &_slide{
            margin-right: rem(20);

            @include laptop{
                margin-right: rem(16);
            }
            @include tablet{
                margin-right: rem(10);
            }

            &:nth-last-of-type(1){
                margin-right: 0;
            }
        }

        &_mobile{
            @include mobile{
                width: rem(174);
                height: rem(314);
                margin-right: rem(0);

                .block-catalog__card-image{
                    height: rem(232);
                }
                .block-catalog__card-title{
                    overflow:hidden; 
                    white-space:nowrap;
                    text-overflow:ellipsis;
                    margin: auto 0;
                }
                .block-catalog__card-button{
                    border-radius: rem(4);
                    margin-top: unset;
                }
                .block-catalog__card-icon{
                    width: rem(16);
                    height: rem(16);
                }
            }
        }

        &:hover{
            @include min-tablet{
                .block-catalog__card-button{
                    background: var(--gray00);
                    padding: rem(8) rem(12);
                    @include laptop{
                        padding: rem(4) rem(8);
                    }
                    .block-catalog__card-icon{
                        display: block;
                    }
                }
                .block-catalog__card-image{
                    .block-catalog__card-cover{
                        &_1{
                            //transform: translateY(-100%);
                            //opacity: 0;
                            display: none;
                        }
                        &_2{
                            //transform: translateY(-100%);
                            //opacity: 1;
                            //visibility: visible;
                            display: block;
                        }
                    }
                }
            }
        }
    }

    &__card-image{
        width: 100%;
        height: rem(600);
        overflow: hidden;
        flex-shrink: 0;
        position: relative;
        display: flex;
        flex-direction: column;

        @include laptop{
            height: rem(364);
        }
        @include tablet{
            height: rem(300);
        }

        img{
            //transition: opacity 0.3s;
            top: 0;
            left: 0;
            width: 100%;
            height: 100%;
        }
    }

    &__card-cover{
        &_2{
            display: none;
        }
    }

    &__card-info{
        display: flex;
        flex-direction: column;
        row-gap: rem(10);
        flex-grow: 1;
        @include laptop{
            row-gap: rem(8);
        }
        @include tablet{
            row-gap: rem(4);
        }
    }

    &__card-colors{
        display: flex;
        align-items: center;
        column-gap: rem(6);

        @include laptop{
            column-gap: rem(4);
        }
        @include mobile{
            *{
                font-size: rem(10);
            }
        }

        div{
            margin-left: rem(4);
            @include laptop{
                margin-left: rem(8);
            }
            @include tablet{
                margin-left: rem(4);
            }
        }
    }

    &__card-label{
        cursor: pointer;
        width: rem(16);
        height: rem(16);
        border: rem(1) solid var(--gray20);
        border-radius: rem(100);
        flex-shrink: 0;
        display: block;
        background: var(--white);
        position: relative;
        //transition-duration: 0.1s;

        @include laptop{
            width: rem(12);
            height: rem(12);
        }
        @include tablet{
            width: rem(10);
            height: rem(10);
        }

        &:has(input:checked){
            margin-right: rem(4);
            margin-left: rem(4);
            &::before{
                display: block;
            }
            @include laptop{
                margin-right: rem(3);
                margin-left: rem(3);
            }
        }

        &::before{
            content: '';
            transition-duration: 0.1s;
            display: none;
            position: absolute;
            width: rem(24);
            height: rem(24);
            border: rem(1) solid var(--black);
            top: 50%;
            left: 50%;
            transform: translate(-50%, -50%);
            border-radius: rem(100);

            @include laptop{
                width: rem(18);
                height: rem(18);
            }
            @include tablet{
                width: rem(14);
                height: rem(14);
            }
        }

    }

    &__card-radio{
        position: absolute;
        width: rem(16);
        height: rem(16);
        opacity: 0;
        pointer-events: none;
    }

    &__card-title{
        margin-top: auto;
        @include laptop{
            font-size: rem(14);
        }
        @include tablet{
            font-size: rem(12);
            margin-top: unset;
        }
        @include mobile{
            margin-top: rem(2);
        }
    }

    &__card-button{
        cursor: pointer;
        padding: rem(8) 0;
        display: flex;
        align-items: flex-end;
        column-gap: rem(8);
        border-radius: rem(10);
        margin-right: auto;

        *{
            pointer-events: none;
        }

        @include laptop{
            padding: rem(4) 0;
            margin-top: rem(-3);
        }
        @include tablet{
            background: var(--gray00);
            padding: rem(4) rem(8);
            margin-top: auto;
            align-items: center;
            .t-secodary-s-line{
                margin-top: rem(2);
            }
        }
        @include mobile{
            margin-top: rem(4);
        }
    }

    &__card-price{

    }

    &__card-icon{
        display: none;
        width: rem(24);
        height: rem(24);
        @include laptop{
            width: rem(22);
            height: rem(22);
        }
        @include tablet{
            display: block;
        }
    }

    &__sizes{
        position: absolute;
        bottom: rem(24);
        left: rem(24);
        width: calc(100% - rem(48));
        background: var(--whiteBlur);
        backdrop-filter: blur(1px);
        border-radius: rem(16);
        padding: rem(24) rem(30);
        display: none;
        box-sizing: border-box;

        @include laptop{
            bottom: rem(16);
            left: rem(16);
            width: calc(100% - rem(32));
            padding: rem(16);
        }

        &_open{
            display: block;
            @include tablet{
                display: none;
            }
        }
    }

    &__sizes-close{
        width: rem(24);
        height: rem(24);
        position: absolute;
        top: rem(24);
        right: rem(30);
        cursor: pointer;
        opacity: 0.7;

        @include laptop{
            top: rem(16);
            right: rem(16);
        }

        img{
            width: 100%;
            height: 100%;
        }
    }

    &__sizes-table{
        margin-top: rem(14);
        display: flex;
        flex-wrap: wrap;
        gap: rem(10) rem(20);

        @include laptop{
            gap: rem(8) rem(23);
        }
    }

    &__sizes-label{
        cursor: pointer;
        opacity: 0.4;
        padding: rem(5) 0;
        text-align: center;
        width: rem(52);
        border-radius: rem(10);
        user-select: none;

        @include laptop{
            padding: rem(6) 0;
        }

        &:has(input:checked){
            opacity: 1;
            background: var(--white);
        }

    }

    &__link{
        text-transform: uppercase;
        position: absolute;
        bottom: 0;
        right: 0;

        @include mobile{
            display: none;
        }

        /* &_mobile{
            display: none;
            position: relative;
            border: none;
            right: unset;
            bottom: unset;
            margin-right: unset;
            padding: unset;
            line-height: 1;
            @include mobile{
                display: block;
            }
        } */
    }

    &__swiper{
        margin-top: rem(40);
        @include laptop{
            margin-top: rem(24);
        }
        @include mobile{
            margin-top: rem(16);
            width: calc(100% + rem(9));
            margin-right: rem(-9);
        }
    }

    &__swiper-navigation{
        position: absolute;
        left: 0;
        top: 0;
        display: flex;
        @include mobile{
            display: none;
        }
    }

    &__swiper-prev,
    &__swiper-next{
        width: rem(24);
        height: rem(24);
        cursor: pointer;

        &.swiper-button-disabled{
            opacity: 0.5;
        }

        img{
            width: 100%;
            height: 100%;
        }
    }

    &__btn{
        @include mobile{
            display: none;
        }
    }



    &__filters{
        display: flex;
        justify-content: space-between;

        &_aside{
            position: absolute;
            right: 0;
            top: 0;
            .block-catalog__filters-form{
                width: auto;
                gap: rem(8);
            }
            .block-catalog__filters-check{
                font-size: rem(14);
                padding: rem(8) rem(12);
                line-height: 1;
                border: rem(1) solid var(--gray00);
                @include laptop{
                    padding: rem(6) rem(12);
                    line-height: 1.3;
                }
                @include tablet{
                    padding: rem(7) rem(12);
                }
                @include mobile{
                    padding: rem(9) rem(12);
                }
            }
            @include tablet{
                position: relative;
                margin-top: rem(24);
                justify-content: flex-end;
                .block-catalog__filters-form .block-catalog__filters-check{
                    display: flex;
                    font-size: rem(12);
                }
            }
            @include mobile{
                justify-content: flex-start;
                margin-top: rem(16);
                .block-catalog__filters-form .block-catalog__filters-check{
                    display: flex;
                    font-size: rem(10);
                }
            }
        }
    }

    &__filters-form{
        display: flex;
        flex-wrap: wrap;
        gap: rem(10);
        width: calc(100% - rem(320));

        @include laptop{
            gap: rem(8);
        }
        @include tablet{
            width: 100%;
            justify-content: space-between;

            .select_drop{
                display: none;
                &:nth-of-type(1){
                    display: block;
                    .select__value{
                        font-size: rem(12);
                    }
                }
            }
            
            .block-catalog__filters-check{
                display: none;
            }
            .block-catalog__filters-clear{
                margin-left: auto;
            }

        }
        @include mobile{
            flex-wrap: nowrap;
            gap: rem(0);
        }
    }

    &__filters-box{
        display: flex;
        flex-wrap: wrap;
        gap: rem(10);
        @include laptop{
            gap: rem(8);
        }
    }

    &__filters-clear{
        column-gap: rem(10);
        padding: rem(9) rem(16);
        display: flex;
        align-items: center;
        flex-shrink: 0;
        cursor: pointer;

        @include laptop{
            padding: rem(7) rem(12);
        }

        *{
            color: var(--gray60);
        }

        img{
            width: rem(24);
            height: rem(24);
            opacity: 0.6;
            @include laptop{
                width: rem(20);
                height: rem(20);
            }
        }
    }
    
    &__filters-count{
        color: var(--gray50);
        height: rem(44);
        display: flex;
        align-items: center;

        @include laptop{
            height: rem(34);
        }
        @include tablet{
            display: none;
        }
    }

    &__filters-check{
        width: auto;
        display: flex;
        align-items: center;
        font-size: rem(16);
        padding: rem(11) rem(16);
        cursor: pointer;
        color: var(--gray60);
        font-weight: 400;
        background: var(--grayBg);
        border: rem(1) solid var(--gray30);
        border-radius: rem(8);
        outline: none;
        user-select: none;

        @include laptop{
            padding: rem(8.5) rem(12);
            line-height: 1.1;
            font-size: rem(14);
        }

        &:has(input:checked){
            color: var(--black);
            font-weight: 500;
            background: var(--gray00);
            border: rem(1) solid var(--gray00);
        }

        input{
            pointer-events: none;
            opacity: 0;
            position: absolute;
        }

    }

    &__filters-btn{
        width: rem(34);
        height: rem(34);
        border: rem(1) solid var(--gray10);
        border-radius: rem(5);
        display: none;

        &_active{
            background: var(--gray00);
        }

        img{
            width: rem(24);
            height: rem(24);
        }

        @include tablet{
            display: flex;
            justify-content: center;
            align-items: center;
        }
    }


    


}