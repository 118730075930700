

.certificate-buy{

    &__box{
        display: flex;
        flex-wrap: wrap;
        row-gap: rem(32);
        justify-content: space-between;

        @include tablet{
            row-gap: rem(20);
        }
        @include mobile{
            row-gap: rem(32);
        }
    }

    &__top{
        width: 100%;
        max-width: rem(1072);

        @include laptop{
            max-width: rem(608);
        }
        @include tablet{
            max-width: unset;
        }
    }

    &__title{
        font-size: rem(28);
        @include laptop{
            font-size: rem(20);
        }
        @include tablet{
            font-size: rem(18);
        }
        @include mobile{
            font-size: rem(16);
        }
    }

    &__product{
        width: 100%;
        max-width: rem(1072);
        display: flex;
        flex-direction: column;
        row-gap: rem(40);

        @include laptop{
            max-width: rem(608);
            row-gap: rem(24);
        }
        @include tablet{
            max-width: unset;
        }
        @include mobile{
            row-gap: rem(20);
        }
    }

    &__info{
        width: 100%;
        max-width: rem(604);
        padding: rem(77) rem(74);
        border-radius: rem(24);
        border: rem(1) solid var(--gray20);
        display: flex;
        flex-direction: column;
        row-gap: rem(16);

        @include laptop{
            padding: rem(48) rem(38);
            max-width: rem(416);
            row-gap: rem(8);
        }
        @include tablet{
            max-width: unset;
            padding: rem(32);
            margin-top: rem(20);
            flex-wrap: wrap;
            flex-direction: row;
            column-gap: rem(80);
            border-radius: rem(16);
        }
        @include mobile{
            margin-top: 0;
            padding: 0;
            border: none;
            border-radius: 0;
        }

    }

    &__info-title{
        margin-bottom: rem(8);

        @include laptop{
            font-size: rem(20);
            margin-bottom: rem(12);
        }
        @include tablet{
            font-size: rem(18);
            width: 100%;
        }
        @include mobile{
            font-size: rem(16);
        }
        @include mobile{
            font-size: rem(22);
        }
    }

    &__info-block{
        @include tablet{
            width: rem(230);
        }
        @include mobile{
            width: rem(233);
        }

        &_mt{
            margin-top: rem(16);
            @include tablet{
                margin-top: 0;
            }
        }
    }

    &__info-text{

        @include tablet{
            font-size: rem(12);
        }

        span{
            color: var(--gray70);
        }
    }

    &__info-btn{
        margin-top: rem(8);

        @include laptop{
            margin-top: rem(12);
        }

        &_pay{
            margin-top: rem(0);
            @include laptop{
                margin-top: rem(8);
            }
            @include tablet{
                margin-top: rem(0);
                padding: rem(16) rem(16);
            }
        }

        &_podeli{
            background: var(--white);
            margin-top: rem(0);
            font-weight: 600;
            display: flex;
            align-items: center;
            justify-content: center;
            column-gap: rem(8);
            padding: rem(15);
            font-size: rem(16);
            letter-spacing: 0.02vw;
            text-transform: none;

            @include laptop{
                margin-top: rem(8);
                padding: rem(10) rem(16);
                font-size: rem(14);
            }
            @include tablet{
                margin-top: rem(0);
                padding: rem(10) rem(16);
            }
        }
    }



    &__product-card{
        display: flex;
        column-gap: rem(64);
        padding-bottom: rem(32);
        border-bottom: rem(1) solid var(--gray20);

        @include laptop{
            column-gap: rem(48);
            padding-bottom: rem(24);
        }
        @include tablet{
            column-gap: rem(30);
        }
        @include mobile{
            padding-bottom: rem(20);
            column-gap: rem(40);
        }
    }

    &__product-image{
        width: rem(327);
        height: rem(228);
        border-radius: rem(24);
        flex-shrink: 0;
        overflow: hidden;

        @include laptop{
            width: rem(224);
            height: rem(156);
            border-radius: rem(12);
        }
        @include tablet{
            width: rem(180);
            height: rem(112);
            border-radius: rem(17);
        }
        @include mobile{
            width: rem(128);
            height: rem(76);
            border-radius: rem(8);
        }

        img{
            width: 100%;
            height: 100%;
            display: block;
        }
    }

    &__product-content{
        display: flex;
        flex-direction: column;
        width: 100%;
    }

    &__product-title{
        max-width: rem(300);
        text-align: left;

        @include laptop{
            font-size: rem(18);
            max-width: rem(230);
        }
        @include tablet{
            font-size: rem(16);
            max-width: rem(170);
        }
        @include mobile{
            font-size: rem(12);
        }
    }

    &__product-bot{
        display: flex;
        justify-content: space-between;
        margin-bottom: rem(36);
        margin-top: auto;
        width: 100%;
        align-items: flex-end;

        @include tablet{
            margin-bottom: rem(24);
        }
        @include mobile{
            margin-bottom: 0;
        }
    }

    &__product-info{
        display: flex;
        flex-direction: column;
        row-gap: rem(10);

        @include laptop{
            row-gap: rem(6);
        }
        @include mobile{
            row-gap: rem(4);
        }
    }

    &__product-sum{
            
    }

    &__product-result{
        text-align: right;

        @include laptop{
            font-size: rem(18);
        }
        @include tablet{
            text-align: left;
            font-size: rem(16);
        }
    }



}