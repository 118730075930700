
.main-gift{

    &__link-mobile{
        display: none;
        @include mobile{
            display: flex;
            justify-content: space-between;
            align-items: center;
            text-decoration: none;
            margin-bottom: rem(16);
        }
    }

    &__title-mobile{
        font-size: rem(22);
    }

    &__box{
        width: 100%;
        height: rem(600);
        background: var(--sageGrad);
        padding: 0 rem(136);
        position: relative;
        overflow: hidden;

        @include laptop{
            height: rem(400);
            padding: 0 rem(100);
        }
        @include tablet{
            height: rem(330);
            padding: 0 rem(46);
        }
        @include mobile{
            height: rem(200);
        }
    }

    &__content{
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: flex-start;
        row-gap: rem(32);
        width: 100%;
        height: 100%;
        max-width: rem(480);

        @include laptop{
            row-gap: rem(16);
        }
        @include tablet{
            max-width: rem(330);
        }
        @include mobile{
            display: none;
        }
    }

    &__title{
    }

    &__text{
        @include laptop{
            margin-bottom: rem(16);
        }
    }

    &__img{
        position: absolute;
        right: rem(-40);

        img{
            width: 100%;
            height: 100%;
        }
        
        &_1{
            top: rem(-103);
            width: rem(740);
            height: rem(505);
            transform: rotate(-3deg);
            z-index: 2;
            @include laptop{
                width: rem(423);
                height: rem(289);
                top: rem(-15);
                right: rem(-16);
            }
            @include tablet{
                width: rem(319);
                height: rem(218);
                transform: rotate(-7deg);
                top: rem(0);
                right: rem(-6);
            }
            @include mobile{
                width: rem(232);
                height: rem(159);
                right: rem(-30);
                top: rem(-30);
                transform: rotate(-3deg);
            }
        }

        &_2{
            bottom: rem(-94);
            width: rem(850);
            height: rem(581);
            transform: rotate(7deg);
            z-index: 1;
            @include laptop{
                width: rem(486);
                height: rem(332);
                bottom: rem(-18);
                right: rem(-16);
            }
            @include tablet{
                width: rem(360);
                height: rem(246);
                bottom: rem(-10);
                right: rem(-24);
            }
            @include mobile{
                width: rem(266);
                height: rem(182);
                bottom: rem(-15);
            }
        }

    }

}