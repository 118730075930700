// если вы хотите использовать sass-переменные - удалите root
// colors
:root {
  // base
  --font-family: "Onest", sans-serif !important;
  --content-width: 1170px;
  --container-offset: 15px;
  --container-width: calc(var(--content-width) + (var(--container-offset) * 2));

  // colors
  --white: #fff;
  --whiteBlur: rgba(255,255,255,0.7);
  --black: #1E1E1E;
  --grayBg: #F8F8F8;

  --gray80: rgba(30,30,30,0.8); //#484848
  --gray70: rgba(30,30,30,0.7); //#5e5e5e
  --gray60: rgba(30,30,30,0.6); //#737373
  --gray50: rgba(30,30,30,0.5); //#888888
  --gray40: rgba(30,30,30,0.4); //#9d9d9d
  --gray30: rgba(30,30,30,0.3); //#b2b2b2
  --gray20: rgba(30,30,30,0.2); //#c8c8c8
  --gray10: rgba(30,30,30,0.1); //#dddddd
  --gray00: #ececec; 

  --sage: #A5C2B6;
  --sageDark: #669887;
  --sageLight: #DFECE8;
  --sageGrad: linear-gradient(95.91deg, #98B8AD 0%, #B3CDBF 50.65%, #A9BFB3 101.3%);
  --red: #EE2C1F;


}
