.header{
    z-index: 30;
    position: sticky;
    width: 100vw;
    top: 0;
    left: 0;
    background: var(--grayBg);
    border-bottom: rem(1) solid var(--black);
    margin-left: calc((100% - 100vw) / 2);

    &__banner{
        text-align: center;
        display: flex;
        justify-content: center;
        align-items: center;
        padding-top: rem(9);
        padding-bottom: rem(11);
        border-bottom: rem(1) solid var(--black);
        transition-duration: 0.3s;
        width: 100%;
        a{
            line-height: 1;
        }
        *{
            text-transform: uppercase;
        }
        @include laptop{
            padding: rem(9) 0;
        }
        @include tablet{
            padding: rem(11) 0;
        }
        @include mobile{
            padding: rem(12) 0;
        }

        &_hidden{
            display: none;
        }

    }

    &__banner-close{
        position: absolute;
        right: rem(34);
        top: rem(8);
        width: rem(20);
        height: rem(20);
        cursor: pointer;
        opacity: 0.7;
        @include laptop{
            top: rem(6);
        }
        @include tablet{
            right: rem(17);
            top: rem(8);
        }
        @include mobile{
            right: rem(9);
            top: rem(7);
        }
    }

    &__main{
        padding: rem(9) 0;
        display: flex;
        //justify-content: space-between;
        justify-content: center;
        align-items: center;
        position: relative;
        min-height: rem(65);

        @include laptop{
            padding: rem(12) 0;
            min-height: rem(58);
        }
        @include tablet{
            min-height: rem(40);
        }
        @include mobile{
            padding: rem(19) 0;
        }
    }

    &__logo{
        width: rem(149);
        height: rem(24);
        display: flex;

        @include laptop{
            width: rem(100);
            height: rem(16);
        }

        img{
            width: 100%;
            height: 100%;
        }
    }

    &__nav{
        display: flex;
        margin-left: auto;
        position: absolute;
        left: 50%;
        will-change: transform;
        transform: translateX(-50%);
        column-gap: rem(1);
        *{
            will-change: transform;
        }
        @include laptop{
            column-gap: 0;
        }
        @include tablet{
            display: none;
        }
    }

    &__link{
        display: block;
        //transition-duration: 0.3s;
        color: var(--black);
        text-transform: uppercase;
        text-decoration: none;

        @include laptop{
            line-height: 1.4;
        }

        &:hover{
            text-decoration: underline;
        }

        &_border{
            padding: rem(15) rem(16);
            border-radius: rem(8);
            border: rem(1) solid transparent;

            @include laptop{
                padding: rem(8) rem(12);
                margin-left: rem(-8);
                margin-right: rem(-8);
            }

            &:hover{
                border: rem(1) solid var(--black);
                text-decoration: none;
            }
        }

        &_drop{
            &:hover{
                & ~ .header__drop{
                    display: flex;
                }
            }
        }

        
    }

    &__drop{
        padding: rem(64) rem(80);
        border-radius: rem(20);
        position: absolute;
        top: calc(100% + rem(20));
        left: 50%;
        transform: translateX(-50%);
        background: var(--grayBg);
        border: rem(1) solid var(--black);
        min-width: rem(577);
        display: none;
        justify-content: space-between;
        z-index: 10;
        //column-gap: rem(120);

        &::before{
            content: '';
            position: absolute;
            width: 100%;
            height: rem(30);
            top: rem(-30);
            left: 0;
            z-index: -1;
        }

        &:hover{
            display: flex;
        }
    }

    &__drop-link{
        text-decoration: none;
        color: var(--gray60);
        transition-duration: 0.3s;
        display: flex;
        align-items: center;
        column-gap: rem(8);
        line-height: rem(24);

        img{
            width: rem(24);
            height: rem(24);
            margin-top: rem(-3);
            opacity: 0;
            transition-duration: 0.3s;
        }

        &_hover{
            color: var(--black);

            img{
                opacity: 1;
            }
        }

        &:hover{
            color: var(--black);
        }

        &_hidden{
            display: none;
        }

        &_fix{
            width: rem(142);
        }
    }

    &__drop-col{
        display: flex;
        flex-direction: column;
        row-gap: rem(16);
    }

    

    &__client{
        display: flex;
        column-gap: rem(68);
        /* position: absolute;
        right: 0;
        top: 50%;
        transform: translateY(-50%); */
        margin-left: auto;

        @include laptop{
            column-gap: rem(16);
        }
        @include tablet{
            display: none;
        }
    }

    &__search{
        text-transform: uppercase;
        display: flex;
        align-items: center;
        column-gap: rem(8);
        text-decoration: none;
        color: var(--black);
        margin-left: rem(62);

        @include laptop{
            margin-left: rem(43);
        }
        @include mobile{
            font-size: rem(12);
        }
        @include tablet{
            position: absolute;
            margin-left: unset;
            right: 0;
            column-gap: rem(4);

            img{
                width: rem(20);
                height: rem(20);
            }
        }
    }

    &__location{
        text-transform: uppercase;
        display: flex;
        align-items: center;
        column-gap: rem(8);
        text-decoration: none;
        color: var(--black);
        opacity: 0.6;
        margin-left: rem(92);

        @include laptop{
            margin-left: rem(17);
            column-gap: rem(4);
        }
        @include tablet{
            display: none;
        }
    }

    &__burger{
        display: none;

        @include tablet{
            display: flex;
            position: absolute;
            left: 0;
        }

        &_open{
            .header__burger-open{
                display: none;
            }
            .header__burger-close{
                display: block;
            }
        }

    }

    &__burger-open,
    &__burger-close{
        width: rem(32);
        height: rem(32);
    }

    &__burger-close{
        display: none;
        @include mobile{
            width: rem(20);
            height: rem(20);
        }
    }

}