/* stylelint-disable */

/* @mixin for-desktop {
  @media (min-width: (1025px)) {
    @content;
  }
} */

@mixin laptop {
  @media (max-width: (1200px)) {
    @content;
  }
}

@mixin tablet {
  @media (max-width: (744px)) {
    @content;
  }
}

@mixin mobile {
  @media (max-width: (480px)) {
    @content;
  }
}



@mixin min-tablet {
  @media (min-width: (768px)) {
    @content;
  }
}