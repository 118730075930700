@charset "UTF-8";
:root {
  --font-family: "Onest", sans-serif !important;
  --content-width: 1170px;
  --container-offset: 15px;
  --container-width: calc(var(--content-width) + (var(--container-offset) * 2));
  --white: #fff;
  --whiteBlur: rgba(255,255,255,0.7);
  --black: #1E1E1E;
  --grayBg: #F8F8F8;
  --gray80: rgba(30,30,30,0.8);
  --gray70: rgba(30,30,30,0.7);
  --gray60: rgba(30,30,30,0.6);
  --gray50: rgba(30,30,30,0.5);
  --gray40: rgba(30,30,30,0.4);
  --gray30: rgba(30,30,30,0.3);
  --gray20: rgba(30,30,30,0.2);
  --gray10: rgba(30,30,30,0.1);
  --gray00: #ececec;
  --sage: #A5C2B6;
  --sageDark: #669887;
  --sageLight: #DFECE8;
  --sageGrad: linear-gradient(95.91deg, #98B8AD 0%, #B3CDBF 50.65%, #A9BFB3 101.3%);
  --red: #EE2C1F;
}

/* stylelint-disable */
/* @mixin for-desktop {
  @media (min-width: (1025px)) {
    @content;
  }
} */
/* stylelint-disable */
/* stylelint-disable */
.custom-checkbox__field:checked + .custom-checkbox__content::after {
  opacity: 1;
}

.custom-checkbox__field:focus + .custom-checkbox__content::before {
  outline: 2px solid #f00;
  outline-offset: 2px;
}

.custom-checkbox__field:disabled + .custom-checkbox__content {
  opacity: 0.4;
  pointer-events: none;
}

/* stylelint-disable */
/* stylelint-disable */
html {
  --refRes: 1920;
  font-size: calc(100vw / var(--refRes) * 10) !important;
}
@media (max-width: 1200px) {
  html {
    --refRes: 1200;
    font-size: calc(100vw / var(--refRes) * 10) !important;
  }
}
@media (max-width: 744px) {
  html {
    --refRes: 744;
    font-size: calc(100vw / var(--refRes) * 10) !important;
  }
}
@media (max-width: 480px) {
  html {
    --refRes: 375;
    font-size: calc(100vw / var(--refRes) * 10) !important;
  }
}

@font-face {
  font-family: "Onest";
  src: url("../fonts/../fonts/Onest-Regular.woff2") format("woff2");
  font-weight: 400;
  font-display: block;
  font-style: normal;
}
@font-face {
  font-family: "Onest";
  src: url("../fonts/../fonts/Onest-Medium.woff2") format("woff2");
  font-weight: 500;
  font-display: block;
  font-style: normal;
}
@font-face {
  font-family: "Stapel";
  src: url("../fonts/../fonts/Stapel-Medium.woff2") format("woff2");
  font-weight: 500;
  font-display: block;
  font-style: normal;
}
@font-face {
  font-family: "Stapel";
  src: url("../fonts/../fonts/Stapel-Bold.woff2") format("woff2");
  font-weight: 600;
  font-display: block;
  font-style: normal;
}
@font-face {
  font-family: "Stapel Expanded";
  src: url("../fonts/../fonts/stapel-semi-expanded-medium.woff2") format("woff2");
  font-weight: 500;
  font-display: block;
  font-style: normal;
}
html {
  box-sizing: border-box;
  background: var(--grayBg);
}

@media screen and (min-width: 960px) {
  html {
    /* margin-left: calc(100vw - 100%);
    margin-right: 0; */
    width: 100vw;
    overflow-x: clip;
    margin-left: calc((100% - 100vw) / 2);
  }
}
*,
*::before,
*::after {
  box-sizing: inherit;
}

.page {
  height: 100%;
  font-family: var(--font-family, sans-serif);
  -webkit-text-size-adjust: 100%;
}

.page__body {
  margin: 0;
  min-width: 320px;
  min-height: 100%;
  font-size: 16px;
}
.page__body::-webkit-scrollbar {
  width: 12px;
}
.page__body::-webkit-scrollbar-track {
  background: var(--grayBg);
}
.page__body::-webkit-scrollbar-thumb {
  background-color: var(--gray50);
  border-radius: 20px;
  border: 3px solid var(--white);
}

img {
  height: auto;
  max-width: 100%;
  object-fit: cover;
}

a {
  text-decoration: none;
}

.site-container {
  overflow: clip;
}
@media screen and (min-width: 1025px) {
  .site-container.dis-scroll {
    left: -0.6rem;
  }
}

.is-hidden {
  display: none !important; /* stylelint-disable-line declaration-no-important */
}

.btn-reset {
  border: none;
  padding: 0;
  background: transparent;
  cursor: pointer;
}

.list-reset {
  list-style: none;
  margin: 0;
  padding: 0;
}

.input-reset {
  -webkit-appearance: none;
  appearance: none;
  border: none;
  border-radius: 0;
  background-color: #fff;
  -moz-appearance: textfield;
}
.input-reset::-webkit-search-decoration, .input-reset::-webkit-search-cancel-button, .input-reset::-webkit-search-results-button, .input-reset::-webkit-search-results-decoration {
  display: none;
}
.input-reset::-webkit-outer-spin-button, .input-reset::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

.visually-hidden {
  position: absolute;
  overflow: hidden;
  margin: -1px;
  border: 0;
  padding: 0;
  width: 1px;
  height: 1px;
  clip: rect(0 0 0 0);
}

.container {
  margin: 0 auto;
  padding: 0 3.4rem;
  width: 100%;
}
@media (max-width: 1200px) {
  .container {
    padding: 0 3.2rem;
  }
}
@media (max-width: 744px) {
  .container {
    padding: 0 1.7rem;
  }
}
@media (max-width: 480px) {
  .container {
    padding: 0 0.9rem;
  }
}

.js-focus-visible :focus:not(.focus-visible) {
  outline: none;
}

.centered {
  text-align: center;
}

.dis-scroll {
  position: fixed;
  left: 0;
  top: 0;
  overflow: hidden;
  width: 100%;
  height: 100vh;
  overscroll-behavior: none;
}

.page--ios .dis-scroll {
  position: relative;
}

.border-radius {
  border-radius: 2rem;
  overflow: hidden;
}
@media (max-width: 1200px) {
  .border-radius {
    border-radius: 1.4rem;
  }
}
@media (max-width: 744px) {
  .border-radius {
    border-radius: 1.2rem;
  }
}

.custom-scroll::-webkit-scrollbar {
  width: 1.1rem;
  border-radius: 3rem;
}
.custom-scroll::-webkit-scrollbar-track {
  background: transparent;
  margin-top: 1rem;
  margin-bottom: 1rem;
}
.custom-scroll::-webkit-scrollbar-thumb {
  background-color: var(--black);
  border-radius: 3rem;
  border: 0.4rem solid var(--white);
}

.js-modal-open * {
  pointer-events: none;
}

@media (min-width: 480px) {
  .swiper-wrapper-mobile {
    transform: translate3d(0px, 0px, 0px) !important;
  }
}

.display-none {
  display: none !important;
}

*:has(.select_open) {
  z-index: 13;
}

.headline-1,
.headline-2,
.headline-3,
.headline-4,
.headline-5 {
  font-family: "Stapel Expanded", serif !important;
  font-weight: 500;
  color: var(--black);
  text-align: center;
  margin: 0;
  text-decoration: none;
}

.headline-1 {
  font-size: 5.6rem;
  letter-spacing: -0.02vw;
  line-height: 0.9;
}
@media (max-width: 1200px) {
  .headline-1 {
    font-size: 3.6rem;
  }
}
@media (max-width: 744px) {
  .headline-1 {
    font-size: 3.6rem;
  }
}
@media (max-width: 480px) {
  .headline-1 {
    font-size: 2.4rem;
  }
}

.headline-2 {
  font-size: 4.8rem;
  letter-spacing: -0.01vw;
  line-height: 0.9;
}
@media (max-width: 1200px) {
  .headline-2 {
    font-size: 3.2rem;
  }
}
@media (max-width: 744px) {
  .headline-2 {
    font-size: 2.8rem;
  }
}
@media (max-width: 480px) {
  .headline-2 {
    font-size: 2rem;
  }
}

.headline-3 {
  font-size: 3.6rem;
  letter-spacing: -0.01vw;
  line-height: 1;
}
@media (max-width: 1200px) {
  .headline-3 {
    font-size: 2.4rem;
  }
}
@media (max-width: 744px) {
  .headline-3 {
    font-size: 2.2rem;
  }
}
@media (max-width: 480px) {
  .headline-3 {
    font-size: 1.6rem;
  }
}

.headline-4 {
  font-size: 2.8rem;
  letter-spacing: -0.01vw;
  line-height: 1.1;
  text-align: left;
}
@media (max-width: 1200px) {
  .headline-4 {
    font-size: 1.8rem;
  }
}
@media (max-width: 744px) {
  .headline-4 {
    font-size: 1.6rem;
  }
}
@media (max-width: 480px) {
  .headline-4 {
    font-size: 1.4rem;
  }
}

.headline-5 {
  font-size: 2.4rem;
  letter-spacing: -0.01vw;
  line-height: 1.1;
}
@media (max-width: 1200px) {
  .headline-5 {
    font-size: 1.6rem;
  }
}
@media (max-width: 744px) {
  .headline-5 {
    font-size: 1.2rem;
  }
}
@media (max-width: 480px) {
  .headline-5 {
    font-size: 1rem;
  }
}

a {
  text-decoration: underline;
}

.t-primary-l,
.t-primary-l-accent,
.t-primary-m,
.t-primary-m-accent,
.t-primary-s,
.t-secodary-s-line,
.t-secodary-s,
.t-caption,
.t-navigation {
  color: var(--black);
  text-decoration: none;
}

.t-primary-l {
  line-height: 1.4;
  font-size: 1.8rem;
}
@media (max-width: 1200px) {
  .t-primary-l {
    font-size: 1.6rem;
  }
}
@media (max-width: 744px) {
  .t-primary-l {
    font-size: 1.4rem;
  }
}
@media (max-width: 480px) {
  .t-primary-l {
    font-size: 1.2rem;
  }
}

.t-primary-l-accent {
  line-height: 1.2;
  font-weight: 500;
  letter-spacing: -0.01vw;
  font-size: 1.8rem;
}
@media (max-width: 1200px) {
  .t-primary-l-accent {
    font-size: 1.6rem;
  }
}
@media (max-width: 744px) {
  .t-primary-l-accent {
    font-size: 1.4rem;
    line-height: 1.4;
  }
}
@media (max-width: 480px) {
  .t-primary-l-accent {
    font-size: 1.2rem;
  }
}

.t-primary-m {
  font-size: 1.6rem;
  line-height: 1.2;
  letter-spacing: -0.03vw;
}
@media (max-width: 1200px) {
  .t-primary-m {
    font-size: 1.4rem;
    line-height: 1.4;
  }
}
@media (max-width: 744px) {
  .t-primary-m {
    font-size: 1.2rem;
    line-height: 1.4;
  }
}
@media (max-width: 480px) {
  .t-primary-m {
    line-height: 1.2;
  }
}

.t-primary-m-accent {
  font-weight: 500;
  font-size: 1.6rem;
  line-height: 1.4;
  letter-spacing: -0.03vw;
}
@media (max-width: 1200px) {
  .t-primary-m-accent {
    font-size: 1.4rem;
  }
}
@media (max-width: 744px) {
  .t-primary-m-accent {
    font-size: 1.2rem;
  }
}
@media (max-width: 480px) {
  .t-primary-m-accent {
    font-size: 1.2rem;
    line-height: 1.2;
  }
}

.t-primary-s {
  font-size: 1.4rem;
  letter-spacing: -0.01vw;
  line-height: 1.4;
}
@media (max-width: 1200px) {
  .t-primary-s {
    font-size: 1.2rem;
    line-height: 1.1;
    letter-spacing: -0.03vw;
  }
}
@media (max-width: 744px) {
  .t-primary-s {
    line-height: 1.1;
  }
}
@media (max-width: 480px) {
  .t-primary-s {
    font-size: 1rem;
    line-height: 1.3;
  }
}

.t-secodary-s-line {
  line-height: 1.4;
  font-size: 1.6rem;
  letter-spacing: -0.01vw;
  text-decoration: line-through;
}
@media (max-width: 1200px) {
  .t-secodary-s-line {
    font-size: 1.4rem;
  }
}
@media (max-width: 744px) {
  .t-secodary-s-line {
    font-size: 1.2rem;
  }
}
@media (max-width: 480px) {
  .t-secodary-s-line {
    font-size: 1rem;
    line-height: 1.4;
  }
}

.t-secodary-s {
  line-height: 1.4;
  font-size: 1.4rem;
}
@media (max-width: 1200px) {
  .t-secodary-s {
    font-size: 1.2rem;
  }
}
@media (max-width: 480px) {
  .t-secodary-s {
    font-size: 1rem;
  }
}

.t-caption {
  color: var(--gray80);
  font-size: 1.2rem;
  line-height: 1.3;
}
@media (max-width: 1200px) {
  .t-caption {
    font-size: 1rem;
  }
}
@media (max-width: 480px) {
  .t-caption {
    font-size: 0.8rem;
  }
}
.t-caption a {
  color: var(--gray80);
}

.t-navigation {
  font-weight: 500;
  font-size: 1.4rem;
  letter-spacing: -0.01vw;
  line-height: 1.1;
  text-transform: uppercase;
}
@media (max-width: 1200px) {
  .t-navigation {
    font-size: 1.2rem;
  }
}
@media (max-width: 744px) {
  .t-navigation {
    font-size: 1.2rem;
  }
}
@media (max-width: 480px) {
  .t-navigation {
    font-size: 1rem;
  }
}

.t-center {
  text-align: center;
}

.t-left {
  text-align: left;
}

.t-right {
  text-align: right;
}

.t-white {
  color: var(--white);
}

.t-gray-00 {
  color: var(--gray00);
}

.t-gray-10 {
  color: var(--gray10);
}

.t-gray-20 {
  color: var(--gray20);
}

.t-gray-30 {
  color: var(--gray30);
}

.t-gray-40 {
  color: var(--gray40);
}

.t-gray-50 {
  color: var(--gray50);
}

.t-gray-60 {
  color: var(--gray60);
}

.t-gray-70 {
  color: var(--gray70);
}

.t-gray-80 {
  color: var(--gray80);
}

.t-sage {
  color: var(--sageDark);
}

.inner {
  margin: 10rem 0;
}
@media (max-width: 744px) {
  .inner {
    margin: 8rem 0;
  }
}
@media (max-width: 480px) {
  .inner {
    margin: 5.6rem 0;
  }
}
.inner_big {
  margin: 16rem 0;
}
@media (max-width: 1200px) {
  .inner_big {
    margin: 12rem 0;
  }
}
@media (max-width: 744px) {
  .inner_big {
    margin: 8rem 0;
  }
}
@media (max-width: 480px) {
  .inner_big {
    margin: 5.6rem 0;
  }
}

.cart-fly {
  position: fixed;
  right: 1.5rem;
  width: 4.8rem;
  height: 4.8rem;
  background: var(--white);
  box-shadow: 0px 0px 20px 8px rgba(0, 0, 0, 0.0588235294);
  border-radius: 10rem;
  top: calc(100dvh - 14rem);
  text-decoration: none;
  z-index: 50;
  cursor: pointer;
  display: none;
}
@media (max-width: 480px) {
  .cart-fly {
    display: flex;
    justify-content: center;
    align-items: center;
  }
}
.cart-fly img {
  width: 2.4rem;
  height: 2.4rem;
}
.cart-fly__label {
  position: absolute;
  bottom: calc(50% - 0.6rem);
  left: calc(50% + 0.3rem);
  background: var(--sageDark);
  width: 1.6rem;
  height: 1.6rem;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 10rem;
  color: var(--white);
}

.btn {
  display: block;
  font-weight: 400 !important;
  color: var(--black);
  text-decoration: none;
  text-transform: uppercase;
  border-radius: 1.2rem;
  padding: 2rem 2.4rem;
  pointer-events: all;
  text-align: center;
  box-sizing: border-box;
  transition-duration: 0.3s;
  line-height: 1;
  font-size: 1.4rem;
  border: 0.1rem solid transparent;
  z-index: 3;
}
@media (max-width: 1200px) {
  .btn {
    padding: 1.6rem 2.4rem;
    border-radius: 0.8rem;
    font-size: 1.2rem;
  }
}
@media (max-width: 744px) {
  .btn {
    padding: 1.3rem 1.6rem;
  }
}
@media (max-width: 480px) {
  .btn {
    font-size: 1.2rem;
  }
}
.btn_small {
  padding: 1.3rem 2.4rem;
  border-radius: 1rem;
}
@media (max-width: 1200px) {
  .btn_small {
    padding: 1.4rem 2.4rem;
  }
}
@media (max-width: 744px) {
  .btn_small {
    padding: 1.3rem 1.6rem;
    border-radius: 0.8rem;
  }
}
.btn_fix {
  margin-right: auto;
}
.btn_wide {
  width: 100%;
}
.btn_border {
  border: 0.1rem solid var(--black);
}
.btn_border:hover {
  background: var(--white);
}
.btn_icon {
  display: flex;
  align-items: center;
  justify-content: center;
  column-gap: 0.4rem;
  padding: 1.5rem 2.4rem;
}
.btn_icon img {
  width: 2.4rem;
  height: 2.4rem;
  pointer-events: none;
}
@media (max-width: 1200px) {
  .btn_icon {
    padding: 1.1rem 1.6rem;
  }
  .btn_icon img {
    width: 2rem;
    height: 2rem;
  }
}
.btn_gray {
  background: var(--grayBg);
}
.btn_gray:hover {
  background: var(--white);
}
.btn_white {
  background: var(--white);
}
.btn_white:disabled {
  color: var(--gray30);
  background: var(--grayBg);
  border-color: var(--gray20);
  user-select: none;
  pointer-events: none;
}
.btn_white:hover {
  background: var(--grayBg);
}
.btn_accent {
  color: var(--white);
  background: var(--black);
  font-size: 1.4rem;
  border: 0.1rem solid var(--black);
}
.btn_accent:hover {
  background: var(--gray80);
}
.btn_accent:active {
  background: var(--gray60);
}
.btn_accent:disabled {
  background: var(--gray20);
  border-color: var(--gray20);
  color: var(--gray00);
  user-select: none;
  pointer-events: none;
}
@media (max-width: 1200px) {
  .btn_accent {
    font-size: 1.2rem;
    padding: 1.6rem 2rem;
    border-radius: 0.8rem;
  }
}

.link {
  transition-duration: 0.3s;
  border-bottom: 0.1rem solid var(--black);
  display: inline-block;
  margin-right: auto;
  text-decoration: none;
}
.link:hover {
  color: var(--gray60);
  border-bottom: 0.1rem solid var(--gray60);
}

.select {
  position: relative;
  display: inline-block;
  z-index: 10;
  width: auto;
  box-sizing: border-box;
}
.select::after {
  content: "";
  position: absolute;
  z-index: 1;
  right: 2rem;
  top: 50%;
  transform: translateY(-50%);
  width: 2.4rem;
  height: 2.4rem;
  background: url(/img/svg/arrow-down.svg) no-repeat;
  background-size: cover;
  font-size: 12px;
  opacity: 0.6;
  pointer-events: none;
}
.select:has(input:disabled)::after {
  opacity: 0.2;
}
.select_wide {
  width: 100%;
}
.select_city {
  max-width: 54.2rem;
}
.select_city .select__list {
  position: relative;
}
.select_city .select__value {
  white-space: nowrap;
  display: block;
  line-height: 5rem;
  height: 5.2rem;
  padding-top: 0;
  padding-bottom: 0;
}
@media (max-width: 1200px) {
  .select_city .select__value {
    min-height: 4.4rem;
    max-height: 4.4rem;
    line-height: 4.2rem;
  }
}
@media (max-width: 744px) {
  .select_city {
    max-width: 44rem;
  }
}
.select_city::after {
  content: "";
  top: 1.4rem;
  background: url(/img/svg/search.svg) no-repeat;
  background-size: cover;
  transform: translateY(0%) !important;
}
@media (max-width: 1200px) {
  .select_city::after {
    top: 1rem;
  }
}
.select__price {
  padding: 1.6rem 0.8rem 0.8rem;
  width: 100%;
}
.select__price-top {
  align-items: center;
  display: flex;
  justify-content: space-between;
  margin-bottom: 2.2rem;
}
.select__price-top .link {
  font-size: 1.2rem;
  margin: 0;
  color: var(--gray50);
  border-color: var(--gray50);
}
.select__price-title {
  font-size: 1.6rem;
}
@media (max-width: 1200px) {
  .select__price-title {
    font-size: 1.6rem;
  }
}
.select__price-btn {
  margin-top: 2rem;
  height: 4.4rem;
  padding: 0;
}
.select__value {
  width: auto;
  height: 100%;
  display: flex;
  align-items: center;
  font-size: 1.6rem;
  padding: 1.7rem 2rem;
  padding-right: calc(2rem + 3rem);
  cursor: pointer;
  color: var(--gray60);
  font-weight: 400;
  background: var(--grayBg);
  border: 0.1rem solid var(--gray30);
  border-radius: 1.2rem;
  outline: none;
  user-select: none;
}
.select__value[data-full] {
  font-weight: 500;
  color: var(--black);
}
.select__value[data-full-hard] {
  font-weight: 500;
  color: var(--black);
}
.select__value:disabled {
  opacity: 0.3;
}
@media (max-width: 1200px) {
  .select__value {
    font-size: 1.2rem;
    padding: 1.2rem 1.6rem;
    padding-right: calc(1.6rem + 3rem);
    border-radius: 0.8rem;
  }
}
@media (max-width: 744px) {
  .select__value {
    padding: 1.3rem 1.6rem;
  }
}
@media (max-width: 480px) {
  .select__value {
    padding: 1.4rem 1.6rem;
  }
}
.select__list {
  margin-top: 0.8rem;
  position: absolute;
  background: var(--white);
  box-shadow: 0px 4px 24px 0px rgba(0, 0, 0, 0.1215686275);
  border-radius: 1.2rem;
  width: 100%;
  min-width: 28rem;
  max-height: 26rem;
  overflow-y: auto;
  overflow-x: clip;
  display: none;
}
.select__list:has(> .select__price) {
  width: 37.4rem;
}
@media (max-width: 1200px) {
  .select__list:has(> .select__price) {
    width: 33.2rem;
  }
}
.select__list_check {
  min-width: 37.2rem;
  height: 36.6rem;
  max-height: unset;
}
@media (max-width: 744px) {
  .select__list_check {
    min-width: unset;
    height: 27.8rem;
  }
}
@media (max-width: 480px) {
  .select__list {
    min-width: 16rem;
  }
}
.select__list:has(.select__scroll) {
  overflow-y: hidden;
}
.select__scroll {
  overflow-y: auto;
  height: calc(36.6rem - 4.7rem);
}
@media (max-width: 744px) {
  .select__scroll {
    height: calc(27.8rem - 3.8rem);
  }
}
.select__clear {
  position: absolute;
  bottom: 0;
  left: 0;
  background: var(--white);
  color: var(--black);
  width: 100%;
  height: 4.7rem;
  font-size: 1.4rem;
}
@media (max-width: 744px) {
  .select__clear {
    font-size: 1.2rem;
    height: 3.8rem;
  }
}
.select__label {
  display: block;
  padding: 1.4rem 1.6rem;
  color: var(--gray60);
  white-space: nowrap;
  display: flex;
  align-items: center;
  column-gap: 0.8rem;
  user-select: none;
  font-size: 1.6rem;
  cursor: pointer;
}
.select__label input {
  position: absolute;
  opacity: 0;
  pointer-events: none;
}
@media (max-width: 744px) {
  .select__label {
    font-size: 1.2rem;
    padding: 1.2rem 1.2rem;
  }
}
.select__label::before {
  content: "";
  width: 2.4rem;
  height: 2.4rem;
  background: url(/img/svg/checkbox-empty.svg) no-repeat;
  background-size: cover;
  flex-shrink: 0;
}
.select__label:has(input:checked) {
  color: var(--black);
}
.select__label:has(input:checked)::before {
  background: url(/img/svg/checkbox-full.svg) no-repeat;
  background-size: cover;
}
.select__option {
  padding: 1.5rem 2rem;
  color: var(--black);
  text-decoration: none;
  display: block;
}
.select__option:hover {
  background: var(--grayBg);
  cursor: pointer;
}
.select__option_selected {
  color: var(--sageDark);
}
.select__option input {
  position: absolute;
  opacity: 0;
  pointer-events: none;
}
.select__option::before {
  content: "";
  width: 2.4rem;
  height: 2.4rem;
  background: url(/img/svg/checkbox-empty.svg) no-repeat;
  background-size: cover;
  flex-shrink: 0;
}
.select__option:has(input:checked) {
  color: var(--black);
}
.select__option:has(input:checked)::before {
  background: url(/img/svg/checkbox-full.svg) no-repeat;
  background-size: cover;
}
.select_open {
  z-index: 11;
}
.select_open::after {
  transform: translateY(-50%) scaleY(-1);
}
.select_open .select__list {
  display: block;
}
.select_drop::after {
  right: 1.6rem;
}
@media (max-width: 1200px) {
  .select_drop::after {
    width: 2rem;
    height: 2rem;
    right: 1.2rem;
  }
}
.select_drop .select__value {
  padding: 1rem 1.6rem;
  padding-right: calc(1.6rem + 3rem);
  border-radius: 0.8rem;
  background: var(--grayBg);
  border: 0.1rem solid var(--gray30);
  /* &:not([value=""]){
              background: var(--gray00);
              border: rem(1) solid var(--gray00);
          } */
}
@media (max-width: 1200px) {
  .select_drop .select__value {
    font-size: 1.4rem;
    padding: 0.7rem 1.2rem;
    padding-right: calc(1.2rem + 3rem);
  }
}
@media (max-width: 480px) {
  .select_drop .select__value {
    font-size: 1.2rem;
    padding: 0.9rem 1.2rem;
    padding-right: calc(1.2rem + 3rem);
  }
}
.select_drop .select__value[data-full] {
  background: var(--gray00);
  border: 0.1rem solid var(--gray00);
}
.select_drop .select__value[data-full-hard] {
  background: var(--gray00);
  border: 0.1rem solid var(--gray00);
}
.select_drop .select__value[data-selected] {
  background: var(--grayBg);
  border: 0.1rem solid var(--gray30);
  font-weight: 500;
  color: var(--black);
}
.select_double .select__value {
  padding: 1.8rem 2rem;
  flex-direction: column;
  align-items: flex-start;
  border: 0.1rem solid var(--black);
}
@media (max-width: 1200px) {
  .select_double .select__value {
    padding: 1.4rem 2rem;
  }
}
@media (max-width: 480px) {
  .select_double .select__value {
    row-gap: 0.4rem;
  }
  .select_double .select__value div:nth-of-type(1) {
    font-size: 1.2rem;
  }
}
.select_double .select__sub-value {
  color: var(--gray60);
}
.select_double .select__double-option {
  padding: 1.6rem 2rem;
  color: var(--black);
  text-decoration: none;
  display: block;
}
.select_double .select__double-option:hover {
  background: var(--grayBg);
  cursor: pointer;
}
.select_double .select__double-option_selected {
  color: var(--sageDark);
}
.select:hover .select__value:not(:disabled) {
  background: var(--white);
  border: 0.1rem solid var(--gray60);
}

.range-slider {
  /* position: relative;
          width: 95vmin;
          background-color: #ffffff;
          padding: 50px 40px 20px 40px;
          border-radius: 10px; */
  /* input[type="range"]:active::-webkit-slider-thumb{
              background-color: #ffffff;
              border: 3px solid #e25922;
          } */
}
.range-slider__box {
  position: relative;
  width: 100%;
}
.range-slider__track {
  width: 100%;
  height: 0.2rem;
  position: absolute;
  margin: auto;
  top: 0;
  bottom: 0;
  border-radius: 2rem;
  overflow-x: hidden;
}
.range-slider input[type=range] {
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  width: 100%;
  outline: none;
  position: absolute;
  margin: auto;
  top: 0;
  bottom: 0;
  background-color: transparent;
  pointer-events: none;
}
.range-slider input[type=range]::-webkit-slider-runnable-track {
  -webkit-appearance: none;
  height: 0.2rem;
}
.range-slider input[type=range]::-moz-range-track {
  -moz-appearance: none;
  height: 0.2rem;
}
.range-slider input[type=range]::-ms-track {
  appearance: none;
  height: 0.2rem;
}
.range-slider input[type=range]::-webkit-slider-thumb {
  -webkit-appearance: none;
  height: 1.8rem;
  width: 1.8rem;
  background-color: var(--black);
  border: 0.5rem solid var(--white);
  cursor: pointer;
  margin-top: -0.85rem;
  pointer-events: auto;
  border-radius: 50%;
  box-shadow: 0px 0px 15px 0px rgba(0, 0, 0, 0.0509803922), 0px 4px 4px 0px rgba(0, 0, 0, 0.0509803922);
  flex-shrink: 0;
}
.range-slider input[type=range]::-moz-range-thumb {
  -webkit-appearance: none;
  height: 1.8rem;
  width: 1.8rem;
  background-color: var(--black);
  border: 0.5rem solid var(--white);
  cursor: pointer;
  margin-top: -0.85rem;
  pointer-events: auto;
  border-radius: 50%;
  box-shadow: 0px 0px 15px 0px rgba(0, 0, 0, 0.0509803922), 0px 4px 4px 0px rgba(0, 0, 0, 0.0509803922);
  flex-shrink: 0;
}
.range-slider input[type=range]::-ms-thumb {
  -webkit-appearance: none;
  height: 1.8rem;
  width: 1.8rem;
  background-color: var(--black);
  border: 0.5rem solid var(--white);
  cursor: pointer;
  margin-top: -0.85rem;
  pointer-events: auto;
  border-radius: 50%;
  box-shadow: 0px 0px 15px 0px rgba(0, 0, 0, 0.0509803922), 0px 4px 4px 0px rgba(0, 0, 0, 0.0509803922);
  flex-shrink: 0;
}
.range-slider .values {
  background-color: #3264fe;
  width: 32%;
  position: absolute;
  margin: auto;
  padding: 10px 0;
  border-radius: 5px;
  text-align: center;
  font-weight: 500;
  font-size: 25px;
  color: #ffffff;
  top: 50vh;
  left: 40vw;
  display: none;
}
.range-slider .values:before {
  content: "";
  position: absolute;
  height: 0;
  width: 0;
  border-top: 15px solid #3264fe;
  border-left: 15px solid transparent;
  border-right: 15px solid transparent;
  margin: auto;
  bottom: -14px;
  left: 0;
  right: 0;
}

.pagination {
  display: flex;
  flex-direction: column;
  align-items: center;
  row-gap: 1.6rem;
  margin-top: 4rem;
}
@media (max-width: 744px) {
  .pagination {
    margin-top: 3.2rem;
  }
}
@media (max-width: 480px) {
  .pagination {
    margin-top: 3.2rem;
  }
}
.pagination__count {
  color: var(--gray50);
  font-size: 1.4rem;
}
@media (max-width: 1200px) {
  .pagination__count {
    font-size: 1.2rem;
  }
}
@media (max-width: 744px) {
  .pagination__count {
    font-size: 1.2rem;
  }
}
@media (max-width: 480px) {
  .pagination__count {
    font-size: 1rem;
  }
}
.pagination__box {
  display: flex;
  column-gap: 4rem;
}
.pagination__btn {
  width: 4rem;
  height: 4rem;
  border-radius: 0.8rem;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  border: 0.1rem solid var(--black);
}
@media (max-width: 744px) {
  .pagination__btn {
    width: 3.6rem;
    height: 3.6rem;
  }
}
.pagination__btn img {
  width: 2.4rem;
  height: 2.4rem;
}
@media (max-width: 744px) {
  .pagination__btn img {
    width: 2rem;
    height: 2rem;
  }
}
.pagination__btn:hover {
  background: var(--white);
}
.pagination__btn_disabled {
  border: 0.1rem solid var(--gray20);
}
.pagination__btn_disabled img {
  opacity: 0.2;
}
.pagination__btn_disabled:hover {
  background: unset;
}
.pagination__navigation {
  display: flex;
}
.pagination__page {
  width: 4rem;
  height: 4rem;
  border-radius: 0.8rem;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  text-decoration: none;
  color: var(--gray50);
  font-size: 1.4rem;
}
@media (max-width: 1200px) {
  .pagination__page {
    font-size: 1.2rem;
  }
}
@media (max-width: 744px) {
  .pagination__page {
    font-size: 1.2rem;
  }
}
@media (max-width: 480px) {
  .pagination__page {
    font-size: 1.2rem;
  }
}
.pagination__page:hover {
  background: var(--white);
  color: var(--black);
}
.pagination__page_active {
  color: var(--black);
  background: var(--gray00);
}
.pagination__page_disabled:hover {
  color: var(--gray50);
  background: var(--grayBg);
}

.search {
  width: 100%;
  max-width: 60rem;
  z-index: 1;
  position: relative;
  border: 0.1rem solid var(--gray30);
  border-radius: 1.2rem;
  overflow: hidden;
}
.search:has(+ .modal__shop) {
  margin-bottom: 4rem;
}
@media (max-width: 1200px) {
  .search:has(+ .modal__shop) {
    margin-bottom: 2.4rem;
  }
}
@media (max-width: 1200px) {
  .search {
    max-width: 36.8rem;
    border-radius: 0.8rem;
  }
}
@media (max-width: 744px) {
  .search {
    max-width: unset;
  }
}
.search__input {
  width: 100%;
  height: 100%;
  padding: 1.8rem 2rem;
  padding-right: 5rem;
  line-height: 1;
  outline: none;
  background: transparent;
}
@media (max-width: 1200px) {
  .search__input {
    padding: 1.3rem 2rem;
  }
}
.search__icon {
  width: 2.4rem;
  height: 2.4rem;
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  right: 2rem;
  z-index: 2;
  cursor: pointer;
}
.search__icon img {
  width: 100%;
  height: 100%;
}

.input {
  width: 100%;
  padding: 1.7rem 2rem;
  border-radius: 1.2rem;
  border: 0.1rem solid var(--gray30);
  background: none;
  font-size: 1.6rem;
  font-weight: 400;
  line-height: 120%;
  letter-spacing: -0.03em;
  color: var(--black);
  transition: all 0.3s ease;
}
.input::-webkit-contacts-auto-fill-button {
  opacity: 0;
  pointer-events: none;
}
.input:disabled {
  pointer-events: none;
  opacity: 0.6;
}
@media (max-width: 1200px) {
  .input {
    padding: 1.2rem 2rem;
    border-radius: 0.8rem;
    font-size: 1.4rem;
  }
}
@media (max-width: 744px) {
  .input {
    padding: 1.4rem 2rem;
    font-size: 1.2rem;
  }
}
.input-block {
  position: relative;
}
.input-block:has(.input:disabled) .input-close {
  opacity: 0.5;
  pointer-events: none;
}
.input-block:has(.input:disabled) .input-close img {
  pointer-events: none;
}
.input-block:has(.input_day:focus:invalid)::before, .input-block:has(.input_day.value-show)::before {
  display: none;
}
.input-block:has(.input_day:invalid)::before {
  content: "Дата рождения*";
  position: absolute;
  top: 0.2rem;
  left: 0.2rem;
  pointer-events: none;
  height: 3.51875rem;
  display: flex;
  align-items: end;
  padding-left: 1.8rem;
  border-radius: 1.2rem;
  background: var(--grayBg);
  z-index: 10;
  font-size: 1.6rem;
  font-weight: 400;
  line-height: 120%;
  letter-spacing: -0.03em;
  color: var(--gray60);
}
@media (max-width: 1200px) {
  .input-block:has(.input_day:invalid)::before {
    border-radius: 0.8rem;
    font-size: 1.4rem;
    height: 2.77969rem;
  }
}
@media (max-width: 744px) {
  .input-block:has(.input_day:invalid)::before {
    font-size: 1.2rem;
    height: 2.83906rem;
  }
}
.input-block.clear-field .input-close {
  visibility: visible;
  opacity: 1;
}
.input-block.error-field .input {
  border: 0.1rem solid var(--red);
}
.input-block.error-field .input__error {
  display: block;
}
.input-title {
  font-weight: 400;
  font-size: 1.4rem;
  line-height: 120%;
  letter-spacing: -0.01em;
  color: var(--black);
  margin-bottom: 0.8rem;
}
@media (max-width: 1200px) {
  .input-title {
    font-size: 1.2rem;
  }
}
@media (max-width: 480px) {
  .input-title {
    font-size: 1rem;
  }
}
.input-title:has(.input-title-change) {
  display: flex;
  align-items: center;
  justify-content: space-between;
  gap: 2.4rem;
}
@media (max-width: 1200px) {
  .input-title:has(.input-title-change) {
    gap: 1.6rem;
  }
}
.input-title-change {
  font-size: 1.2rem;
  font-style: normal;
  font-weight: 500;
  line-height: 130%;
  text-decoration: underline;
  text-decoration-skip-ink: none;
  text-transform: uppercase;
  color: var(--gray40);
  cursor: pointer;
}
@media (max-width: 1200px) {
  .input-title-change {
    font-size: 1rem;
  }
}
@media (max-width: 480px) {
  .input-title-change {
    font-size: 0.8rem;
  }
}
.input-title_big {
  font-size: 1.6rem;
}
@media (max-width: 1200px) {
  .input-title_big {
    font-size: 1.4rem;
  }
}
@media (max-width: 744px) {
  .input-title_big {
    font-size: 1.2rem;
  }
}
@media (max-width: 480px) {
  .input-title_big {
    font-size: 1rem;
  }
}
.input-close {
  background: var(--grayBg);
  position: absolute;
  right: 2rem;
  top: 1.6rem;
  width: 2.4rem;
  height: 2.4rem;
  cursor: pointer;
  opacity: 0;
  visibility: hidden;
  transition: all 0.3s ease;
}
@media (max-width: 1200px) {
  .input-close {
    top: 1rem;
  }
}
.input-close__img {
  width: 2.4rem;
  height: 2.4rem;
}
.input_text {
  border-radius: 0.8rem;
  height: 7.2rem;
  resize: vertical;
}
.input_border {
  border-color: var(--black);
}
.input::placeholder {
  color: var(--gray60);
}
.input:hover {
  border-color: var(--gray60);
}
.input:focus {
  outline: none;
  border-color: var(--black);
}
.input:user-invalid, .input:not(:placeholder-shown):user-invalid {
  border: 0.1rem solid var(--red);
}
.input:user-invalid + .input__error, .input:not(:placeholder-shown):user-invalid + .input__error {
  display: block;
}
.input__error {
  display: none;
  margin-top: 0.4rem;
  color: var(--red);
  font-weight: 500;
  font-size: 1.4rem;
  line-height: 120%;
}
.input__error a {
  color: var(--red);
}
@media (max-width: 1200px) {
  .input__error {
    font-size: 1.2rem;
  }
}
.input_day {
  position: relative;
  overflow: hidden;
  height: 5.51875rem;
}
@media (max-width: 1200px) {
  .input_day {
    height: 4.27969rem;
  }
}
@media (max-width: 744px) {
  .input_day {
    height: 4.43906rem;
  }
}
.input_day::-webkit-inner-spin-button, .input_day::-webkit-calendar-picker-indicator {
  position: absolute;
  inset: 0;
  height: 100vh;
  width: 100vw;
  opacity: 0;
  cursor: pointer;
}
.input-sub {
  margin-top: 0.8rem;
  color: var(--gray50);
  font-size: 1.4rem;
  font-style: normal;
  font-weight: 400;
  line-height: 140%;
}

.tab-box:not(.tab-box-active) {
  display: none;
}

.tabs__panel {
  border-radius: 0.8rem;
  border: 0.1rem solid var(--black);
  display: flex;
  justify-content: space-between;
  column-gap: 2rem;
}
@media (max-width: 480px) {
  .tabs__panel {
    display: none;
  }
}
.tabs__select {
  display: none;
}
@media (max-width: 480px) {
  .tabs__select {
    display: block;
    margin-top: 2.4rem;
  }
}
.tabs__btn {
  cursor: pointer;
  width: 100%;
  padding: 1.2rem 1.6rem;
  color: var(--gray50);
  text-align: center;
  user-select: none;
}
@media (max-width: 1200px) {
  .tabs__btn {
    padding: 1.4rem;
  }
}
@media (max-width: 744px) {
  .tabs__btn {
    padding: 1.6rem;
  }
}
.tabs__btn.tab-btn-active {
  color: var(--black);
}

.variants {
  display: flex;
  gap: 1.2rem;
  width: 100%;
}
@media (max-width: 744px) {
  .variants {
    gap: 0.8rem;
  }
}
.variants_wrap {
  flex-wrap: wrap;
}
.variants__label {
  color: var(--gray50);
  padding: 0.95rem 1.2rem;
  border-radius: 0.8rem;
  border: 0.1rem solid var(--gray00);
  cursor: pointer;
  display: flex;
  justify-content: center;
  text-align: center;
  align-items: center;
  user-select: none;
  line-height: 1;
}
@media (max-width: 480px) {
  .variants__label {
    font-size: 1.2rem;
  }
}
.variants__label_wide {
  width: 100%;
}
.variants__label input {
  display: none;
}
.variants__label:has(input:checked) {
  background: var(--gray10);
  border: 0.1rem solid transparent;
  color: var(--black);
}

.checkradio {
  display: flex;
  align-items: center;
  gap: 1.6rem;
  user-select: none;
}
.checkradio__input {
  display: none;
}
.checkradio__mask {
  position: relative;
  cursor: pointer;
  width: 5.1rem;
  height: 3.1rem;
  border-radius: 10rem;
  background: var(--gray30);
  padding: 0.3rem;
  transition: all 0.3s ease;
}
.checkradio__mask::after {
  content: "";
  position: absolute;
  left: 0.3rem;
  width: 2.5rem;
  height: 2.5rem;
  box-shadow: 0 0.3rem 0.1rem 0 rgba(0, 0, 0, 0.06), 0 0.3rem 0.7rem 0 rgba(0, 0, 0, 0.15), 0 0 0 0.1rem rgba(0, 0, 0, 0.04);
  background: #fff;
  border-radius: 100%;
  transition: all 0.3s ease;
}
.checkradio__text {
  font-weight: 400;
  font-size: 1.6rem;
  line-height: 140%;
  letter-spacing: -0.01em;
  color: var(--gray50);
  cursor: pointer;
}
@media (max-width: 1200px) {
  .checkradio__text {
    font-size: 1.4rem;
  }
}
@media (max-width: 744px) {
  .checkradio__text {
    font-size: 1.2rem;
  }
}
.checkradio__input:checked ~ .checkradio__mask {
  background: var(--black);
}
.checkradio__input:checked ~ .checkradio__mask::after {
  left: 2.3rem;
}
.checkradio__input:checked ~ .checkradio__text {
  color: var(--black);
}

.quantity {
  display: flex;
}
@media (max-width: 480px) {
  .quantity {
    margin-left: auto;
  }
}
.quantity input {
  width: 5.4rem;
  text-align: center;
  background: transparent;
  text-align: center;
  border: none;
  outline: none;
}
@media (max-width: 480px) {
  .quantity input {
    width: 3.7rem;
  }
}
.quantity__button {
  width: 2.4rem;
  height: 2.4rem;
  transition-duration: 0.3s;
  user-select: none;
  cursor: pointer;
}
.quantity__button img {
  width: 100%;
  height: 100%;
  pointer-events: none;
}
.quantity__button:hover {
  opacity: 0.6;
}
@media (max-width: 1200px) {
  .quantity__button {
    width: 2rem;
    height: 2rem;
  }
}

.accordion {
  user-select: none;
}
.accordion_footer-mobile {
  border-bottom: 0.1rem solid var(--gray10);
  padding-bottom: 0.8rem;
}
.accordion_footer-mobile .accordion__title {
  min-height: 2rem;
}
.accordion_footer-mobile .accordion__title .t-navigation {
  width: 100%;
  color: var(--gray40);
}
.accordion_footer-mobile .accordion__title .t-navigation::after {
  right: 0;
  transform: translateY(-50%) rotate(-90deg);
  opacity: 0.3;
  top: 50%;
}
.accordion_borders {
  padding: 2.4rem 0;
  border-top: 0.1rem solid var(--black);
}
@media (max-width: 1200px) {
  .accordion_borders {
    padding: 1.4rem 0rem;
  }
}
.accordion_borders .accordion__content {
  padding-top: 1.6rem;
  max-width: calc(100% - 4.8rem);
}
.accordion_borders .accordion__title div {
  width: 100%;
}
.accordion_borders .accordion__title div::after {
  content: "";
  right: 0;
}
@media (max-width: 1200px) {
  .accordion_borders .accordion__title div {
    font-size: 1.4rem;
  }
}
@media (max-width: 480px) {
  .accordion_borders .accordion__title div {
    font-size: 1.2rem;
  }
}
.accordion_borders:nth-last-of-type(1) {
  border-bottom: 0.1rem solid var(--black);
}
.accordion_vacancy {
  padding: 2rem;
  border: 0.1rem solid var(--black);
  border-radius: 1.2rem;
}
.accordion_vacancy .accordion__title div {
  width: 100%;
}
.accordion_vacancy .accordion__title div::after {
  content: "";
  right: 0;
  opacity: 0.6;
}
@media (max-width: 1200px) {
  .accordion_vacancy .accordion__title div {
    font-size: 1.4rem;
  }
}
@media (max-width: 480px) {
  .accordion_vacancy .accordion__title div {
    font-size: 1.2rem;
  }
}
.accordion_vacancy .accordion__content {
  padding-top: 1.6rem;
  display: flex;
  flex-direction: column;
  row-gap: 1.6rem;
}
.accordion_vacancy .accordion__content-block {
  display: flex;
  flex-direction: column;
  row-gap: 0.8rem;
}
.accordion_vacancy .accordion__content-ul {
  padding: 0;
  margin: 0;
  padding-left: 2.2rem;
}
.accordion_vacancy .accordion__content-ul li {
  color: var(--gray60);
}
.accordion_vacancy .accordion__text {
  color: var(--gray80);
}
.accordion_vacancy .accordion__text_gray {
  color: var(--gray60);
}
.accordion_vacancy .accordion__text_fix {
  max-width: 44rem;
}
@media (max-width: 1200px) {
  .accordion_vacancy .accordion__text_fix {
    max-width: 39rem;
  }
}
@media (max-width: 744px) {
  .accordion_vacancy .accordion__text_fix {
    max-width: 34rem;
  }
}
.accordion_vacancy:has(.accordion__top_open) {
  background: var(--white);
}
.accordion__top {
  position: relative;
  transition-duration: 0.3s;
}
.accordion__top_open .accordion__title .t-navigation::after {
  top: 50%;
  transform: translateY(-50%) scaleY(-1);
}
@media (max-width: 1200px) {
  .accordion__top_open .accordion__title .t-navigation::after {
    top: calc(50% + 0.1rem);
  }
}
.accordion__top_open .accordion__title div::after {
  content: "";
  transform: translateY(-50%) scaleY(-1);
}
.accordion__title {
  position: relative;
  cursor: pointer;
}
.accordion__title div {
  position: relative;
  display: inline-block;
}
.accordion__title div::after {
  content: "";
  display: block;
  position: absolute;
  width: 2.4rem;
  height: 2.4rem;
  right: -3rem;
  top: calc(50% - 0.2rem);
  background: url(/img/svg/accordion-arrow.svg) no-repeat;
  background-size: cover;
  transition-duration: 0.3s;
  transform: translateY(-50%);
}
@media (max-width: 1200px) {
  .accordion__title div::after {
    width: 2rem;
    height: 2rem;
    right: -2.4rem;
    top: calc(50% - 0.1rem);
  }
}
.accordion__title:has(+ .accordion__text) {
  margin-bottom: 0.8rem;
}
.accordion__hide {
  height: 0;
  overflow: hidden;
  transition-duration: 0.3s;
}
.accordion__hide_absolute {
  position: absolute;
}
.accordion__content {
  padding-top: 2.4rem;
}
@media (max-width: 1200px) {
  .accordion__content {
    padding-top: 1.6rem;
  }
}
@media (max-width: 744px) {
  .accordion__content {
    padding-bottom: 1.2rem;
    padding-top: 2rem;
  }
}
.accordion__content .footer__nav-link {
  white-space: unset;
  color: var(--gray80);
}
@media (max-width: 744px) {
  .accordion__content .footer__nav-link {
    color: var(--black);
  }
}
.accordion__text_gray {
  color: var(--gray60);
}
.accordion__text_title {
  margin-bottom: 1.6rem;
  font-size: 1.8rem;
}

.mobile-menu {
  position: absolute;
  width: calc(100% + 3.4rem);
  height: calc(100dvh - 7.6rem);
  background: var(--grayBg);
  z-index: 40;
  top: 4.1rem;
  transform: translateX(-130%);
  transition-duration: 0.3s;
  left: 0;
  margin-left: -1.7rem;
}
@media (min-width: 768px) {
  .mobile-menu {
    display: none;
  }
}
@media (max-width: 480px) {
  .mobile-menu {
    top: 5.5rem;
  }
}
.mobile-menu_open {
  transform: translateX(0);
}
.mobile-menu__container {
  position: relative;
  height: calc(100% - 6.8rem);
  padding: 0 1.7rem;
}
.mobile-menu__buttons {
  display: flex;
}
.mobile-menu__btn {
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 1.7rem 0;
  width: 50%;
  border-bottom: 0.1rem solid var(--black);
  text-decoration: none;
  background: var(--grayBg);
  font-size: 1.2rem;
  font-weight: 400;
}
@media (max-width: 480px) {
  .mobile-menu__btn {
    font-size: 1.2rem;
  }
}
.mobile-menu__btn:nth-of-type(2) {
  border-left: 0.1rem solid var(--black);
}
.mobile-menu__location {
  display: flex;
  justify-content: space-between;
  padding: 2rem 0;
  margin-top: 0.4rem;
  margin-bottom: 0.8rem;
  border-bottom: 0.1rem solid var(--gray10);
}
@media (max-width: 480px) {
  .mobile-menu__location {
    margin-bottom: unset;
  }
}
.mobile-menu__location-current {
  display: flex;
  align-items: center;
  column-gap: 0.8rem;
  font-size: 1.2rem;
}
@media (max-width: 744px) {
  .mobile-menu__location-current {
    font-size: 1.2rem;
  }
}
@media (max-width: 480px) {
  .mobile-menu__location-current {
    font-size: 1.2rem;
  }
}
.mobile-menu__location-current img {
  width: 2rem;
  height: 2rem;
}
@media (max-width: 480px) {
  .mobile-menu__location-current img {
    width: 1.6rem;
    height: 1.6rem;
  }
}
.mobile-menu__location-change {
  color: var(--gray40);
  text-decoration: none;
  line-height: 1;
  display: flex;
  align-items: center;
  font-size: 1.2rem;
}
@media (max-width: 744px) {
  .mobile-menu__location-change {
    font-size: 1.2rem;
  }
}
@media (max-width: 480px) {
  .mobile-menu__location-change {
    font-size: 1.2rem;
  }
}
.mobile-menu__nav {
  position: relative;
  margin: 4rem 0;
  overflow-y: auto;
  display: flex;
  flex-direction: column;
  row-gap: 2.4rem;
  max-height: calc(100% - 12rem);
}
.mobile-menu__nav_submenu {
  margin-top: 3.2rem;
}
@media (max-width: 480px) {
  .mobile-menu__nav_submenu {
    margin-top: 2.4rem;
  }
}
.mobile-menu__li {
  display: block;
  font-size: 1.2rem;
  line-height: 1.5rem;
}
.mobile-menu__bottom {
  margin-top: 1.6rem;
  padding-top: 4rem;
  border-top: 0.1rem solid var(--gray10);
  display: flex;
  flex-direction: column;
  row-gap: 4rem;
}
.mobile-menu__ul {
  display: flex;
  flex-direction: column;
  row-gap: 2.4rem;
}
.mobile-menu__link {
  text-decoration: none;
  display: block;
  line-height: 1.5rem;
}
@media (max-width: 480px) {
  .mobile-menu__link {
    font-size: 1.2rem;
  }
}
.mobile-menu__link_bottom {
  color: var(--gray70);
}
.mobile-menu__submenu {
  position: absolute;
  width: 100%;
  height: calc(100% + 5.2rem);
  background: var(--grayBg);
  top: -5.2rem;
  left: 0;
  padding: 4.8rem 1.7rem;
  transform: translateX(-130%);
  transition-duration: 0.3s;
}
.mobile-menu__submenu_open {
  transform: translateX(0);
}
@media (max-width: 480px) {
  .mobile-menu__submenu {
    height: calc(100% + 3.2rem);
    padding-top: 3.2rem 0.9rem;
  }
}
.mobile-menu__back {
  display: flex;
  align-items: center;
  column-gap: 1.2rem;
  text-decoration: none;
}
.mobile-menu__back img {
  width: 2rem;
  height: 2rem;
}
.mobile-menu__back-text {
  font-size: 1.6rem;
  line-height: 1;
}
.mobile-menu__city {
  display: flex;
  flex-direction: column;
  row-gap: 1rem;
  position: absolute;
  top: 12rem;
  left: 0;
  width: 100vw;
  z-index: 45;
  padding: 1.6rem;
  border-radius: 0.6rem;
  background: var(--white);
  box-shadow: 0px 11px 20px 0px rgba(5, 16, 55, 0.1019607843);
  display: none;
}
.mobile-menu__city-title {
  font-size: 1.2rem;
  text-align: center;
  font-weight: 400;
}
@media (max-width: 744px) {
  .mobile-menu__city-title {
    font-size: 1.2rem;
  }
}
@media (max-width: 480px) {
  .mobile-menu__city-title {
    font-size: 1.2rem;
  }
}
.mobile-menu__city-buttons {
  display: flex;
  column-gap: 0.8rem;
}
.mobile-menu__city-btn {
  width: calc(50% - 0.4rem);
  border: 0.1rem solid var(--black);
  border-radius: 0.8rem;
  line-height: 1;
  padding: 1.3rem 0;
}
.mobile-menu__city-btn_black {
  background: var(--black);
  color: var(--white);
}

.header {
  z-index: 30;
  position: sticky;
  width: 100vw;
  top: 0;
  left: 0;
  background: var(--grayBg);
  border-bottom: 0.1rem solid var(--black);
  margin-left: calc((100% - 100vw) / 2);
}
.header__banner {
  text-align: center;
  display: flex;
  justify-content: center;
  align-items: center;
  padding-top: 0.9rem;
  padding-bottom: 1.1rem;
  border-bottom: 0.1rem solid var(--black);
  transition-duration: 0.3s;
  width: 100%;
}
.header__banner a {
  line-height: 1;
}
.header__banner * {
  text-transform: uppercase;
}
@media (max-width: 1200px) {
  .header__banner {
    padding: 0.9rem 0;
  }
}
@media (max-width: 744px) {
  .header__banner {
    padding: 1.1rem 0;
  }
}
@media (max-width: 480px) {
  .header__banner {
    padding: 1.2rem 0;
  }
}
.header__banner_hidden {
  display: none;
}
.header__banner-close {
  position: absolute;
  right: 3.4rem;
  top: 0.8rem;
  width: 2rem;
  height: 2rem;
  cursor: pointer;
  opacity: 0.7;
}
@media (max-width: 1200px) {
  .header__banner-close {
    top: 0.6rem;
  }
}
@media (max-width: 744px) {
  .header__banner-close {
    right: 1.7rem;
    top: 0.8rem;
  }
}
@media (max-width: 480px) {
  .header__banner-close {
    right: 0.9rem;
    top: 0.7rem;
  }
}
.header__main {
  padding: 0.9rem 0;
  display: flex;
  justify-content: center;
  align-items: center;
  position: relative;
  min-height: 6.5rem;
}
@media (max-width: 1200px) {
  .header__main {
    padding: 1.2rem 0;
    min-height: 5.8rem;
  }
}
@media (max-width: 744px) {
  .header__main {
    min-height: 4rem;
  }
}
@media (max-width: 480px) {
  .header__main {
    padding: 1.9rem 0;
  }
}
.header__logo {
  width: 14.9rem;
  height: 2.4rem;
  display: flex;
}
@media (max-width: 1200px) {
  .header__logo {
    width: 10rem;
    height: 1.6rem;
  }
}
.header__logo img {
  width: 100%;
  height: 100%;
}
.header__nav {
  display: flex;
  margin-left: auto;
  position: absolute;
  left: 50%;
  will-change: transform;
  transform: translateX(-50%);
  column-gap: 0.1rem;
}
.header__nav * {
  will-change: transform;
}
@media (max-width: 1200px) {
  .header__nav {
    column-gap: 0;
  }
}
@media (max-width: 744px) {
  .header__nav {
    display: none;
  }
}
.header__link {
  display: block;
  color: var(--black);
  text-transform: uppercase;
  text-decoration: none;
}
@media (max-width: 1200px) {
  .header__link {
    line-height: 1.4;
  }
}
.header__link:hover {
  text-decoration: underline;
}
.header__link_border {
  padding: 1.5rem 1.6rem;
  border-radius: 0.8rem;
  border: 0.1rem solid transparent;
}
@media (max-width: 1200px) {
  .header__link_border {
    padding: 0.8rem 1.2rem;
    margin-left: -0.8rem;
    margin-right: -0.8rem;
  }
}
.header__link_border:hover {
  border: 0.1rem solid var(--black);
  text-decoration: none;
}
.header__link_drop:hover ~ .header__drop {
  display: flex;
}
.header__drop {
  padding: 6.4rem 8rem;
  border-radius: 2rem;
  position: absolute;
  top: calc(100% + 2rem);
  left: 50%;
  transform: translateX(-50%);
  background: var(--grayBg);
  border: 0.1rem solid var(--black);
  min-width: 57.7rem;
  display: none;
  justify-content: space-between;
  z-index: 10;
}
.header__drop::before {
  content: "";
  position: absolute;
  width: 100%;
  height: 3rem;
  top: -3rem;
  left: 0;
  z-index: -1;
}
.header__drop:hover {
  display: flex;
}
.header__drop-link {
  text-decoration: none;
  color: var(--gray60);
  transition-duration: 0.3s;
  display: flex;
  align-items: center;
  column-gap: 0.8rem;
  line-height: 2.4rem;
}
.header__drop-link img {
  width: 2.4rem;
  height: 2.4rem;
  margin-top: -0.3rem;
  opacity: 0;
  transition-duration: 0.3s;
}
.header__drop-link_hover {
  color: var(--black);
}
.header__drop-link_hover img {
  opacity: 1;
}
.header__drop-link:hover {
  color: var(--black);
}
.header__drop-link_hidden {
  display: none;
}
.header__drop-link_fix {
  width: 14.2rem;
}
.header__drop-col {
  display: flex;
  flex-direction: column;
  row-gap: 1.6rem;
}
.header__client {
  display: flex;
  column-gap: 6.8rem;
  /* position: absolute;
  right: 0;
  top: 50%;
  transform: translateY(-50%); */
  margin-left: auto;
}
@media (max-width: 1200px) {
  .header__client {
    column-gap: 1.6rem;
  }
}
@media (max-width: 744px) {
  .header__client {
    display: none;
  }
}
.header__search {
  text-transform: uppercase;
  display: flex;
  align-items: center;
  column-gap: 0.8rem;
  text-decoration: none;
  color: var(--black);
  margin-left: 6.2rem;
}
@media (max-width: 1200px) {
  .header__search {
    margin-left: 4.3rem;
  }
}
@media (max-width: 480px) {
  .header__search {
    font-size: 1.2rem;
  }
}
@media (max-width: 744px) {
  .header__search {
    position: absolute;
    margin-left: unset;
    right: 0;
    column-gap: 0.4rem;
  }
  .header__search img {
    width: 2rem;
    height: 2rem;
  }
}
.header__location {
  text-transform: uppercase;
  display: flex;
  align-items: center;
  column-gap: 0.8rem;
  text-decoration: none;
  color: var(--black);
  opacity: 0.6;
  margin-left: 9.2rem;
}
@media (max-width: 1200px) {
  .header__location {
    margin-left: 1.7rem;
    column-gap: 0.4rem;
  }
}
@media (max-width: 744px) {
  .header__location {
    display: none;
  }
}
.header__burger {
  display: none;
}
@media (max-width: 744px) {
  .header__burger {
    display: flex;
    position: absolute;
    left: 0;
  }
}
.header__burger_open .header__burger-open {
  display: none;
}
.header__burger_open .header__burger-close {
  display: block;
}
.header__burger-open, .header__burger-close {
  width: 3.2rem;
  height: 3.2rem;
}
.header__burger-close {
  display: none;
}
@media (max-width: 480px) {
  .header__burger-close {
    width: 2rem;
    height: 2rem;
  }
}

.main-first__title {
  max-width: 120rem;
}
@media (max-width: 480px) {
  .main-first__title {
    font-size: 2rem;
  }
}
.main-first__swiper {
  width: 100%;
  height: 100svh;
  max-height: 96rem;
  position: relative;
  z-index: 1;
}
@media (max-width: 1200px) {
  .main-first__swiper {
    max-height: 60rem;
    height: 100svh;
  }
}
@media (max-width: 744px) {
  .main-first__swiper {
    max-height: 80rem;
    height: 100svh;
  }
}
@media (max-width: 480px) {
  .main-first__swiper {
    max-height: 50rem;
    height: 100svh;
  }
}
.main-first__swiper .swiper-pagination {
  height: 2rem;
  bottom: 2.2rem;
}
.main-first__swiper .swiper-pagination .swiper-pagination-bullet {
  width: 7.2rem;
  height: 0.4rem;
  border-radius: 9rem;
  margin: 0.8rem 0.6rem;
  background: var(--black);
  transition-duration: 0.3s;
}
@media (max-width: 744px) {
  .main-first__swiper .swiper-pagination .swiper-pagination-bullet {
    width: 3.5rem;
    margin: 0.8rem 0.25rem;
    height: 0.2rem;
  }
}
.main-first__swiper .swiper-pagination .swiper-pagination-bullet-active {
  background: var(--white);
}
.main-first__swiper-slide {
  display: flex;
}
.main-first__swiper-slide::before {
  content: "";
  position: absolute;
  width: 100%;
  height: 100%;
  background: linear-gradient(180deg, rgba(0, 0, 0, 0) 0%, rgba(0, 0, 0, 0.3) 100%);
  top: 0;
  left: 0;
  z-index: 2;
}
.main-first__swiper-slide img {
  width: 192rem;
}
.main-first__swiper-half {
  width: 50%;
}
@media (max-width: 744px) {
  .main-first__swiper-half {
    width: 100%;
  }
}
.main-first__swiper-half img {
  width: 100%;
  height: 100%;
}
.main-first__swiper-half video {
  height: 100%;
  width: auto;
}
@media (max-width: 744px) {
  .main-first__swiper-half_left {
    display: none;
  }
}
.main-first__swiper-content {
  position: absolute;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  z-index: 2;
  row-gap: 3.2rem;
}
@media (max-width: 1200px) {
  .main-first__swiper-content {
    row-gap: 2.4rem;
  }
}

.main-gift__link-mobile {
  display: none;
}
@media (max-width: 480px) {
  .main-gift__link-mobile {
    display: flex;
    justify-content: space-between;
    align-items: center;
    text-decoration: none;
    margin-bottom: 1.6rem;
  }
}
.main-gift__title-mobile {
  font-size: 2.2rem;
}
.main-gift__box {
  width: 100%;
  height: 60rem;
  background: var(--sageGrad);
  padding: 0 13.6rem;
  position: relative;
  overflow: hidden;
}
@media (max-width: 1200px) {
  .main-gift__box {
    height: 40rem;
    padding: 0 10rem;
  }
}
@media (max-width: 744px) {
  .main-gift__box {
    height: 33rem;
    padding: 0 4.6rem;
  }
}
@media (max-width: 480px) {
  .main-gift__box {
    height: 20rem;
  }
}
.main-gift__content {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
  row-gap: 3.2rem;
  width: 100%;
  height: 100%;
  max-width: 48rem;
}
@media (max-width: 1200px) {
  .main-gift__content {
    row-gap: 1.6rem;
  }
}
@media (max-width: 744px) {
  .main-gift__content {
    max-width: 33rem;
  }
}
@media (max-width: 480px) {
  .main-gift__content {
    display: none;
  }
}
@media (max-width: 1200px) {
  .main-gift__text {
    margin-bottom: 1.6rem;
  }
}
.main-gift__img {
  position: absolute;
  right: -4rem;
}
.main-gift__img img {
  width: 100%;
  height: 100%;
}
.main-gift__img_1 {
  top: -10.3rem;
  width: 74rem;
  height: 50.5rem;
  transform: rotate(-3deg);
  z-index: 2;
}
@media (max-width: 1200px) {
  .main-gift__img_1 {
    width: 42.3rem;
    height: 28.9rem;
    top: -1.5rem;
    right: -1.6rem;
  }
}
@media (max-width: 744px) {
  .main-gift__img_1 {
    width: 31.9rem;
    height: 21.8rem;
    transform: rotate(-7deg);
    top: 0rem;
    right: -0.6rem;
  }
}
@media (max-width: 480px) {
  .main-gift__img_1 {
    width: 23.2rem;
    height: 15.9rem;
    right: -3rem;
    top: -3rem;
    transform: rotate(-3deg);
  }
}
.main-gift__img_2 {
  bottom: -9.4rem;
  width: 85rem;
  height: 58.1rem;
  transform: rotate(7deg);
  z-index: 1;
}
@media (max-width: 1200px) {
  .main-gift__img_2 {
    width: 48.6rem;
    height: 33.2rem;
    bottom: -1.8rem;
    right: -1.6rem;
  }
}
@media (max-width: 744px) {
  .main-gift__img_2 {
    width: 36rem;
    height: 24.6rem;
    bottom: -1rem;
    right: -2.4rem;
  }
}
@media (max-width: 480px) {
  .main-gift__img_2 {
    width: 26.6rem;
    height: 18.2rem;
    bottom: -1.5rem;
  }
}

.main-categories__box {
  display: flex;
  flex-wrap: wrap;
  gap: 2rem;
}
@media (max-width: 1200px) {
  .main-categories__box {
    gap: 1.6rem;
  }
}
.main-categories__card {
  text-decoration: none;
  display: flex;
  flex-direction: column;
  gap: 3.2rem;
  width: 44.8rem;
  margin-right: 2rem;
}
@media (max-width: 1200px) {
  .main-categories__card {
    width: 27.2rem;
    gap: 1.6rem;
    margin-right: 1.6rem;
  }
}
@media (max-width: 744px) {
  .main-categories__card {
    width: 29rem;
    margin-right: 0.9rem;
  }
}
@media (max-width: 480px) {
  .main-categories__card {
    margin-right: 0.8rem;
    width: 26.2rem;
    gap: 0.8rem;
  }
}
.main-categories__card:nth-last-of-type(1) {
  margin-right: 0;
}
.main-categories__card-title {
  color: var(--gray80);
}
@media (max-width: 1200px) {
  .main-categories__card-title {
    font-size: 2rem;
  }
}
@media (max-width: 744px) {
  .main-categories__card-title {
    font-size: 1.8rem;
  }
}
@media (max-width: 480px) {
  .main-categories__card-title {
    font-size: 1.6rem;
  }
}
.main-categories__image {
  width: 100%;
  height: 46rem;
  overflow: hidden;
}
@media (max-width: 1200px) {
  .main-categories__image {
    height: 27.2rem;
  }
}
@media (max-width: 744px) {
  .main-categories__image {
    height: 29rem;
  }
}
@media (max-width: 480px) {
  .main-categories__image {
    height: 26.2rem;
  }
}
.main-categories__image img {
  width: 100%;
  height: 100%;
  object-position: center;
}

@media (max-width: 1200px) {
  .main-news__title {
    font-size: 2.8rem;
  }
}
@media (max-width: 744px) {
  .main-news__title {
    font-size: 1.6rem;
  }
}
@media (max-width: 480px) {
  .main-news__title {
    font-size: 2rem;
  }
}
.main-news__swiper {
  position: relative;
  margin: 0 auto;
  margin-top: 4rem;
  max-width: 123.6rem;
  overflow: visible;
}
@media (max-width: 1200px) {
  .main-news__swiper {
    max-width: 80rem;
    margin-top: 2.4rem;
  }
}
@media (max-width: 744px) {
  .main-news__swiper {
    max-width: 50rem;
  }
}
@media (max-width: 480px) {
  .main-news__swiper {
    max-width: calc(100% - 1.8rem);
    margin-top: 1.6rem;
  }
  .main-news__swiper .swiper-slide {
    width: 34.8rem;
  }
}
.main-news__swiper-slide {
  margin: 0 16rem;
  display: flex;
  flex-direction: column;
  row-gap: 4rem;
}
@media (max-width: 1200px) {
  .main-news__swiper-slide {
    margin: 0 10rem;
    row-gap: 1.6rem;
  }
}
@media (max-width: 744px) {
  .main-news__swiper-slide {
    margin: 0 7.5rem;
  }
}
@media (max-width: 480px) {
  .main-news__swiper-slide {
    margin: 0;
    margin-right: 1.2rem;
  }
}
.main-news__swiper-image {
  display: flex;
  justify-content: center;
}
.main-news__swiper-image img {
  width: 100%;
  height: 100%;
}
.main-news__swiper-prev, .main-news__swiper-next {
  width: 3.2rem;
  height: 3.2rem;
  left: 0;
  top: 30.5rem;
  position: absolute;
  cursor: pointer;
  z-index: 10;
}
@media (max-width: 1200px) {
  .main-news__swiper-prev, .main-news__swiper-next {
    top: 16rem;
  }
}
@media (max-width: 744px) {
  .main-news__swiper-prev, .main-news__swiper-next {
    width: 2.2rem;
    height: 2.2rem;
    top: 10rem;
    left: 3rem;
  }
}
@media (max-width: 480px) {
  .main-news__swiper-prev, .main-news__swiper-next {
    display: none;
  }
}
.main-news__swiper-next {
  right: 0;
  left: unset;
}
@media (max-width: 744px) {
  .main-news__swiper-next {
    right: 3rem;
  }
}
.main-news__swiper-title {
  max-width: 60rem;
  color: var(--gray80);
}
.main-news__swiper-title span {
  color: var(--sageDark);
}
@media (max-width: 1200px) {
  .main-news__swiper-title {
    font-size: 2rem;
  }
}
@media (max-width: 744px) {
  .main-news__swiper-title {
    font-size: 1.6rem;
  }
}
@media (max-width: 480px) {
  .main-news__swiper-title {
    font-size: 1.6rem;
  }
}
.main-news__swiper-link {
  margin-top: -1.6rem;
  text-transform: uppercase;
}
@media (max-width: 1200px) {
  .main-news__swiper-link {
    margin-top: -0.8rem;
  }
}
@media (max-width: 744px) {
  .main-news__swiper-link {
    font-size: 1.2rem;
  }
}
@media (max-width: 480px) {
  .main-news__swiper-link {
    font-size: 1.2rem;
  }
}

.franchise-first__box {
  display: flex;
  column-gap: 13rem;
  width: 100%;
  position: relative;
}
@media (max-width: 1200px) {
  .franchise-first__box {
    column-gap: 4rem;
  }
}
@media (max-width: 744px) {
  .franchise-first__box {
    flex-wrap: wrap;
  }
}
.franchise-first__left {
  display: flex;
  flex-direction: column;
  min-height: 100%;
}
.franchise-first__description {
  text-transform: uppercase;
  margin-top: 12.4rem;
  margin-bottom: 22.3rem;
  color: var(--gray60);
}
@media (max-width: 1200px) {
  .franchise-first__description {
    margin-top: 6rem;
    margin-bottom: 11.4rem;
  }
}
@media (max-width: 744px) {
  .franchise-first__description {
    margin-top: 0;
    margin-bottom: 6.4rem;
  }
}
@media (max-width: 480px) {
  .franchise-first__description {
    margin-bottom: 3.2rem;
  }
}
.franchise-first__title {
  text-align: left;
  max-width: 81rem;
  margin-bottom: 4rem;
  line-height: 1.1;
}
.franchise-first__title span {
  color: var(--sageDark);
}
@media (max-width: 744px) {
  .franchise-first__title {
    margin-bottom: 4.8rem;
    max-width: 47rem;
  }
}
@media (max-width: 480px) {
  .franchise-first__title {
    margin-bottom: 3.2rem;
    font-size: 2.2rem;
    max-width: 29rem;
  }
}
.franchise-first__btn {
  margin-right: auto;
  min-width: 30rem;
}
@media (max-width: 1200px) {
  .franchise-first__btn {
    min-width: 17.6rem;
  }
}
@media (max-width: 744px) {
  .franchise-first__btn {
    display: none;
  }
}
.franchise-first__btn_mobile {
  display: none;
}
@media (max-width: 744px) {
  .franchise-first__btn_mobile {
    display: block;
    margin-top: 4rem;
    width: 100%;
  }
}
@media (max-width: 480px) {
  .franchise-first__btn_mobile {
    margin-top: 3.2rem;
  }
}
.franchise-first__image {
  width: 91.6rem;
  height: 92.4rem;
}
@media (max-width: 1200px) {
  .franchise-first__image {
    width: 56rem;
    height: 56rem;
    flex-shrink: 0;
  }
}
@media (max-width: 744px) {
  .franchise-first__image {
    width: 100%;
    height: 45.6rem;
    margin-top: 4rem;
  }
}
@media (max-width: 480px) {
  .franchise-first__image {
    height: 23rem;
    margin-top: 3.2rem;
  }
}
.franchise-first__image img {
  width: 100%;
  height: 100%;
}
.franchise-first__info {
  margin-top: auto;
  display: flex;
  justify-content: space-between;
}
@media (max-width: 480px) {
  .franchise-first__info {
    flex-wrap: wrap;
    row-gap: 2.4rem;
  }
}
.franchise-first__info-block {
  max-width: 19rem;
}
@media (max-width: 480px) {
  .franchise-first__info-block {
    width: 100%;
    max-width: unset;
  }
}
.franchise-first__info-title {
  color: var(--gray80);
  margin-bottom: 0.4rem;
}
@media (max-width: 1200px) {
  .franchise-first__info-title {
    font-size: 2rem;
  }
}
@media (max-width: 744px) {
  .franchise-first__info-title {
    font-size: 1.8rem;
  }
}
@media (max-width: 480px) {
  .franchise-first__info-title {
    font-size: 1.6rem;
  }
}
.franchise-first__info-text {
  color: var(--gray60);
}

.franchise-tabs__title {
  font-size: 3.6rem;
}
@media (max-width: 1200px) {
  .franchise-tabs__title {
    font-size: 2.8rem;
  }
}
@media (max-width: 744px) {
  .franchise-tabs__title {
    font-size: 2.6rem;
  }
}
@media (max-width: 480px) {
  .franchise-tabs__title {
    font-size: 2rem;
  }
}
.franchise-tabs__panel {
  margin-top: 5.6rem;
  border-radius: 0.8rem;
  border: 0.1rem solid var(--black);
  display: flex;
  justify-content: space-between;
  column-gap: 2rem;
}
@media (max-width: 1200px) {
  .franchise-tabs__panel {
    margin-top: 3.2rem;
  }
}
@media (max-width: 480px) {
  .franchise-tabs__panel {
    display: none;
  }
}
.franchise-tabs__select {
  display: none;
}
@media (max-width: 480px) {
  .franchise-tabs__select {
    display: block;
    margin-top: 2.4rem;
  }
}
.franchise-tabs__btn {
  cursor: pointer;
  width: 100%;
  padding: 1.2rem 1.6rem;
  color: var(--gray50);
  text-align: center;
}
@media (max-width: 1200px) {
  .franchise-tabs__btn {
    padding: 1.4rem;
  }
}
@media (max-width: 744px) {
  .franchise-tabs__btn {
    padding: 1.6rem;
  }
}
.franchise-tabs__btn.tab-btn-active {
  color: var(--black);
}
.franchise-tabs__box {
  margin-top: 7.2rem;
  display: none;
  justify-content: space-between;
}
.franchise-tabs__box_center {
  align-items: center;
}
@media (max-width: 1200px) {
  .franchise-tabs__box {
    margin-top: 4.8rem;
  }
}
@media (max-width: 744px) {
  .franchise-tabs__box {
    margin-top: 4rem;
    flex-wrap: wrap;
  }
}
@media (max-width: 480px) {
  .franchise-tabs__box {
    margin-top: 1.6rem;
  }
}
.franchise-tabs__box.tab-box-active {
  display: flex;
}
.franchise-tabs__content-title {
  max-width: 52.6rem;
  margin-bottom: 3.2rem;
}
@media (max-width: 480px) {
  .franchise-tabs__content-title {
    font-size: 1.6rem;
  }
}
.franchise-tabs__list {
  display: flex;
  flex-direction: column;
  row-gap: 2.4rem;
  margin: 0;
  padding: 0 0 0 2.6rem;
}
@media (max-width: 1200px) {
  .franchise-tabs__list {
    row-gap: 1.2rem;
  }
}
@media (max-width: 744px) {
  .franchise-tabs__list {
    row-gap: 0.8rem;
  }
}
@media (max-width: 480px) {
  .franchise-tabs__list {
    row-gap: 0.8rem;
  }
}
.franchise-tabs__li {
  color: var(--gray80);
  max-width: 47rem;
}
@media (max-width: 1200px) {
  .franchise-tabs__image {
    max-width: 56rem;
  }
}
@media (max-width: 744px) {
  .franchise-tabs__image {
    width: 100%;
    max-width: unset;
    max-height: 45.6rem;
    margin-top: 4rem;
  }
}
@media (max-width: 480px) {
  .franchise-tabs__image {
    margin-top: 2.8rem;
  }
}

.franchise-info__box {
  display: flex;
  justify-content: space-between;
  column-gap: 10rem;
}
@media (max-width: 1200px) {
  .franchise-info__box {
    column-gap: 1rem;
  }
}
@media (max-width: 744px) {
  .franchise-info__box {
    flex-wrap: wrap;
  }
}
.franchise-info__box_reverse {
  flex-direction: row-reverse;
}
.franchise-info__box_reverse .franchise-info__content {
  width: 50%;
}
@media (max-width: 744px) {
  .franchise-info__box_reverse .franchise-info__content {
    width: 100%;
  }
}
.franchise-info__content {
  display: flex;
  flex-direction: column;
}
.franchise-info__content_row {
  align-items: center;
  flex-direction: row;
  column-gap: 14rem;
}
.franchise-info__title {
  margin-bottom: 7.2rem;
  max-width: 73rem;
  text-align: left;
}
@media (max-width: 1200px) {
  .franchise-info__title {
    margin-bottom: 3.2rem;
  }
}
@media (max-width: 744px) {
  .franchise-info__title {
    margin-bottom: 3.2rem;
  }
}
@media (max-width: 480px) {
  .franchise-info__title {
    margin-bottom: 2.4rem;
    font-size: 2.2rem;
  }
}
.franchise-info__title:has(+ .franchise-info__subtitle) {
  margin-bottom: 5.6rem;
}
@media (max-width: 1200px) {
  .franchise-info__title:has(+ .franchise-info__subtitle) {
    margin-bottom: 3.2rem;
  }
}
@media (max-width: 744px) {
  .franchise-info__title:has(+ .franchise-info__subtitle) {
    margin-bottom: 3.2rem;
  }
}
@media (max-width: 480px) {
  .franchise-info__title:has(+ .franchise-info__subtitle) {
    margin-bottom: 2.4rem;
  }
}
.franchise-info__title:has(+ .franchise-info__box) {
  margin-bottom: 6.4rem;
  max-width: 95rem;
}
@media (max-width: 1200px) {
  .franchise-info__title:has(+ .franchise-info__box) {
    margin-bottom: 4.8rem;
  }
}
@media (max-width: 744px) {
  .franchise-info__title:has(+ .franchise-info__box) {
    margin-bottom: 3.2rem;
  }
}
@media (max-width: 480px) {
  .franchise-info__title:has(+ .franchise-info__box) {
    margin-bottom: 2.4rem;
  }
}
.franchise-info__title span {
  color: var(--sageDark);
}
.franchise-info__title_center {
  text-align: center;
  margin: 0 auto;
  max-width: 120rem;
}
@media (max-width: 1200px) {
  .franchise-info__title_center {
    max-width: 80rem;
  }
}
.franchise-info__description {
  margin: 0 auto;
  margin-top: 3.2rem;
  margin-bottom: 4.8rem;
  text-align: center;
  max-width: 44rem;
}
@media (max-width: 1200px) {
  .franchise-info__description {
    max-width: 36rem;
    margin-top: 2.4rem;
    margin-bottom: 4rem;
  }
}
@media (max-width: 744px) {
  .franchise-info__description {
    margin-top: 1.6rem;
    margin-bottom: 4.8rem;
  }
}
@media (max-width: 480px) {
  .franchise-info__description {
    margin-top: 1.6rem;
    margin-bottom: 3.2rem;
    max-width: 31rem;
  }
}
.franchise-info__subtitle {
  margin-bottom: 3.2rem;
  max-width: 69rem;
}
@media (max-width: 1200px) {
  .franchise-info__subtitle {
    font-size: 2rem;
  }
}
@media (max-width: 744px) {
  .franchise-info__subtitle {
    font-size: 1.8rem;
  }
}
@media (max-width: 480px) {
  .franchise-info__subtitle {
    font-size: 1.6rem;
  }
}
.franchise-info__list {
  display: flex;
  flex-direction: column;
  row-gap: 2.4rem;
  margin: 0;
  padding: 0 0 0 2.6rem;
}
@media (max-width: 1200px) {
  .franchise-info__list {
    row-gap: 1.2rem;
  }
}
@media (max-width: 480px) {
  .franchise-info__list {
    row-gap: 0.8rem;
  }
}
.franchise-info__list_short {
  row-gap: 1.6rem;
}
@media (max-width: 1200px) {
  .franchise-info__list_short {
    row-gap: 0.4rem;
  }
}
@media (max-width: 480px) {
  .franchise-info__list_short {
    row-gap: 0.4rem;
  }
}
.franchise-info__list_grid {
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  column-gap: 10rem;
}
@media (max-width: 1200px) {
  .franchise-info__list_grid {
    column-gap: 8rem;
  }
}
@media (max-width: 744px) {
  .franchise-info__list_grid {
    grid-template-columns: repeat(3, 1fr);
    column-gap: 14rem;
  }
}
@media (max-width: 480px) {
  .franchise-info__list_grid {
    grid-template-columns: repeat(2, 1fr);
    column-gap: 8rem;
  }
}
.franchise-info__li {
  color: var(--gray80);
  max-width: 47rem;
}
.franchise-info__image {
  height: 60rem;
}
@media (max-width: 1200px) {
  .franchise-info__image {
    max-height: 36rem;
  }
}
@media (max-width: 744px) {
  .franchise-info__image {
    max-height: 45.6rem;
    width: 100%;
    margin-top: 4rem;
  }
}
@media (max-width: 480px) {
  .franchise-info__image {
    margin-top: 2.8rem;
    max-height: 23rem;
  }
}
@media (max-width: 1200px) {
  .franchise-info__image_map {
    margin-top: 0;
  }
}
@media (max-width: 744px) {
  .franchise-info__image_map {
    margin-top: 0;
    max-height: 36.6rem;
  }
}
@media (max-width: 480px) {
  .franchise-info__image_map {
    margin-top: 2.4rem;
    max-height: 18.5rem;
  }
}
.franchise-info__image img {
  width: 100%;
  height: 100%;
}
.franchise-info__grid {
  display: flex;
  flex-wrap: wrap;
  margin-top: auto;
  row-gap: 10rem;
}
@media (max-width: 1200px) {
  .franchise-info__grid {
    row-gap: 5.2rem;
  }
}
@media (max-width: 744px) {
  .franchise-info__grid {
    row-gap: 3.2rem;
  }
}
@media (max-width: 480px) {
  .franchise-info__grid {
    row-gap: 2rem;
  }
}
.franchise-info__block {
  width: 50%;
}
@media (max-width: 480px) {
  .franchise-info__block {
    width: 100%;
  }
}
.franchise-info__block-title {
  font-size: 8rem;
  color: var(--sageDark);
  text-align: left;
  margin-bottom: 1.2rem;
}
@media (max-width: 1200px) {
  .franchise-info__block-title {
    font-size: 5.6rem;
  }
}
@media (max-width: 744px) {
  .franchise-info__block-title {
    font-size: 5.6rem;
  }
}
@media (max-width: 480px) {
  .franchise-info__block-title {
    font-size: 4.8rem;
  }
}
.franchise-info__block-title span {
  font-size: 3.6rem;
  margin-left: 0.8rem;
}
@media (max-width: 1200px) {
  .franchise-info__block-title span {
    font-size: 2.8rem;
  }
}
@media (max-width: 744px) {
  .franchise-info__block-title span {
    font-size: 2.6rem;
  }
}
@media (max-width: 480px) {
  .franchise-info__block-title span {
    font-size: 2rem;
  }
}
.franchise-info__block-text {
  color: var(--gray80);
}

.franchise-line__title {
  max-width: 60rem;
  margin: 0 auto;
}
@media (max-width: 1200px) {
  .franchise-line__title {
    font-size: 2.8rem;
  }
}
@media (max-width: 744px) {
  .franchise-line__title {
    font-size: 2.6rem;
  }
}
@media (max-width: 480px) {
  .franchise-line__title {
    font-size: 2rem;
  }
}
.franchise-line__box {
  margin-top: 6.4rem;
  border-top: 0.1rem solid var(--black);
  border-bottom: 0.1rem solid var(--black);
  padding: 7.2rem 0;
  display: flex;
  justify-content: space-around;
}
@media (max-width: 1200px) {
  .franchise-line__box {
    margin-top: 3.2rem;
    padding: 4.8rem 0;
  }
}
@media (max-width: 480px) {
  .franchise-line__box {
    margin-top: 2.4rem;
    padding: 2.4rem 0;
    flex-wrap: wrap;
    row-gap: 2.4rem;
  }
}
.franchise-line__box_special {
  justify-content: flex-start;
  column-gap: 15rem;
}
@media (max-width: 1200px) {
  .franchise-line__box_special {
    column-gap: 3rem;
  }
}
@media (max-width: 744px) {
  .franchise-line__box_special {
    flex-wrap: wrap;
    column-gap: 0;
    row-gap: 2.8rem;
  }
}
@media (max-width: 480px) {
  .franchise-line__box_special {
    row-gap: 1.6rem;
  }
}
@media (max-width: 1200px) {
  .franchise-line__box_special .franchise-line__block {
    max-width: 27rem;
  }
}
@media (max-width: 744px) {
  .franchise-line__box_special .franchise-line__block {
    min-width: 50%;
    max-width: 50%;
  }
}
@media (max-width: 480px) {
  .franchise-line__box_special .franchise-line__block {
    max-width: 100%;
  }
}
.franchise-line__block {
  max-width: 34rem;
}
@media (max-width: 744px) {
  .franchise-line__block {
    max-width: 16.8rem;
  }
}
@media (max-width: 480px) {
  .franchise-line__block {
    max-width: unset;
    width: 100%;
  }
}
.franchise-line__block_half {
  min-width: calc(50% - 15rem);
}
@media (max-width: 1200px) {
  .franchise-line__block_half {
    min-width: calc(50% - 3rem);
  }
}
@media (max-width: 744px) {
  .franchise-line__block_half {
    max-width: 41.6rem !important;
  }
}
.franchise-line__block_half .franchise-line__title {
  text-align: left;
  margin: 0;
}
.franchise-line__block-title {
  color: var(--gray80);
  text-align: center;
  margin-bottom: 1.6rem;
}
@media (max-width: 1200px) {
  .franchise-line__block-title {
    font-size: 2rem;
    margin-bottom: 0.8rem;
  }
}
@media (max-width: 744px) {
  .franchise-line__block-title {
    font-size: 1.8rem;
    margin-bottom: 0.4rem;
  }
}
@media (max-width: 480px) {
  .franchise-line__block-title {
    font-size: 1.6rem;
  }
}
.franchise-line__block-title_black {
  color: var(--black);
}
.franchise-line__block-title_sage {
  color: var(--sageDark);
  text-align: left;
  font-size: 8rem;
  margin-bottom: 0rem;
}
@media (max-width: 1200px) {
  .franchise-line__block-title_sage {
    font-size: 5.6rem;
  }
}
@media (max-width: 480px) {
  .franchise-line__block-title_sage {
    font-size: 4.8rem;
  }
}
.franchise-line__block-title_sage span {
  font-size: 3.6rem;
  margin-left: 0.8rem;
}
@media (max-width: 1200px) {
  .franchise-line__block-title_sage span {
    font-size: 2.8rem;
  }
}
@media (max-width: 744px) {
  .franchise-line__block-title_sage span {
    font-size: 2.6rem;
  }
}
@media (max-width: 480px) {
  .franchise-line__block-title_sage span {
    font-size: 2rem;
  }
}
@media (max-width: 744px) {
  .franchise-line__block-title_height {
    min-height: 4rem;
  }
}
@media (max-width: 480px) {
  .franchise-line__block-title_height {
    min-height: unset;
  }
}
.franchise-line__block-text {
  color: var(--gray80);
  text-align: center;
  letter-spacing: -0.02vw;
}
@media (max-width: 1200px) {
  .franchise-line__block-text {
    font-size: 1.4rem;
  }
}
@media (max-width: 744px) {
  .franchise-line__block-text {
    font-size: 1.2rem;
  }
}
@media (max-width: 480px) {
  .franchise-line__block-text {
    font-size: 1.2rem;
  }
}
.franchise-line__block-text_left {
  text-align: left;
}
@media (max-width: 744px) {
  .franchise-line__block-text_left {
    max-width: 30rem;
  }
}

.franchise-banner__box {
  min-height: 60rem;
  border-radius: 2rem;
  border: 0.1rem solid var(--black);
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  row-gap: 3.2rem;
}
@media (max-width: 1200px) {
  .franchise-banner__box {
    min-height: 360px;
    row-gap: 1.6rem;
  }
}
.franchise-banner__title {
  max-width: 104rem;
}
@media (max-width: 1200px) {
  .franchise-banner__title {
    max-width: 67rem;
  }
}
@media (max-width: 744px) {
  .franchise-banner__title {
    max-width: 66rem;
  }
}
@media (max-width: 480px) {
  .franchise-banner__title {
    max-width: 32rem;
  }
}
.franchise-banner__title span {
  color: var(--sageDark);
}
.franchise-banner__text {
  max-width: 50rem;
  text-align: center;
  color: var(--gray80);
}
@media (max-width: 744px) {
  .franchise-banner__text {
    max-width: 40rem;
  }
}
@media (max-width: 480px) {
  .franchise-banner__text {
    max-width: 35rem;
  }
}
.franchise-banner__btn {
  min-width: 30rem;
}
@media (max-width: 1200px) {
  .franchise-banner__btn {
    min-width: 20.8rem;
  }
}

.franchise-img {
  max-width: 68.2rem;
}
@media (max-width: 1200px) {
  .franchise-img {
    max-width: 41.6rem;
  }
}
@media (max-width: 744px) {
  .franchise-img {
    max-width: 100%;
  }
}

.franchise-img-wide {
  max-width: 91.6rem;
}
@media (max-width: 1200px) {
  .franchise-img-wide {
    max-width: 56rem;
  }
}
@media (max-width: 744px) {
  .franchise-img-wide {
    max-width: 100%;
  }
}

.franchise-img-map {
  max-width: 118.4rem;
}
@media (max-width: 1200px) {
  .franchise-img-map {
    max-width: 70.4rem;
  }
}
@media (max-width: 744px) {
  .franchise-img-map {
    max-width: 100%;
  }
}
.franchise-img-map img {
  object-fit: contain;
}

.shops__panel {
  margin-bottom: 2.4rem;
  display: flex;
  column-gap: 2rem;
}
@media (max-width: 1200px) {
  .shops__panel {
    column-gap: 1.6rem;
  }
}
@media (max-width: 744px) {
  .shops__panel {
    column-gap: 1rem;
    margin-top: 4.8rem;
  }
}
@media (max-width: 480px) {
  .shops__panel {
    flex-wrap: wrap;
    position: relative;
    row-gap: 0.8rem;
    margin-top: 3.6rem;
  }
}
.shops__panel-left {
  display: flex;
  align-items: center;
  column-gap: 2.4rem;
  width: 50%;
}
@media (max-width: 744px) {
  .shops__panel-left {
    flex-wrap: wrap;
    row-gap: 0.8rem;
  }
}
@media (max-width: 480px) {
  .shops__panel-left {
    width: 100%;
  }
}
.shops__panel-right {
  display: flex;
  align-items: center;
  column-gap: 2.4rem;
  width: 50%;
}
@media (max-width: 744px) {
  .shops__panel-right {
    align-items: flex-start;
  }
}
@media (max-width: 480px) {
  .shops__panel-right {
    width: 100%;
    padding-bottom: 3.2rem;
  }
}
.shops__panel-text {
  color: var(--gray60);
}
@media (max-width: 480px) {
  .shops__panel-text {
    position: absolute;
    left: 0;
    bottom: 0;
  }
}
.shops__panel-label {
  display: block;
  color: var(--gray40);
  white-space: nowrap;
  display: flex;
  align-items: center;
  column-gap: 0.8rem;
  user-select: none;
  font-size: 1.6rem;
  cursor: pointer;
}
.shops__panel-label input {
  position: absolute;
  opacity: 0;
  pointer-events: none;
}
@media (max-width: 1200px) {
  .shops__panel-label {
    font-size: 1.4rem;
  }
}
@media (max-width: 744px) {
  .shops__panel-label {
    font-size: 1.2rem;
    display: none;
  }
}
.shops__panel-label::before {
  content: "";
  width: 2.4rem;
  height: 2.4rem;
  background: url(/img/svg/checkbox-empty.svg) no-repeat;
  background-size: cover;
  flex-shrink: 0;
  opacity: 0.4;
}
.shops__panel-label:has(input:checked) {
  color: var(--black);
}
.shops__panel-label:has(input:checked)::before {
  background: url(/img/svg/checkbox-full.svg) no-repeat;
  background-size: cover;
  opacity: 1;
}
.shops__panel-select {
  display: none;
}
@media (max-width: 744px) {
  .shops__panel-select {
    display: block;
    width: 100%;
    height: 4.4rem;
  }
  .shops__panel-select .select__list_check {
    height: 21rem;
  }
  .shops__panel-select .select__scroll {
    height: calc(21rem - 3.8rem);
  }
}
.shops__box {
  display: flex;
  column-gap: 2rem;
}
@media (max-width: 1200px) {
  .shops__box {
    column-gap: 1.6rem;
  }
}
@media (max-width: 744px) {
  .shops__box {
    row-gap: 1.6rem;
    flex-wrap: wrap;
  }
}
.shops__left {
  width: 50%;
  height: 80rem;
  border: 0.1rem solid var(--gray20);
  padding: 7.2rem;
  overflow-y: auto;
  display: flex;
  flex-direction: column;
  row-gap: 4rem;
}
@media (max-width: 1200px) {
  .shops__left {
    height: 48.8rem;
    padding: 4.8rem;
    row-gap: 3rem;
  }
}
@media (max-width: 744px) {
  .shops__left {
    width: 100%;
  }
}
@media (max-width: 480px) {
  .shops__left {
    padding: 2.4rem;
    height: 34rem;
    row-gap: 2.4rem;
  }
}
.shops__right {
  width: 50%;
  height: 80rem;
  background: #ddd;
}
@media (max-width: 1200px) {
  .shops__right {
    height: 48.8rem;
  }
}
@media (max-width: 744px) {
  .shops__right {
    width: 100%;
  }
}
@media (max-width: 480px) {
  .shops__right {
    height: 34rem;
  }
}
.shops__block {
  flex-shrink: 0;
  padding-bottom: 4rem;
  border-bottom: 0.1rem solid var(--gray40);
  display: flex;
  flex-direction: column;
  row-gap: 1.6rem;
}
@media (max-width: 1200px) {
  .shops__block {
    padding-bottom: 3rem;
  }
}
@media (max-width: 480px) {
  .shops__block {
    padding-bottom: 2.4rem;
  }
}
.shops__block:last-of-type {
  border: none;
  padding-bottom: 0;
}
.shops__block_hidden {
  display: none;
}
@media (max-width: 1200px) {
  .shops__block-title {
    font-size: 2rem;
  }
}
@media (max-width: 744px) {
  .shops__block-title {
    font-size: 1.8rem;
  }
}
@media (max-width: 480px) {
  .shops__block-title {
    font-size: 1.6rem;
  }
}
.shops__block-text {
  display: flex;
  flex-direction: column;
  row-gap: 0.4rem;
}
.shops__block-text p {
  margin: 0;
}
.shops__block-text_gray * {
  color: var(--gray80);
}
.shops__block-grid {
  display: flex;
  flex-wrap: wrap;
  gap: 1.8rem;
  margin-top: 1rem;
}
@media (max-width: 1200px) {
  .shops__block-grid {
    gap: 1rem;
  }
}
@media (max-width: 744px) {
  .shops__block-grid {
    gap: 1rem 4rem;
  }
}
.shops__block-grid_links {
  flex-wrap: nowrap;
}
.shops__block-cell {
  display: flex;
  column-gap: 1.8rem;
  width: 27.2rem;
}
@media (max-width: 1200px) {
  .shops__block-cell {
    width: 22rem;
    column-gap: 1.2rem;
  }
}
.shops__block-cell * {
  color: var(--gray80);
}
.shops__block-icon {
  flex-shrink: 0;
  width: 2.4rem;
  height: 2.4rem;
}
.shops__block-icon img {
  width: 100%;
  height: 100%;
}
.shops__block-buttons {
  margin-top: 1rem;
  display: flex;
}
.shops__block-link {
  display: block;
}
.shops__block-link_gray {
  color: var(--gray50);
  border-color: var(--gray50);
}
@media (max-width: 480px) {
  .shops__block-link {
    font-size: 1.2rem;
  }
}
.shops__map {
  width: 100%;
  height: 100%;
}

.map-baloon-text {
  margin: 2rem 0;
}
.map-baloon-text p {
  margin: 0;
}

@media (max-width: 480px) {
  .contacts__title {
    font-size: 1.6rem;
  }
}
.contacts__top {
  display: flex;
  flex-direction: column;
  row-gap: 2rem;
  margin-bottom: 5.6rem;
}
@media (max-width: 1200px) {
  .contacts__top {
    margin-bottom: 4rem;
    row-gap: 1.6rem;
  }
}
@media (max-width: 480px) {
  .contacts__top {
    margin-bottom: 3.2rem;
    margin-top: 4rem;
  }
}
.contacts__rows {
  display: flex;
  flex-direction: column;
  row-gap: 0.8rem;
}
.contacts__text {
  color: var(--gray70);
  max-width: 56rem;
}
@media (max-width: 1200px) {
  .contacts__text {
    max-width: 50rem;
  }
}
.contacts__text a {
  color: var(--gray70);
}
.contacts__text span {
  color: var(--black);
}
.contacts__box {
  display: flex;
  justify-content: space-between;
  column-gap: 2rem;
}
@media (max-width: 1200px) {
  .contacts__box {
    column-gap: 1.6rem;
  }
}
@media (max-width: 744px) {
  .contacts__box {
    column-gap: 1rem;
  }
}
@media (max-width: 480px) {
  .contacts__box {
    flex-wrap: wrap;
    row-gap: 4rem;
  }
}
.contacts__box_mt {
  margin-top: 6.4rem;
}
@media (max-width: 1200px) {
  .contacts__box_mt {
    margin-top: 4.8rem;
  }
}
@media (max-width: 744px) {
  .contacts__box_mt {
    margin-top: 4rem;
  }
}
@media (max-width: 480px) {
  .contacts__box_mt {
    margin-top: 3.2rem;
    row-gap: 2.4rem;
  }
}
.contacts__left {
  width: 50%;
  display: flex;
  flex-direction: column;
  row-gap: 3.2rem;
}
@media (max-width: 1200px) {
  .contacts__left {
    row-gap: 2.4rem;
  }
}
@media (max-width: 480px) {
  .contacts__left {
    width: 100%;
    row-gap: 1.6rem;
  }
}
.contacts__left_row {
  flex-direction: row;
  flex-wrap: wrap;
}
@media (max-width: 480px) {
  .contacts__left_row {
    row-gap: 2.4rem;
  }
}
.contacts__right {
  width: 50%;
  display: flex;
  flex-direction: column;
  row-gap: 3.2rem;
}
@media (max-width: 1200px) {
  .contacts__right {
    row-gap: 2.4rem;
  }
}
@media (max-width: 480px) {
  .contacts__right {
    width: 100%;
    row-gap: 1.6rem;
  }
}
.contacts__right_row {
  flex-direction: row;
  flex-wrap: wrap;
}
.contacts__block {
  display: flex;
  flex-direction: column;
  row-gap: 1.6rem;
  min-width: 50%;
}
@media (max-width: 1200px) {
  .contacts__block {
    width: 100%;
    row-gap: 0.8rem;
  }
}
@media (max-width: 1200px) {
  .contacts__block_head {
    row-gap: 1.6rem;
    margin-bottom: -0.8rem;
  }
}
@media (max-width: 480px) {
  .contacts__block_head {
    margin-bottom: 0;
    row-gap: 0.8rem;
  }
}
.contacts__block-title {
  font-size: 2.4rem;
}
@media (max-width: 1200px) {
  .contacts__block-title {
    font-size: 1.8rem;
  }
}
@media (max-width: 744px) {
  .contacts__block-title {
    font-size: 1.6rem;
  }
}
@media (max-width: 480px) {
  .contacts__block-title {
    font-size: 1.6rem;
  }
}
.contacts__map {
  margin-top: auto;
  background: #eee;
  height: 57.1rem;
  width: 100%;
  border-radius: 1.2rem;
  overflow: hidden;
}
@media (max-width: 1200px) {
  .contacts__map {
    height: 34.8rem;
  }
}
@media (max-width: 744px) {
  .contacts__map {
    height: 23.2rem;
  }
}

.certificate__box {
  margin-top: 6.4rem;
  display: flex;
  justify-content: space-between;
  max-width: 146rem;
}
@media (max-width: 1200px) {
  .certificate__box {
    margin-top: 4rem;
  }
}
@media (max-width: 744px) {
  .certificate__box {
    margin-top: 3.2rem;
  }
}
@media (max-width: 480px) {
  .certificate__box {
    flex-wrap: wrap-reverse;
    row-gap: 5.6rem;
  }
}
.certificate__tab:not(.tab-box-active) {
  display: none;
}
.certificate__accordions {
  width: 100%;
  max-width: 54.6rem;
}
@media (max-width: 1200px) {
  .certificate__accordions {
    max-width: 46.4rem;
  }
}
@media (max-width: 744px) {
  .certificate__accordions {
    max-width: 32rem;
  }
}
@media (max-width: 480px) {
  .certificate__accordions {
    max-width: 100%;
  }
}
.certificate__form {
  width: 100%;
  max-width: 58.4rem;
  display: flex;
  flex-direction: column;
  row-gap: 3.2rem;
}
@media (max-width: 1200px) {
  .certificate__form {
    max-width: 51.2rem;
  }
}
@media (max-width: 744px) {
  .certificate__form {
    max-width: 34rem;
    row-gap: 2.4rem;
  }
}
@media (max-width: 480px) {
  .certificate__form {
    max-width: 100%;
  }
}
.certificate__check {
  width: 100%;
  max-width: 62.4rem;
  display: flex;
  flex-direction: column;
  row-gap: 3.2rem;
}
@media (max-width: 1200px) {
  .certificate__check {
    max-width: 47.2rem;
  }
}
@media (max-width: 744px) {
  .certificate__check {
    max-width: 32rem;
    row-gap: 2.4rem;
  }
}
@media (max-width: 480px) {
  .certificate__check {
    max-width: 100%;
  }
}
.certificate__poly {
  color: var(--gray50);
  margin-top: -0.8rem;
}
.certificate__explain {
  color: var(--gray50);
  margin-top: -0.8rem;
  max-width: 31rem;
}
@media (max-width: 1200px) {
  .certificate__explain {
    max-width: 26rem;
  }
}
@media (max-width: 744px) {
  .certificate__explain {
    margin-top: -0.2rem;
  }
}
@media (max-width: 480px) {
  .certificate__explain {
    margin-top: -0.6rem;
  }
}
.certificate__explain a {
  color: var(--gray50);
}
.certificate__block {
  display: flex;
  flex-direction: column;
  row-gap: 1.6rem;
}
@media (max-width: 744px) {
  .certificate__block {
    row-gap: 1rem;
  }
}
@media (max-width: 744px) {
  .certificate__block .variants {
    gap: 0.5rem;
    margin-top: 0.5rem;
    margin-bottom: 0.5rem;
  }
}
.certificate__block textarea {
  min-height: 5.6rem;
}
@media (max-width: 1200px) {
  .certificate__block textarea {
    min-height: 4rem;
  }
}
@media (max-width: 744px) {
  .certificate__block textarea {
    min-height: 4.4rem;
  }
}
.certificate__image {
  width: 60.4rem;
  height: 34rem;
  border-radius: 2rem;
  overflow: hidden;
  margin-bottom: 0.8rem;
}
@media (max-width: 1200px) {
  .certificate__image {
    border-radius: 1.4rem;
    width: 48rem;
    height: 26.9rem;
  }
}
@media (max-width: 744px) {
  .certificate__image {
    width: 32rem;
    height: 19.6rem;
    margin-bottom: 0;
  }
}
@media (max-width: 480px) {
  .certificate__image {
    width: 100%;
  }
}
.certificate__image img {
  width: 100%;
  height: 100%;
}

.certificate-buy__box {
  display: flex;
  flex-wrap: wrap;
  row-gap: 3.2rem;
  justify-content: space-between;
}
@media (max-width: 744px) {
  .certificate-buy__box {
    row-gap: 2rem;
  }
}
@media (max-width: 480px) {
  .certificate-buy__box {
    row-gap: 3.2rem;
  }
}
.certificate-buy__top {
  width: 100%;
  max-width: 107.2rem;
}
@media (max-width: 1200px) {
  .certificate-buy__top {
    max-width: 60.8rem;
  }
}
@media (max-width: 744px) {
  .certificate-buy__top {
    max-width: unset;
  }
}
.certificate-buy__title {
  font-size: 2.8rem;
}
@media (max-width: 1200px) {
  .certificate-buy__title {
    font-size: 2rem;
  }
}
@media (max-width: 744px) {
  .certificate-buy__title {
    font-size: 1.8rem;
  }
}
@media (max-width: 480px) {
  .certificate-buy__title {
    font-size: 1.6rem;
  }
}
.certificate-buy__product {
  width: 100%;
  max-width: 107.2rem;
  display: flex;
  flex-direction: column;
  row-gap: 4rem;
}
@media (max-width: 1200px) {
  .certificate-buy__product {
    max-width: 60.8rem;
    row-gap: 2.4rem;
  }
}
@media (max-width: 744px) {
  .certificate-buy__product {
    max-width: unset;
  }
}
@media (max-width: 480px) {
  .certificate-buy__product {
    row-gap: 2rem;
  }
}
.certificate-buy__info {
  width: 100%;
  max-width: 60.4rem;
  padding: 7.7rem 7.4rem;
  border-radius: 2.4rem;
  border: 0.1rem solid var(--gray20);
  display: flex;
  flex-direction: column;
  row-gap: 1.6rem;
}
@media (max-width: 1200px) {
  .certificate-buy__info {
    padding: 4.8rem 3.8rem;
    max-width: 41.6rem;
    row-gap: 0.8rem;
  }
}
@media (max-width: 744px) {
  .certificate-buy__info {
    max-width: unset;
    padding: 3.2rem;
    margin-top: 2rem;
    flex-wrap: wrap;
    flex-direction: row;
    column-gap: 8rem;
    border-radius: 1.6rem;
  }
}
@media (max-width: 480px) {
  .certificate-buy__info {
    margin-top: 0;
    padding: 0;
    border: none;
    border-radius: 0;
  }
}
.certificate-buy__info-title {
  margin-bottom: 0.8rem;
}
@media (max-width: 1200px) {
  .certificate-buy__info-title {
    font-size: 2rem;
    margin-bottom: 1.2rem;
  }
}
@media (max-width: 744px) {
  .certificate-buy__info-title {
    font-size: 1.8rem;
    width: 100%;
  }
}
@media (max-width: 480px) {
  .certificate-buy__info-title {
    font-size: 1.6rem;
  }
}
@media (max-width: 480px) {
  .certificate-buy__info-title {
    font-size: 2.2rem;
  }
}
@media (max-width: 744px) {
  .certificate-buy__info-block {
    width: 23rem;
  }
}
@media (max-width: 480px) {
  .certificate-buy__info-block {
    width: 23.3rem;
  }
}
.certificate-buy__info-block_mt {
  margin-top: 1.6rem;
}
@media (max-width: 744px) {
  .certificate-buy__info-block_mt {
    margin-top: 0;
  }
}
@media (max-width: 744px) {
  .certificate-buy__info-text {
    font-size: 1.2rem;
  }
}
.certificate-buy__info-text span {
  color: var(--gray70);
}
.certificate-buy__info-btn {
  margin-top: 0.8rem;
}
@media (max-width: 1200px) {
  .certificate-buy__info-btn {
    margin-top: 1.2rem;
  }
}
.certificate-buy__info-btn_pay {
  margin-top: 0rem;
}
@media (max-width: 1200px) {
  .certificate-buy__info-btn_pay {
    margin-top: 0.8rem;
  }
}
@media (max-width: 744px) {
  .certificate-buy__info-btn_pay {
    margin-top: 0rem;
    padding: 1.6rem 1.6rem;
  }
}
.certificate-buy__info-btn_podeli {
  background: var(--white);
  margin-top: 0rem;
  font-weight: 600;
  display: flex;
  align-items: center;
  justify-content: center;
  column-gap: 0.8rem;
  padding: 1.5rem;
  font-size: 1.6rem;
  letter-spacing: 0.02vw;
  text-transform: none;
}
@media (max-width: 1200px) {
  .certificate-buy__info-btn_podeli {
    margin-top: 0.8rem;
    padding: 1rem 1.6rem;
    font-size: 1.4rem;
  }
}
@media (max-width: 744px) {
  .certificate-buy__info-btn_podeli {
    margin-top: 0rem;
    padding: 1rem 1.6rem;
  }
}
.certificate-buy__product-card {
  display: flex;
  column-gap: 6.4rem;
  padding-bottom: 3.2rem;
  border-bottom: 0.1rem solid var(--gray20);
}
@media (max-width: 1200px) {
  .certificate-buy__product-card {
    column-gap: 4.8rem;
    padding-bottom: 2.4rem;
  }
}
@media (max-width: 744px) {
  .certificate-buy__product-card {
    column-gap: 3rem;
  }
}
@media (max-width: 480px) {
  .certificate-buy__product-card {
    padding-bottom: 2rem;
    column-gap: 4rem;
  }
}
.certificate-buy__product-image {
  width: 32.7rem;
  height: 22.8rem;
  border-radius: 2.4rem;
  flex-shrink: 0;
  overflow: hidden;
}
@media (max-width: 1200px) {
  .certificate-buy__product-image {
    width: 22.4rem;
    height: 15.6rem;
    border-radius: 1.2rem;
  }
}
@media (max-width: 744px) {
  .certificate-buy__product-image {
    width: 18rem;
    height: 11.2rem;
    border-radius: 1.7rem;
  }
}
@media (max-width: 480px) {
  .certificate-buy__product-image {
    width: 12.8rem;
    height: 7.6rem;
    border-radius: 0.8rem;
  }
}
.certificate-buy__product-image img {
  width: 100%;
  height: 100%;
  display: block;
}
.certificate-buy__product-content {
  display: flex;
  flex-direction: column;
  width: 100%;
}
.certificate-buy__product-title {
  max-width: 30rem;
  text-align: left;
}
@media (max-width: 1200px) {
  .certificate-buy__product-title {
    font-size: 1.8rem;
    max-width: 23rem;
  }
}
@media (max-width: 744px) {
  .certificate-buy__product-title {
    font-size: 1.6rem;
    max-width: 17rem;
  }
}
@media (max-width: 480px) {
  .certificate-buy__product-title {
    font-size: 1.2rem;
  }
}
.certificate-buy__product-bot {
  display: flex;
  justify-content: space-between;
  margin-bottom: 3.6rem;
  margin-top: auto;
  width: 100%;
  align-items: flex-end;
}
@media (max-width: 744px) {
  .certificate-buy__product-bot {
    margin-bottom: 2.4rem;
  }
}
@media (max-width: 480px) {
  .certificate-buy__product-bot {
    margin-bottom: 0;
  }
}
.certificate-buy__product-info {
  display: flex;
  flex-direction: column;
  row-gap: 1rem;
}
@media (max-width: 1200px) {
  .certificate-buy__product-info {
    row-gap: 0.6rem;
  }
}
@media (max-width: 480px) {
  .certificate-buy__product-info {
    row-gap: 0.4rem;
  }
}
.certificate-buy__product-result {
  text-align: right;
}
@media (max-width: 1200px) {
  .certificate-buy__product-result {
    font-size: 1.8rem;
  }
}
@media (max-width: 744px) {
  .certificate-buy__product-result {
    text-align: left;
    font-size: 1.6rem;
  }
}

.loyalty__box {
  display: flex;
  justify-content: space-between;
  margin-top: 8rem;
}
@media (max-width: 1200px) {
  .loyalty__box {
    margin-top: 6.4rem;
  }
}
@media (max-width: 744px) {
  .loyalty__box {
    margin-top: 3.2rem;
    flex-wrap: wrap;
    row-gap: 5.6rem;
  }
}
@media (max-width: 480px) {
  .loyalty__box {
    row-gap: 3.2rem;
  }
}
.loyalty__title {
  max-width: 60rem;
  text-align: left;
}
@media (max-width: 1200px) {
  .loyalty__title {
    max-width: 44rem;
    font-size: 2.8rem;
  }
}
@media (max-width: 744px) {
  .loyalty__title {
    font-size: 2.6rem;
  }
}
@media (max-width: 480px) {
  .loyalty__title {
    font-size: 2rem;
  }
}
.loyalty__info {
  width: 100%;
  max-width: 60.4rem;
  border: 0.1rem solid var(--gray20);
  border-radius: 2.4rem;
  padding: 7.6rem 7.8rem;
  display: flex;
  flex-direction: column;
  row-gap: 3.2rem;
}
@media (max-width: 1200px) {
  .loyalty__info {
    max-width: 41.6rem;
    padding: 4.8rem;
  }
}
@media (max-width: 744px) {
  .loyalty__info {
    max-width: 100%;
    padding: 3rem;
  }
}
@media (max-width: 480px) {
  .loyalty__info {
    padding: 0;
    border: none;
    border-radius: 0;
  }
}
.loyalty__info-block {
  display: flex;
  flex-direction: column;
  row-gap: 1.6rem;
}
.loyalty hr {
  background: var(--gray20);
  border: none;
  height: 0.1rem;
  width: 100%;
  margin: 0;
}
@media (max-width: 744px) {
  .loyalty hr {
    display: none;
  }
}
.loyalty__info-text {
  color: var(--gray70);
}
.loyalty__info-title {
  text-align: left;
}
@media (max-width: 1200px) {
  .loyalty__info-title {
    font-size: 1.8rem;
  }
}
@media (max-width: 744px) {
  .loyalty__info-title {
    font-size: 1.6rem;
  }
}
@media (max-width: 480px) {
  .loyalty__info-title {
    font-size: 1.6rem;
  }
}
.loyalty__info-flex {
  display: flex;
  justify-content: space-between;
}
.loyalty__info-buttons {
  display: flex;
  column-gap: 2rem;
  margin-top: 0.8rem;
}
.loyalty__info-buttons .btn {
  padding-left: 0.5rem;
  padding-right: 0.5rem;
}
@media (max-width: 1200px) {
  .loyalty__info-buttons {
    column-gap: 1rem;
  }
}
@media (max-width: 744px) {
  .loyalty__info-buttons {
    margin-top: 0;
  }
}
@media (max-width: 480px) {
  .loyalty__info-buttons {
    flex-wrap: wrap;
    row-gap: 0.8rem;
  }
}
.loyalty__sale {
  width: 100%;
  max-width: 107.2rem;
}
@media (max-width: 1200px) {
  .loyalty__sale {
    max-width: 66rem;
  }
}
.loyalty__sale-slider-wrap {
  margin-top: 4rem;
  margin-bottom: 7.2rem;
}
@media (max-width: 1200px) {
  .loyalty__sale-slider-wrap {
    margin-top: 3.2rem;
    margin-bottom: 3.2rem;
  }
}
@media (max-width: 744px) {
  .loyalty__sale-slider-wrap {
    margin-top: 2.4rem;
  }
}
.loyalty__sale-scale {
  display: flex;
  justify-content: space-between;
}
.loyalty__sale-step {
  color: var(--gray40);
}
.loyalty__sale-step_active {
  color: var(--black);
  font-weight: 500;
}
.loyalty__sale-slider-track {
  margin-top: 1.7rem;
  height: 0.8rem;
  background: var(--gray20);
  position: relative;
  border-radius: 10rem;
}
.loyalty__sale-slider {
  top: 0;
  left: 0;
  position: absolute;
  height: 0.8rem;
  width: 100%;
  background: transparent;
  -webkit-appearance: none;
  border-radius: 10rem;
}
.loyalty__sale-slider::-webkit-slider-thumb {
  -webkit-appearance: none;
  background: var(--sageDark);
  width: 2rem;
  height: 2rem;
  border-radius: 10rem;
  border: 0.5rem solid var(--sage);
  margin-top: -0.6rem;
  cursor: pointer;
}
.loyalty__sale-slider::-moz-range-thumb {
  box-sizing: border-box;
  background: var(--sageDark);
  width: 2rem;
  height: 2rem;
  border-radius: 10rem;
  border: 0.5rem solid var(--sage);
  margin-top: -0.6rem;
  cursor: pointer;
}
.loyalty__sale-slider::-webkit-slider-runnable-track {
  height: 0.8rem;
  border-radius: 10rem;
  /* border: none;
  border-radius: rem(100);
  cursor: pointer; */
}
.loyalty__sale-results {
  display: flex;
  justify-content: space-between;
  max-width: 99rem;
}
@media (max-width: 1200px) {
  .loyalty__sale-results {
    column-gap: 1.6rem;
  }
}
@media (max-width: 480px) {
  .loyalty__sale-results {
    flex-wrap: wrap;
    row-gap: 3.2rem;
  }
}
.loyalty__sale-col {
  display: flex;
  flex-direction: column;
  row-gap: 2.4rem;
  width: 44rem;
}
@media (max-width: 744px) {
  .loyalty__sale-col {
    width: 27.2rem;
  }
}
@media (max-width: 480px) {
  .loyalty__sale-col {
    width: 100%;
  }
}
@media (max-width: 480px) {
  .loyalty__sale-col_border {
    padding-top: 3.2rem;
    border-top: 0.1rem solid var(--gray20);
  }
}
.loyalty__sale-pictures {
  display: flex;
}
.loyalty__sale-img {
  width: 21.4rem;
  height: 21.4rem;
  border-radius: 20rem;
  display: block;
  z-index: 2;
  flex-shrink: 0;
}
@media (max-width: 1200px) {
  .loyalty__sale-img {
    width: 11.3rem;
    height: 11.3rem;
  }
}
.loyalty__sale-img:nth-of-type(2) {
  margin-left: -5.8rem;
  z-index: 1;
}
@media (max-width: 1200px) {
  .loyalty__sale-img:nth-of-type(2) {
    margin-left: -3rem;
  }
}
.loyalty__sale-col-title {
  flex-grow: 1;
  max-width: 31rem;
}
@media (max-width: 1200px) {
  .loyalty__sale-col-title {
    font-size: 1.6rem;
  }
}
@media (max-width: 744px) {
  .loyalty__sale-col-title {
    font-size: 1.4rem;
  }
}
.loyalty__sale-block {
  display: flex;
  flex-direction: column;
  row-gap: 0.8rem;
  flex-grow: 1;
}
@media (max-width: 1200px) {
  .loyalty__sale-block {
    row-gap: 0.6rem;
  }
}
.loyalty__sale-line {
  display: flex;
  justify-content: space-between;
}
.loyalty__sale-text_gray {
  color: var(--gray50);
}
.loyalty__sale-text_sage {
  color: var(--sageDark);
}
.loyalty__conditions {
  width: 100%;
  max-width: 107.2rem;
}
@media (max-width: 1200px) {
  .loyalty__conditions {
    max-width: 65.6rem;
  }
}
.loyalty__conditions-box {
  display: flex;
  column-gap: 8rem;
  margin-top: 5.6rem;
}
@media (max-width: 1200px) {
  .loyalty__conditions-box {
    column-gap: 1.6rem;
    margin-top: 3rem;
  }
}
@media (max-width: 744px) {
  .loyalty__conditions-box {
    flex-wrap: wrap;
    row-gap: 4rem;
    margin-top: 2.4rem;
  }
}
@media (max-width: 480px) {
  .loyalty__conditions-box {
    row-gap: 2.4rem;
  }
}
.loyalty__conditions-col {
  width: 100%;
  display: flex;
  flex-direction: column;
  row-gap: 1.6rem;
}
@media (max-width: 1200px) {
  .loyalty__conditions-col {
    row-gap: 1.2rem;
  }
}
@media (max-width: 744px) {
  .loyalty__conditions-col {
    width: 55rem;
  }
}
@media (max-width: 480px) {
  .loyalty__conditions-col {
    width: 100%;
  }
}
.loyalty__conditions-title {
  margin-bottom: 0.8rem;
  max-width: 40rem;
  color: var(--gray70);
}
@media (max-width: 1200px) {
  .loyalty__conditions-title {
    max-width: 27rem;
  }
}
@media (max-width: 744px) {
  .loyalty__conditions-title {
    max-width: 100%;
    font-size: 1.8rem;
  }
}
@media (max-width: 480px) {
  .loyalty__conditions-title {
    font-size: 1.6rem;
  }
}
.loyalty__conditions-text {
  color: var(--gray60);
}

.cart_success .certificate-buy__info {
  position: sticky;
  top: 12rem;
}
@media (max-width: 744px) {
  .cart_success .cart__top {
    justify-content: flex-start;
  }
  .cart_success .certificate-buy__info {
    margin-top: 5.6rem;
  }
}
@media (max-width: 480px) {
  .cart_success .cart__card {
    padding-left: 0;
  }
  .cart_success .cart__card-title {
    max-width: 20rem;
  }
  .cart_success .cart__card-block_auto {
    position: relative;
    bottom: 0;
  }
  .cart_success .cart__card-image {
    margin-bottom: 0;
  }
  .cart_success .certificate-buy__info {
    margin-top: 4rem;
  }
}
.cart .inner {
  margin-top: 6.4rem;
}
@media (max-width: 744px) {
  .cart .inner {
    margin-top: 4.8rem;
  }
}
@media (max-width: 480px) {
  .cart .inner {
    margin-top: 2.4rem;
  }
}
.cart__box {
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
}
.cart__empty {
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  row-gap: 3.2rem;
  margin-top: 0.8rem;
}
@media (max-width: 1200px) {
  .cart__empty {
    row-gap: 2.4rem;
  }
}
@media (max-width: 480px) {
  .cart__empty {
    margin-top: 0;
  }
}
.cart__empty-btn {
  width: 100%;
  max-width: 29.2rem;
}
.cart__top {
  width: 100%;
  max-width: 107.2rem;
  display: flex;
  justify-content: flex-end;
  align-items: center;
  margin-bottom: 2.8rem;
  column-gap: 17.3rem;
  position: relative;
}
@media (max-width: 1200px) {
  .cart__top {
    max-width: 60.8rem;
    column-gap: 6rem;
    margin-bottom: 2.4rem;
  }
}
@media (max-width: 744px) {
  .cart__top {
    max-width: 100%;
    column-gap: 10.2rem;
  }
}
@media (max-width: 480px) {
  .cart__top {
    flex-wrap: wrap;
    margin-bottom: 2.4rem;
    row-gap: 2.4rem;
    column-gap: 2.4rem;
    justify-content: flex-start;
  }
}
@media (max-width: 1200px) {
  .cart__top-title {
    font-size: 2rem;
  }
}
@media (max-width: 744px) {
  .cart__top-title {
    font-size: 1.8rem;
  }
}
@media (max-width: 480px) {
  .cart__top-title {
    font-size: 2.2rem;
  }
}
.cart__top-block {
  margin-right: auto;
  display: flex;
  flex-direction: column;
  row-gap: 0.4rem;
}
@media (max-width: 744px) {
  .cart__top-block {
    margin-right: -2rem;
  }
}
@media (max-width: 480px) {
  .cart__top-block {
    width: 100%;
  }
}
.cart__top-link {
  text-decoration: none;
  white-space: nowrap;
}
@media (max-width: 480px) {
  .cart__top-link_share {
    display: none;
  }
}
.cart__top-link_mobile {
  display: none;
}
@media (max-width: 480px) {
  .cart__top-link_mobile {
    display: block;
    margin-left: auto;
  }
  .cart__top-link_mobile img {
    width: 2rem;
    height: 2rem;
  }
}
@media (max-width: 480px) {
  .cart__top-link {
    font-size: 1.2rem;
  }
}
@media (max-width: 480px) {
  .cart__top-amount {
    font-size: 1rem;
  }
}
.cart__top-check {
  display: flex;
  align-items: center;
  column-gap: 2rem;
  cursor: pointer;
  flex-shrink: 0;
  user-select: none;
}
.cart__top-check input {
  position: absolute;
  opacity: 0;
  pointer-events: none;
}
.cart__top-check::before {
  content: "";
  display: block;
  width: 2.4rem;
  height: 2.4rem;
  background: url(/img/svg/checkbox-empty.svg) no-repeat;
  background-size: cover;
  flex-shrink: 0;
  pointer-events: none;
}
@media (max-width: 480px) {
  .cart__top-check::before {
    width: 2rem;
    height: 2rem;
  }
}
.cart__top-check:has(input:checked)::before {
  background: url(/img/svg/checkbox-full.svg) no-repeat;
  background-size: cover;
}
@media (max-width: 480px) {
  .cart__top-check {
    font-size: 1.2rem;
  }
}
.cart__content {
  width: 100%;
  max-width: 107.2rem;
  display: flex;
  flex-direction: column;
  row-gap: 10rem;
}
@media (max-width: 1200px) {
  .cart__content {
    max-width: 60.8rem;
    row-gap: 8rem;
  }
}
@media (max-width: 744px) {
  .cart__content {
    max-width: 100%;
    row-gap: 6.4rem;
  }
}
@media (max-width: 744px) {
  .cart__sticky {
    width: 100%;
  }
}
.cart__info {
  top: 12rem;
  position: sticky;
  width: 100%;
  max-width: 60.4rem;
  border-radius: 2.4rem;
  padding: 6.4rem 7.8rem;
  border: 0.1rem solid var(--gray20);
  display: flex;
  flex-direction: column;
  row-gap: 3.2rem;
}
@media (max-width: 1200px) {
  .cart__info {
    padding: 4rem;
    max-width: 41.6rem;
    row-gap: 2.4rem;
  }
}
@media (max-width: 744px) {
  .cart__info {
    position: relative;
    top: unset;
    margin-top: 6.4rem;
    max-width: unset;
    padding: 2.4rem;
    border-radius: 1.8rem;
  }
}
@media (max-width: 480px) {
  .cart__info {
    padding: 0;
    border-radius: 0;
    border: none;
  }
}
@media (max-width: 1200px) {
  .cart__info-title {
    font-size: 2rem;
  }
}
.cart__info-list {
  display: flex;
  flex-direction: column;
  row-gap: 0.5rem;
}
.cart__info-line {
  display: flex;
  justify-content: space-between;
}
.cart__info-alert {
  border-radius: 1.2rem;
  border: 0.1rem solid var(--gray20);
  display: flex;
  align-items: center;
  padding: 1.6rem 2.4rem;
  column-gap: 1.2rem;
  margin-top: -0.8rem;
  margin-bottom: -0.8rem;
}
@media (max-width: 1200px) {
  .cart__info-alert {
    padding: 1.1rem 2rem;
  }
}
@media (max-width: 744px) {
  .cart__info-alert {
    margin-top: 0;
    margin-bottom: 0;
  }
}
.cart__info-result {
  display: flex;
  justify-content: space-between;
}
@media (max-width: 480px) {
  .cart__info-result * {
    font-size: 1.6rem;
  }
}
@media (max-width: 744px) {
  .cart__info-payment {
    margin-top: -8.8rem;
    max-width: 48%;
    padding: 2rem 1.8rem !important;
    font-size: 1.4rem !important;
  }
  .cart__info-payment .block-product__payment-arrow {
    display: none;
  }
}
@media (max-width: 480px) {
  .cart__info-payment {
    max-width: 100%;
    position: relative;
    order: 20 !important;
    padding: 1rem 2rem !important;
    padding-right: 3.6rem !important;
    margin-top: unset !important;
  }
  .cart__info-payment .block-product__payment-arrow {
    display: block;
  }
}
.cart__info-text_gray {
  color: var(--gray40);
}
.cart__info-text_sage {
  color: var(--sageDark);
}
.cart__info-additional {
  display: flex;
  align-items: center;
  column-gap: 1.6rem;
  position: relative;
}
@media (max-width: 744px) {
  .cart__info-additional_second {
    margin-top: -1.6rem;
  }
}
@media (max-width: 480px) {
  .cart__info-additional_second {
    margin-top: unset;
  }
}
@media (max-width: 744px) {
  .cart__info-additional {
    margin-left: 54%;
    column-gap: 1.2rem;
  }
}
@media (max-width: 480px) {
  .cart__info-additional {
    margin-left: unset;
  }
}
.cart__info-icon {
  width: 3.6rem;
  height: 3.6rem;
  display: flex;
  justify-content: center;
  align-items: center;
  border: 0.1rem solid var(--gray20);
  border-radius: 0.8rem;
  flex-shrink: 0;
}
@media (max-width: 1200px) {
  .cart__info-icon {
    width: 2.4rem;
    height: 2.4rem;
    border-radius: 0.6rem;
  }
}
.cart__info-icon img {
  width: 2.4rem;
  height: 2.4rem;
}
@media (max-width: 1200px) {
  .cart__info-icon img {
    width: 1.8rem;
    height: 1.8rem;
  }
}
.cart__info-link {
  font-size: 1.4rem;
  max-width: 27.6rem;
  color: var(--black);
}
.cart__info-link a {
  font-weight: 500;
  color: var(--black);
}
.cart__info-link span {
  cursor: pointer;
  font-weight: 500;
}
@media (max-width: 480px) {
  .cart__info-link {
    font-size: 1rem;
    max-width: unset;
  }
}
.cart__info-close {
  position: absolute;
  right: 0;
  top: 50%;
  transform: translateY(-50%);
  cursor: pointer;
}
.cart__info-close img {
  width: 2.4rem;
  height: 2.4rem;
}
@media (max-width: 1200px) {
  .cart__info-close img {
    width: 2rem;
    height: 2rem;
  }
}
@media (max-width: 744px) {
  .cart__info-btn {
    order: 19;
  }
}
.cart__info-btn_pay {
  margin-top: -0.8rem;
}
.cart__info-note {
  color: var(--gray60);
  margin-top: -2.4rem;
}
@media (max-width: 1200px) {
  .cart__info-note {
    margin-top: -1.2rem;
  }
}
@media (max-width: 744px) {
  .cart__info-note {
    order: 20;
  }
}
.cart__info-note a {
  color: var(--gray60);
}
.cart__list {
  border-top: 0.1rem solid var(--gray20);
}
.cart__list-result {
  display: none;
}
@media (max-width: 480px) {
  .cart__list-result {
    display: block;
    margin-top: 2.4rem;
    font-size: 1.6rem;
  }
}
.cart__card {
  border-bottom: 0.1rem solid var(--gray20);
  padding: 4rem 0;
  display: flex;
  column-gap: 17rem;
}
@media (max-width: 1200px) {
  .cart__card {
    column-gap: 6.4rem;
    padding: 3.2rem 0;
  }
}
@media (max-width: 744px) {
  .cart__card {
    padding: 2rem 0;
    column-gap: 8.2rem;
  }
}
@media (max-width: 480px) {
  .cart__card {
    column-gap: 1rem;
    padding: 1.6rem 0;
    padding-left: 3.6rem;
    position: relative;
  }
}
.cart__card-title {
  width: 100%;
  text-align: left;
}
@media (max-width: 1200px) {
  .cart__card-title {
    max-width: 26rem;
    margin-right: auto;
    font-size: 1.8rem;
  }
}
@media (max-width: 744px) {
  .cart__card-title {
    font-size: 1.6rem;
    max-width: 24rem;
  }
}
@media (max-width: 480px) {
  .cart__card-title {
    max-width: 15.4rem;
    display: -webkit-box;
    overflow: hidden;
    -webkit-box-orient: vertical;
    -webkit-line-clamp: 2;
    padding-top: 2.4rem;
  }
}
.cart__card-image {
  width: 21.4rem;
  height: 29.6rem;
  overflow: hidden;
  border-radius: 1.6rem;
  flex-shrink: 0;
}
@media (max-width: 1200px) {
  .cart__card-image {
    width: 12.8rem;
    height: 17.7rem;
  }
}
@media (max-width: 480px) {
  .cart__card-image {
    width: 9.2rem;
    height: 12.4rem;
    margin-bottom: 3.3rem;
    border-radius: 0.8rem;
  }
}
.cart__card-image img {
  width: 100%;
  height: 100%;
}
.cart__card-content {
  width: 100%;
  display: flex;
  flex-direction: column;
}
.cart__card-top {
  display: flex;
  justify-content: flex-end;
  column-gap: 2.4rem;
  width: 100%;
  position: relative;
}
@media (max-width: 480px) {
  .cart__card-top {
    column-gap: 1.6rem;
  }
}
.cart__card-check {
  display: block;
  cursor: pointer;
  width: 2.4rem;
  height: 2.4rem;
  flex-shrink: 0;
  position: absolute;
  top: 0;
  left: -4.4rem;
}
.cart__card-check input {
  position: absolute;
  opacity: 0;
  pointer-events: none;
}
.cart__card-check::before {
  content: "";
  display: block;
  width: 2.4rem;
  height: 2.4rem;
  background: url(/img/svg/checkbox-empty.svg) no-repeat;
  background-size: cover;
  flex-shrink: 0;
  pointer-events: none;
}
@media (max-width: 480px) {
  .cart__card-check::before {
    width: 2rem;
    height: 2rem;
  }
}
.cart__card-check:has(input:checked)::before {
  background: url(/img/svg/checkbox-full.svg) no-repeat;
  background-size: cover;
}
@media (max-width: 480px) {
  .cart__card-check {
    width: 2rem;
    height: 2rem;
    left: -14rem;
  }
}
.cart__card-favourite {
  flex-shrink: 0;
  display: block;
  width: 2.4rem;
  height: 2.4rem;
  background: url(/img/svg/favourite.svg) no-repeat;
  background-size: cover;
  cursor: pointer;
}
.cart__card-favourite input {
  position: absolute;
  pointer-events: none;
  opacity: 0;
}
.cart__card-favourite:has(input:checked) {
  background: url(/img/svg/favourite-fill.svg) no-repeat;
  background-size: cover;
}
@media (max-width: 480px) {
  .cart__card-favourite {
    width: 2rem;
    height: 2rem;
  }
}
.cart__card-del {
  flex-shrink: 0;
  width: 2.4rem;
  height: 2.4rem;
  display: block;
}
.cart__card-del img {
  width: 100%;
  height: 100%;
  pointer-events: none;
}
@media (max-width: 480px) {
  .cart__card-del {
    width: 2rem;
    height: 2rem;
  }
}
.cart__card-info {
  display: flex;
  column-gap: 2rem;
  margin-top: auto;
  margin-bottom: 7.2rem;
}
@media (max-width: 1200px) {
  .cart__card-info {
    column-gap: 1.6rem;
    margin-bottom: 0;
  }
}
@media (max-width: 480px) {
  .cart__card-info {
    flex-wrap: wrap;
    column-gap: 0.9rem;
    margin-top: 2rem;
  }
}
.cart__card-block {
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  row-gap: 1.2rem;
  width: 21.4rem;
}
@media (max-width: 1200px) {
  .cart__card-block {
    width: 11.1rem;
    row-gap: 0.8rem;
  }
}
@media (max-width: 744px) {
  .cart__card-block {
    width: 13.4rem;
  }
}
@media (max-width: 480px) {
  .cart__card-block {
    width: 10.5rem;
    row-gap: 0.2rem;
  }
}
.cart__card-block_auto {
  width: auto;
  margin-left: auto;
}
@media (max-width: 480px) {
  .cart__card-block_auto {
    position: absolute;
    bottom: 1.6rem;
  }
}
@media (max-width: 480px) {
  .cart__card-block_quantity {
    position: absolute;
    bottom: 1.6rem;
    right: 0;
  }
}
.cart__card-number {
  color: var(--gray40);
  margin-bottom: 1.2rem;
}
@media (max-width: 480px) {
  .cart__card-number {
    position: absolute;
    top: 0;
    margin-top: 1.6rem;
  }
}
.cart__card-price {
  display: flex;
  column-gap: 1.6rem;
  white-space: nowrap;
}
@media (max-width: 1200px) {
  .cart__card-price {
    column-gap: 1.2rem;
  }
}
.cart__card-text_sage {
  color: var(--sageDark);
}
@media (max-width: 480px) {
  .cart__card-text_sage {
    text-align: right;
  }
}
.cart__card-text_old {
  color: var(--gray40);
  text-decoration: line-through;
}
@media (max-width: 480px) {
  .cart__card-text_old {
    display: none;
  }
}
.cart__form {
  display: flex;
  flex-direction: column;
  row-gap: 10rem;
}
@media (max-width: 1200px) {
  .cart__form {
    row-gap: 8rem;
  }
}
@media (max-width: 744px) {
  .cart__form {
    row-gap: 6.4rem;
  }
}
@media (max-width: 480px) {
  .cart__form {
    row-gap: 4.8rem;
  }
}
.cart__form-top {
  display: flex;
  justify-content: space-between;
  margin-bottom: 2.4rem;
}
@media (max-width: 1200px) {
  .cart__form-title {
    font-size: 2rem;
  }
}
@media (max-width: 744px) {
  .cart__form-title {
    font-size: 1.8rem;
  }
}
@media (max-width: 1200px) {
  .cart__form-title {
    font-size: 2.2rem;
  }
}
.cart__form-link {
  align-self: center;
  margin-right: unset;
  color: var(--gray50);
  border-color: var(--gray50);
}
@media (max-width: 480px) {
  .cart__form-link {
    margin-top: auto;
    font-size: 1.2rem;
  }
}
.cart__form-box_grid {
  display: flex;
  flex-wrap: wrap;
  gap: 0.8rem 2rem;
}
@media (max-width: 1200px) {
  .cart__form-box_grid {
    gap: 1.2rem;
  }
}
@media (max-width: 480px) {
  .cart__form-box_grid {
    flex-wrap: wrap;
    row-gap: 0.8rem;
  }
}
.cart__form-box_grid-delivery {
  display: flex;
  flex-wrap: wrap;
  gap: 2rem;
}
@media (max-width: 1200px) {
  .cart__form-box_grid-delivery {
    gap: 1.2rem;
  }
}
@media (max-width: 480px) {
  .cart__form-box_grid-delivery {
    flex-wrap: wrap;
    gap: 0.8rem;
  }
  .cart__form-box_grid-delivery .cart__form-field {
    width: calc(50% - 0.4rem);
  }
  .cart__form-box_grid-delivery .cart__form-field_wide {
    width: 100%;
  }
}
.cart__form-box_delivery {
  display: flex;
  flex-wrap: wrap;
  gap: 4rem 2rem;
}
@media (max-width: 1200px) {
  .cart__form-box_delivery {
    gap: 3.2rem 1.2rem;
  }
}
@media (max-width: 480px) {
  .cart__form-box_delivery {
    row-gap: 2.4rem;
  }
}
.cart__form-box_pay {
  display: flex;
  flex-wrap: wrap;
  row-gap: 2.4rem;
}
@media (max-width: 744px) {
  .cart__form-box_pay {
    row-gap: 1.6rem;
  }
}
.cart__form-subtitle {
  width: 100%;
}
.cart__form-select {
  margin-bottom: 4rem;
}
@media (max-width: 1200px) {
  .cart__form-select {
    margin-bottom: 3.2rem;
  }
}
@media (max-width: 744px) {
  .cart__form-select {
    margin-bottom: 3.2rem;
  }
}
@media (max-width: 480px) {
  .cart__form-select {
    margin-bottom: 2.4rem;
  }
}
.cart__form-field {
  display: flex;
  flex-direction: column;
  row-gap: 1.6rem;
  width: calc(50% - 1rem);
}
@media (max-width: 1200px) {
  .cart__form-field {
    row-gap: 0.8rem;
    width: calc(50% - 0.6rem);
  }
}
@media (max-width: 480px) {
  .cart__form-field {
    width: 100%;
  }
}
.cart__form-field textarea {
  height: 9rem;
  min-height: 5.6rem;
}
@media (max-width: 1200px) {
  .cart__form-field textarea {
    font-size: 1.2rem;
    height: 6.5rem;
    min-height: 4rem;
  }
}
@media (max-width: 744px) {
  .cart__form-field textarea {
    min-height: 4.4rem;
  }
}
.cart__form-field .input__error {
  margin-top: -1.2rem;
}
@media (max-width: 1200px) {
  .cart__form-field .input__error {
    margin-top: -0.4rem;
  }
}
.cart__form-field_wide {
  width: 100%;
  margin-top: 0.8rem;
}
.cart__form-field.select_city::after {
  content: "";
  display: none;
}
.cart__form-field.select_city .select__list {
  position: absolute;
  margin-top: 6.6rem;
}
@media (max-width: 1200px) {
  .cart__form-field.select_city .select__list {
    margin-top: 5rem;
  }
}
.cart__form-field.select_city .select__value {
  height: 5.8rem;
  line-height: 5.6rem;
  overflow: hidden;
}
@media (max-width: 1200px) {
  .cart__form-field.select_city .select__value {
    line-height: 4.2rem;
  }
}
.cart__form-info {
  flex-direction: row;
  column-gap: 1.2rem;
  max-width: 38.8rem;
  margin-top: auto;
  margin-bottom: auto;
}
.cart__form-info img {
  width: 2.4rem;
  height: 2.4rem;
}
@media (max-width: 1200px) {
  .cart__form-info img {
    width: 1.6rem;
    height: 1.6rem;
  }
}
@media (max-width: 744px) {
  .cart__form-info {
    max-width: 29.5rem;
  }
  .cart__form-info img {
    width: 1.6rem;
    height: 1.6rem;
  }
}
@media (max-width: 480px) {
  .cart__form-info {
    max-width: 26.7rem;
  }
  .cart__form-info img {
    width: 1.4rem;
    height: 1.4rem;
  }
}
.cart__form-about {
  max-width: 27rem;
  display: flex;
  column-gap: 1.2rem;
  margin-left: 2.6rem;
}
.cart__form-about img {
  width: 2.4rem;
  height: 2.4rem;
  flex-shrink: 0;
}
@media (max-width: 1200px) {
  .cart__form-about {
    max-width: 25rem;
    column-gap: 0.8rem;
  }
  .cart__form-about img {
    width: 1.6rem;
    height: 1.6rem;
  }
}
@media (max-width: 480px) {
  .cart__form-about {
    max-width: unset;
    margin-left: 0;
    align-items: center;
  }
}
.cart__form-text {
  font-size: 1.6rem;
}
.cart__form-text_gray {
  color: var(--gray80);
}
.cart__form-text a {
  font-weight: 500;
  color: var(--black);
}
@media (max-width: 1200px) {
  .cart__form-text {
    font-size: 1.4rem;
  }
}
@media (max-width: 480px) {
  .cart__form-text {
    font-size: 1.2rem;
  }
}
@media (max-width: 744px) {
  .cart__form-text {
    font-size: 1.2rem;
  }
}
.cart__form-additional {
  display: flex;
  align-items: center;
  column-gap: 1.6rem;
  width: 100%;
}
.cart__form-additional_label {
  margin-top: -1.6rem;
}
.cart__form-additional_label input {
  position: absolute;
  opacity: 0;
  pointer-events: none;
}
.cart__form-additional_label::before {
  content: "";
  display: block;
  width: 2.4rem;
  height: 2.4rem;
  background: url(/img/svg/checkbox-empty.svg) no-repeat;
  background-size: cover;
  flex-shrink: 0;
  pointer-events: none;
  /* @include mobile{
      width: rem(20);
      height: rem(20);
  } */
}
.cart__form-additional_label:has(input:checked)::before {
  background: url(/img/svg/checkbox-full.svg) no-repeat;
  background-size: cover;
}
@media (max-width: 744px) {
  .cart__form-additional_label {
    margin-top: -0.8rem;
  }
}
.cart__form-icon {
  width: 2.4rem;
  height: 2.4rem;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-shrink: 0;
}
.cart__form-icon img {
  width: 2.4rem;
  height: 2.4rem;
}

.sizes__box {
  margin-top: 8rem;
  display: flex;
  flex-wrap: wrap;
  gap: 7.2rem 2rem;
  width: 100%;
}
@media (max-width: 1200px) {
  .sizes__box {
    gap: 4.8rem 1.6rem;
    margin-top: 4.8rem;
  }
}
@media (max-width: 744px) {
  .sizes__box {
    gap: 4.8rem 3rem;
  }
}
@media (max-width: 480px) {
  .sizes__box {
    margin-top: 3.2rem;
    gap: 3.2rem 0rem;
  }
}
.sizes__part {
  width: 100%;
  max-width: 60.4rem;
  display: flex;
  flex-direction: column;
}
@media (max-width: 1200px) {
  .sizes__part {
    max-width: 36.8rem;
  }
}
@media (max-width: 744px) {
  .sizes__part {
    max-width: 32.5rem;
  }
}
@media (max-width: 480px) {
  .sizes__part {
    max-width: 100%;
  }
}
.sizes__part_wide {
  max-width: 100%;
}
.sizes__part_end {
  margin-top: auto;
}
.sizes__part_title {
  row-gap: 3.2rem;
}
@media (max-width: 1200px) {
  .sizes__part_title {
    row-gap: 2.4rem;
  }
}
.sizes__part_bottom {
  row-gap: 1.6rem;
}
@media (max-width: 1200px) {
  .sizes__part_bottom {
    max-width: 50.6rem;
    margin-right: 6.4rem;
  }
}
@media (max-width: 744px) {
  .sizes__part_bottom {
    margin-right: 0;
  }
}
.sizes__part_2fr {
  max-width: 122.8rem;
}
@media (max-width: 1200px) {
  .sizes__part_2fr {
    max-width: 73.6rem;
  }
}
@media (max-width: 744px) {
  .sizes__part_2fr {
    max-width: 35.5rem;
  }
}
.sizes__part_images {
  display: flex;
  column-gap: 2rem;
  flex-direction: row;
}
@media (max-width: 1200px) {
  .sizes__part_images {
    column-gap: 1.6rem;
  }
}
@media (max-width: 744px) {
  .sizes__part_images {
    column-gap: 1rem;
  }
}
.sizes__part_form {
  row-gap: 6.8rem;
}
@media (max-width: 1200px) {
  .sizes__part_form {
    row-gap: 3.2rem;
  }
}
@media (max-width: 744px) {
  .sizes__part_result {
    max-width: 50.4rem;
    margin-top: -1.6rem;
  }
  .sizes__part_result .sizes__block {
    max-width: 100%;
  }
}
@media (max-width: 480px) {
  .sizes__part_result {
    margin-top: -0.8rem;
    max-width: 35.5rem;
  }
}
@media (max-width: 480px) {
  .sizes__part_note {
    margin-top: -2.4rem;
  }
}
.sizes__step {
  display: flex;
  flex-direction: column;
  row-gap: 2.4rem;
  max-width: 39rem;
}
@media (max-width: 1200px) {
  .sizes__step {
    row-gap: 1.6rem;
    max-width: 33.2rem;
  }
}
@media (max-width: 744px) {
  .sizes__step {
    max-width: 28rem;
  }
}
@media (max-width: 480px) {
  .sizes__step {
    max-width: unset;
  }
}
.sizes__title {
  text-align: left;
}
@media (max-width: 1200px) {
  .sizes__title {
    font-size: 2.8rem;
  }
}
@media (max-width: 744px) {
  .sizes__title {
    font-size: 2.6rem;
  }
}
@media (max-width: 480px) {
  .sizes__title {
    font-size: 2rem;
  }
}
.sizes__title_step {
  color: var(--gray80);
}
@media (max-width: 1200px) {
  .sizes__title_step {
    font-size: 2rem;
  }
}
@media (max-width: 744px) {
  .sizes__title_step {
    font-size: 1.8rem;
  }
}
@media (max-width: 480px) {
  .sizes__title_step {
    font-size: 1.6rem;
  }
}
.sizes__text {
  color: var(--gray80);
  max-width: 41rem;
}
.sizes__text_note {
  color: var(--gray60);
}
@media (max-width: 744px) {
  .sizes__text_note {
    width: 120%;
  }
}
@media (max-width: 480px) {
  .sizes__text_note {
    width: 100%;
    max-width: 30rem;
  }
}
.sizes__block {
  display: flex;
  flex-direction: column;
  row-gap: 0.8rem;
  max-width: 37rem;
}
@media (max-width: 1200px) {
  .sizes__block {
    max-width: 33.5rem;
  }
}
@media (max-width: 480px) {
  .sizes__block {
    max-width: unset;
  }
}
.sizes__line {
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.sizes__image {
  border-radius: 2.4rem;
  overflow: hidden;
  position: relative;
  width: 60.4rem;
  height: 68rem;
}
@media (max-width: 1200px) {
  .sizes__image {
    border-radius: 1.4rem;
    width: 36.8rem;
    height: 41.5rem;
  }
}
@media (max-width: 744px) {
  .sizes__image {
    border-radius: 0.8rem;
    width: 17rem;
    height: 19.2rem;
  }
}
@media (max-width: 480px) {
  .sizes__image {
    border-radius: 1.2rem;
    width: 17.4rem;
  }
}
.sizes__image img {
  width: 100%;
  display: block;
}
.sizes__number {
  font-size: 2.8rem;
  position: absolute;
  border: 1.2rem;
  background: var(--white);
  width: 4.8rem;
  height: 4.8rem;
  border-radius: 1.2rem;
  top: 3.2rem;
  left: 3.2rem;
  display: flex;
  justify-content: center;
  align-items: center;
  line-height: 1;
}
@media (max-width: 744px) {
  .sizes__number {
    width: 3rem;
    height: 3rem;
    border-radius: 0.8rem;
    font-size: 1.8rem;
    top: 0.8rem;
    left: 0.8rem;
  }
}
.sizes__table {
  margin-top: 2.4rem;
  display: flex;
  flex-wrap: wrap;
  gap: 0.8rem;
  max-width: 50.4rem;
}
@media (max-width: 1200px) {
  .sizes__table {
    margin-top: 0.8rem;
  }
}
@media (max-width: 480px) {
  .sizes__table {
    width: 100%;
    max-width: unset;
  }
}
.sizes__table-label {
  cursor: pointer;
  text-align: center;
  width: 5.6rem;
  height: 3.6rem;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 1rem;
  user-select: none;
  border: 0.1rem solid var(--gray00);
  color: var(--gray60);
  font-weight: 400;
  font-size: 1.2rem;
}
@media (max-width: 480px) {
  .sizes__table-label {
    width: 8.3rem;
    height: 3.8rem;
  }
}
.sizes__table-label input {
  position: absolute;
  opacity: 0;
  pointer-events: none;
}
.sizes__table-label:has(input:checked) {
  background: var(--gray10);
  border: 0.1rem solid transparent;
}

.error__inner {
  margin: 0 0 10rem 0;
}
@media (max-width: 744px) {
  .error__inner {
    margin: 0 0 8rem 0;
  }
}
@media (max-width: 480px) {
  .error__inner {
    margin: 0 0 5.6rem 0;
  }
}
.error__box {
  width: 100%;
  height: 100svh;
  max-height: 80rem;
  position: relative;
  z-index: 1;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  row-gap: 3.2rem;
}
@media (max-width: 1200px) {
  .error__box {
    max-height: 60rem;
    height: 100svh;
    row-gap: 2.4rem;
  }
}
@media (max-width: 744px) {
  .error__box {
    max-height: 80rem;
    height: 100svh;
    row-gap: 1.6rem;
  }
}
@media (max-width: 480px) {
  .error__box {
    max-height: 50rem;
    height: 100svh;
  }
}
.error__cover {
  position: absolute;
  width: 100%;
  height: 100%;
  z-index: -1;
}
.error__cover img {
  width: 100%;
  height: 100%;
}
.error__cover::after {
  content: "";
  background: linear-gradient(180deg, rgba(0, 0, 0, 0) 0%, rgba(0, 0, 0, 0.3) 100%);
  position: absolute;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  z-index: 1;
}
.error__title {
  text-align: center;
}
.error__text {
  text-align: center;
  margin-bottom: 0.8rem;
}
@media (max-width: 480px) {
  .error__text {
    margin-bottom: 0;
  }
}

.footer {
  padding-bottom: 0.1rem;
}
@media (max-width: 744px) {
  .footer {
    display: flex;
    flex-direction: column;
  }
}
.footer__section {
  border-top: 0.1rem solid var(--black);
  /* &_rights-mobile{
      display: none;
      @include mobile{
          display: block;
      }
  } */
}
@media (max-width: 744px) {
  .footer__section:has(.footer__inner_short) {
    order: 10;
  }
}
@media (max-width: 744px) and (max-width: 480px) {
  .footer__section:has(.footer__inner_short) {
    order: unset;
  }
}
@media (max-width: 480px) {
  .footer__section_auth {
    display: none;
  }
}
.footer__section_bottom {
  display: flex;
  flex-direction: column;
  position: relative;
}
@media (max-width: 744px) {
  .footer__section_bottom {
    flex-direction: column-reverse;
  }
}
@media (max-width: 480px) {
  .footer__section_bottom {
    border-top: none;
  }
  .footer__section_bottom .container {
    padding: 0;
  }
}
.footer__inner {
  margin: 8rem 0;
}
@media (max-width: 1200px) {
  .footer__inner {
    margin: 6rem 0;
  }
}
@media (max-width: 744px) {
  .footer__inner {
    margin: 4rem 0;
  }
}
@media (max-width: 480px) {
  .footer__inner {
    margin: 2rem 0;
  }
}
.footer__inner_tall {
  margin-bottom: 20rem;
}
@media (max-width: 1200px) {
  .footer__inner_tall {
    margin-top: 8rem;
    margin-bottom: 18rem;
  }
}
@media (max-width: 744px) {
  .footer__inner_tall {
    margin-top: 4rem;
    margin-bottom: 4rem;
    min-height: 36rem;
  }
}
@media (max-width: 480px) {
  .footer__inner_tall {
    margin-bottom: 0.8rem;
    min-height: unset;
  }
}
.footer__inner_short {
  margin: 2rem 0;
}
@media (max-width: 744px) {
  .footer__inner_short {
    margin: 1.6rem 0;
  }
}
@media (max-width: 480px) {
  .footer__inner_short {
    margin: 2.4rem 0;
  }
}
.footer__inner_auth {
  margin: 0;
  padding: 1.1rem 0 0.9rem 0;
  border-top: 0.1rem solid var(--black);
}
@media (max-width: 744px) {
  .footer__inner_auth {
    border-top: none;
    border-bottom: 0.1rem solid var(--black);
  }
}
@media (max-width: 480px) {
  .footer__inner_auth {
    display: none;
  }
}
.footer__inner_rights {
  margin: 2.8rem 0;
}
.footer__inner_rights .footer__box {
  display: flex;
  justify-content: center;
}
.footer__box {
  display: flex;
  column-gap: 21.6rem;
  width: 100%;
}
@media (max-width: 1200px) {
  .footer__box {
    column-gap: 11.2rem;
  }
}
@media (max-width: 744px) {
  .footer__box {
    column-gap: 13rem;
  }
}
@media (max-width: 480px) {
  .footer__box {
    flex-wrap: wrap;
  }
}
.footer__box_bottom {
  align-items: center;
  justify-content: space-between;
  column-gap: 0;
}
.footer__box_info {
  column-gap: 48.5rem;
}
@media (max-width: 1200px) {
  .footer__box_info {
    column-gap: 12.5rem;
  }
}
@media (max-width: 744px) {
  .footer__box_info {
    column-gap: 6rem;
  }
}
.footer__phone {
  color: var(--sageDark);
}
@media (max-width: 1200px) {
  .footer__phone {
    font-size: 2rem;
  }
}
@media (max-width: 744px) {
  .footer__phone {
    font-size: 1.8rem;
  }
}
@media (max-width: 480px) {
  .footer__phone {
    font-size: 1.6rem;
  }
}
.footer__time {
  margin-top: 0.8rem;
  color: var(--gray40);
}
@media (max-width: 480px) {
  .footer__time {
    font-size: 1.2rem;
  }
}
.footer__contacts {
  margin-top: 3.2rem;
  display: flex;
  column-gap: 1.2rem;
  flex-shrink: 0;
}
@media (max-width: 1200px) {
  .footer__contacts {
    margin-top: 2.2rem;
  }
}
@media (max-width: 744px) {
  .footer__contacts {
    margin-top: 2rem;
  }
}
@media (max-width: 480px) {
  .footer__contacts {
    margin-top: 3.2rem;
  }
}
.footer__contacts-link {
  text-decoration: none;
  text-transform: uppercase;
  font-weight: 400;
}
@media (max-width: 1200px) {
  .footer__contacts-link {
    font-size: 1.2rem;
  }
}
@media (max-width: 744px) {
  .footer__contacts-link {
    font-size: 1.2rem;
  }
}
@media (max-width: 480px) {
  .footer__contacts-link {
    font-size: 1.2rem;
  }
}
.footer__subscribe {
  margin-top: 11rem;
}
@media (max-width: 1200px) {
  .footer__subscribe {
    margin-top: 4rem;
  }
}
@media (max-width: 480px) {
  .footer__subscribe {
    margin-bottom: 4rem;
    margin-top: 3.2rem;
  }
}
@media (max-width: 480px) {
  .footer__subscribe-title {
    font-size: 1.2rem;
  }
}
.footer__subscribe-form {
  margin-top: 2rem;
  min-width: 44.8rem;
  position: relative;
}
@media (max-width: 1200px) {
  .footer__subscribe-form {
    margin-top: 1.6rem;
    min-width: 27.2rem;
  }
}
@media (max-width: 744px) {
  .footer__subscribe-form {
    min-width: 23rem;
    margin-top: 0.4rem;
  }
}
.footer__subscribe-input {
  padding: 1.8rem 0;
  background: transparent;
  border: none;
  border-bottom: 0.1rem solid var(--gray60);
  outline: none;
  width: 100%;
}
@media (max-width: 744px) {
  .footer__subscribe-input {
    padding: 1.7rem 0;
  }
}
.footer__subscribe-btn {
  width: 2.4rem;
  height: 2.4rem;
  opacity: 0.6;
  position: absolute;
  right: 0;
  top: 50%;
  transform: translateY(-50%);
}
.footer__subscribe-btn img {
  width: 100%;
  height: 100%;
}
.footer__nav-mobile {
  display: none;
  width: 100%;
}
@media (max-width: 744px) {
  .footer__nav-mobile {
    display: flex;
    flex-direction: column;
    row-gap: 1.6rem;
  }
}
.footer__nav-mobile ul {
  margin: 0;
  padding: 0;
  display: flex;
  flex-direction: column;
  row-gap: 2rem;
}
.footer__nav-mobile li {
  list-style: none;
}
.footer__nav {
  display: flex;
  column-gap: 12rem;
  width: 100%;
}
@media (max-width: 1200px) {
  .footer__nav {
    column-gap: 5rem;
  }
}
@media (max-width: 744px) {
  .footer__nav {
    display: none;
  }
}
.footer__nav ul {
  margin: 0;
  padding: 0;
  display: flex;
  flex-direction: column;
  row-gap: 2.4rem;
  max-width: 30rem;
}
@media (max-width: 1200px) {
  .footer__nav ul {
    max-width: 15.5rem;
    row-gap: 1.6rem;
  }
  .footer__nav ul:nth-of-type(1) {
    margin-right: 1rem;
  }
}
.footer__nav li {
  list-style: none;
}
.footer__nav-link {
  text-transform: uppercase;
  text-decoration: none;
  display: block;
  max-width: 20rem;
  white-space: nowrap;
}
@media (max-width: 1200px) {
  .footer__nav-link {
    max-width: unset;
    white-space: unset;
  }
}
.footer__nav-link_title {
  color: var(--gray40);
  margin-bottom: 0.8rem;
}
.footer__rights {
  color: var(--gray40);
  /* &_mobile{
      display: block;
  } */
}
@media (max-width: 744px) {
  .footer__rights {
    max-width: 12.5rem;
  }
}
@media (max-width: 480px) {
  .footer__rights {
    max-width: 100%;
    order: 3;
    text-align: center;
    width: 100%;
    padding-top: 2.4rem;
    border-top: 0.1rem solid var(--black);
  }
}
.footer__social {
  display: flex;
  column-gap: 2.4rem;
  /* &_mobile{
      display: none;
      @include mobile{
          display: flex;
          justify-content: center;
          width: 100%;
          margin: rem(40) 0 rem(24) 0;
      }
  } */
}
@media (max-width: 480px) {
  .footer__social {
    order: -1;
    margin: 1.6rem 0 0 0;
    padding-bottom: 3.2rem;
    width: 100%;
    justify-content: center;
    border-bottom: 0.1rem solid var(--black);
  }
}
.footer__social-icon {
  width: 2.4rem;
  height: 2.4rem;
  display: block;
}
.footer__social-icon img {
  width: 100%;
  height: 100%;
}
.footer__partners {
  display: flex;
  column-gap: 2.4rem;
  position: absolute;
  left: 50%;
  transform: translateX(-50%);
}
@media (max-width: 744px) {
  .footer__partners {
    column-gap: 1.2rem;
  }
}
@media (max-width: 480px) {
  .footer__partners {
    column-gap: 2.4rem;
    width: 100%;
    justify-content: center;
    position: relative;
    margin: 2.4rem 0;
  }
}
.footer__partners-icon {
  position: relative;
  height: 1.1rem;
  display: flex;
  justify-content: center;
  align-items: center;
}
.footer__partners-icon img {
  width: auto;
  height: 100%;
  display: block;
  object-fit: contain;
}
@media (max-width: 744px) {
  .footer__partners-icon {
    height: 0.8rem;
  }
}
@media (max-width: 480px) {
  .footer__partners-icon {
    height: 1.1rem;
  }
}
.footer__auth {
  text-transform: uppercase;
  text-align: center;
  width: 100%;
}
.footer__auth a {
  line-height: 1;
}
.footer__block {
  width: 45rem;
  display: flex;
  flex-direction: column;
  row-gap: 0.8rem;
}
@media (max-width: 744px) {
  .footer__block {
    width: 30rem;
  }
}
@media (max-width: 480px) {
  .footer__block {
    width: 100%;
    row-gap: 0.4rem;
    position: relative;
  }
  .footer__block:nth-of-type(2) {
    margin-top: 4rem;
  }
  .footer__block:nth-of-type(2)::before {
    content: "";
    width: calc(100% + 2rem);
    top: -1.9rem;
    left: -1rem;
    position: absolute;
    border-top: 0.1rem solid var(--black);
  }
}
.footer__block .t-primary-m {
  color: var(--gray80);
}
.footer__block .t-navigation {
  text-transform: uppercase;
  margin-top: 0.8rem;
}
@media (max-width: 1200px) {
  .footer__block-title {
    font-size: 2rem;
  }
}
@media (max-width: 744px) {
  .footer__block-title {
    font-size: 1.8rem;
  }
}
@media (max-width: 480px) {
  .footer__block-title {
    font-size: 1.6rem;
  }
}
@media (max-width: 1200px) {
  .footer__block-link {
    font-size: 1.2rem;
  }
}
@media (max-width: 744px) {
  .footer__block-link {
    font-size: 1.2rem;
  }
}
@media (max-width: 480px) {
  .footer__block-link {
    font-size: 1.2rem;
  }
}
.footer__block-link_mobile {
  display: none;
}
@media (max-width: 480px) {
  .footer__block-link_mobile {
    display: block;
    position: absolute;
    right: 0;
    bottom: calc(50% - 0.5rem);
    transform: translateY(50%);
  }
}

.modal {
  position: fixed;
  width: 100%;
  height: 100%;
  left: 0;
  top: 0;
  background: var(--gray50);
  display: flex;
  z-index: 60;
  pointer-events: none;
  opacity: 0;
  transition-duration: 0.3s;
  transition-delay: 0.25s;
}
.modal * {
  pointer-events: none;
}
.modal_open {
  z-index: 60;
  pointer-events: all;
  opacity: 1;
}
.modal_open * {
  pointer-events: all;
}
.modal_open .modal__inner {
  right: 0;
}
.modal_open[data-modal=cookies] {
  z-index: 70;
}
@media (max-width: 1200px) {
  .modal_open[data-modal=askCity] {
    display: none;
  }
}
.modal__inner {
  transition-duration: 0.3s;
  background: var(--grayBg);
  position: absolute;
  width: 100%;
  height: 100%;
  top: 0;
  right: -100%;
  max-width: 65.8rem;
  overflow: hidden;
  /* @include mobile{
      padding: rem(40) rem(9);
  } */
}
@media (max-width: 1200px) {
  .modal__inner {
    max-width: 42rem;
  }
}
@media (max-width: 744px) {
  .modal__inner {
    max-width: 42rem;
  }
}
@media (max-width: 480px) {
  .modal__inner {
    max-width: 42rem;
  }
}
.modal__inner_hello {
  padding: 2.4rem;
  display: flex;
  align-items: center;
  justify-content: space-between;
  height: auto;
  text-transform: uppercase;
  top: 2rem;
  margin-right: 2rem;
  font-weight: 500;
  max-width: 60.4rem;
  border-radius: 0.6rem;
}
@media (max-width: 1200px) {
  .modal__inner_hello {
    top: 1.5rem;
    max-width: 38.4rem;
  }
}
@media (max-width: 744px) {
  .modal__inner_hello {
    padding: 2rem 1.6rem;
    top: 1rem;
    margin-right: 1rem;
  }
}
@media (max-width: 480px) {
  .modal__inner_hello {
    top: 2rem;
    width: calc(100% - 2rem);
    margin-right: 1rem;
  }
}
.modal__inner_hello .modal__close {
  top: 1.2rem;
  border: none;
}
@media (max-width: 1200px) {
  .modal__inner_hello .modal__close {
    top: 1rem;
  }
}
@media (max-width: 744px) {
  .modal__inner_hello .modal__close {
    right: 0.2rem;
    top: 0.6rem;
  }
  .modal__inner_hello .modal__close img {
    width: 2rem;
    height: 2rem;
  }
}
@media (max-width: 480px) {
  .modal__inner_hello .modal__close {
    top: 0.4rem;
  }
}
.modal__inner_search {
  max-width: unset;
  width: 100%;
}
.modal__inner_search .modal__close {
  right: 50%;
  transform: translateX(50%);
}
.modal__inner_more {
  max-width: unset;
  width: 100%;
}
.modal__inner_more .modal__title {
  max-width: 54.6rem;
  margin-right: auto;
}
@media (max-width: 1200px) {
  .modal__inner_more .modal__title {
    max-width: 42.2rem;
  }
}
@media (max-width: 744px) {
  .modal__inner_more .modal__title {
    max-width: 37.2rem;
  }
}
@media (max-width: 480px) {
  .modal__inner_more .modal__title {
    max-width: 30.2rem;
  }
}
.modal__inner_more .modal__box {
  padding: 12.8rem 3.4rem;
}
@media (max-width: 1200px) {
  .modal__inner_more .modal__box {
    padding: 10.2rem 3.2rem;
  }
}
@media (max-width: 744px) {
  .modal__inner_more .modal__box {
    padding: 8rem 1.7rem 4.8rem 1.7rem;
    row-gap: 1.6rem;
  }
}
@media (max-width: 480px) {
  .modal__inner_more .modal__box {
    padding: 8rem 0.9rem;
    row-gap: 1.2rem;
  }
}
.modal__inner_ask-city {
  border-radius: 0.6rem;
  padding: 2rem 1.6rem;
  max-width: 38rem;
  height: auto;
  row-gap: 1.6rem;
  display: flex;
  align-items: center;
  overflow: visible;
  top: 12rem;
  left: 17rem;
}
@media (max-width: 1200px) {
  .modal__inner_ask-city {
    left: 4rem;
    top: 10rem;
  }
}
@media (max-width: 744px) {
  .modal__inner_ask-city {
    max-width: 100%;
    left: 0;
    top: 12.4rem;
    padding: 1.6rem;
    flex-wrap: wrap;
    justify-content: center;
    row-gap: 1rem;
  }
}
@media (max-width: 480px) {
  .modal__inner_ask-city {
    top: 13.4rem;
    padding: 1.7rem 1.6rem;
  }
}
.modal__inner_ask-city::before {
  content: "";
  background: #000;
  position: absolute;
  width: 2rem;
  height: 2rem;
  top: 0;
  left: 50%;
  transform: translateY(-50%) translateX(-50%) rotate(45deg);
  z-index: -1;
  background: var(--grayBg);
}
@media (max-width: 744px) {
  .modal__inner_ask-city::before {
    display: none;
  }
}
.modal__inner_cookies {
  border-radius: 1.2rem;
  padding: 3.2rem;
  max-width: 44rem;
  height: auto;
  row-gap: 1.6rem;
  display: flex;
  flex-direction: column;
  align-items: center;
  top: unset;
  bottom: 3.2rem;
  margin-right: 3.2rem;
}
@media (max-width: 1200px) {
  .modal__inner_cookies {
    max-width: 42rem;
  }
  .modal__inner_cookies .modal__text {
    max-width: 32rem;
  }
}
@media (max-width: 744px) {
  .modal__inner_cookies {
    margin-right: unset;
    right: unset !important;
    left: calc(50% - 21rem);
  }
  .modal__inner_cookies .modal__text {
    max-width: 27rem;
  }
}
@media (max-width: 480px) {
  .modal__inner_cookies {
    padding: 2.4rem;
    max-width: 35.8rem;
    bottom: 0.8rem;
    left: calc(50% - 17.9rem);
  }
}
.modal__inner_banner {
  width: 100%;
  height: auto;
  max-width: 91.6rem;
  min-height: 48rem;
  right: unset;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
  padding: 9.5rem 15.6rem;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  row-gap: 3.2rem;
  border-radius: 1.2rem;
  overflow: hidden;
  background: transparent;
}
@media (max-width: 1200px) {
  .modal__inner_banner {
    padding: 11.5rem 12rem;
    max-width: 84.8rem;
  }
}
@media (max-width: 744px) {
  .modal__inner_banner {
    max-width: 59rem;
    padding: 9rem 6rem;
    min-height: 41rem;
  }
}
@media (max-width: 480px) {
  .modal__inner_banner {
    max-width: calc(100% - 1.8rem);
    min-height: 39.6rem;
    padding: 4rem 0.9rem;
    display: flex;
    flex-direction: column;
    justify-content: center;
    row-gap: 2.4rem;
  }
}
.modal__inner_city {
  width: 100%;
  height: auto;
  max-width: 91.6rem;
  min-height: 48rem;
  right: unset;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  row-gap: 2.4rem;
  border-radius: 1.2rem;
  overflow: visible;
}
.modal__inner_city .modal__title {
  transition: all 0.3s;
}
.modal__inner_city:has(.select_open) .modal__title {
  transform: translateY(-14rem);
}
@media (max-width: 744px) {
  .modal__inner_city:has(.select_open) .modal__title {
    transform: translateY(-12rem);
  }
}
.modal__inner_city:has(.select_open) .select {
  transform: translateY(-14rem);
}
@media (max-width: 744px) {
  .modal__inner_city:has(.select_open) .select {
    transform: translateY(-12rem);
  }
}
.modal__inner_city .select {
  transition: all 0.3s;
  margin-top: 0.8rem;
  max-width: 51.1rem;
}
@media (max-width: 1200px) {
  .modal__inner_city .select {
    max-width: 44rem;
  }
}
.modal__inner_city .select__list {
  position: absolute;
}
@media (max-width: 1200px) {
  .modal__inner_city {
    max-width: 84.8rem;
    row-gap: 1.6rem;
  }
}
@media (max-width: 744px) {
  .modal__inner_city {
    max-width: 59rem;
    min-height: 41rem;
  }
  .modal__inner_city .select__list {
    max-height: 23rem;
  }
}
@media (max-width: 480px) {
  .modal__inner_city {
    width: 100%;
    height: 100%;
    border-radius: 0;
    padding: 4rem 0.9rem;
    display: flex;
    flex-direction: column;
    justify-content: center;
  }
  .modal__inner_city .select {
    margin-top: unset;
  }
  .modal__inner_city .select__list {
    max-height: 21.7rem;
  }
}
.modal__inner_thanks {
  width: 100%;
  height: auto;
  max-width: 91.6rem;
  min-height: 48rem;
  right: unset;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
  padding: 9.5rem 15.6rem;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  row-gap: 2.4rem;
  border-radius: 1.2rem;
  overflow: hidden;
}
.modal__inner_thanks .btn {
  margin-top: 0.8rem;
}
@media (max-width: 1200px) {
  .modal__inner_thanks {
    padding: 11.5rem 12rem;
    max-width: 84.8rem;
    row-gap: 1.6rem;
  }
}
@media (max-width: 744px) {
  .modal__inner_thanks {
    max-width: 59rem;
    padding: 9rem 6rem;
    min-height: 41rem;
  }
}
@media (max-width: 480px) {
  .modal__inner_thanks {
    width: 100%;
    height: 100%;
    border-radius: 0;
    padding: 4rem 0.9rem;
    display: flex;
    flex-direction: column;
    justify-content: center;
  }
  .modal__inner_thanks .btn {
    margin-top: unset;
  }
}
.modal__inner_center {
  width: 100%;
  height: auto;
  max-width: 91.6rem;
  right: unset;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
  padding: 9.5rem 15.6rem;
  border-radius: 1.2rem;
}
@media (max-width: 1200px) {
  .modal__inner_center {
    padding: 11.5rem 12rem;
    max-width: 84.8rem;
  }
}
@media (max-width: 744px) {
  .modal__inner_center {
    max-width: 59rem;
    padding: 9rem 6rem;
  }
}
@media (max-width: 480px) {
  .modal__inner_center {
    height: 100%;
    padding: 4rem 0.9rem;
    display: flex;
    flex-direction: column;
    justify-content: center;
    border-radius: 0;
  }
}
.modal__inner_filters {
  max-width: 42rem;
  padding: 4.9rem 5rem;
  display: flex;
  flex-direction: column;
  overflow-y: auto;
}
@media (max-width: 480px) {
  .modal__inner_filters {
    padding: 4rem 0.9rem;
  }
}
.modal__inner_filters .modal__title {
  font-size: 2.2rem;
  color: var(--black);
  text-align: left;
}
@media (max-width: 480px) {
  .modal__inner_filters .modal__title {
    font-size: 2rem;
  }
}
.modal__inner_filters .modal__buttons {
  margin-top: auto;
  column-gap: 0.8rem;
}
@media (max-width: 480px) {
  .modal__inner_filters .modal__buttons {
    max-width: unset;
  }
}
.modal__inner_filters .btn {
  padding-left: 0;
  padding-right: 0;
}
@media (max-width: 744px) {
  .modal__inner_filters .select__value {
    max-height: 3.5rem;
  }
}
@media (max-width: 480px) {
  .modal__inner_filters .select__value {
    max-height: 3.4rem;
  }
}
.modal__inner_sizes {
  position: absolute;
  bottom: 0;
  top: unset;
  max-width: unset;
  height: auto;
  padding: 3.2rem 1.7rem;
  border-radius: 1.2rem 1.2rem 0 0;
}
@media (max-width: 480px) {
  .modal__inner_sizes {
    padding: 1.6rem 0.9rem;
    padding-bottom: 0.8rem;
  }
}
.modal__close {
  position: absolute;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 0.6rem;
  border: 0.1rem solid var(--gray10);
  width: 4rem;
  height: 4rem;
  top: 1.6rem;
  right: 1.6rem;
  z-index: 70;
  background: var(--grayBg);
  cursor: pointer;
  transition-duration: 0.3s;
}
@media (max-width: 480px) {
  .modal__close {
    top: 0.8rem;
    right: 0.8rem;
  }
}
.modal__close img {
  width: 2.4rem;
  height: 2.4rem;
  pointer-events: none;
}
.modal__close:hover {
  background: var(--white);
}
.modal__buttons {
  display: flex;
  justify-content: space-between;
  column-gap: 1.6rem;
  max-width: 47.6rem;
  width: 100%;
  margin-top: 1.6rem;
}
@media (max-width: 1200px) {
  .modal__buttons {
    max-width: 38.4rem;
  }
}
@media (max-width: 744px) {
  .modal__buttons {
    max-width: 38.4rem;
  }
}
@media (max-width: 480px) {
  .modal__buttons {
    max-width: 23.6rem;
    margin-top: 0.8rem;
  }
}
.modal__cover {
  position: absolute;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  z-index: -1;
}
.modal__cover img {
  width: 100%;
  height: 100%;
}
.modal__cover::after {
  content: "";
  background: #000;
  opacity: 0.3;
  width: 110%;
  height: 110%;
  position: absolute;
  top: -5%;
  left: -5%;
}
.modal__title {
  color: var(--black);
  text-align: left;
}
@media (max-width: 1200px) {
  .modal__title {
    font-size: 2.8rem;
  }
}
@media (max-width: 744px) {
  .modal__title {
    font-size: 2.6rem;
  }
}
@media (max-width: 480px) {
  .modal__title {
    font-size: 2rem;
    max-width: 32.5rem;
  }
}
.modal__title_center {
  text-align: center;
}
@media (max-width: 480px) {
  .modal__title_center {
    max-width: unset;
  }
}
.modal__title_fix {
  max-width: 33rem;
}
.modal__title_white {
  color: var(--white);
}
.modal__subtitle {
  color: var(--black);
  text-align: left;
}
@media (max-width: 1200px) {
  .modal__subtitle {
    font-size: 1.8rem;
  }
}
@media (max-width: 744px) {
  .modal__subtitle {
    font-size: 1.6rem;
    margin-bottom: -0.8rem;
  }
}
@media (max-width: 480px) {
  .modal__subtitle {
    font-size: 1.6rem;
  }
}
.modal__box {
  padding: 5.6rem 5.8rem;
  overflow-y: auto;
  position: absolute;
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  row-gap: 2.4rem;
}
@media (max-width: 1200px) {
  .modal__box {
    padding: 4rem 5rem;
  }
}
@media (max-width: 480px) {
  .modal__box {
    padding: 4rem 0.8rem;
  }
}
.modal__box_stock .modal__card {
  margin-bottom: 2.4rem;
}
@media (max-width: 1200px) {
  .modal__box_stock .modal__card {
    padding-bottom: 6rem;
    column-gap: 1.6rem;
    margin-bottom: 0.8rem;
  }
}
@media (max-width: 744px) {
  .modal__box_stock .modal__card {
    margin-bottom: 0;
  }
}
@media (max-width: 1200px) {
  .modal__box_stock .modal__card-flex {
    margin-top: auto;
  }
}
.modal__box_stock .modal__card-cover {
  width: 12.8rem;
  height: 17rem;
}
@media (max-width: 1200px) {
  .modal__box_stock .modal__card-cover {
    width: 9.6rem;
    height: 12.2rem;
  }
}
@media (max-width: 744px) {
  .modal__box_stock .modal__card-cover {
    width: 7.6rem;
    height: 10rem;
  }
}
.modal__box_stock .modal__card-title {
  margin-bottom: 0.8rem;
}
@media (max-width: 1200px) {
  .modal__box_stock .modal__card-title {
    font-size: 1.8rem;
  }
}
@media (max-width: 744px) {
  .modal__box_stock .modal__card-title {
    font-size: 1.6rem;
  }
}
.modal__box_certificate .certificate__explain {
  max-width: unset;
}
.modal__box_certificate .certificate__explain_fix {
  max-width: 30rem;
}
@media (max-width: 1200px) {
  .modal__box_certificate .certificate__explain {
    max-width: 25rem;
  }
  .modal__box_certificate .variants {
    gap: 0.8rem;
  }
  .modal__box_certificate .variants__label {
    padding: 0.75rem;
    border-radius: 0.8rem;
  }
}
@media (max-width: 480px) {
  .modal__box_certificate .certificate__explain {
    max-width: unset;
    margin-top: -0.4rem;
  }
  .modal__box_certificate .certificate__explain_fix {
    max-width: 22rem;
  }
  .modal__box_certificate .variants {
    gap: 1.2rem;
  }
}
@media (max-width: 1200px) {
  .modal__box_search {
    padding: 4rem 3.2rem;
  }
}
@media (max-width: 744px) {
  .modal__box_search {
    padding: 4rem 1.7rem;
  }
}
@media (max-width: 480px) {
  .modal__box_search {
    padding: 4rem 0.8rem;
  }
}
.modal__box_gap {
  row-gap: 5.6rem;
}
@media (max-width: 1200px) {
  .modal__box_gap {
    row-gap: 4rem;
  }
}
@media (max-width: 480px) {
  .modal__box_gap {
    row-gap: 2.4rem;
  }
}
.modal__box_gift {
  row-gap: 3.2rem;
}
@media (max-width: 1200px) {
  .modal__box_gift {
    row-gap: 2.4rem;
  }
}
.modal__box_gift .modal__title {
  max-width: 40.4rem;
}
@media (max-width: 744px) {
  .modal__box_gift .modal__title {
    max-width: 30.4rem;
  }
}
@media (max-width: 480px) {
  .modal__box_gift .modal__title {
    max-width: 23.4rem;
  }
}
.modal__box_product-info .modal__subtitle {
  max-width: 30rem;
}
@media (max-width: 1200px) {
  .modal__box_product-info .modal__subtitle {
    max-width: 20rem;
  }
}
.modal__box_picksize {
  row-gap: 5.6rem;
}
@media (max-width: 1200px) {
  .modal__box_picksize {
    row-gap: 3.6rem;
  }
}
@media (max-width: 744px) {
  .modal__box_picksize {
    row-gap: 3.6rem;
  }
}
@media (max-width: 480px) {
  .modal__box_picksize {
    row-gap: 2.4rem;
  }
}
.modal__form {
  display: flex;
  flex-direction: column;
  row-gap: 2.4rem;
}
@media (max-width: 1200px) {
  .modal__form {
    row-gap: 1.6rem;
  }
}
.modal__form-text {
  text-align: center;
  max-width: 48rem;
  margin: 0 auto;
}
@media (max-width: 1200px) {
  .modal__form-text {
    max-width: 40rem;
  }
}
@media (max-width: 744px) {
  .modal__form-text {
    max-width: 35.6rem;
  }
}
@media (max-width: 480px) {
  .modal__form-text {
    max-width: 30rem;
  }
}
.modal__form-text_fix {
  max-width: 42rem;
}
@media (max-width: 1200px) {
  .modal__form-text_fix {
    max-width: 37rem;
  }
}
@media (max-width: 744px) {
  .modal__form-text_fix {
    max-width: 32rem;
  }
}
@media (max-width: 480px) {
  .modal__form-text_fix {
    max-width: 27rem;
  }
}
.modal__form-fields {
  margin-top: 0.8rem;
  display: flex;
  flex-wrap: wrap;
  gap: 1.2rem 2rem;
}
@media (max-width: 744px) {
  .modal__form-fields {
    gap: 1.2rem 1rem;
  }
}
@media (max-width: 480px) {
  .modal__form-fields {
    margin-top: 0;
  }
}
.modal__form-block {
  width: calc(50% - 1rem);
}
@media (max-width: 744px) {
  .modal__form-block {
    width: calc(50% - 0.5rem);
  }
}
@media (max-width: 480px) {
  .modal__form-block {
    width: 100%;
  }
}
.modal__form-block_wide {
  width: 100%;
}
.modal__form-block textarea {
  height: 11rem;
  min-height: 5.6rem;
  max-height: 13.4rem;
}
@media (max-width: 1200px) {
  .modal__form-block textarea {
    height: 10rem;
    min-height: 4.3rem;
    max-height: 12.4rem;
  }
}
@media (max-width: 744px) {
  .modal__form-block textarea {
    height: 7.2rem;
    min-height: 4.5rem;
    max-height: 15.2rem;
  }
}
@media (max-width: 480px) {
  .modal__form-block textarea {
    height: 7.2rem;
    min-height: 4.5rem;
    max-height: 13.6rem;
  }
}
.modal__form-note {
  color: var(--gray50);
  margin-top: -1.6rem;
}
@media (max-width: 1200px) {
  .modal__form-note {
    margin-top: -0.8rem;
  }
}
.modal__form-note a {
  color: var(--gray50);
}
.modal__information {
  display: flex;
  flex-direction: column;
  row-gap: 2.4rem;
}
@media (max-width: 1200px) {
  .modal__information {
    row-gap: 1.6rem;
  }
}
@media (max-width: 1200px) {
  .modal__information .modal__subtitle {
    margin-bottom: 0.8rem;
  }
}
@media (max-width: 480px) {
  .modal__information .modal__subtitle {
    margin-bottom: 0;
  }
}
.modal__information-block {
  display: flex;
  flex-direction: column;
  row-gap: 0.8rem;
}
.modal__information-link {
  color: var(--gray60);
}
.modal__information-gray {
  color: var(--gray60);
}
.modal__information-flex {
  display: flex;
  justify-content: space-between;
  align-items: flex-end;
}
.modal__information-sale {
  text-align-last: left;
  font-size: 3.6rem;
}
@media (max-width: 1200px) {
  .modal__information-sale {
    font-size: 2.8rem;
  }
}
@media (max-width: 744px) {
  .modal__information-sale {
    font-size: 2.6rem;
  }
}
@media (max-width: 480px) {
  .modal__information-sale {
    font-size: 2rem;
  }
}
.modal__information-limit {
  color: var(--gray60);
}
@media (max-width: 480px) {
  .modal__information-limit {
    font-size: 1.4rem;
  }
}
.modal__product {
  display: flex;
  flex-wrap: wrap;
  row-gap: 1.6rem;
}
.modal__product-column {
  width: 50%;
  display: flex;
  flex-direction: column;
  row-gap: 1.6rem;
}
.modal__product-column_wide {
  width: 100%;
}
.modal__product-block {
  max-width: 50rem;
}
.modal__product-label {
  color: var(--gray40);
}
.modal__product-image {
  margin: 0;
  margin-left: auto;
  width: 100%;
  max-width: 24.2rem;
  max-height: 32rem;
}
@media (max-width: 1200px) {
  .modal__product-image {
    max-width: 11.8rem;
    max-height: 15.5rem;
  }
}
@media (max-width: 480px) {
  .modal__product-image {
    max-width: 12.7rem;
  }
}
.modal__product-image img {
  display: block;
  border-radius: 1.6rem;
  width: 100%;
  height: 100%;
}
.modal__product-caption {
  text-align: center;
  text-transform: uppercase;
  padding: 1rem;
  font-weight: 500;
  color: var(--gray60);
}
@media (max-width: 1200px) {
  .modal__product-caption {
    padding: 0.7rem 1rem;
  }
}
@media (max-width: 1200px) {
  .modal__product-caption {
    padding: 0.7rem 2.3rem;
  }
}
.modal__sizes {
  display: flex;
  flex-direction: column;
  row-gap: 3.2rem;
}
@media (max-width: 480px) {
  .modal__sizes {
    row-gap: 2.4rem;
  }
}
.modal__sizes-close {
  right: 1.7rem;
  top: 2.8rem;
  border: none;
  width: 2.4rem;
  height: 2.4rem;
}
@media (max-width: 480px) {
  .modal__sizes-close {
    right: 0.8rem;
    top: 1.6rem;
  }
}
@media (max-width: 744px) {
  .modal__sizes-title {
    font-size: 1.8rem;
  }
}
@media (max-width: 480px) {
  .modal__sizes-title {
    font-size: 1.6rem;
  }
}
.modal__sizes-table {
  display: flex;
  flex-wrap: wrap;
  gap: 0.8rem;
}
@media (max-width: 480px) {
  .modal__sizes-table {
    gap: 0.8rem 0.8rem;
  }
}
.modal__sizes-label {
  cursor: pointer;
  text-align: center;
  width: 8.1rem;
  height: 3.8rem;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 1rem;
  user-select: none;
  border: 0.1rem solid var(--gray00);
  color: var(--gray60);
  font-weight: 400;
  font-size: 1.2rem;
}
@media (max-width: 480px) {
  .modal__sizes-label {
    width: 8.3rem;
    height: 3.8rem;
    border-radius: 0.8rem;
  }
}
.modal__sizes-label input {
  position: absolute;
  width: 1.6rem;
  height: 1.6rem;
  opacity: 0;
  pointer-events: none;
}
.modal__sizes-label:has(input:checked) {
  background: var(--gray10);
  border: 0.1rem solid transparent;
}
.modal__price {
  margin-top: 2.4rem;
}
.modal__price-top {
  display: flex;
  justify-content: space-between;
}
.modal__price-title {
  font-size: 1.2rem;
}
.modal__price-clear {
  font-size: 1.2rem;
  margin-right: unset;
  color: var(--gray50);
  border-bottom: 0.1rem solid var(--gray50);
}
.modal__price-slider {
  height: 1.8rem;
  display: flex;
  align-items: center;
  margin-top: 2.4rem;
  margin-bottom: 2rem;
  /* .ui-widget.ui-widget-content{
      height: rem(4) !important;
      border: none !important;
      background: var(--gray10) !important;
      width: 100%;
  }

  .ui-slider {
      //width: calc(100% - 8px);
      //left: 4px;
      //bottom: -1px;           
  }

  .ui-slider-range {
      background: var(--black) !important;
  }

  .ui-slider-handle {
      border-radius: 30px !important;
      background: var(--black) !important;
      border: rem(5) solid var(--white) !important;
      width: rem(18) !important;
      height: rem(18) !important;
      outline: none !important;
      margin-top: -3px !important;
      cursor: pointer !important;
      box-shadow: 0 3px 10px 0px rgba(0, 0, 0, 0.1);
  } */
}
.modal__price-box {
  display: flex;
  justify-content: space-between;
  column-gap: 1rem;
}
.modal__price-half {
  width: calc(50% - 0.5rem);
  position: relative;
}
.modal__price-label {
  font-size: 1rem;
  line-height: 1.4;
  color: var(--gray30);
  text-transform: uppercase;
}
.modal__price-input {
  width: 100%;
  margin-top: 0.6rem;
  height: 4.4rem;
  font-weight: 500;
  color: var(--black);
  padding: 0 2rem;
  font-size: 1.2rem;
  outline: none;
  border: 0.1rem solid var(--gray30);
  background: var(--grayBg);
  border-radius: 0.8rem;
}
.modal__selects {
  margin-top: 2.4rem;
  margin-bottom: 2.4rem;
  display: flex;
  flex-direction: column;
  row-gap: 0.8rem;
}
.modal__selects .select_drop::after {
  top: 0.7rem;
  transform: translateY(0);
}
.modal__selects .select_open::after {
  transform: translateY(0) scaleY(-1);
}
.modal__tabs {
  margin-top: -2.2rem;
}
@media (max-width: 1200px) {
  .modal__tabs {
    margin-top: 0;
  }
}
.modal__map {
  width: 100%;
  background: var(--black);
  height: 53.8rem;
  border-radius: 1.2rem;
  margin: 5.6rem 0;
}
@media (max-width: 1200px) {
  .modal__map {
    height: 27rem;
    margin-bottom: 2.4rem;
  }
}
@media (max-width: 480px) {
  .modal__map {
    height: 35.8rem;
  }
}
.modal__shop {
  display: flex;
  flex-direction: column;
  row-gap: 1.6rem;
}
@media (max-width: 1200px) {
  .modal__shop-title {
    max-width: 34rem;
  }
}
@media (max-width: 480px) {
  .modal__shop-title {
    font-size: 1.6rem;
  }
}
.modal__shop-block {
  display: flex;
  flex-direction: column;
  row-gap: 0.8rem;
}
.modal__text_gray {
  color: var(--gray70);
}
.modal__shop-image {
  width: 100%;
  height: 32rem;
  border-radius: 2rem;
  overflow: hidden;
}
.modal__shop-image img {
  width: 100%;
  height: 100%;
}
@media (max-width: 1200px) {
  .modal__shop-image {
    height: 18.6rem;
    border-radius: 1.4rem;
  }
}
@media (max-width: 480px) {
  .modal__shop-image {
    height: 23.2rem;
  }
}
.modal__shop-btn {
  margin-top: 2.4rem;
}
.modal__list {
  display: flex;
  flex-direction: column;
  row-gap: 1.6rem;
  width: 100%;
}
.modal__list-item {
  padding: 2.4rem;
  border-radius: 1.6rem;
  border: 0.1rem solid var(--gray20);
  display: flex;
  justify-content: space-between;
  align-items: center;
}
@media (max-width: 1200px) {
  .modal__list-item {
    padding: 1.6rem;
    flex-wrap: wrap;
    row-gap: 0.8rem;
  }
}
.modal__list-text {
  max-width: 32rem;
}
.modal__list-text:has(+ .modal__list-text) {
  margin-bottom: -0.8rem;
}
.modal__list-link {
  margin-right: unset;
  cursor: pointer;
}
.modal__addition {
  position: relative;
  width: 100%;
  overflow: unset;
  overflow-x: clip;
  margin-bottom: 2.4rem;
  margin-top: 5.6rem;
}
@media (max-width: 1200px) {
  .modal__addition {
    margin-top: 1.6rem;
    margin-bottom: 0;
  }
}
@media (max-width: 480px) {
  .modal__addition {
    margin-top: 0;
  }
}
.modal__addition .block-product__card {
  margin-right: 1.2rem;
}
@media (max-width: 1200px) {
  .modal__addition .block-product__card {
    margin-right: 0.8rem;
  }
}
.modal__addition .block-product__card:nth-last-of-type(1) {
  margin-right: 0;
}
.modal__addition .block-product__card-cover {
  width: 100%;
}
.modal__addition .block-product__card {
  width: 25.9rem;
}
@media (max-width: 1200px) {
  .modal__addition .block-product__card {
    width: 15.5rem;
  }
}
@media (max-width: 480px) {
  .modal__addition .block-product__card {
    width: 17.4rem;
  }
}
@media (max-width: 480px) {
  .modal__addition .select__value {
    padding: 0.8rem 1.6rem;
  }
}
@media (max-width: 480px) {
  .modal__addition .block-product__card-cart {
    width: 3.4rem;
    height: 3.4rem;
  }
  .modal__addition .block-product__card-cart img {
    width: 1.8rem;
    height: 1.8rem;
  }
}
.modal__addition-top {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 1.6rem;
}
.modal__addition-title {
  text-align: left;
}
@media (max-width: 1200px) {
  .modal__addition-title {
    font-size: 1.8rem;
  }
}
@media (max-width: 480px) {
  .modal__addition-title {
    font-size: 1.6rem;
  }
}
.modal__addition-controls {
  display: flex;
}
.modal__addition-next, .modal__addition-prev {
  width: 2.4rem;
  height: 2.4rem;
  cursor: pointer;
}
.modal__addition-next.swiper-button-disabled, .modal__addition-prev.swiper-button-disabled {
  opacity: 0.5;
}
.modal__addition-next img, .modal__addition-prev img {
  width: 100%;
  height: 100%;
}
.modal__addition-filters {
  display: flex;
  gap: 0.8rem;
  margin-bottom: 3.2rem;
}
@media (max-width: 1200px) {
  .modal__addition-filters {
    gap: 0.4rem;
    margin-bottom: 2.4rem;
  }
}
.modal__addition-check {
  width: auto;
  height: 3.6rem;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 1.4rem;
  padding: 0 1.6rem;
  cursor: pointer;
  color: var(--gray60);
  font-weight: 400;
  background: var(--grayBg);
  border: 0.1rem solid var(--gray30);
  border-radius: 0.8rem;
  outline: none;
  user-select: none;
}
@media (max-width: 1200px) {
  .modal__addition-check {
    font-size: 1.2rem;
    padding: 0 1.2rem;
    height: 3.2rem;
    width: 100%;
  }
}
@media (max-width: 480px) {
  .modal__addition-check {
    width: auto;
  }
}
.modal__addition-check:has(input:checked) {
  color: var(--black);
  font-weight: 500;
  background: var(--gray00);
  border: 0.1rem solid var(--gray00);
}
.modal__addition-check input {
  pointer-events: none;
  opacity: 0;
  position: absolute;
}
.modal__cart {
  display: flex;
  flex-direction: column;
  row-gap: 3.2rem;
}
@media (max-width: 1200px) {
  .modal__cart {
    row-gap: 2.4rem;
  }
}
.modal__card {
  display: flex;
  column-gap: 2.4rem;
  position: relative;
}
@media (max-width: 1200px) {
  .modal__card {
    column-gap: 2.8rem;
  }
}
.modal__card_disabled .modal__card-cover {
  opacity: 0.4;
}
.modal__card-cover {
  width: 12rem;
  height: 16rem;
  border-radius: 1rem;
  overflow: hidden;
  flex-shrink: 0;
}
@media (max-width: 1200px) {
  .modal__card-cover {
    width: 5rem;
    height: 6.7rem;
  }
}
.modal__card-cover img {
  width: 100%;
  height: 100%;
}
.modal__card-content {
  width: 100%;
  display: flex;
  flex-direction: column;
}
.modal__card-title {
  max-width: 30rem;
  margin-bottom: 0.4rem;
}
@media (max-width: 1200px) {
  .modal__card-title {
    font-size: 2rem;
  }
}
@media (max-width: 744px) {
  .modal__card-title {
    font-size: 1.8rem;
  }
}
@media (max-width: 480px) {
  .modal__card-title {
    font-size: 1.6rem;
    max-width: 22rem;
  }
}
.modal__card-flex {
  display: flex;
  justify-content: space-between;
  margin-top: auto;
}
@media (max-width: 1200px) {
  .modal__card-flex {
    margin-top: 1rem;
  }
}
.modal__card-block {
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
}
@media (max-width: 1200px) {
  .modal__card-block {
    row-gap: 0.4rem;
  }
}
.modal__card-block_quantity {
  row-gap: 1rem;
}
@media (max-width: 744px) {
  .modal__card-block_quantity {
    row-gap: 0.4rem;
  }
}
.modal__card-block_quantity .quantity {
  margin-left: auto;
}
.modal__card-block_select {
  width: 19.5rem;
}
@media (max-width: 1200px) {
  .modal__card-block_select {
    position: absolute;
    width: 100%;
    bottom: 0;
    left: 0;
  }
}
@media (max-width: 1200px) {
  .modal__card-block_price {
    flex-direction: row;
    width: 100%;
    margin-top: auto;
    justify-content: flex-start;
    column-gap: 0.4rem;
    align-items: center;
  }
}
.modal__card-price {
  margin-top: 1rem;
}
@media (max-width: 1200px) {
  .modal__card-price {
    margin-top: 0.6rem;
  }
}
.modal__picksize {
  display: flex;
  flex-direction: column;
  row-gap: 3.2rem;
  position: relative;
  z-index: 1;
}
@media (max-width: 744px) {
  .modal__picksize {
    font-size: 2.4rem;
  }
}
.modal__picksize-block {
  display: flex;
  flex-direction: column;
  row-gap: 1.6rem;
  position: relative;
  max-width: 37.2rem;
}
@media (max-width: 1200px) {
  .modal__picksize-block {
    max-width: 25.5rem;
  }
}
@media (max-width: 480px) {
  .modal__picksize-block {
    max-width: 26.5rem;
  }
}
.modal__picksize-block .input-block {
  margin-top: 1.2rem;
}
@media (max-width: 1200px) {
  .modal__picksize-block .input-block {
    max-width: 19rem;
  }
}
@media (max-width: 744px) {
  .modal__picksize-block .input-block {
    margin-top: 0;
  }
}
.modal__picksize-block_wide {
  max-width: unset;
}
.modal__picksize-step {
  padding-left: 5.2rem;
  min-height: 3.6rem;
}
.modal__picksize-number {
  width: 3.6rem;
  height: 3.6rem;
  background: var(--gray00);
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 2.4rem;
  border-radius: 0.8rem;
  position: absolute;
  left: 0;
  top: 0;
}
@media (max-width: 1200px) {
  .modal__picksize-number {
    font-size: 1.8rem;
  }
}
@media (max-width: 744px) {
  .modal__picksize-number {
    font-size: 1.6rem;
  }
}
@media (max-width: 480px) {
  .modal__picksize-number {
    font-size: 1.6rem;
  }
}
.modal__picksize-text {
  max-width: 24.6rem;
}
.modal__picksize-value {
  width: 8rem;
}
@media (max-width: 1200px) {
  .modal__picksize-value {
    width: 6rem;
  }
}
.modal__picksize-line {
  display: flex;
  justify-content: space-between;
  max-width: 28.9rem;
}
.modal__picksize-image {
  width: 29.2rem;
  height: 38.6rem;
  position: absolute;
  right: -4.8rem;
  top: 1.6rem;
  z-index: -1;
}
@media (max-width: 1200px) {
  .modal__picksize-image {
    width: 14.2rem;
    height: 18.7rem;
  }
}
@media (max-width: 480px) {
  .modal__picksize-image {
    right: 1rem;
    top: 5.6rem;
  }
}
.modal__picksize-image img {
  width: 100%;
  height: 100%;
}
.modal__notify {
  display: flex;
  flex-direction: column;
  row-gap: 0.8rem;
}
.modal__notify-title {
  margin-bottom: 2.4rem;
}
@media (max-width: 1200px) {
  .modal__notify-title {
    margin-bottom: 0.8rem;
  }
}
.modal__notify-btn {
  margin-top: 0.8rem;
}
@media (max-width: 1200px) {
  .modal__notify-note {
    max-width: 23.2rem;
  }
}
@media (max-width: 480px) {
  .modal__notify-note {
    max-width: unset;
  }
}
.modal__repeat {
  display: flex;
  flex-direction: column;
  row-gap: 1.6rem;
}
.modal__repeat-list {
  display: flex;
  flex-direction: column;
  row-gap: 2.4rem;
  margin-top: 1.2rem;
}
.modal__repeat-list .modal__card {
  padding-bottom: 2.4rem;
  border-bottom: 0.1rem solid var(--gray20);
}
.modal__repeat-list .modal__card:nth-last-of-type(1) {
  border: none;
  padding-bottom: 0.8rem;
}
.modal__repeat-btn {
  margin-bottom: 2.4rem;
}
@media (max-width: 1200px) {
  .modal__repeat-btn {
    margin-bottom: 1.6rem;
  }
}
.modal__back {
  display: flex;
  align-items: center;
  column-gap: 1.2rem;
  font-weight: 500;
  text-transform: uppercase;
  position: absolute;
  top: 3.2rem;
  left: 2.4rem;
  z-index: 70;
}
@media (max-width: 744px) {
  .modal__back {
    top: 2.4rem;
    left: 1.7rem;
  }
}
@media (max-width: 480px) {
  .modal__back {
    left: 0.9rem;
    column-gap: 0.8rem;
  }
}
.modal__more {
  display: flex;
  justify-content: space-between;
  column-gap: 3.6rem;
  position: relative;
}
@media (max-width: 1200px) {
  .modal__more {
    flex-wrap: wrap;
    row-gap: 2.4rem;
  }
}
.modal__more-left {
  flex-shrink: 0;
  position: relative;
}
@media (max-width: 744px) {
  .modal__more-left {
    width: 100%;
  }
}
.modal__more-right {
  width: 122.8rem;
}
@media (max-width: 1200px) {
  .modal__more-right {
    width: 75.2rem;
  }
}
@media (max-width: 744px) {
  .modal__more-right {
    width: 100%;
  }
}
@media (max-width: 744px) {
  .modal__more-right .modal__addition-controls {
    display: none;
  }
}
.modal__more-right .modal__addition {
  margin-top: 0;
}
@media (max-width: 744px) {
  .modal__more-right .modal__addition {
    width: calc(100% + 1.7rem);
    padding-right: 1.7rem;
  }
}
@media (max-width: 480px) {
  .modal__more-right .modal__addition {
    width: calc(100% + 0.9rem);
    padding-right: 0.9rem;
  }
}
.modal__more-right .block-product__card {
  width: 29.2rem;
  margin-right: 2rem;
}
@media (max-width: 1200px) {
  .modal__more-right .block-product__card {
    width: 17.6rem;
    margin-right: 1.6rem;
  }
}
@media (max-width: 744px) {
  .modal__more-right .block-product__card {
    width: 17rem;
    margin-right: 1rem;
  }
}
@media (max-width: 480px) {
  .modal__more-right .block-product__card {
    width: 16rem;
  }
}
.modal__more-right .modal__addition-top {
  margin-bottom: 2.4rem;
}
@media (max-width: 1200px) {
  .modal__more-right .modal__addition-top {
    margin-bottom: 1.9rem;
  }
}
@media (max-width: 744px) {
  .modal__more-right .modal__addition-top {
    margin-bottom: 1.2rem;
  }
}
.modal__more-right .modal__addition-filters {
  margin-bottom: 2.4rem;
}
@media (max-width: 744px) {
  .modal__more-right .modal__addition-filters {
    margin-bottom: 1.6rem;
  }
}
@media (max-width: 1200px) {
  .modal__more-right .modal__addition-check {
    width: auto;
  }
}
.modal__more-title {
  text-align: left;
}
.modal__more-swiper {
  width: 48.2rem;
  height: 63.9rem;
}
@media (max-width: 1200px) {
  .modal__more-swiper {
    width: 34.7rem;
    height: 46rem;
  }
}
@media (max-width: 744px) {
  .modal__more-swiper {
    width: calc(100% + 1.7rem);
    padding-right: 1.7rem;
    height: auto;
    position: relative;
  }
}
@media (max-width: 480px) {
  .modal__more-swiper {
    width: calc(100% + 0.9rem);
    padding-right: 0.9rem;
  }
}
.modal__more-swiper .swiper-pagination {
  height: 2rem;
  bottom: 2.2rem;
}
@media (max-width: 1200px) {
  .modal__more-swiper .swiper-pagination {
    bottom: 1rem;
  }
}
@media (max-width: 744px) {
  .modal__more-swiper .swiper-pagination {
    display: none;
  }
}
.modal__more-swiper .swiper-pagination .swiper-pagination-bullet {
  width: 7.2rem;
  height: 0.4rem;
  border-radius: 9rem;
  margin: 0.8rem 0.6rem;
  background: var(--black);
  transition-duration: 0.3s;
}
@media (max-width: 1200px) {
  .modal__more-swiper .swiper-pagination .swiper-pagination-bullet {
    width: 3.5rem;
    height: 0.2rem;
    margin: 0.8rem 0.3rem;
  }
}
.modal__more-swiper .swiper-pagination .swiper-pagination-bullet-active {
  background: var(--white);
}
.modal__more-slide {
  border-radius: 2.4rem;
  overflow: hidden;
}
@media (max-width: 1200px) {
  .modal__more-slide {
    border-radius: 2rem;
  }
}
@media (max-width: 744px) {
  .modal__more-slide {
    border-radius: 1.6rem;
    width: 29rem;
    height: 38.6rem;
    margin-right: 1rem;
  }
}
@media (max-width: 480px) {
  .modal__more-slide {
    border-radius: 1.2rem;
    width: 22rem;
    height: 29.2rem;
  }
}
.modal__more-slide::nth-last-of-type(1) {
  margin-right: 0 !important;
}
.modal__alert {
  padding: 2.4rem;
  background: var(--gray00);
  border-radius: 1.6rem;
  display: flex;
  column-gap: 7rem;
}
@media (max-width: 1200px) {
  .modal__alert {
    flex-wrap: wrap;
    row-gap: 1.6rem;
  }
}
@media (max-width: 1200px) {
  .modal__alert-text {
    max-width: 24rem;
  }
}
@media (max-width: 480px) {
  .modal__alert-text {
    max-width: unset;
  }
}
.modal__alert-btn {
  flex-shrink: 0;
  min-width: 18.6rem;
}
@media (max-width: 1200px) {
  .modal__alert-btn {
    width: 100%;
  }
}
.modal__order {
  display: flex;
  flex-direction: column;
  row-gap: 5.6rem;
  margin-top: 2.4rem;
}
@media (max-width: 1200px) {
  .modal__order {
    row-gap: 4rem;
  }
}
.modal__order .input-block {
  width: 100%;
}
.modal__order-text {
  max-width: 41rem;
}
@media (max-width: 480px) {
  .modal__order-text {
    max-width: 32.5rem;
    font-size: 1.4rem;
  }
}
.modal__order-text_fix {
  max-width: 37rem;
}
@media (max-width: 1200px) {
  .modal__order-text_fix {
    max-width: 29.5rem;
  }
}
.modal__order-block {
  display: flex;
  flex-direction: column;
  row-gap: 1.6rem;
}
@media (max-width: 1200px) {
  .modal__order-block {
    row-gap: 0.8rem;
  }
}
.modal__order-block_gap {
  row-gap: 3.2rem;
}
@media (max-width: 1200px) {
  .modal__order-block_gap {
    row-gap: 2.4rem;
  }
}
.modal__order-buttons {
  display: flex;
  justify-content: space-between;
  column-gap: 1.6rem;
}
@media (max-width: 1200px) {
  .modal__order-buttons {
    flex-wrap: wrap;
    row-gap: 0.8rem;
  }
}
.modal__certificate {
  display: flex;
  flex-direction: column;
  row-gap: 2.4rem;
  margin-top: 1.6rem;
}
@media (max-width: 1200px) {
  .modal__certificate {
    margin-top: 0rem;
    row-gap: 1.6rem;
  }
}
.modal__certificate-response {
  display: flex;
  flex-direction: column;
  row-gap: 1.6rem;
  margin-top: 1.6rem;
}
@media (max-width: 1200px) {
  .modal__certificate-text {
    max-width: 25.1rem;
  }
}
@media (max-width: 480px) {
  .modal__certificate-text {
    max-width: 23.1rem;
  }
}
.modal__certificate-btn {
  margin-top: 1.6rem;
}
.modal__search {
  margin: 0 auto;
  margin-top: 2.8rem;
  max-width: 138.6rem;
  width: 100%;
}
.modal__search-form {
  display: flex;
  column-gap: 2.4rem;
}
@media (max-width: 480px) {
  .modal__search-form {
    flex-wrap: wrap;
    row-gap: 1.6rem;
  }
}
.modal__search-form .input-block {
  width: 100%;
}
.modal__search-btn {
  flex-shrink: 0;
  min-width: 21.2rem;
  height: 5.6rem;
}
@media (max-width: 1200px) {
  .modal__search-btn {
    height: 4.4rem;
  }
}
@media (max-width: 744px) {
  .modal__search-btn {
    min-width: 17rem;
  }
}
@media (max-width: 480px) {
  .modal__search-btn {
    width: 100%;
  }
}
.modal__search-line {
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  margin-top: 2.4rem;
  margin-bottom: 3.6rem;
}
@media (max-width: 744px) {
  .modal__search-line {
    margin-top: 1.6rem;
    margin-bottom: 2.4rem;
  }
}
.modal__search-link {
  margin-right: unset;
}
.modal__search-results {
  display: flex;
  flex-wrap: wrap;
  gap: 4rem 2rem;
}
@media (max-width: 1200px) {
  .modal__search-results {
    gap: 2.4rem 1.6rem;
  }
}
@media (max-width: 744px) {
  .modal__search-results {
    gap: 3.2rem 1rem;
  }
}
@media (max-width: 480px) {
  .modal__search-results .block-catalog__card {
    width: 17.4rem;
    height: 33rem;
    margin-right: 0;
  }
}
@media (max-width: 480px) {
  .modal__search-results .block-catalog__card-image {
    height: 23.2rem;
  }
}
.modal__search-preview {
  display: flex;
  justify-content: space-between;
  margin-top: 4.8rem;
}
@media (max-width: 1200px) {
  .modal__search-preview {
    margin-top: 4rem;
  }
}
@media (max-width: 744px) {
  .modal__search-preview {
    margin-top: 3.2rem;
    flex-wrap: wrap;
    row-gap: 3.2rem;
  }
}
@media (max-width: 480px) {
  .modal__search-preview {
    margin-top: 2.4rem;
  }
}
.modal__search-suggest {
  width: 44.8rem;
  display: flex;
  flex-direction: column;
  row-gap: 3.2rem;
}
@media (max-width: 1200px) {
  .modal__search-suggest {
    width: 31.7rem;
    row-gap: 2.4rem;
  }
}
@media (max-width: 744px) {
  .modal__search-suggest {
    width: 30rem;
    row-gap: 1.6rem;
  }
}
@media (max-width: 480px) {
  .modal__search-suggest {
    width: 100%;
  }
}
.modal__search-categories {
  width: 85.6rem;
  overflow-y: clip;
  overflow-x: scroll;
}
@media (max-width: 1200px) {
  .modal__search-categories {
    width: 56rem;
  }
}
@media (max-width: 744px) {
  .modal__search-categories {
    width: 100%;
  }
}
.modal__search-categories .main-categories__card {
  width: 27.2rem;
  gap: 1.6rem;
  margin-right: 0;
}
@media (max-width: 480px) {
  .modal__search-categories .main-categories__card {
    gap: 0.8rem;
  }
}
.modal__search-categories .main-categories__image {
  height: 27.2rem;
}
.modal__search-categories .main-categories__card-title {
  font-size: 2rem;
}
@media (max-width: 744px) {
  .modal__search-categories .main-categories__card-title {
    font-size: 1.8rem;
  }
}
@media (max-width: 480px) {
  .modal__search-categories .main-categories__card-title {
    font-size: 1.6rem;
  }
}
.modal__search-scroll {
  width: 85.6rem;
  display: flex;
  gap: 2rem;
  padding-bottom: 3rem;
}
@media (max-width: 1200px) {
  .modal__search-scroll {
    gap: 1.6rem;
    width: 83.5rem;
  }
}
@media (max-width: 744px) {
  .modal__search-scroll {
    gap: 0.9rem;
    width: 85.5rem;
  }
}
.modal__search-title {
  text-align: left;
  border-bottom: 0.1rem solid var(--gray60);
  padding-bottom: 1.2rem;
}
@media (max-width: 1200px) {
  .modal__search-title {
    margin-bottom: 0.8rem;
    font-size: 1.8rem;
  }
}
@media (max-width: 744px) {
  .modal__search-title {
    font-size: 1.6rem;
  }
}
@media (max-width: 480px) {
  .modal__search-title {
    font-size: 1.6rem;
  }
}
.modal__search-description {
  max-width: 50%;
}
.modal__search-pagination {
  width: 100%;
  display: flex;
  justify-content: center;
  margin-top: 3.2rem;
}
@media (max-width: 744px) {
  .modal__search-pagination {
    margin-top: 0.8rem;
  }
}
@media (max-width: 480px) {
  .modal__search-pagination {
    margin-top: -1.6rem;
  }
}
.modal__change {
  display: flex;
  flex-direction: column;
  row-gap: 0.8rem;
  margin-top: 0.8rem;
}
.modal__change_row {
  flex-direction: row;
  flex-wrap: wrap;
  gap: 4rem 1rem;
}
@media (max-width: 1200px) {
  .modal__change_row {
    gap: 3.2rem 0.8rem;
  }
}
.modal__change_row .modal__change-btn {
  margin-top: unset;
}
.modal__change-text {
  max-width: 36.5rem;
}
@media (max-width: 744px) {
  .modal__change-text {
    max-width: 24rem;
  }
}
.modal__change-btn {
  margin-top: 3.2rem;
}
@media (max-width: 1200px) {
  .modal__change-btn {
    margin-top: 2.4rem;
  }
}
.modal__change-block {
  width: calc(50% - 0.5rem);
  display: flex;
  flex-direction: column;
  row-gap: 0.8rem;
}
@media (max-width: 1200px) {
  .modal__change-block {
    width: calc(50% - 0.4rem);
  }
}
.modal__change-block_wide {
  width: 100%;
}
.modal__change-block_third {
  width: calc(33.3333% - 0.7rem);
}
@media (max-width: 1200px) {
  .modal__change-block_third {
    width: calc(33.3333% - 0.6rem);
  }
}
.modal__change-block_check {
  justify-content: flex-end;
  max-height: 6.6rem;
}
@media (max-width: 1200px) {
  .modal__change-block_check {
    max-height: 6rem;
  }
}
@media (max-width: 480px) {
  .modal__change-block_check {
    max-height: 5.6rem;
  }
}
.modal__change-block_check .input-block {
  height: 100%;
}
@media (max-width: 1200px) {
  .modal__change-block .input-close {
    top: 1rem;
    right: 0.8rem;
  }
}
.modal__change-label {
  display: flex;
  align-items: center;
  column-gap: 0.8rem;
}
.modal__change-label input {
  position: absolute;
  opacity: 0;
  pointer-events: none;
}
.modal__change-label::before {
  content: "";
  display: block;
  width: 2.4rem;
  height: 2.4rem;
  background: url(/img/svg/checkbox-empty.svg) no-repeat;
  background-size: cover;
  flex-shrink: 0;
  pointer-events: none;
  /* @include mobile{
      width: rem(20);
      height: rem(20);
  } */
}
.modal__change-label:has(input:checked)::before {
  background: url(/img/svg/checkbox-full.svg) no-repeat;
  background-size: cover;
}
.modal__return {
  display: flex;
  align-items: center;
  column-gap: 0.8rem;
  position: fixed;
  width: 100%;
  background: var(--grayBg);
  text-transform: uppercase;
  font-weight: 500;
  cursor: pointer;
}
.modal__return::before {
  content: "";
  position: absolute;
  display: block;
  width: 100%;
  height: 14rem;
  bottom: -3rem;
  left: -0.5rem;
  background: var(--grayBg);
  z-index: -1;
}
.modal__ask-city-text {
  text-transform: uppercase;
  margin-right: 1rem;
  font-weight: 500;
}
@media (max-width: 744px) {
  .modal__ask-city-text {
    width: 100%;
    text-align: center;
    margin-right: 0;
    font-size: 1.2rem;
    line-height: 1;
  }
}
.modal__ask-city-buttons {
  display: flex;
  column-gap: 0.8rem;
}
@media (max-width: 744px) {
  .modal__ask-city-buttons {
    width: 100%;
  }
}
.modal__ask-city-btn {
  width: 10rem;
  height: 4rem;
  padding: 0;
  border-radius: 0.8rem;
}
@media (max-width: 744px) {
  .modal__ask-city-btn {
    width: 100%;
  }
}
.modal__login {
  display: flex;
  flex-direction: column;
  row-gap: 4rem;
}
@media (max-width: 480px) {
  .modal__login {
    row-gap: 3.2rem;
  }
}
@media (max-width: 1200px) {
  .modal__login .variants {
    gap: 0.8rem;
  }
}
.modal__login-form {
  display: flex;
  flex-direction: column;
  row-gap: 1.6rem;
}
@media (max-width: 744px) {
  .modal__login-form {
    row-gap: 1rem;
  }
}
@media (max-width: 480px) {
  .modal__login-form {
    row-gap: 0.8rem;
  }
}
.modal__login-form .input-block.error-field-response + .modal__login-text_tohide {
  display: none;
}
.modal__login-form .input-block.error-field:not(.error-field-response) .input__error:nth-of-type(2) {
  display: none;
}
.modal__login-form .input-block.error-field-response .input__error:nth-of-type(1) {
  display: none;
}
.modal__login-form[data-login-form=resetPassword] {
  margin-top: 1.6rem;
}
.modal__login-text {
  margin-bottom: 0.8rem;
  max-width: 53rem;
}
.modal__login-text_fix {
  max-width: 43.7rem;
}
@media (max-width: 480px) {
  .modal__login-text_fix {
    max-width: 25rem;
  }
}
.modal__login-text_confirm {
  max-width: 30rem;
}
.modal__login-text_note {
  margin-top: -0.8rem;
}
@media (max-width: 744px) {
  .modal__login-text_note {
    margin-top: -0.4rem;
  }
}
@media (max-width: 480px) {
  .modal__login-text_note {
    margin-top: -0.2rem;
  }
}
.modal__login-capcha {
  width: 30rem;
  height: 7.2rem;
  background: #ddd;
  margin-top: 0.8rem;
  margin-bottom: -0.8rem;
}
.modal__login-capcha img {
  width: 100%;
  height: 100%;
}
@media (max-width: 1200px) {
  .modal__login-capcha {
    width: 23.4rem;
    height: 5.6rem;
  }
}
.modal__login-btn {
  margin-top: 1.6rem;
}
.modal__double-input {
  display: flex;
  column-gap: 0.8rem;
}
.modal__double-input .input-block:nth-of-type(1) {
  width: 100%;
}
.modal__double-input .input-block:nth-of-type(2) {
  max-width: 12.5rem;
  flex-shrink: 0;
}

.news-page__section_certificate .news-page__title {
  font-size: 3.6rem;
}
@media (max-width: 1200px) {
  .news-page__section_certificate .news-page__title {
    font-size: 2.8rem;
  }
}
@media (max-width: 744px) {
  .news-page__section_certificate .news-page__title {
    font-size: 2.6rem;
  }
}
@media (max-width: 480px) {
  .news-page__section_certificate .news-page__title {
    font-size: 2rem;
  }
}
@media (max-width: 744px) {
  .news-page__section_certificate .news-page__title_fix {
    max-width: 40.9rem;
  }
}
.news-page__section_certificate .news-page__box_reverse {
  flex-direction: row-reverse;
}
@media (max-width: 744px) {
  .news-page__section_certificate .news-page__box_reverse {
    flex-direction: row;
  }
}
@media (max-width: 744px) {
  .news-page__section_certificate .news-page__left {
    right: 0;
  }
}
@media (max-width: 744px) {
  .news-page__section_certificate .news-page__right {
    width: 40.7rem;
  }
}
@media (max-width: 744px) {
  .news-page__section_content .news-page__left {
    width: 50%;
    position: relative;
    bottom: unset;
  }
}
@media (max-width: 480px) {
  .news-page__section_content .news-page__left {
    width: 100%;
  }
}
@media (max-width: 744px) {
  .news-page__section_content .news-page__right {
    width: 50%;
    position: relative;
    bottom: unset;
    padding: 0;
  }
}
@media (max-width: 480px) {
  .news-page__section_content .news-page__right {
    width: 100%;
  }
}
@media (max-width: 480px) {
  .news-page__section_content .news-page__box {
    flex-wrap: wrap;
    row-gap: 2.4rem;
  }
}
.news-page__box {
  display: flex;
  position: relative;
}
.news-page__box_reverse {
  flex-direction: row-reverse;
}
.news-page__box_reverse .news-page__image {
  margin-left: auto;
}
@media (max-width: 744px) {
  .news-page__box_reverse .news-page__products {
    padding-left: 0;
    padding-right: 24rem;
  }
}
@media (max-width: 480px) {
  .news-page__box_reverse .news-page__products {
    padding: 0;
    padding-top: 40rem;
  }
}
@media (max-width: 744px) {
  .news-page__box_reverse .news-page__left {
    right: 0;
  }
}
.news-page__left {
  position: relative;
  width: 50%;
}
@media (max-width: 744px) {
  .news-page__left {
    position: absolute;
    width: 23rem;
    bottom: 0;
  }
}
@media (max-width: 480px) {
  .news-page__left {
    width: 100%;
    bottom: 35rem;
  }
}
.news-page__right {
  position: relative;
  width: 50%;
  display: flex;
  flex-direction: column;
  row-gap: 1.6rem;
}
@media (max-width: 744px) {
  .news-page__right {
    width: 100%;
  }
}
.news-page__image {
  border-radius: 2rem;
  overflow: hidden;
  width: 68.2rem;
  height: 92rem;
}
@media (max-width: 1200px) {
  .news-page__image {
    width: 46.4rem;
    height: 62.8rem;
  }
}
@media (max-width: 744px) {
  .news-page__image {
    width: 23rem;
    height: 38.5rem;
  }
}
@media (max-width: 480px) {
  .news-page__image {
    width: 35.8rem;
    height: 38.2rem;
  }
}
.news-page__image_certificate {
  height: 48rem;
}
@media (max-width: 1200px) {
  .news-page__image_certificate {
    height: 32rem;
  }
}
@media (max-width: 744px) {
  .news-page__image_certificate {
    height: 20.7rem;
  }
}
@media (max-width: 480px) {
  .news-page__image_certificate {
    display: none;
  }
}
@media (max-width: 1200px) {
  .news-page__image_height {
    height: 100%;
  }
  .news-page__image_height img {
    object-position: left;
  }
}
@media (max-width: 744px) {
  .news-page__image_height {
    height: 30rem;
  }
}
.news-page__image_mobile {
  display: none;
}
@media (max-width: 480px) {
  .news-page__image_mobile {
    display: block;
    position: relative;
    width: 100%;
    height: 20.7rem;
  }
}
.news-page__image img {
  display: block;
  width: 100%;
  height: 100%;
}
.news-page__title_fix {
  max-width: 55rem;
}
@media (max-width: 1200px) {
  .news-page__title_fix {
    max-width: 36rem;
  }
}
@media (max-width: 744px) {
  .news-page__title_fix {
    max-width: 31.6rem;
  }
}
@media (max-width: 1200px) {
  .news-page__title {
    font-size: 2rem;
  }
}
@media (max-width: 744px) {
  .news-page__title {
    font-size: 1.8rem;
  }
}
@media (max-width: 480px) {
  .news-page__title {
    font-size: 1.6rem;
  }
}
.news-page__text {
  max-width: 64rem;
}
.news-page__text_fix {
  max-width: 55rem;
}
@media (max-width: 744px) {
  .news-page__text_fix {
    max-width: 42.8rem;
  }
}
.news-page__products {
  margin-top: 1.6rem;
  display: flex;
  column-gap: 2rem;
}
@media (max-width: 744px) {
  .news-page__products {
    margin-top: 0.4rem;
    padding-left: 24rem;
    column-gap: 0.8rem;
  }
}
@media (max-width: 480px) {
  .news-page__products {
    padding: 0;
    padding-top: 40rem;
  }
  .news-page__products .block-catalog__card {
    height: 33rem;
    margin-right: 0;
  }
  .news-page__products .block-catalog__card-image {
    height: 23.2rem;
    width: 100%;
  }
}
.news-page__info {
  display: flex;
  flex-wrap: wrap;
  max-width: 76rem;
  justify-content: space-between;
  padding-right: 6rem;
  margin-top: 1.6rem;
}
@media (max-width: 744px) {
  .news-page__info {
    padding-right: 0;
    margin-top: 0;
  }
}
.news-page__info-col {
  display: flex;
  flex-direction: column;
  row-gap: 0.8rem;
  max-width: 23rem;
}
@media (max-width: 744px) {
  .news-page__info-col {
    max-width: 17rem;
  }
}
.news-page__info-col h1, .news-page__info-col h2, .news-page__info-col h3, .news-page__info-col h4, .news-page__info-col h5 {
  margin-bottom: 0.8rem;
}
.news-page__btn {
  margin-top: 4.4rem;
  max-width: 76rem;
}
@media (max-width: 744px) {
  .news-page__btn {
    margin-top: 4rem;
  }
}
@media (max-width: 480px) {
  .news-page__btn {
    margin-top: 0;
  }
}
.news-page__content {
  display: flex;
  flex-direction: column;
  row-gap: 1.6rem;
}
.news-page__content-title {
  max-width: 59.4rem;
  margin-bottom: 3.2rem;
  font-size: 3.6rem;
}
@media (max-width: 1200px) {
  .news-page__content-title {
    font-size: 2.8rem;
    max-width: 54rem;
  }
}
@media (max-width: 744px) {
  .news-page__content-title {
    font-size: 2.6rem;
    margin-bottom: 2.4rem;
  }
}
@media (max-width: 480px) {
  .news-page__content-title {
    font-size: 2rem;
  }
}
.news-page__content-subtitle {
  max-width: 51.4rem;
  margin-bottom: 1.2rem;
  font-size: 2.8rem;
}
@media (max-width: 1200px) {
  .news-page__content-subtitle {
    font-size: 2rem;
  }
}
@media (max-width: 744px) {
  .news-page__content-subtitle {
    font-size: 1.8rem;
    max-width: 24.4rem;
    margin-bottom: 0;
  }
}
@media (max-width: 480px) {
  .news-page__content-subtitle {
    font-size: 1.6rem;
  }
}
.news-page__content-text {
  max-width: 46.5rem;
}
@media (max-width: 744px) {
  .news-page__content-text {
    max-width: 31.8rem;
  }
}

.materials-page__section .container {
  max-width: 160.8rem;
}
.materials-page__section_wide .container {
  max-width: unset;
}
@media (max-width: 744px) {
  .materials-page__section_sizes .materials-page__right {
    align-content: end;
  }
}
.materials-page__section_sizes .materials-page__image {
  height: 55rem;
}
@media (max-width: 1200px) {
  .materials-page__section_sizes .materials-page__image {
    height: 43.1rem;
  }
}
@media (max-width: 744px) {
  .materials-page__section_sizes .materials-page__image {
    height: 19.2rem;
  }
}
.materials-page__box {
  display: flex;
  column-gap: 12rem;
  position: relative;
}
@media (max-width: 1200px) {
  .materials-page__box {
    column-gap: 11rem;
  }
}
@media (max-width: 744px) {
  .materials-page__box {
    column-gap: 5rem;
  }
}
@media (max-width: 480px) {
  .materials-page__box {
    flex-wrap: wrap;
    row-gap: 1.6rem;
  }
}
.materials-page__box_reverse {
  flex-direction: row-reverse;
  justify-content: flex-end;
}
@media (max-width: 480px) {
  .materials-page__box_reverse {
    flex-direction: row;
  }
  .materials-page__box_reverse:has(> .materials-page__right > .materials-page__image_wide) {
    flex-direction: row-reverse;
    flex-wrap: wrap-reverse;
  }
  .materials-page__box_reverse:has(> .materials-page__right_single) {
    flex-direction: row-reverse;
    flex-wrap: wrap-reverse;
  }
}
.materials-page__left {
  padding-top: 4rem;
  display: flex;
  flex-direction: column;
  row-gap: 1.6rem;
  position: relative;
}
@media (max-width: 1200px) {
  .materials-page__left {
    padding-top: 0;
    max-width: 46.4rem;
  }
  .materials-page__left:has(> .materials-page__text_item) {
    padding-bottom: 5.6rem;
  }
}
@media (max-width: 744px) {
  .materials-page__left {
    padding-bottom: 0;
    max-width: 33rem;
  }
}
@media (max-width: 480px) {
  .materials-page__left {
    max-width: unset;
  }
}
.materials-page__left_pt {
  padding-top: 7.6rem;
}
@media (max-width: 1200px) {
  .materials-page__left_pt {
    padding-top: 5.6rem;
  }
}
@media (max-width: 744px) {
  .materials-page__left_pt {
    padding-top: 2.4rem;
  }
}
@media (max-width: 480px) {
  .materials-page__left_pt {
    padding-top: 0rem;
  }
}
.materials-page__right {
  display: flex;
  column-gap: 2rem;
}
@media (max-width: 1200px) {
  .materials-page__right {
    gap: 1.6rem;
    flex-wrap: wrap;
  }
}
@media (max-width: 744px) {
  .materials-page__right {
    gap: 1rem;
    align-items: flex-start;
    align-content: baseline;
    max-width: 33rem;
  }
}
@media (max-width: 480px) {
  .materials-page__right {
    gap: 0.9rem;
    max-width: unset;
    width: 100%;
  }
}
@media (max-width: 744px) {
  .materials-page__right_single .materials-page__image:nth-of-type(1) {
    display: none;
  }
  .materials-page__right_single .materials-page__image:nth-of-type(2) {
    width: 29rem;
    height: 38.1rem;
  }
}
@media (max-width: 480px) {
  .materials-page__right_single .materials-page__image:nth-of-type(2) {
    width: 100%;
    height: 37.7rem;
  }
}
.materials-page__image {
  width: 44.8rem;
  height: 60rem;
  border-radius: 2rem;
  overflow: hidden;
}
@media (max-width: 1200px) {
  .materials-page__image {
    width: 27.2rem;
    height: 36.4rem;
  }
}
@media (max-width: 744px) {
  .materials-page__image {
    width: 16rem;
    height: 21.6rem;
  }
}
@media (max-width: 480px) {
  .materials-page__image {
    width: 17.4rem;
    height: 23.2rem;
  }
}
.materials-page__image_wide {
  width: 68rem;
  height: 44.2rem;
}
@media (max-width: 1200px) {
  .materials-page__image_wide {
    width: 51.2rem;
    height: 33.3rem;
  }
}
@media (max-width: 744px) {
  .materials-page__image_wide {
    width: 32rem;
    height: 20.8rem;
  }
}
@media (max-width: 480px) {
  .materials-page__image_wide {
    width: 100%;
    height: 23.3rem;
  }
}
.materials-page__image img {
  display: block;
  width: 100%;
  height: 100%;
}
.materials-page__title {
  margin-bottom: 1.6rem;
}
@media (max-width: 1200px) {
  .materials-page__title {
    font-size: 2.8rem;
  }
}
@media (max-width: 744px) {
  .materials-page__title {
    font-size: 2.6rem;
    margin-bottom: 0.8rem;
  }
}
@media (max-width: 480px) {
  .materials-page__title {
    font-size: 2rem;
  }
}
.materials-page__subtitle {
  margin-top: 2.4rem;
  margin-bottom: 0.8rem;
}
.materials-page__subtitle_auto {
  margin-top: auto;
}
@media (max-width: 1200px) {
  .materials-page__subtitle_auto {
    margin-top: 2.4rem;
  }
}
@media (max-width: 1200px) {
  .materials-page__subtitle {
    font-size: 2rem;
    margin-bottom: 0;
  }
}
@media (max-width: 744px) {
  .materials-page__subtitle {
    font-size: 1.8rem;
    margin-top: 1rem;
  }
}
@media (max-width: 480px) {
  .materials-page__subtitle {
    font-size: 1.6rem;
  }
}
.materials-page__text {
  max-width: 64.4rem;
}
.materials-page__text_item {
  margin-top: auto;
  max-width: 21rem;
}
@media (max-width: 1200px) {
  .materials-page__text_item {
    display: none;
  }
}
@media (max-width: 744px) {
  .materials-page__text_note {
    display: none;
  }
}
@media (max-width: 480px) {
  .materials-page__text_note {
    display: block;
    margin-top: -0.8rem;
    font-size: 1.2rem;
    max-width: 34rem;
  }
}
.materials-page__text_laptop {
  display: none;
}
@media (max-width: 1200px) {
  .materials-page__text_laptop {
    display: block;
    max-width: 21rem;
  }
}
@media (max-width: 744px) {
  .materials-page__text_laptop {
    max-width: 16rem;
  }
}
.materials-page__text_tablet {
  display: none;
}
@media (max-width: 744px) {
  .materials-page__text_tablet {
    display: block;
    margin-bottom: 2.4rem;
  }
}
@media (max-width: 480px) {
  .materials-page__text_tablet {
    display: none;
  }
}
.materials-page__btn {
  margin-top: 4.4rem;
  max-width: 30.8rem;
}
@media (max-width: 1200px) {
  .materials-page__btn {
    margin-top: auto;
  }
}
@media (max-width: 744px) {
  .materials-page__btn {
    display: none;
  }
}
.materials-page__btn_tablet {
  display: none;
}
@media (max-width: 744px) {
  .materials-page__btn_tablet {
    display: block;
    width: 100%;
    max-width: unset;
    margin-top: 2.4rem;
  }
}
@media (max-width: 480px) {
  .materials-page__btn_tablet {
    margin-top: 1.6rem;
  }
}
.materials-page__list {
  margin: 0;
  padding-left: 2.8rem;
  display: flex;
  flex-direction: column;
  row-gap: 2.4rem;
}
@media (max-width: 1200px) {
  .materials-page__list {
    row-gap: 1.6rem;
  }
}
@media (max-width: 480px) {
  .materials-page__list {
    row-gap: 0.8rem;
  }
}
.materials-page__content-box {
  display: flex;
}
@media (max-width: 480px) {
  .materials-page__content-box {
    flex-wrap: wrap;
    row-gap: 1.6rem;
  }
}
.materials-page__content-box_images {
  justify-content: space-between;
}
.materials-page__content-box_images .materials-page__content-col {
  width: 60.4rem;
}
@media (max-width: 1200px) {
  .materials-page__content-box_images .materials-page__content-col {
    width: 36.8rem;
  }
}
@media (max-width: 744px) {
  .materials-page__content-box_images .materials-page__content-col {
    width: 23rem;
  }
}
@media (max-width: 480px) {
  .materials-page__content-box_images .materials-page__content-col {
    width: 17.4rem;
  }
}
.materials-page__content-col {
  width: 50%;
  display: flex;
  flex-direction: column;
  row-gap: 1.6rem;
}
@media (max-width: 480px) {
  .materials-page__content-col {
    width: 100%;
  }
  .materials-page__content-col:nth-of-type(3n + 1) {
    display: none;
  }
}
.materials-page__content-title {
  max-width: 60rem;
  margin-bottom: 3.2rem;
}
@media (max-width: 1200px) {
  .materials-page__content-title {
    max-width: 49.4rem;
    font-size: 2.8rem;
  }
}
@media (max-width: 744px) {
  .materials-page__content-title {
    font-size: 2.6rem;
    margin-bottom: 2.4rem;
  }
}
@media (max-width: 480px) {
  .materials-page__content-title {
    font-size: 2rem;
  }
}
.materials-page__content-text {
  max-width: 60rem;
}
@media (max-width: 1200px) {
  .materials-page__content-text {
    max-width: 50rem;
  }
}
@media (max-width: 744px) {
  .materials-page__content-text {
    max-width: 33rem;
  }
}
.materials-page__content-text + .materials-page__content-subtitle {
  margin-top: 1.6rem;
}
@media (max-width: 744px) {
  .materials-page__content-text + .materials-page__content-subtitle {
    margin-top: 0;
  }
}
@media (max-width: 1200px) {
  .materials-page__content-subtitle {
    font-size: 1.8rem;
  }
}
@media (max-width: 744px) {
  .materials-page__content-subtitle {
    font-size: 1.6rem;
  }
}
@media (max-width: 480px) {
  .materials-page__content-subtitle {
    font-size: 1.6rem;
  }
}
.materials-page__content-image {
  width: 100%;
  height: 68rem;
}
@media (max-width: 1200px) {
  .materials-page__content-image {
    height: 41.7rem;
  }
}
@media (max-width: 744px) {
  .materials-page__content-image {
    height: 26.1rem;
  }
}
@media (max-width: 480px) {
  .materials-page__content-image {
    height: 19.8rem;
  }
}
.materials-page__content-image img {
  width: 100%;
  height: 100%;
}
.materials-page__block-banner .block-banner__box {
  border-radius: 2rem;
  overflow: hidden;
}
@media (max-width: 480px) {
  .materials-page__block-banner .block-banner__box {
    border-radius: 0;
  }
}
@media (max-width: 480px) {
  .materials-page__block-banner .container {
    padding: 0;
  }
}

.lookbook-page__box {
  display: flex;
  flex-direction: column;
  row-gap: 6.4rem;
}
@media (max-width: 1200px) {
  .lookbook-page__box {
    row-gap: 4.8rem;
  }
}
@media (max-width: 744px) {
  .lookbook-page__box {
    row-gap: 3.2rem;
  }
}
.lookbook-page__section {
  position: relative;
  display: flex;
  flex-wrap: wrap;
  gap: 6.4rem 2rem;
}
@media (max-width: 1200px) {
  .lookbook-page__section {
    gap: 4.8rem 1.6rem;
  }
}
@media (max-width: 744px) {
  .lookbook-page__section {
    gap: 3.2rem 1rem;
  }
}
@media (max-width: 480px) {
  .lookbook-page__section {
    gap: 1rem;
  }
}
@media (max-width: 480px) {
  .lookbook-page__section_third {
    justify-content: center;
  }
  .lookbook-page__section_third .lookbook-page__frame:nth-of-type(1) {
    width: 50%;
  }
}
.lookbook-page__frame {
  width: calc(50% - 1rem);
}
@media (max-width: 1200px) {
  .lookbook-page__frame {
    width: calc(50% - 0.8rem);
  }
}
@media (max-width: 744px) {
  .lookbook-page__frame {
    width: calc(50% - 0.5rem);
  }
}
@media (max-width: 480px) {
  .lookbook-page__frame {
    width: 100%;
  }
}
.lookbook-page__frame_third {
  width: calc(33.3333% - 1.4rem);
}
@media (max-width: 1200px) {
  .lookbook-page__frame_third {
    width: calc(33.3333% - 1.1rem);
  }
}
@media (max-width: 744px) {
  .lookbook-page__frame_third {
    width: calc(33.3333% - 0.7rem);
  }
}
@media (max-width: 480px) {
  .lookbook-page__frame_third {
    width: calc(50% - 0.5rem);
  }
}
.lookbook-page__frame_wide {
  width: calc(100% + 6.8rem);
  margin-left: -3.4rem;
  margin-right: -3.4rem;
}
@media (max-width: 744px) {
  .lookbook-page__frame_wide {
    width: calc(100% + 3.4rem);
    margin-left: -1.7rem;
    margin-right: -1.7rem;
  }
}
@media (max-width: 480px) {
  .lookbook-page__frame_wide {
    width: calc(100% + 1.8rem);
    margin-left: -0.9rem;
    margin-right: -0.9rem;
  }
}
.lookbook-page__frame_wide .lookbook-page__card {
  max-height: 111rem;
}
.lookbook-page__frame_wide .lookbook-page__image {
  border-radius: 0;
  max-height: 111rem;
}
.lookbook-page__frame_center {
  width: 100%;
}
.lookbook-page__frame_center .lookbook-page__card {
  width: fit-content;
  margin: 0 auto;
}
@media (max-width: 1200px) {
  .lookbook-page__frame_center .lookbook-page__card {
    max-width: 94.4rem;
  }
}
.lookbook-page__frame_fix .lookbook-page__card {
  max-width: 91.6rem;
}
@media (max-width: 1200px) {
  .lookbook-page__frame_fix .lookbook-page__card {
    max-width: 56rem;
  }
}
@media (max-width: 744px) {
  .lookbook-page__frame_fix .lookbook-page__card {
    max-width: 39rem;
  }
}
.lookbook-page__frame_text .lookbook-page__card {
  max-width: 60.4rem;
  flex-direction: column;
  row-gap: 1.6rem;
  margin: 0 auto;
}
@media (max-width: 1200px) {
  .lookbook-page__frame_text .lookbook-page__card {
    max-width: 46.4rem;
    margin-right: 0;
  }
}
@media (max-width: 744px) {
  .lookbook-page__frame_text .lookbook-page__card {
    max-width: 29rem;
    row-gap: 1.2rem;
  }
}
@media (max-width: 480px) {
  .lookbook-page__frame_text .lookbook-page__card {
    max-width: 26.6rem;
    margin-bottom: 2.4rem;
  }
}
.lookbook-page__frame_text .lookbook-page__image {
  margin-bottom: 1.6rem;
  max-height: 81.5rem;
}
@media (max-width: 1200px) {
  .lookbook-page__frame_text .lookbook-page__image {
    max-height: 60.8rem;
  }
}
@media (max-width: 744px) {
  .lookbook-page__frame_text .lookbook-page__image {
    max-height: 34.2rem;
    margin-bottom: 0.4rem;
  }
}
.lookbook-page__frame_right .lookbook-page__image {
  width: auto;
  margin-left: auto;
  max-height: 85.4rem;
}
@media (max-width: 1200px) {
  .lookbook-page__frame_right .lookbook-page__image {
    max-height: 69rem;
    max-width: 46.4rem;
  }
}
@media (max-width: 744px) {
  .lookbook-page__frame_right .lookbook-page__image {
    max-height: 43rem;
    max-width: 25rem;
  }
}
@media (max-width: 480px) {
  .lookbook-page__frame_right .lookbook-page__image {
    max-width: 26.6rem;
    max-height: 39rem;
  }
}
.lookbook-page__frame_left .lookbook-page__image {
  width: auto;
  margin-right: auto;
  max-height: 85.4rem;
}
@media (max-width: 1200px) {
  .lookbook-page__frame_left .lookbook-page__image {
    max-height: 69rem;
    max-width: 46.4rem;
  }
}
@media (max-width: 744px) {
  .lookbook-page__frame_left .lookbook-page__image {
    max-height: 43rem;
    max-width: 25rem;
  }
}
@media (max-width: 480px) {
  .lookbook-page__frame_left .lookbook-page__image {
    max-width: 26.6rem;
    max-height: 39rem;
  }
}
@media (max-width: 480px) {
  .lookbook-page__frame_small {
    width: calc(50% - 0.5rem);
  }
}
@media (max-width: 480px) {
  .lookbook-page__frame_small .lookbook-page__image {
    max-width: 17.4rem;
    max-height: 27.4rem;
  }
}
.lookbook-page__card {
  width: 100%;
  display: flex;
}
.lookbook-page__image {
  border-radius: 1.2rem;
  overflow: hidden;
  width: 100%;
  max-height: 127rem;
}
.lookbook-page__image img {
  width: 100%;
  height: 100%;
  display: block;
}
.lookbook-page__buttons {
  display: flex;
  justify-content: center;
  width: 100%;
}
.lookbook-page__btn {
  max-width: 30rem;
}
@media (max-width: 744px) {
  .lookbook-page__btn {
    max-width: unset;
  }
}
@media (max-width: 1200px) {
  .lookbook-page__title {
    font-size: 2.8rem;
  }
}
@media (max-width: 744px) {
  .lookbook-page__title {
    font-size: 2.6rem;
  }
}
@media (max-width: 480px) {
  .lookbook-page__title {
    font-size: 2rem;
  }
}

.vacancy .inner {
  margin-top: 6.4rem;
}
@media (max-width: 1200px) {
  .vacancy .inner {
    margin-top: 4rem;
  }
}
@media (max-width: 744px) {
  .vacancy .inner {
    margin-top: 4rem;
  }
}
@media (max-width: 480px) {
  .vacancy .inner {
    margin-top: 3.2rem;
  }
}
.vacancy__box {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  column-gap: 30rem;
  row-gap: 7.2rem;
}
@media (max-width: 1200px) {
  .vacancy__box {
    column-gap: 10rem;
    row-gap: 5.6rem;
  }
}
@media (max-width: 744px) {
  .vacancy__box {
    flex-wrap: wrap;
    row-gap: 4.8rem;
  }
}
@media (max-width: 480px) {
  .vacancy__box {
    row-gap: 4rem;
  }
}
.vacancy__left {
  width: 100%;
  max-width: 91.6rem;
}
@media (max-width: 1200px) {
  .vacancy__left {
    max-width: 56rem;
  }
}
@media (max-width: 744px) {
  .vacancy__left {
    width: 100%;
    max-width: unset;
  }
}
.vacancy__left_col {
  display: flex;
  flex-direction: column;
  row-gap: 2.4rem;
}
.vacancy__top {
  width: 100%;
  display: flex;
  column-gap: 2rem;
}
@media (max-width: 1200px) {
  .vacancy__top {
    column-gap: 1.6rem;
  }
}
@media (max-width: 744px) {
  .vacancy__top {
    column-gap: 1rem;
  }
}
@media (max-width: 480px) {
  .vacancy__top {
    flex-wrap: wrap;
    row-gap: 0.8rem;
  }
}
.vacancy__title {
  text-align: left;
}
@media (max-width: 1200px) {
  .vacancy__title {
    font-size: 2.8rem;
  }
}
@media (max-width: 744px) {
  .vacancy__title {
    font-size: 2.6rem;
  }
}
@media (max-width: 480px) {
  .vacancy__title {
    font-size: 2rem;
  }
}
.vacancy__accordions {
  display: flex;
  flex-direction: column;
  row-gap: 0.8rem;
}

.vacancy-form {
  width: 60.6rem;
  margin-top: 6rem;
  /* &__inner {
    display: grid;
    grid-template-columns: rem(916) rem(606);
    justify-content: space-between;

    @include laptop {
      grid-template-columns: rem(560) rem(368);
    }

    @include tablet {
     display: flex;
     flex-direction: column;
     gap: rem(40);
    }
  } */
}
@media (max-width: 1200px) {
  .vacancy-form {
    width: 36.8rem;
    margin-top: 5.2rem;
  }
}
@media (max-width: 744px) {
  .vacancy-form {
    width: 100%;
    margin-top: 0rem;
  }
}
.vacancy-form:has(.error-field) .vacancy-form__btn {
  user-select: none;
  pointer-events: none;
  opacity: 0.3;
}
.vacancy-form__title {
  text-align: left;
  max-width: 46.5rem;
  margin-bottom: 2.4rem;
}
@media (max-width: 1200px) {
  .vacancy-form__title {
    font-size: 1.8rem;
    max-width: 35rem;
  }
}
@media (max-width: 744px) {
  .vacancy-form__title {
    font-size: 1.6rem;
    max-width: 49.6rem;
  }
}
@media (max-width: 480px) {
  .vacancy-form__title {
    font-size: 1.6rem;
    max-width: 30.5rem;
  }
}
.vacancy-form__policy {
  margin-top: 1rem;
  color: var(--gray50);
}
.vacancy-form__policy a {
  color: inherit;
}
@media (max-width: 1200px) {
  .vacancy-form__policy {
    max-width: 36.7rem;
  }
}
@media (max-width: 744px) {
  .vacancy-form__policy {
    max-width: none;
  }
}
.vacancy-form__btn {
  width: 100%;
  margin-block: 2.4rem 1rem;
}
.vacancy-form__inputs {
  display: flex;
  flex-direction: column;
  gap: 0.8rem;
  margin-bottom: 2.4rem;
}
@media (max-width: 1200px) {
  .vacancy-form__inputs {
    margin-bottom: 1.6rem;
  }
}
@media (max-width: 480px) {
  .vacancy-form__inputs {
    margin-bottom: 2.4rem;
  }
}

.drop-video {
  margin-bottom: 1.6rem;
}
@media (max-width: 744px) {
  .drop-video {
    margin-bottom: 2.4rem;
  }
}
.drop-video__description {
  font-weight: 400;
  font-size: 1.4rem;
  line-height: 120%;
  letter-spacing: -0.01em;
  color: var(--black);
  margin-bottom: 0.8rem;
}
@media (max-width: 1200px) {
  .drop-video__description {
    font-size: 1.2rem;
  }
}
@media (max-width: 480px) {
  .drop-video__description {
    font-size: 1rem;
  }
}

.drag-drop {
  position: relative;
  overflow: hidden;
  height: 12.7rem;
  width: 100%;
  transition: border-style 0.3s ease;
}
.drag-drop__error {
  position: absolute;
  bottom: 1rem;
  left: 1rem;
}
.drag-drop__field {
  position: absolute;
  left: 0;
  top: 0;
  z-index: 100;
  width: 100%;
  height: 100%;
  transform: scale(20);
  letter-spacing: 10em;
  opacity: 0;
  cursor: pointer;
}
.drag-drop__field:user-invalid + .drag-drop__wrapper {
  border: 0.1rem solid var(--red);
  border-radius: 1rem;
}
.drag-drop__field:user-invalid + .drag-drop__wrapper > .input__error {
  display: block;
}
.drag-drop__wrapper {
  height: 100%;
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 1.5rem;
  border: 0.1rem dashed var(--black);
  border-radius: 1.2rem;
}
.drag-drop__icon {
  width: 2.4rem;
  height: 2.4rem;
}
.drag-drop__text {
  font-weight: 500;
  font-size: 1.4rem;
  line-height: 140%;
  letter-spacing: -0.01em;
  text-align: center;
  color: var(--gray70);
  max-width: 38rem;
}
@media (max-width: 1200px) {
  .drag-drop__text {
    max-width: 22rem;
    font-size: 1.2rem;
  }
}
@media (max-width: 480px) {
  .drag-drop__text {
    font-size: 1rem;
  }
}
@media (max-width: 650px) {
  .drag-drop__text_desktop {
    display: none;
  }
}
@media (min-width: 651px) {
  .drag-drop__text_mobile {
    display: none;
  }
}
.drag-drop__text span {
  display: block;
}
@media (max-width: 1440px) {
  .drag-drop__text span {
    display: inline-block;
  }
}
@media (max-width: 650px) {
  .drag-drop__text span {
    display: block;
  }
}

.drop-file {
  display: block;
  position: relative;
  margin-top: 0.8rem;
  padding: 0 4rem;
  height: 13rem;
  display: flex;
  flex-direction: column;
  justify-content: center;
  flex: 0 0 auto;
  border-radius: 1.2rem;
  background: #fff;
}
@media (max-width: 1200px) {
  .drop-file {
    margin-top: 1.6rem;
  }
}
@media (max-width: 744px) {
  .drop-file {
    margin-top: 0.8rem;
  }
}
@media (max-width: 480px) {
  .drop-file {
    height: 13.5rem;
    padding: 0 2.3rem;
  }
}
.drop-file__info {
  display: flex;
  align-items: start;
  gap: 1.6rem;
  margin-bottom: 1rem;
  padding-right: 7rem;
}
.drop-file__ext {
  position: relative;
  width: 2.4rem;
  height: 2.4rem;
  flex: 0 0 auto;
}
.drop-file__ext-img {
  width: 2.4rem;
  height: 2.4rem;
}
.drop-file__ext-text {
  position: absolute;
  top: 1.2rem;
  left: 0.2rem;
  color: #fff;
  font-size: 0.6rem;
  text-transform: uppercase;
}
@media (max-width: 1200px) {
  .drop-file__ext-text {
    top: 1.2rem;
  }
}
.drop-file__name-block {
  flex: 1 1 auto;
  width: 100%;
}
.drop-file__name {
  font-weight: 500;
  font-size: 1.6rem;
  line-height: 140%;
  letter-spacing: -0.01em;
  color: var(--gray70);
  margin-bottom: 0.4rem;
  display: flex;
}
@media (max-width: 1200px) {
  .drop-file__name {
    font-size: 1.4rem;
  }
}
@media (max-width: 744px) {
  .drop-file__name {
    font-size: 1.6rem;
  }
}
@media (max-width: 480px) {
  .drop-file__name {
    font-size: 1.2rem;
  }
}
.drop-file__name span {
  display: inline-block;
  text-wrap: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;
}
.drop-file__name span:last-child {
  flex: 0 0 auto;
}
.drop-file__size {
  font-weight: 400;
  line-height: 120%;
  letter-spacing: -0.04em;
  color: var(--gray60);
  font-size: 1.4rem;
}
@media (max-width: 1200px) {
  .drop-file__size {
    font-size: 1.2rem;
  }
}
@media (max-width: 744px) {
  .drop-file__size {
    font-size: 1.4rem;
  }
}
@media (max-width: 480px) {
  .drop-file__size {
    font-size: 1rem;
  }
}
.drop-file__close-block {
  cursor: pointer;
  position: absolute;
  right: 4rem;
  width: 2.4rem;
  height: 2.4rem;
}
@media (max-width: 480px) {
  .drop-file__close-block {
    right: 2.3rem;
  }
}
.drop-file__close {
  width: 100%;
  height: 100%;
}
.drop-file__line-block {
  display: flex;
  align-items: center;
  gap: 1.2rem;
  width: 100%;
}
.drop-file__line {
  position: relative;
  --width: 40%;
  flex: 1 1 auto;
  height: 0.4rem;
  border-radius: 0.4rem;
  background: #f8f8ed;
  overflow: hidden;
}
.drop-file__line::after {
  content: "";
  position: absolute;
  left: 0;
  height: 100%;
  width: var(--width);
  background: #a5c2b6;
  border-radius: 0.4rem;
  transition: width 0.2s linear;
}
.drop-file__line-count {
  font-weight: 500;
  font-size: 1.2rem;
  line-height: 110%;
  color: var(--black);
  width: 3.5rem;
  text-align: right;
}
@media (max-width: 1200px) {
  .drop-file__line-count {
    font-size: 1.4rem;
    width: 4rem;
  }
}
@media (max-width: 744px) {
  .drop-file__line-count {
    font-size: 1.2rem;
    width: 3.5rem;
  }
}
.drop-file__watch {
  text-decoration: none;
  margin-top: 1rem;
  font-weight: 500;
  font-size: 1.4rem;
  line-height: 120%;
  letter-spacing: -0.01em;
  color: var(--grey60);
  display: none;
}
@media (max-width: 1200px) {
  .drop-file__watch {
    margin-top: 0.8rem;
    font-size: 1.2rem;
  }
}
@media (max-width: 480px) {
  .drop-file__watch {
    font-size: 1rem;
  }
}
.drop-file__another {
  margin-top: 1.6rem;
  font-weight: 400;
  font-size: 1.4rem;
  line-height: 140%;
  letter-spacing: -0.01em;
  text-transform: uppercase;
  color: var(--black);
}
@media (max-width: 1200px) {
  .drop-file__another {
    font-size: 1.2rem;
  }
}
.drop-file .js-file-count,
.drop-file .js-file-now {
  font-variant-numeric: tabular-nums;
}

.disabled_drop {
  pointer-events: none;
  user-select: none;
  opacity: 0.4;
}

.drop-file-error .drop-file__line-block {
  visibility: collapse;
  display: none;
}
.drop-file-error .drop-file__name {
  color: var(--red);
}
.drop-file-error .drop-file__size {
  color: var(--red);
}
.drop-file-error .drop-file__another {
  color: var(--red);
}

.drop-file-ex .drop-file__watch {
  display: block;
}
.drop-file-ex .drop-file__close {
  opacity: 0.9;
}
.drop-file-ex .drop-file__line::after {
  background: #50c878;
}
.drop-file-ex .drop-file__name {
  color: var(--black);
}
.drop-file-ex .drop-file__size {
  color: var(--gray90);
}
.drop-file-ex .drop-file__another {
  color: var(--red);
}

.profile__inner {
  display: grid;
  grid-template-columns: 37rem 60.5rem;
  gap: 25.4rem;
}
@media (max-width: 1200px) {
  .profile__inner {
    grid-template-columns: 27rem 42.6rem;
    gap: 11.4rem;
  }
}
@media (max-width: 744px) {
  .profile__inner {
    grid-template-columns: 27rem 1fr;
    gap: 1rem;
  }
}
@media (max-width: 480px) {
  .profile__inner {
    display: flex;
    flex-direction: column;
  }
}
@media (max-width: 1200px) {
  .profile__inner .care-menu__title {
    font-size: 2rem;
  }
}
@media (max-width: 744px) {
  .profile__inner .care-menu__title {
    font-size: 1.8rem;
  }
}
@media (max-width: 480px) {
  .profile__inner .care-menu__title {
    font-size: 2.2rem;
  }
}
.profile__inner .care-menu {
  width: 37rem;
}
@media (max-width: 1200px) {
  .profile__inner .care-menu {
    width: 27rem;
  }
}
@media (max-width: 744px) {
  .profile__inner .care-menu {
    width: 27rem;
  }
}
@media (max-width: 480px) {
  .profile__inner .care-menu {
    width: 100%;
    display: block;
    margin-bottom: -1rem;
    z-index: 10;
  }
}
@media (max-width: 480px) {
  .profile__inner .care-menu__nav {
    display: none;
  }
}
@media (max-width: 480px) {
  .profile__inner .care-menu__sticky {
    position: static;
  }
}
.profile__inner_full-screen {
  grid-template-columns: 37rem 123.2rem;
  justify-content: space-between;
  gap: 2rem;
}
@media (max-width: 1200px) {
  .profile__inner_full-screen {
    grid-template-columns: 27rem 75.2rem;
    gap: 2rem;
  }
}
@media (max-width: 744px) {
  .profile__inner_full-screen {
    grid-template-columns: 20.7rem 1fr;
    gap: 3.3rem;
  }
  .profile__inner_full-screen .care-menu {
    width: 21.7rem;
  }
}
@media (max-width: 480px) {
  .profile__inner_full-screen {
    display: flex;
    flex-direction: column;
    justify-content: start;
    gap: 0;
  }
  .profile__inner_full-screen .care-menu {
    width: 100%;
  }
}

.profile-main__tabs {
  margin-bottom: 4rem;
}
@media (max-width: 1200px) {
  .profile-main__tabs {
    margin-bottom: 3.5rem;
  }
}
@media (max-width: 744px) {
  .profile-main__tabs {
    margin-bottom: 3.2rem;
  }
}
.profile-main__body:not(.tab-box-active) {
  display: none;
}
.profile-main__body-info {
  display: flex;
  flex-direction: column;
  gap: 3.2rem;
}
@media (max-width: 1200px) {
  .profile-main__body-info {
    gap: 2.4rem;
  }
}
@media (max-width: 1200px) {
  .profile-main__field .input-sub {
    font-size: 1.2rem;
  }
  .profile-main__field .input {
    font-size: 1.2rem;
  }
}
@media (max-width: 744px) {
  .profile-main__field .input-title_big {
    font-size: 1.2rem;
  }
}
.profile-main__delete {
  font-size: 1.8rem;
  font-style: normal;
  font-weight: 400;
  line-height: 140%;
  text-decoration: underline;
  text-decoration-skip-ink: none;
  color: var(--gray40);
  text-align: left;
  max-width: max-content;
}
@media (max-width: 1200px) {
  .profile-main__delete {
    font-size: 1.4rem;
  }
}
@media (max-width: 480px) {
  .profile-main__delete {
    font-size: 1.2rem;
  }
}
.profile-main__addresses {
  display: flex;
  flex-direction: column;
  gap: 2.4rem;
}
@media (max-width: 1200px) {
  .profile-main__addresses {
    gap: 1.6rem;
  }
}
@media (max-width: 480px) {
  .profile-main__addresses {
    gap: 2.4rem;
  }
}
.profile-main__address {
  display: grid;
  grid-template-columns: 1fr 14rem;
  gap: 6.5rem;
  align-items: start;
  border-radius: 1.2rem;
  padding: 1.6rem 2rem;
  border: 0.1rem solid var(--gray30);
}
@media (max-width: 1200px) {
  .profile-main__address {
    grid-template-columns: 1fr 12rem;
    gap: 1.5rem;
  }
}
@media (max-width: 744px) {
  .profile-main__address {
    gap: 2.5rem;
  }
}
@media (max-width: 480px) {
  .profile-main__address {
    grid-template-columns: 1fr 6rem;
    gap: 2.2rem;
  }
}
.profile-main__address-left {
  display: flex;
  flex-direction: column;
  gap: 1.6rem;
}
.profile-main__address-text {
  font-size: 1.6rem;
  font-style: normal;
  font-weight: 500;
  line-height: 140%;
  color: var(--black);
}
@media (max-width: 1200px) {
  .profile-main__address-text {
    font-size: 1.4rem;
  }
}
@media (max-width: 744px) {
  .profile-main__address-text {
    font-size: 1.2rem;
  }
}
.profile-main__address-city {
  font-size: 1.4rem;
  font-style: normal;
  font-weight: 400;
  line-height: 140%;
  color: var(--gray60);
}
@media (max-width: 1200px) {
  .profile-main__address-city {
    font-size: 1.2rem;
  }
}
@media (max-width: 480px) {
  .profile-main__address-city {
    font-size: 1rem;
  }
}
.profile-main__address-changer {
  font-size: 1.2rem;
  font-style: normal;
  font-weight: 500;
  line-height: 130%;
  color: var(--gray40);
  text-transform: uppercase;
  text-decoration: underline;
  text-decoration-skip-ink: none;
}
@media (max-width: 1200px) {
  .profile-main__address-changer {
    font-size: 1rem;
  }
}
@media (max-width: 480px) {
  .profile-main__address-changer span {
    display: none;
  }
}
.profile-main__address-changer svg {
  display: none;
}
@media (max-width: 480px) {
  .profile-main__address-changer svg {
    width: 2rem;
    height: 2rem;
    display: block;
  }
}
.profile-main__address-right {
  display: flex;
  gap: 1.6rem;
}
@media (max-width: 1200px) {
  .profile-main__address-right {
    justify-content: space-between;
  }
}
@media (max-width: 480px) {
  .profile-main__address-right {
    gap: 2rem;
  }
}
.profile-main__address-add {
  margin-top: 4rem;
  width: 100%;
}
@media (max-width: 1200px) {
  .profile-main__address-add {
    margin-top: 2.4rem;
  }
}
@media (max-width: 480px) {
  .profile-main__address-add {
    margin-top: 3.2rem;
  }
}
.profile-main__address-empty {
  color: var(--black);
  font-size: 1.8rem;
  font-style: normal;
  font-weight: 500;
  line-height: 140%;
}
@media (max-width: 1200px) {
  .profile-main__address-empty {
    font-size: 1.6rem;
  }
}
@media (max-width: 744px) {
  .profile-main__address-empty {
    font-size: 1.4rem;
  }
}
@media (max-width: 480px) {
  .profile-main__address-empty {
    font-size: 1.2rem;
  }
}

.profile-config__heading {
  text-align: left;
  margin-bottom: 3.2rem;
}
@media (max-width: 1200px) {
  .profile-config__heading {
    max-width: 41.6rem;
    font-size: 1.8rem;
  }
}
@media (max-width: 744px) {
  .profile-config__heading {
    font-size: 1.6rem;
    margin-bottom: 2.4rem;
    max-width: 30rem;
  }
}
@media (max-width: 480px) {
  .profile-config__heading {
    font-size: 1.6rem;
    max-width: 25rem;
    margin-bottom: 1.6rem;
  }
}
.profile-config__body {
  display: flex;
  flex-direction: column;
  gap: 2rem;
}
@media (max-width: 744px) {
  .profile-config__body {
    gap: 1.2rem;
  }
}

@media (max-width: 744px) {
  .profile-loyalty {
    max-width: 35rem;
  }
}
@media (max-width: 480px) {
  .profile-loyalty {
    max-width: none;
  }
}
.profile-loyalty__card {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  padding: 3.2rem;
  width: 100%;
  height: 34rem;
  border-radius: 2rem;
  background: linear-gradient(96deg, #98b8ad 0%, #b3cdbf 50.65%, #a9bfb3 101.3%);
}
@media (max-width: 1200px) {
  .profile-loyalty__card {
    height: 26rem;
    padding: 2.4rem;
    border-radius: 1.4rem;
  }
}
@media (max-width: 744px) {
  .profile-loyalty__card {
    height: 19.6rem;
  }
}
@media (max-width: 480px) {
  .profile-loyalty__card {
    height: 20rem;
  }
}
.profile-loyalty__date {
  font-size: 1.6rem;
  font-style: normal;
  font-weight: 500;
  line-height: 140%;
  color: var(--black);
}
.profile-loyalty__date span {
  display: block;
}
@media (max-width: 1200px) {
  .profile-loyalty__date {
    font-size: 1.4rem;
  }
}
@media (max-width: 744px) {
  .profile-loyalty__date {
    font-size: 1.2rem;
  }
}
.profile-loyalty__info {
  display: flex;
  flex-direction: column;
  gap: 1.6rem;
}
@media (max-width: 1200px) {
  .profile-loyalty__info {
    gap: 0.8rem;
  }
}
.profile-loyalty__discount {
  font-family: "Stapel Expanded", serif;
  font-size: 7.2rem;
  font-style: normal;
  font-weight: 400;
  line-height: 80%;
  color: var(--white);
}
@media (max-width: 1200px) {
  .profile-loyalty__discount {
    font-size: 4.8rem;
  }
}
@media (max-width: 744px) {
  .profile-loyalty__discount {
    font-size: 3.2rem;
  }
}
.profile-loyalty__remains {
  font-size: 1.6rem;
  font-style: normal;
  font-weight: 500;
  line-height: 140%;
  color: var(--white);
}
@media (max-width: 1200px) {
  .profile-loyalty__remains {
    font-size: 1.4rem;
  }
}
@media (max-width: 744px) {
  .profile-loyalty__remains {
    font-size: 1.2rem;
  }
}
.profile-loyalty__grid {
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  gap: 2rem;
}
.profile-loyalty__description {
  margin-block: 4rem;
  font-size: 1.6rem;
  font-style: normal;
  font-weight: 400;
  line-height: 140%;
  color: var(--gray60);
}
@media (max-width: 1200px) {
  .profile-loyalty__description {
    margin-block: 2.4rem;
    grid-template-columns: 31rem;
    gap: 0.8rem;
    font-size: 1.4rem;
  }
}
@media (max-width: 744px) {
  .profile-loyalty__description {
    grid-template-columns: 26rem;
    font-size: 1.2rem;
  }
}
@media (max-width: 480px) {
  .profile-loyalty__description {
    grid-template-columns: 31rem;
    gap: 1.6rem;
  }
}
@media (max-width: 744px) {
  .profile-loyalty__btns {
    grid-template-columns: 1fr;
    gap: 0.8rem;
  }
}

.profile-history__tabs {
  display: flex;
  gap: 1.6rem;
  margin-bottom: 4rem;
}
@media (max-width: 1200px) {
  .profile-history__tabs {
    gap: 1rem;
    margin-bottom: 3.2rem;
  }
}
@media (max-width: 744px) {
  .profile-history__tabs {
    justify-content: space-between;
    overflow-x: auto;
    padding-bottom: 1rem;
    margin-bottom: 1.2rem;
    width: 48.7rem;
    margin-right: -1.7rem;
    padding-right: 1.7rem;
    gap: 0.8rem;
  }
}
@media (max-width: 480px) {
  .profile-history__tabs {
    width: 100vw;
    gap: 0.6rem;
    margin-bottom: 2.2rem;
  }
}
.profile-history__tab {
  user-select: none;
  color: var(--gray50);
  font-size: 1.4rem;
  font-style: normal;
  font-weight: 400;
  line-height: 140%;
  cursor: pointer;
  padding: 0.8rem 1.2rem;
  border-radius: 0.8rem;
  border: 0.1rem solid var(--gray00);
  transition: all 0.3s ease;
}
.profile-history__tab:has(.profile-history__tab-radio:checked) {
  background: var(--gray10);
  border-color: transparent;
  color: var(--black);
}
@media (max-width: 744px) {
  .profile-history__tab {
    padding: 0.6rem 1rem;
    flex: 0 0 auto;
    font-size: 1.2rem;
  }
}
.profile-history__tab-radio {
  display: none;
}

.profile-history-item {
  display: flex;
  height: 25.7rem;
  padding-block: 4rem;
  border-bottom: 0.1rem solid var(--gray20);
}
@media (max-width: 1200px) {
  .profile-history-item {
    height: 21.1rem;
    padding-block: 3.2rem;
  }
}
@media (max-width: 744px) {
  .profile-history-item {
    height: 22.2rem;
    padding-block: 2.8rem;
    position: relative;
  }
}
@media (max-width: 480px) {
  .profile-history-item {
    flex-direction: column;
    height: 21.1rem;
    padding-block: 2rem;
  }
}
.profile-history-item:first-child {
  border-top: 0.1rem solid var(--gray20);
}
.profile-history-item__text {
  font-size: 1.6rem;
  font-style: normal;
  font-weight: 400;
  line-height: 140%;
  color: var(--black);
}
@media (max-width: 1200px) {
  .profile-history-item__text {
    font-size: 1.4rem;
  }
}
@media (max-width: 744px) {
  .profile-history-item__text {
    font-size: 1.2rem;
  }
}
.profile-history-item__subtitle {
  font-size: 1.6rem;
  font-style: normal;
  font-weight: 400;
  line-height: 140%;
  color: var(--gray50);
}
@media (max-width: 1200px) {
  .profile-history-item__subtitle {
    font-size: 1.4rem;
  }
}
@media (max-width: 744px) {
  .profile-history-item__subtitle {
    font-size: 1.2rem;
  }
}
.profile-history-item__order {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  gap: 2rem;
  width: 26.6rem;
  margin-right: 4.6rem;
}
@media (max-width: 1200px) {
  .profile-history-item__order {
    width: 17.6rem;
    margin-right: 1.6rem;
  }
}
@media (max-width: 744px) {
  .profile-history-item__order {
    width: 16.8rem;
    margin-right: 1.3rem;
  }
}
@media (max-width: 480px) {
  .profile-history-item__order {
    position: absolute;
    inset: 2rem 0;
    width: 100%;
    margin-right: 0;
  }
}
@media (max-width: 480px) {
  .profile-history-item__order-top {
    display: flex;
    flex-direction: row-reverse;
    justify-content: space-between;
    align-items: center;
  }
}
.profile-history-item__order-num {
  font-size: 1.6rem;
  font-style: normal;
  font-weight: 400;
  line-height: 140%;
  color: var(--white);
  padding: 0.4rem 1.6rem;
  border-radius: 9rem;
  background: var(--sageDark);
  max-width: max-content;
  margin-bottom: 1.6rem;
}
@media (max-width: 1200px) {
  .profile-history-item__order-num {
    font-size: 1.4rem;
    margin-bottom: 0.8rem;
  }
}
@media (max-width: 744px) {
  .profile-history-item__order-num {
    font-size: 1.2rem;
  }
}
@media (max-width: 480px) {
  .profile-history-item__order-num {
    margin-bottom: 0;
  }
}
.profile-history-item__order-date {
  font-size: 1.6rem;
  font-style: normal;
  font-weight: 400;
  line-height: 140%;
  color: var(--black);
}
@media (max-width: 1200px) {
  .profile-history-item__order-date {
    font-size: 1.4rem;
  }
}
@media (max-width: 744px) {
  .profile-history-item__order-date {
    font-size: 1.2rem;
  }
}
.profile-history-item__order-price {
  margin-bottom: 0.3rem;
}
@media (max-width: 480px) {
  .profile-history-item__order-price {
    margin-bottom: 0;
  }
}
.profile-history-item__order-price-sum {
  font-size: 1.8rem;
  font-style: normal;
  font-weight: 500;
  line-height: 140%;
  color: var(--black);
}
@media (max-width: 1200px) {
  .profile-history-item__order-price-sum {
    font-size: 1.6rem;
  }
}
@media (max-width: 744px) {
  .profile-history-item__order-price-sum {
    font-size: 1.4rem;
  }
}
@media (max-width: 480px) {
  .profile-history-item__order-price-sum {
    font-size: 1.2rem;
  }
}
.profile-history-item__status {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  gap: 2rem;
  width: 12rem;
  margin-right: 19.2rem;
}
@media (max-width: 1200px) {
  .profile-history-item__status {
    width: 10.5rem;
    margin-right: auto;
  }
}
@media (max-width: 744px) {
  .profile-history-item__status {
    width: 8.9rem;
  }
}
@media (max-width: 480px) {
  .profile-history-item__status {
    margin-right: 0;
    margin-left: auto;
    text-align: right;
    padding-top: 4rem;
    gap: 1.2rem;
  }
}
.profile-history-item__products {
  width: 39.6rem;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}
@media (max-width: 1200px) {
  .profile-history-item__products {
    width: 24.4rem;
  }
}
@media (max-width: 744px) {
  .profile-history-item__products {
    position: absolute;
    right: 4.5rem;
    height: 10.9rem;
    text-align: right;
  }
}
@media (max-width: 480px) {
  .profile-history-item__products {
    top: 6rem;
    right: auto;
    left: 0;
    text-align: left;
    width: 18.6rem;
    gap: 1.2rem;
    height: 8.2rem;
  }
}
.profile-history-item__more {
  width: 11.9rem;
  margin-left: auto;
  align-self: end;
  display: flex;
  align-items: center;
  gap: 0.8rem;
  font-size: 1.4rem;
  font-style: normal;
  font-weight: 400;
  line-height: 110%;
  text-transform: uppercase;
  text-decoration: none;
  color: var(--black);
}
@media (max-width: 1200px) {
  .profile-history-item__more {
    width: 10.7rem;
    font-size: 1.2rem;
  }
}
@media (max-width: 480px) {
  .profile-history-item__more {
    position: absolute;
    z-index: 10;
    right: 0;
    bottom: 2rem;
  }
}
.profile-history-item__more-img {
  flex: 0 0 auto;
  width: 2.4rem;
  height: 2.4rem;
}
.profile-history-item__products-imgs {
  display: flex;
  align-items: center;
  justify-content: space-between;
  gap: 1.2rem;
}
@media (max-width: 1200px) {
  .profile-history-item__products-imgs {
    gap: 0.6rem;
  }
}
.profile-history-item__products-img {
  width: 9rem;
  height: 11.8rem;
  border-radius: 1.2rem;
  flex: 0 0 auto;
}
@media (max-width: 1200px) {
  .profile-history-item__products-img {
    width: 5.6rem;
    height: 7.2rem;
  }
}
@media (max-width: 480px) {
  .profile-history-item__products-img {
    width: 4.2rem;
    height: 5.4rem;
    border-radius: 0.4rem;
  }
}
.profile-history-item__products-link {
  flex: 0 0 auto;
  width: 9rem;
  height: 11.8rem;
  padding: 1rem;
  border-radius: 1.2rem;
  display: grid;
  place-content: center;
  border: 0.1rem solid var(--gray20);
  color: var(--gray30);
  text-align: center;
  font-size: 1.6rem;
  font-style: normal;
  font-weight: 400;
  line-height: 140%;
  letter-spacing: -0.48px;
  text-decoration: none;
}
@media (max-width: 1200px) {
  .profile-history-item__products-link {
    width: 5.6rem;
    height: 7.2rem;
    font-size: 1.4rem;
  }
}
@media (max-width: 480px) {
  .profile-history-item__products-link {
    width: 4.2rem;
    height: 5.4rem;
    border-radius: 0.4rem;
    font-size: 1.2rem;
  }
}

.profile-history-empty__text {
  margin: 0;
  margin-bottom: 4rem;
}
@media (max-width: 1200px) {
  .profile-history-empty__text {
    margin-bottom: 2.4rem;
  }
}
@media (max-width: 744px) {
  .profile-history-empty__text {
    font-size: 1.4rem;
  }
}
@media (max-width: 480px) {
  .profile-history-empty__text {
    font-size: 1.2rem;
    margin-bottom: 1.6rem;
  }
}
.profile-history-empty__link {
  display: grid;
  width: 30rem;
  height: 5.6rem;
  text-decoration: none;
  place-content: center;
  border-radius: 1.2rem;
  text-transform: uppercase;
}
@media (max-width: 1200px) {
  .profile-history-empty__link {
    height: 4.4rem;
  }
}
@media (max-width: 744px) {
  .profile-history-empty__link {
    width: 23rem;
  }
}
@media (max-width: 480px) {
  .profile-history-empty__link {
    width: 22rem;
  }
}

.profile-order__wrapper {
  padding: 7.8rem;
  border-radius: 2.4rem;
  border: 0.1rem solid var(--gray20);
}
@media (max-width: 1200px) {
  .profile-order__wrapper {
    padding: 4.8rem;
  }
}
@media (max-width: 744px) {
  .profile-order__wrapper {
    padding: 3rem;
    border-radius: 1.8rem;
  }
}
@media (max-width: 480px) {
  .profile-order__wrapper {
    padding: 0;
    border-radius: 0;
    border: none;
  }
}
.profile-order__item {
  padding-bottom: 5.6rem;
  border-bottom: 0.1rem solid var(--gray20);
  margin-bottom: 5.6rem;
}
@media (max-width: 1200px) {
  .profile-order__item {
    padding-bottom: 3.2rem;
    margin-bottom: 3.2rem;
  }
}
@media (max-width: 744px) {
  .profile-order__item {
    padding-bottom: 2.4rem;
    margin-bottom: 2.4rem;
  }
}
@media (max-width: 480px) {
  .profile-order__item {
    padding-bottom: 3.1rem;
    margin-bottom: 3.1rem;
  }
}
.profile-order__headline {
  margin-bottom: 4rem;
}
@media (max-width: 1200px) {
  .profile-order__headline {
    font-size: 2rem;
    margin-bottom: 2.4rem;
  }
}
@media (max-width: 744px) {
  .profile-order__headline {
    font-size: 1.8rem;
    margin-bottom: 1.6rem;
  }
}
@media (max-width: 480px) {
  .profile-order__headline {
    font-size: 1.6rem;
  }
}
.profile-order__info-list {
  display: flex;
  flex-direction: column;
  gap: 2.4rem;
}
@media (max-width: 1200px) {
  .profile-order__info-list {
    gap: 1.6rem;
  }
}
@media (max-width: 744px) {
  .profile-order__info-list {
    gap: 0.8rem;
  }
}
.profile-order__info-item {
  display: grid;
  grid-template-columns: 1fr minmax(auto, 51.3rem);
  gap: 1rem;
}
@media (max-width: 1200px) {
  .profile-order__info-item {
    grid-template-columns: 1fr minmax(auto, 35.8rem);
  }
}
@media (max-width: 744px) {
  .profile-order__info-item {
    grid-template-columns: 1fr minmax(auto, 23.6rem);
  }
}
@media (max-width: 744px) {
  .profile-order__info-name, .profile-order__info-text {
    font-size: 1.4rem;
  }
}
@media (max-width: 480px) {
  .profile-order__info-name, .profile-order__info-text {
    font-size: 1.2rem;
  }
}
.profile-order__info-name {
  color: var(--gray60);
}
.profile-order__info-text {
  text-align: right;
}
.profile-order__info-text_balance {
  text-wrap: balance;
}
.profile-order__structure-item {
  display: flex;
  justify-content: space-between;
  align-items: start;
  gap: 2rem;
  padding-bottom: 3.2rem;
  border-bottom: 0.1rem solid var(--gray10);
  margin-bottom: 3.2rem;
}
@media (max-width: 1200px) {
  .profile-order__structure-item {
    padding-bottom: 2.4rem;
    margin-bottom: 2.4rem;
  }
}
@media (max-width: 480px) {
  .profile-order__structure-item {
    padding-bottom: 1.6rem;
    margin-bottom: 1.6rem;
  }
}
@media (max-width: 480px) {
  .profile-order__structure-item {
    position: relative;
  }
}
.profile-order__structure-left {
  display: flex;
  align-items: stretch;
  gap: 2rem;
}
@media (max-width: 1200px) {
  .profile-order__structure-left {
    gap: 1.6rem;
  }
}
@media (max-width: 744px) {
  .profile-order__structure-left {
    gap: 1rem;
  }
}
@media (max-width: 480px) {
  .profile-order__structure-left {
    justify-content: space-between;
  }
}
.profile-order__structure-img-wrapper {
  width: 13.6rem;
  height: 18.3rem;
  border-radius: 1rem;
  overflow: hidden;
  flex: 0 0 auto;
}
@media (max-width: 1200px) {
  .profile-order__structure-img-wrapper {
    width: 12.8rem;
    height: 17rem;
  }
}
@media (max-width: 744px) {
  .profile-order__structure-img-wrapper {
    width: 8rem;
    height: 10.7rem;
  }
}
@media (max-width: 480px) {
  .profile-order__structure-img-wrapper {
    width: 7.6rem;
    height: 10rem;
  }
}
.profile-order__structure-img {
  width: 13.6rem;
  height: 18.3rem;
  border-radius: 1rem;
  overflow: hidden;
}
@media (max-width: 1200px) {
  .profile-order__structure-img {
    width: 12.8rem;
    height: 17rem;
  }
}
@media (max-width: 744px) {
  .profile-order__structure-img {
    width: 8rem;
    height: 10.7rem;
  }
}
@media (max-width: 480px) {
  .profile-order__structure-img {
    width: 7.6rem;
    height: 10rem;
  }
}
.profile-order__structure-info {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  gap: 2rem;
  max-width: 32.1rem;
}
@media (max-width: 1200px) {
  .profile-order__structure-info {
    max-width: 21.8rem;
  }
}
@media (max-width: 744px) {
  .profile-order__structure-info {
    max-width: 17.6rem;
  }
}
@media (max-width: 480px) {
  .profile-order__structure-info {
    max-width: 20rem;
  }
}
.profile-order__structure-top {
  display: flex;
  flex-direction: column;
  gap: 1.2rem;
}
@media (max-width: 1200px) {
  .profile-order__structure-top {
    gap: 0.5rem;
  }
}
@media (max-width: 480px) {
  .profile-order__structure-top {
    gap: 0.2rem;
  }
}
.profile-order__structure-article {
  color: var(--gray40);
}
.profile-order__structure-name {
  text-align: left;
}
@media (max-width: 1200px) {
  .profile-order__structure-name {
    font-size: 1.8rem;
  }
}
@media (max-width: 744px) {
  .profile-order__structure-name {
    font-size: 1.6rem;
  }
}
.profile-order__structure-right {
  padding-top: 2.8rem;
}
@media (max-width: 1200px) {
  .profile-order__structure-right {
    padding-top: 1.8rem;
  }
}
@media (max-width: 480px) {
  .profile-order__structure-right {
    position: absolute;
    right: 0;
    bottom: 1.6rem;
    padding-top: 0;
    width: 12.1rem;
    flex: 0 0 auto;
    text-align: right;
    display: flex;
    flex-direction: column-reverse;
  }
}
.profile-order__structure-price-old {
  text-align: right;
  color: var(--gray40);
}
.profile-order__structure-btns {
  margin-top: 5.6rem;
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  gap: 2rem;
}
@media (max-width: 1200px) {
  .profile-order__structure-btns {
    margin-top: 0;
  }
}
@media (max-width: 744px) {
  .profile-order__structure-btns {
    grid-template-columns: 1fr;
    gap: 1.2rem;
  }
  .profile-order__structure-btns .btn:not(.profile-order__structure-gone) {
    display: grid;
    place-content: center;
  }
}
@media (max-width: 480px) {
  .profile-order__structure-btns {
    margin-top: 3.2rem;
    gap: 0.8rem;
  }
}
.profile-order__structure-gone-block {
  display: flex;
  flex-direction: column;
  gap: 0.8rem;
}
.profile-order__structure-gone-block + .btn {
  height: 5.8rem;
}
@media (max-width: 1200px) {
  .profile-order__structure-gone-block + .btn {
    height: 4.6rem;
  }
}
.profile-order__structure-gone {
  width: 100%;
  text-transform: none;
  font-size: 1.6rem;
  font-weight: 500;
  height: 5.8rem;
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 0.8rem;
}
@media (max-width: 1200px) {
  .profile-order__structure-gone {
    gap: 0.7rem;
    font-size: 1.4rem;
    padding: 1.1rem 2rem;
    height: 4.6rem;
  }
}
@media (max-width: 480px) {
  .profile-order__structure-gone {
    font-size: 1.3rem;
  }
}
.profile-order__structure-gone_img {
  width: 9rem;
  height: 2.5rem;
}
@media (max-width: 1200px) {
  .profile-order__structure-gone_img {
    width: 7.5rem;
    height: 2.1rem;
  }
}
@media (max-width: 480px) {
  .profile-order__structure-gone_img {
    width: 6.2rem;
    height: auto;
  }
}
.profile-order__structure-gone_more {
  font-size: 1.4rem;
  font-style: normal;
  font-weight: 400;
  line-height: 140%;
  text-decoration-line: underline;
  text-decoration-style: solid;
  text-decoration-skip-ink: none;
  color: var(--gray50);
}
@media (max-width: 1200px) {
  .profile-order__structure-gone_more {
    font-size: 1.2rem;
  }
}
@media (max-width: 744px) {
  .profile-order__structure-gone_more {
    display: none;
  }
}

.profile-order-head {
  display: flex;
  justify-content: space-between;
  align-items: center;
  gap: 2rem;
  margin-bottom: 5.6rem;
}
@media (max-width: 1200px) {
  .profile-order-head {
    margin-bottom: 4rem;
  }
}
@media (max-width: 744px) {
  .profile-order-head {
    margin-bottom: 3.2rem;
    align-items: end;
  }
}
.profile-order-head__left {
  display: flex;
  align-items: center;
  gap: 5rem;
}
@media (max-width: 1200px) {
  .profile-order-head__left {
    gap: 2.8rem;
  }
}
@media (max-width: 744px) {
  .profile-order-head__left {
    flex-direction: column;
    align-items: start;
    gap: 2rem;
  }
}
.profile-order-head__back {
  display: flex;
  align-items: center;
  color: var(--black);
  font-size: 1.2rem;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
  text-transform: uppercase;
  text-decoration: none;
}
@media (max-width: 1200px) {
  .profile-order-head__back {
    font-size: 1rem;
  }
}
@media (max-width: 480px) {
  .profile-order-head__back {
    display: none;
  }
}
.profile-order-head__back-arrow {
  width: 2.4rem;
  height: 2.4rem;
}
@media (max-width: 1200px) {
  .profile-order-head__back-arrow {
    width: 2rem;
    height: 2rem;
  }
}
.profile-order-head__num {
  color: var(--black);
  font-family: "Stapel";
  font-size: 2.8rem;
  font-style: normal;
  font-weight: 400;
  line-height: 110%;
}
@media (max-width: 1200px) {
  .profile-order-head__num {
    font-size: 2rem;
  }
}
@media (max-width: 744px) {
  .profile-order-head__num {
    font-size: 1.8rem;
  }
}
@media (max-width: 480px) {
  .profile-order-head__num {
    font-size: 1.6rem;
  }
}
.profile-order-head__right {
  display: flex;
  align-items: center;
  gap: 3.4rem;
}
@media (max-width: 1200px) {
  .profile-order-head__right {
    gap: 6.2rem;
  }
}
@media (max-width: 744px) {
  .profile-order-head__right {
    flex-direction: column;
    justify-content: space-between;
    align-items: end;
    gap: 1.5rem;
  }
}
.profile-order-head__cancel-btn {
  display: flex;
  align-items: center;
  gap: 0.8rem;
  font-size: 1.2rem;
  font-style: normal;
  font-weight: 500;
  text-transform: uppercase;
  line-height: normal;
  color: var(--black);
}
@media (max-width: 1200px) {
  .profile-order-head__cancel-btn {
    font-size: 1rem;
    gap: 0.4rem;
  }
}
@media (max-width: 480px) {
  .profile-order-head__cancel-btn {
    display: none;
  }
}
.profile-order-head__cancel-img {
  width: 2.4rem;
  height: 2.4rem;
}
@media (max-width: 1200px) {
  .profile-order-head__cancel-img {
    width: 2rem;
    height: 2rem;
  }
}
.profile-order-head__status {
  color: var(--white);
  font-size: 1.4rem;
  font-style: normal;
  font-weight: 400;
  line-height: 140%;
  padding: 0.4rem 1.6rem;
  border-radius: 9rem;
  background: var(--sageDark);
}
@media (max-width: 1200px) {
  .profile-order-head__status {
    font-size: 1.2rem;
  }
}
@media (max-width: 480px) {
  .profile-order-head__status {
    font-size: 1rem;
  }
}

.profile-gift__tab {
  margin-top: 4.8rem;
  display: flex;
  flex-direction: column;
}
@media (max-width: 1200px) {
  .profile-gift__tab {
    margin-top: 3.2rem;
  }
}
@media (max-width: 744px) {
  .profile-gift__tab {
    margin-top: 2.4rem;
  }
}
.profile-gift__box {
  display: flex;
  flex-direction: column;
  row-gap: 3.2rem;
  padding: 4.8rem 0;
  border-top: 0.1rem solid var(--gray20);
  border-bottom: 0.1rem solid var(--gray20);
}
@media (max-width: 1200px) {
  .profile-gift__box {
    row-gap: 2.4rem;
    padding: 3.2rem 0;
  }
}
@media (max-width: 744px) {
  .profile-gift__box {
    row-gap: 1.6rem;
    padding: 2.4rem 0;
  }
}
.profile-gift__box + .profile-gift__box {
  border-top: none;
}
.profile-gift__box + .profile-gift__note {
  margin-top: 3.2rem;
}
@media (max-width: 1200px) {
  .profile-gift__box + .profile-gift__note {
    margin-top: 2.4rem;
  }
}
@media (max-width: 744px) {
  .profile-gift__box + .profile-gift__note {
    margin-top: 1.6rem;
  }
}
.profile-gift__box_none {
  padding: 0;
  border: none;
}
.profile-gift__flex {
  display: flex;
  justify-content: space-between;
}
.profile-gift__block {
  display: flex;
  flex-direction: column;
  row-gap: 1.6rem;
  justify-content: flex-end;
}
.profile-gift__note {
  max-width: 36rem;
  margin-top: -0.8rem;
}
@media (max-width: 1200px) {
  .profile-gift__note {
    max-width: 32rem;
    margin-bottom: 0.8rem;
  }
}
@media (max-width: 744px) {
  .profile-gift__note {
    margin-top: 0;
    margin-bottom: 1.4rem;
  }
}
@media (max-width: 480px) {
  .profile-gift__note {
    max-width: 24rem;
  }
}
.profile-gift__title {
  max-width: 40rem;
}
@media (max-width: 480px) {
  .profile-gift__title {
    max-width: 26rem;
  }
}

@media (max-width: 744px) {
  .profile-favourite {
    max-width: 35rem;
    margin-left: auto;
  }
}
.profile-favourite__top {
  display: flex;
  flex-direction: column;
  row-gap: 0.8rem;
  margin-bottom: 2.4rem;
}
@media (max-width: 1200px) {
  .profile-favourite__top {
    margin-bottom: 1.6rem;
  }
}
.profile-favourite__tabs {
  display: flex;
  gap: 1.6rem;
  padding-top: 3.2rem;
  margin-bottom: 4rem;
  border-top: 0.1rem solid var(--gray20);
}
@media (max-width: 1200px) {
  .profile-favourite__tabs {
    gap: 1rem;
    margin-bottom: 3.2rem;
  }
}
@media (max-width: 744px) {
  .profile-favourite__tabs {
    overflow-x: auto;
    padding-bottom: 1rem;
    margin-bottom: 1.2rem;
    margin-right: -1.7rem;
    padding-right: 1.7rem;
    gap: 0.8rem;
    padding-top: 2.4rem;
  }
}
@media (max-width: 480px) {
  .profile-favourite__tabs {
    gap: 0.6rem;
    margin-bottom: 2.2rem;
    padding-top: 2rem;
  }
}
.profile-favourite__tab {
  user-select: none;
  color: var(--gray50);
  font-size: 1.4rem;
  font-style: normal;
  font-weight: 400;
  line-height: 140%;
  cursor: pointer;
  padding: 0.8rem 1.2rem;
  border-radius: 0.8rem;
  border: 0.1rem solid var(--gray00);
  transition: all 0.3s ease;
}
.profile-favourite__tab:has(.profile-favourite__tab-radio:checked) {
  background: var(--gray10);
  border-color: transparent;
  color: var(--black);
}
@media (max-width: 744px) {
  .profile-favourite__tab {
    padding: 0.6rem 1rem;
    flex: 0 0 auto;
    font-size: 1.2rem;
  }
}
.profile-favourite__tab-radio {
  display: none;
}
.profile-favourite__banner {
  display: none !important;
  position: relative;
  display: flex;
  align-items: center;
  margin-bottom: 4.8rem;
  background: var(--white);
  padding: 3.2rem;
  border-radius: 2rem;
  border: 0.1rem solid var(--gray10);
  overflow: hidden;
  height: 8.9rem;
  transition-duration: 0.3s;
}
@media (max-width: 1200px) {
  .profile-favourite__banner {
    margin-bottom: 3.2rem;
    border-radius: 1.8rem;
  }
}
@media (max-width: 744px) {
  .profile-favourite__banner {
    padding: 2rem;
    height: 8rem;
    border-radius: 1.4rem;
  }
}
@media (max-width: 480px) {
  .profile-favourite__banner {
    padding: 2rem 1.2rem;
    margin-bottom: 2.4rem;
    margin-top: 1.6rem;
    height: 5.7rem;
    border-radius: 1.2rem;
  }
}
.profile-favourite__banner_open {
  height: 11.9rem;
}
.profile-favourite__banner_open .profile-favourite__banner-inner {
  row-gap: 0.8rem;
}
.profile-favourite__banner_open .profile-favourite__banner-image {
  opacity: 1;
}
.profile-favourite__banner_open .profile-favourite__banner-text {
  opacity: 1;
  height: auto;
}
.profile-favourite__banner_open .profile-favourite__banner-link span {
  margin-top: 0;
}
.profile-favourite__banner_open .profile-favourite__banner-link span:nth-last-of-type(1) {
  display: none;
}
.profile-favourite__banner_open .profile-favourite__banner-link span:nth-last-of-type(2) {
  display: block;
}
.profile-favourite__banner_open .profile-favourite__banner-link img {
  transform: scaleY(-1);
}
.profile-favourite__banner-inner {
  display: flex;
  flex-direction: column;
  row-gap: 0rem;
}
@media (max-width: 1200px) {
  .profile-favourite__banner-inner {
    max-width: 33rem;
  }
}
@media (max-width: 744px) {
  .profile-favourite__banner-inner {
    max-width: 17rem;
  }
}
@media (max-width: 480px) {
  .profile-favourite__banner-inner {
    max-width: 25.2rem;
  }
}
.profile-favourite__banner-title {
  display: flex;
  align-items: center;
  column-gap: 0.4rem;
}
@media (max-width: 744px) {
  .profile-favourite__banner-title {
    display: block;
  }
}
.profile-favourite__banner-title img {
  width: 2.4rem;
  height: 2.4rem;
}
@media (max-width: 744px) {
  .profile-favourite__banner-title img {
    display: inline-block;
    width: 2rem;
    height: 2rem;
    margin-bottom: -0.4rem;
  }
}
.profile-favourite__banner-text {
  opacity: 0;
  height: 0;
  transition-duration: 0.3s;
}
@media (max-width: 480px) {
  .profile-favourite__banner-text {
    max-width: 21rem;
  }
}
@media (max-width: 1200px) {
  .profile-favourite__title {
    font-size: 2rem;
  }
}
@media (max-width: 744px) {
  .profile-favourite__title {
    font-size: 1.8rem;
  }
}
@media (max-width: 480px) {
  .profile-favourite__title {
    font-size: 1.8rem;
  }
}
.profile-favourite__banner-image {
  position: absolute;
  right: 12rem;
  top: 0.5rem;
  transform: rotate(10deg);
  width: 32.2rem;
  height: 21rem;
  transition-delay: 0.2s;
  transition-duration: 0.3s;
  opacity: 0;
}
@media (max-width: 1200px) {
  .profile-favourite__banner-image {
    width: 28rem;
    height: 19rem;
    right: 8rem;
  }
}
@media (max-width: 744px) {
  .profile-favourite__banner-image {
    width: 20rem;
    height: 12rem;
    right: -5rem;
    top: 7rem;
  }
}
@media (max-width: 480px) {
  .profile-favourite__banner-image {
    width: 16rem;
    height: 9rem;
    top: 7rem;
    right: -2rem;
  }
}
.profile-favourite__banner-link {
  display: flex;
  align-items: center;
  column-gap: 0.4rem;
  text-transform: uppercase;
  position: absolute;
  top: 3.2rem;
  right: 3.2rem;
}
@media (max-width: 1200px) {
  .profile-favourite__banner-link {
    top: 3.4rem;
  }
}
@media (max-width: 744px) {
  .profile-favourite__banner-link {
    top: 2rem;
    right: 2rem;
  }
}
@media (max-width: 480px) {
  .profile-favourite__banner-link {
    top: 2rem;
    right: 1.2rem;
  }
}
.profile-favourite__banner-link span {
  margin-top: 0.2rem;
}
.profile-favourite__banner-link span:nth-last-of-type(1) {
  display: block;
}
.profile-favourite__banner-link span:nth-last-of-type(2) {
  display: none;
}
.profile-favourite__banner-link img {
  width: 2rem;
  height: 2rem;
  opacity: 0.6;
  transition-duration: 0.3s;
  transform: scaleY(1);
}
.profile-favourite__box {
  margin-top: 3.2rem;
  display: flex;
  gap: 2rem;
  flex-wrap: wrap;
}
@media (max-width: 1200px) {
  .profile-favourite__box {
    gap: 1.6rem;
    margin-top: 2.4rem;
  }
}
@media (max-width: 744px) {
  .profile-favourite__box {
    gap: 2rem 1rem;
    margin-top: 2.4rem;
  }
}
@media (max-width: 480px) {
  .profile-favourite__box {
    gap: 1.6rem 1rem;
    margin-top: 1.6rem;
  }
}
.profile-favourite__box .block-product__card {
  width: 29.2rem;
  position: relative;
}
@media (max-width: 1200px) {
  .profile-favourite__box .block-product__card {
    width: 17.6rem;
  }
}
@media (max-width: 744px) {
  .profile-favourite__box .block-product__card {
    width: 17rem;
  }
}
@media (max-width: 480px) {
  .profile-favourite__box .block-product__card {
    width: 16.7rem;
  }
}
.profile-favourite__box .block-product__card-cover {
  width: 29.2rem;
}
@media (max-width: 1200px) {
  .profile-favourite__box .block-product__card-cover {
    width: 17.6rem;
  }
}
@media (max-width: 744px) {
  .profile-favourite__box .block-product__card-cover {
    width: 17rem;
  }
}
@media (max-width: 480px) {
  .profile-favourite__box .block-product__card-cover {
    width: 16.7rem;
  }
}
.profile-favourite__box .block-product__card-favourite {
  position: absolute;
  top: 1.6rem;
  right: 1.6rem;
  width: 2.4rem;
  height: 2.4rem;
}
@media (max-width: 1200px) {
  .profile-favourite__box .block-product__card-favourite {
    top: 1.2rem;
    right: 1.2rem;
  }
}
.profile-favourite__box .block-product__card-favourite img {
  width: 100%;
  height: 100%;
}

.block-catalog__top {
  position: relative;
  text-decoration: none;
  display: block;
}
.block-catalog__top .block-catalog__arrow {
  display: none;
}
@media (max-width: 480px) {
  .block-catalog__top {
    display: flex;
    justify-content: space-between;
    align-items: center;
  }
  .block-catalog__top .block-catalog__arrow {
    display: block;
  }
}
@media (max-width: 480px) {
  .block-catalog__top:has(> .block-catalog__filters_aside) {
    display: flex;
    flex-wrap: wrap;
  }
}
@media (max-width: 1200px) {
  .block-catalog__title {
    font-size: 2.8rem;
  }
}
@media (max-width: 744px) {
  .block-catalog__title {
    font-size: 2.6rem;
  }
}
@media (max-width: 480px) {
  .block-catalog__title {
    font-size: 2rem;
  }
}
.block-catalog__box {
  margin-bottom: 3.2rem;
  display: flex;
  flex-wrap: wrap;
  gap: 4rem 2rem;
}
@media (max-width: 1200px) {
  .block-catalog__box {
    gap: 3.2rem 1.6rem;
  }
}
@media (max-width: 744px) {
  .block-catalog__box {
    gap: 2.4rem 1rem;
  }
}
@media (max-width: 480px) {
  .block-catalog__box {
    gap: unset;
    flex-wrap: unset;
    margin-bottom: unset;
  }
}
@media (max-width: 480px) {
  .block-catalog__box_wrap {
    flex-wrap: wrap;
    gap: 3.2rem 1rem;
  }
}
.block-catalog__card {
  width: 44.8rem;
  height: 74rem;
  display: flex;
  flex-direction: column;
  row-gap: 2.4rem;
}
@media (max-width: 1200px) {
  .block-catalog__card {
    width: 27.2rem;
    height: 46.9rem;
    row-gap: 2rem;
  }
}
@media (max-width: 744px) {
  .block-catalog__card {
    width: 23rem;
    height: 38.6rem;
    row-gap: 1rem;
  }
}
@media (max-width: 480px) {
  .block-catalog__card {
    margin-right: 0.8rem;
  }
}
.block-catalog__card_slide {
  margin-right: 2rem;
}
@media (max-width: 1200px) {
  .block-catalog__card_slide {
    margin-right: 1.6rem;
  }
}
@media (max-width: 744px) {
  .block-catalog__card_slide {
    margin-right: 1rem;
  }
}
.block-catalog__card_slide:nth-last-of-type(1) {
  margin-right: 0;
}
@media (max-width: 480px) {
  .block-catalog__card_mobile {
    width: 17.4rem;
    height: 31.4rem;
    margin-right: 0rem;
  }
  .block-catalog__card_mobile .block-catalog__card-image {
    height: 23.2rem;
  }
  .block-catalog__card_mobile .block-catalog__card-title {
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
    margin: auto 0;
  }
  .block-catalog__card_mobile .block-catalog__card-button {
    border-radius: 0.4rem;
    margin-top: unset;
  }
  .block-catalog__card_mobile .block-catalog__card-icon {
    width: 1.6rem;
    height: 1.6rem;
  }
}
@media (min-width: 768px) {
  .block-catalog__card:hover .block-catalog__card-button {
    background: var(--gray00);
    padding: 0.8rem 1.2rem;
  }
}
@media (min-width: 768px) and (max-width: 1200px) {
  .block-catalog__card:hover .block-catalog__card-button {
    padding: 0.4rem 0.8rem;
  }
}
@media (min-width: 768px) {
  .block-catalog__card:hover .block-catalog__card-button .block-catalog__card-icon {
    display: block;
  }
  .block-catalog__card:hover .block-catalog__card-image .block-catalog__card-cover_1 {
    display: none;
  }
  .block-catalog__card:hover .block-catalog__card-image .block-catalog__card-cover_2 {
    display: block;
  }
}
.block-catalog__card-image {
  width: 100%;
  height: 60rem;
  overflow: hidden;
  flex-shrink: 0;
  position: relative;
  display: flex;
  flex-direction: column;
}
@media (max-width: 1200px) {
  .block-catalog__card-image {
    height: 36.4rem;
  }
}
@media (max-width: 744px) {
  .block-catalog__card-image {
    height: 30rem;
  }
}
.block-catalog__card-image img {
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
}
.block-catalog__card-cover_2 {
  display: none;
}
.block-catalog__card-info {
  display: flex;
  flex-direction: column;
  row-gap: 1rem;
  flex-grow: 1;
}
@media (max-width: 1200px) {
  .block-catalog__card-info {
    row-gap: 0.8rem;
  }
}
@media (max-width: 744px) {
  .block-catalog__card-info {
    row-gap: 0.4rem;
  }
}
.block-catalog__card-colors {
  display: flex;
  align-items: center;
  column-gap: 0.6rem;
}
@media (max-width: 1200px) {
  .block-catalog__card-colors {
    column-gap: 0.4rem;
  }
}
@media (max-width: 480px) {
  .block-catalog__card-colors * {
    font-size: 1rem;
  }
}
.block-catalog__card-colors div {
  margin-left: 0.4rem;
}
@media (max-width: 1200px) {
  .block-catalog__card-colors div {
    margin-left: 0.8rem;
  }
}
@media (max-width: 744px) {
  .block-catalog__card-colors div {
    margin-left: 0.4rem;
  }
}
.block-catalog__card-label {
  cursor: pointer;
  width: 1.6rem;
  height: 1.6rem;
  border: 0.1rem solid var(--gray20);
  border-radius: 10rem;
  flex-shrink: 0;
  display: block;
  background: var(--white);
  position: relative;
}
@media (max-width: 1200px) {
  .block-catalog__card-label {
    width: 1.2rem;
    height: 1.2rem;
  }
}
@media (max-width: 744px) {
  .block-catalog__card-label {
    width: 1rem;
    height: 1rem;
  }
}
.block-catalog__card-label:has(input:checked) {
  margin-right: 0.4rem;
  margin-left: 0.4rem;
}
.block-catalog__card-label:has(input:checked)::before {
  display: block;
}
@media (max-width: 1200px) {
  .block-catalog__card-label:has(input:checked) {
    margin-right: 0.3rem;
    margin-left: 0.3rem;
  }
}
.block-catalog__card-label::before {
  content: "";
  transition-duration: 0.1s;
  display: none;
  position: absolute;
  width: 2.4rem;
  height: 2.4rem;
  border: 0.1rem solid var(--black);
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  border-radius: 10rem;
}
@media (max-width: 1200px) {
  .block-catalog__card-label::before {
    width: 1.8rem;
    height: 1.8rem;
  }
}
@media (max-width: 744px) {
  .block-catalog__card-label::before {
    width: 1.4rem;
    height: 1.4rem;
  }
}
.block-catalog__card-radio {
  position: absolute;
  width: 1.6rem;
  height: 1.6rem;
  opacity: 0;
  pointer-events: none;
}
.block-catalog__card-title {
  margin-top: auto;
}
@media (max-width: 1200px) {
  .block-catalog__card-title {
    font-size: 1.4rem;
  }
}
@media (max-width: 744px) {
  .block-catalog__card-title {
    font-size: 1.2rem;
    margin-top: unset;
  }
}
@media (max-width: 480px) {
  .block-catalog__card-title {
    margin-top: 0.2rem;
  }
}
.block-catalog__card-button {
  cursor: pointer;
  padding: 0.8rem 0;
  display: flex;
  align-items: flex-end;
  column-gap: 0.8rem;
  border-radius: 1rem;
  margin-right: auto;
}
.block-catalog__card-button * {
  pointer-events: none;
}
@media (max-width: 1200px) {
  .block-catalog__card-button {
    padding: 0.4rem 0;
    margin-top: -0.3rem;
  }
}
@media (max-width: 744px) {
  .block-catalog__card-button {
    background: var(--gray00);
    padding: 0.4rem 0.8rem;
    margin-top: auto;
    align-items: center;
  }
  .block-catalog__card-button .t-secodary-s-line {
    margin-top: 0.2rem;
  }
}
@media (max-width: 480px) {
  .block-catalog__card-button {
    margin-top: 0.4rem;
  }
}
.block-catalog__card-icon {
  display: none;
  width: 2.4rem;
  height: 2.4rem;
}
@media (max-width: 1200px) {
  .block-catalog__card-icon {
    width: 2.2rem;
    height: 2.2rem;
  }
}
@media (max-width: 744px) {
  .block-catalog__card-icon {
    display: block;
  }
}
.block-catalog__sizes {
  position: absolute;
  bottom: 2.4rem;
  left: 2.4rem;
  width: calc(100% - 4.8rem);
  background: var(--whiteBlur);
  backdrop-filter: blur(1px);
  border-radius: 1.6rem;
  padding: 2.4rem 3rem;
  display: none;
  box-sizing: border-box;
}
@media (max-width: 1200px) {
  .block-catalog__sizes {
    bottom: 1.6rem;
    left: 1.6rem;
    width: calc(100% - 3.2rem);
    padding: 1.6rem;
  }
}
.block-catalog__sizes_open {
  display: block;
}
@media (max-width: 744px) {
  .block-catalog__sizes_open {
    display: none;
  }
}
.block-catalog__sizes-close {
  width: 2.4rem;
  height: 2.4rem;
  position: absolute;
  top: 2.4rem;
  right: 3rem;
  cursor: pointer;
  opacity: 0.7;
}
@media (max-width: 1200px) {
  .block-catalog__sizes-close {
    top: 1.6rem;
    right: 1.6rem;
  }
}
.block-catalog__sizes-close img {
  width: 100%;
  height: 100%;
}
.block-catalog__sizes-table {
  margin-top: 1.4rem;
  display: flex;
  flex-wrap: wrap;
  gap: 1rem 2rem;
}
@media (max-width: 1200px) {
  .block-catalog__sizes-table {
    gap: 0.8rem 2.3rem;
  }
}
.block-catalog__sizes-label {
  cursor: pointer;
  opacity: 0.4;
  padding: 0.5rem 0;
  text-align: center;
  width: 5.2rem;
  border-radius: 1rem;
  user-select: none;
}
@media (max-width: 1200px) {
  .block-catalog__sizes-label {
    padding: 0.6rem 0;
  }
}
.block-catalog__sizes-label:has(input:checked) {
  opacity: 1;
  background: var(--white);
}
.block-catalog__link {
  text-transform: uppercase;
  position: absolute;
  bottom: 0;
  right: 0;
  /* &_mobile{
      display: none;
      position: relative;
      border: none;
      right: unset;
      bottom: unset;
      margin-right: unset;
      padding: unset;
      line-height: 1;
      @include mobile{
          display: block;
      }
  } */
}
@media (max-width: 480px) {
  .block-catalog__link {
    display: none;
  }
}
.block-catalog__swiper {
  margin-top: 4rem;
}
@media (max-width: 1200px) {
  .block-catalog__swiper {
    margin-top: 2.4rem;
  }
}
@media (max-width: 480px) {
  .block-catalog__swiper {
    margin-top: 1.6rem;
    width: calc(100% + 0.9rem);
    margin-right: -0.9rem;
  }
}
.block-catalog__swiper-navigation {
  position: absolute;
  left: 0;
  top: 0;
  display: flex;
}
@media (max-width: 480px) {
  .block-catalog__swiper-navigation {
    display: none;
  }
}
.block-catalog__swiper-prev, .block-catalog__swiper-next {
  width: 2.4rem;
  height: 2.4rem;
  cursor: pointer;
}
.block-catalog__swiper-prev.swiper-button-disabled, .block-catalog__swiper-next.swiper-button-disabled {
  opacity: 0.5;
}
.block-catalog__swiper-prev img, .block-catalog__swiper-next img {
  width: 100%;
  height: 100%;
}
@media (max-width: 480px) {
  .block-catalog__btn {
    display: none;
  }
}
.block-catalog__filters {
  display: flex;
  justify-content: space-between;
}
.block-catalog__filters_aside {
  position: absolute;
  right: 0;
  top: 0;
}
.block-catalog__filters_aside .block-catalog__filters-form {
  width: auto;
  gap: 0.8rem;
}
.block-catalog__filters_aside .block-catalog__filters-check {
  font-size: 1.4rem;
  padding: 0.8rem 1.2rem;
  line-height: 1;
  border: 0.1rem solid var(--gray00);
}
@media (max-width: 1200px) {
  .block-catalog__filters_aside .block-catalog__filters-check {
    padding: 0.6rem 1.2rem;
    line-height: 1.3;
  }
}
@media (max-width: 744px) {
  .block-catalog__filters_aside .block-catalog__filters-check {
    padding: 0.7rem 1.2rem;
  }
}
@media (max-width: 480px) {
  .block-catalog__filters_aside .block-catalog__filters-check {
    padding: 0.9rem 1.2rem;
  }
}
@media (max-width: 744px) {
  .block-catalog__filters_aside {
    position: relative;
    margin-top: 2.4rem;
    justify-content: flex-end;
  }
  .block-catalog__filters_aside .block-catalog__filters-form .block-catalog__filters-check {
    display: flex;
    font-size: 1.2rem;
  }
}
@media (max-width: 480px) {
  .block-catalog__filters_aside {
    justify-content: flex-start;
    margin-top: 1.6rem;
  }
  .block-catalog__filters_aside .block-catalog__filters-form .block-catalog__filters-check {
    display: flex;
    font-size: 1rem;
  }
}
.block-catalog__filters-form {
  display: flex;
  flex-wrap: wrap;
  gap: 1rem;
  width: calc(100% - 32rem);
}
@media (max-width: 1200px) {
  .block-catalog__filters-form {
    gap: 0.8rem;
  }
}
@media (max-width: 744px) {
  .block-catalog__filters-form {
    width: 100%;
    justify-content: space-between;
  }
  .block-catalog__filters-form .select_drop {
    display: none;
  }
  .block-catalog__filters-form .select_drop:nth-of-type(1) {
    display: block;
  }
  .block-catalog__filters-form .select_drop:nth-of-type(1) .select__value {
    font-size: 1.2rem;
  }
  .block-catalog__filters-form .block-catalog__filters-check {
    display: none;
  }
  .block-catalog__filters-form .block-catalog__filters-clear {
    margin-left: auto;
  }
}
@media (max-width: 480px) {
  .block-catalog__filters-form {
    flex-wrap: nowrap;
    gap: 0rem;
  }
}
.block-catalog__filters-box {
  display: flex;
  flex-wrap: wrap;
  gap: 1rem;
}
@media (max-width: 1200px) {
  .block-catalog__filters-box {
    gap: 0.8rem;
  }
}
.block-catalog__filters-clear {
  column-gap: 1rem;
  padding: 0.9rem 1.6rem;
  display: flex;
  align-items: center;
  flex-shrink: 0;
  cursor: pointer;
}
@media (max-width: 1200px) {
  .block-catalog__filters-clear {
    padding: 0.7rem 1.2rem;
  }
}
.block-catalog__filters-clear * {
  color: var(--gray60);
}
.block-catalog__filters-clear img {
  width: 2.4rem;
  height: 2.4rem;
  opacity: 0.6;
}
@media (max-width: 1200px) {
  .block-catalog__filters-clear img {
    width: 2rem;
    height: 2rem;
  }
}
.block-catalog__filters-count {
  color: var(--gray50);
  height: 4.4rem;
  display: flex;
  align-items: center;
}
@media (max-width: 1200px) {
  .block-catalog__filters-count {
    height: 3.4rem;
  }
}
@media (max-width: 744px) {
  .block-catalog__filters-count {
    display: none;
  }
}
.block-catalog__filters-check {
  width: auto;
  display: flex;
  align-items: center;
  font-size: 1.6rem;
  padding: 1.1rem 1.6rem;
  cursor: pointer;
  color: var(--gray60);
  font-weight: 400;
  background: var(--grayBg);
  border: 0.1rem solid var(--gray30);
  border-radius: 0.8rem;
  outline: none;
  user-select: none;
}
@media (max-width: 1200px) {
  .block-catalog__filters-check {
    padding: 0.85rem 1.2rem;
    line-height: 1.1;
    font-size: 1.4rem;
  }
}
.block-catalog__filters-check:has(input:checked) {
  color: var(--black);
  font-weight: 500;
  background: var(--gray00);
  border: 0.1rem solid var(--gray00);
}
.block-catalog__filters-check input {
  pointer-events: none;
  opacity: 0;
  position: absolute;
}
.block-catalog__filters-btn {
  width: 3.4rem;
  height: 3.4rem;
  border: 0.1rem solid var(--gray10);
  border-radius: 0.5rem;
  display: none;
}
.block-catalog__filters-btn_active {
  background: var(--gray00);
}
.block-catalog__filters-btn img {
  width: 2.4rem;
  height: 2.4rem;
}
@media (max-width: 744px) {
  .block-catalog__filters-btn {
    display: flex;
    justify-content: center;
    align-items: center;
  }
}

@media (max-width: 744px) {
  .block-banner_tablet .block-banner__box {
    height: 80rem;
  }
}
@media (max-width: 480px) {
  .block-banner_tablet .block-banner__box {
    height: 50rem;
  }
}
.block-banner__box {
  width: 100%;
  height: 80rem;
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  row-gap: 3.2rem;
}
@media (max-width: 1200px) {
  .block-banner__box {
    height: 60rem;
  }
}
@media (max-width: 744px) {
  .block-banner__box {
    height: 40rem;
    row-gap: 2.4rem;
  }
}
@media (max-width: 480px) {
  .block-banner__box {
    height: 32rem;
    row-gap: 1.6rem;
  }
}
.block-banner__cover {
  position: absolute;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  z-index: -1;
}
.block-banner__cover img {
  width: 100%;
  height: 100%;
}
.block-banner__cover::before {
  content: "";
  position: absolute;
  width: 100%;
  height: 100%;
  background: linear-gradient(180deg, rgba(0, 0, 0, 0) 0%, rgba(0, 0, 0, 0.3) 100%);
  top: 0;
  left: 0;
  z-index: 2;
}
.block-banner__cover_overlay::before {
  background: var(--black);
  opacity: 0.3;
}
.block-banner__img {
  display: block;
}
@media (max-width: 744px) {
  .block-banner__img {
    display: none;
  }
}
.block-banner__img-mobile {
  display: none;
}
@media (max-width: 744px) {
  .block-banner__img-mobile {
    display: block;
  }
}
.block-banner__text {
  max-width: 47rem;
  text-align: center;
}
@media (max-width: 1200px) {
  .block-banner__text {
    max-width: 42rem;
  }
}
.block-banner__text_loyalty {
  max-width: 54rem;
}
@media (max-width: 744px) {
  .block-banner__text_loyalty {
    max-width: 48.4rem;
  }
}
@media (max-width: 480px) {
  .block-banner__text_loyalty {
    max-width: 29rem;
  }
}
.block-banner__title_vacancy {
  max-width: 130.7rem;
}
@media (max-width: 1200px) {
  .block-banner__title_vacancy {
    max-width: 88rem;
  }
}
@media (max-width: 744px) {
  .block-banner__title_vacancy {
    max-width: 58rem;
  }
}
@media (max-width: 480px) {
  .block-banner__title_vacancy {
    max-width: 33.6rem;
  }
}
.block-banner__title_certificate {
  max-width: 120rem;
}
@media (max-width: 1200px) {
  .block-banner__title_certificate {
    max-width: 76.8rem;
  }
}
@media (max-width: 744px) {
  .block-banner__title_certificate {
    max-width: 57.4rem;
  }
}
@media (max-width: 480px) {
  .block-banner__title_certificate {
    max-width: 33rem;
  }
}
.block-banner__title_loyalty {
  max-width: 68rem;
}
.block-banner__title_sizes {
  max-width: 48rem;
}
@media (max-width: 1200px) {
  .block-banner__title_sizes {
    max-width: 36rem;
  }
}
@media (max-width: 744px) {
  .block-banner__title_sizes {
    max-width: 36rem;
  }
}
@media (max-width: 480px) {
  .block-banner__title_sizes {
    max-width: 29rem;
  }
}

.list-card__list {
  display: flex;
  flex-wrap: wrap;
  justify-content: flex-start;
  margin: -1rem;
  row-gap: 1.8rem;
}
@media (max-width: 1200px) {
  .list-card__list {
    margin: -0.8rem;
  }
}
@media (max-width: 744px) {
  .list-card__list {
    margin: -0.5rem;
  }
}

.list-card__link {
  font-size: 1.4rem;
  color: var(--gray40);
  border-color: var(--gray40);
  text-transform: uppercase;
  position: absolute;
  right: 0;
  bottom: 0;
}
@media (max-width: 1200px) {
  .list-card__link {
    font-size: 1.2rem;
  }
}
@media (max-width: 744px) {
  .list-card__link {
    font-size: 1.2rem;
  }
}
@media (max-width: 480px) {
  .list-card__link {
    display: none;
    font-size: 1.2rem;
  }
}
.list-card__filters-form {
  position: relative;
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  gap: 1rem;
  width: 100%;
  margin-top: -3.2rem;
  margin-bottom: 3.2rem;
  padding: 0 11rem;
}
@media (max-width: 1200px) {
  .list-card__filters-form {
    gap: 0.8rem;
    margin-top: -2.4rem;
    margin-bottom: 2.4rem;
  }
}
@media (max-width: 744px) {
  .list-card__filters-form {
    margin-top: -1.6rem;
  }
}
@media (max-width: 480px) {
  .list-card__filters-form {
    flex-wrap: nowrap;
    justify-content: flex-start;
    margin-top: 0;
    margin-bottom: 0.8rem;
    padding-bottom: 0.8rem;
    padding-left: 0.9rem;
    padding-right: 0.9rem;
    overflow-x: auto;
    margin-left: -0.9rem;
    width: calc(100% + 0.9rem + 0.9rem);
  }
}
.list-card__filters-check {
  flex-shrink: 0;
  width: auto;
  display: flex;
  align-items: center;
  font-size: 1.4rem;
  padding: 0.9rem 1.2rem;
  cursor: pointer;
  color: var(--gray60);
  font-weight: 400;
  background: var(--grayBg);
  border: 0.1rem solid var(--gray30);
  border-radius: 0.8rem;
  outline: none;
  user-select: none;
}
@media (max-width: 1200px) {
  .list-card__filters-check {
    padding: 0.75rem 1.2rem;
    line-height: 1.1;
    font-size: 1.4rem;
  }
}
@media (max-width: 744px) {
  .list-card__filters-check {
    font-size: 1.2rem;
  }
}
@media (max-width: 480px) {
  .list-card__filters-check {
    font-size: 1rem;
    padding: 0.95rem 1.2rem;
  }
}
.list-card__filters-check:has(input:checked) {
  color: var(--black);
  font-weight: 500;
  background: var(--gray00);
  border: 0.1rem solid var(--gray00);
}
.list-card__filters-check input {
  pointer-events: none;
  opacity: 0;
  position: absolute;
}

.list-card__list--grid .list-card__list {
  display: flex;
  flex-wrap: wrap;
  justify-content: flex-start;
  margin: -1rem;
  row-gap: 1.8rem;
}
@media (max-width: 1200px) {
  .list-card__list--grid .list-card__list {
    margin: -0.8rem;
  }
}
@media (max-width: 744px) {
  .list-card__list--grid .list-card__list {
    margin: -0.5rem;
  }
}

/* .list-card__inner{
    padding-bottom: 10rem;
    @include laptop{
        padding-bottom: 10rem;
    }    
    @include tablet{
        padding-bottom: 8rem;
    }
    @include mobile{
        padding-bottom: 5.6rem;
    }
} */
.preview-card {
  flex: 0 1 calc(33.33% - 2rem);
  margin: 1rem;
}
@media (max-width: 1200px) {
  .preview-card {
    margin: 0.8rem;
    flex: 0 1 calc(33.33% - 1.6rem);
  }
}
@media (max-width: 744px) {
  .preview-card {
    margin: 0.5rem;
    flex: 0 1 calc(50% - 1rem);
  }
}
@media (max-width: 480px) {
  .preview-card {
    flex: 0 1 100%;
  }
}
.preview-card__block-text {
  width: 85%;
  margin-top: 0.8rem;
}
@media (max-width: 1200px) {
  .preview-card__block-text {
    width: 90%;
  }
}
.preview-card__img {
  width: 100%;
  height: 100%;
}
.preview-card__block-title, .preview-card__block-season {
  margin: 2rem 0 0rem 0;
  font-size: 2.4rem;
  color: var(--black);
  font-weight: 400;
  margin-bottom: 0;
  text-align: left;
}
@media (max-width: 1200px) {
  .preview-card__block-title, .preview-card__block-season {
    font-size: 1.8rem;
    margin: 1.6rem 0 0rem 0;
  }
}
@media (max-width: 744px) {
  .preview-card__block-title, .preview-card__block-season {
    font-size: 1.6rem;
  }
  .preview-card__block-title br, .preview-card__block-season br {
    display: none;
  }
}
.preview-card__block-date {
  color: var(--gray80);
  font-size: 1.6rem;
  margin: 0;
}
@media (max-width: 1200px) {
  .preview-card__block-date {
    font-size: 1.4rem;
  }
}
@media (max-width: 744px) {
  .preview-card__block-date {
    font-size: 1.2rem;
  }
}
@media (max-width: 480px) {
  .preview-card__block-date {
    font-size: 1.2rem;
  }
}
.preview-card__block-season {
  margin-top: 0;
  margin-bottom: 1.6rem;
}
@media (max-width: 1200px) {
  .preview-card__block-season {
    margin-bottom: 1rem;
  }
}
@media (max-width: 744px) {
  .preview-card__block-season {
    margin-bottom: 0.8rem;
  }
}
.preview-card__block-link {
  margin-top: 1.6rem;
}
@media (max-width: 480px) {
  .preview-card__block-link {
    font-size: 1.2rem;
  }
}
.preview-card__block-desc {
  margin: 0;
  font-size: 1.6rem;
  color: var(--gray60);
  line-height: 1.4;
  font-weight: 500;
  letter-spacing: -0.04rem;
  margin-top: 0.8rem;
}
@media (max-width: 1200px) {
  .preview-card__block-desc {
    font-size: 1.4rem;
    letter-spacing: -0.035rem;
  }
}
@media (max-width: 480px) {
  .preview-card__block-desc {
    font-size: 1.2rem;
  }
}
.preview-card__block-desc_short {
  display: -webkit-box;
  overflow: hidden;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 2;
}
.preview-card__link {
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
}

.preview-card__img-wrap {
  height: 68rem;
}
@media (max-width: 1200px) {
  .preview-card__img-wrap {
    height: 40rem;
  }
}
@media (max-width: 744px) {
  .preview-card__img-wrap {
    height: 37rem;
  }
}
@media (max-width: 480px) {
  .preview-card__img-wrap {
    height: 36.8rem;
  }
}

.list-card__list--grid .preview-card__img-wrap {
  height: 91rem;
}
@media (max-width: 1200px) {
  .list-card__list--grid .preview-card__img-wrap {
    height: 69.6rem;
  }
}

.list-card__list--grid-2 .preview-card, .list-card__list--grid-4 .preview-card, .list-card__list--grid-6 .preview-card, .list-card__list--grid-8 .preview-card {
  flex: 0 1 calc(50% - 2rem);
}
@media (max-width: 1200px) {
  .list-card__list--grid-2 .preview-card, .list-card__list--grid-4 .preview-card, .list-card__list--grid-6 .preview-card, .list-card__list--grid-8 .preview-card {
    flex: 0 1 calc(50% - 1.6rem);
  }
  .list-card__list--grid-2 .preview-card .preview-card__img-wrap, .list-card__list--grid-4 .preview-card .preview-card__img-wrap, .list-card__list--grid-6 .preview-card .preview-card__img-wrap, .list-card__list--grid-8 .preview-card .preview-card__img-wrap {
    height: 61rem;
  }
}
@media (max-width: 744px) {
  .list-card__list--grid-2 .preview-card, .list-card__list--grid-4 .preview-card, .list-card__list--grid-6 .preview-card, .list-card__list--grid-8 .preview-card {
    flex: 0 1 calc(50% - 1rem);
  }
  .list-card__list--grid-2 .preview-card .preview-card__img-wrap, .list-card__list--grid-4 .preview-card .preview-card__img-wrap, .list-card__list--grid-6 .preview-card .preview-card__img-wrap, .list-card__list--grid-8 .preview-card .preview-card__img-wrap {
    height: 38.1rem;
  }
}
@media (max-width: 480px) {
  .list-card__list--grid-2 .preview-card, .list-card__list--grid-4 .preview-card, .list-card__list--grid-6 .preview-card, .list-card__list--grid-8 .preview-card {
    flex: 0 1 100%;
  }
  .list-card__list--grid-2 .preview-card .preview-card__img-wrap, .list-card__list--grid-4 .preview-card .preview-card__img-wrap, .list-card__list--grid-6 .preview-card .preview-card__img-wrap, .list-card__list--grid-8 .preview-card .preview-card__img-wrap {
    height: 38.8rem;
  }
}

.list-card__list--grid-3 .preview-card {
  flex: 0 1 calc(33.33% - 2rem);
}
.list-card__list--grid-3 .preview-card .preview-card__img-wrap {
  height: 60rem;
}
@media (max-width: 1200px) {
  .list-card__list--grid-3 .preview-card {
    flex: 0 1 calc(33.33% - 1.6rem);
  }
  .list-card__list--grid-3 .preview-card .preview-card__img-wrap {
    height: 40rem;
  }
}
@media (max-width: 744px) {
  .list-card__list--grid-3 .preview-card {
    flex: 0 1 calc(50% - 1rem);
  }
  .list-card__list--grid-3 .preview-card .preview-card__img-wrap {
    height: 38.1rem;
  }
}
@media (max-width: 480px) {
  .list-card__list--grid-3 .preview-card {
    flex: 0 1 100%;
  }
  .list-card__list--grid-3 .preview-card .preview-card__img-wrap {
    height: 38.8rem;
  }
}

.list-card__list--grid-5 .preview-card:nth-child(1), .list-card__list--grid-5 .preview-card:nth-child(2) {
  flex: 0 1 calc(50% - 2rem);
}
@media (max-width: 1200px) {
  .list-card__list--grid-5 .preview-card:nth-child(1), .list-card__list--grid-5 .preview-card:nth-child(2) {
    flex: 0 1 calc(50% - 1.6rem);
  }
  .list-card__list--grid-5 .preview-card:nth-child(1) .preview-card__img-wrap, .list-card__list--grid-5 .preview-card:nth-child(2) .preview-card__img-wrap {
    height: 61rem;
  }
}
@media (max-width: 744px) {
  .list-card__list--grid-5 .preview-card:nth-child(1), .list-card__list--grid-5 .preview-card:nth-child(2) {
    flex: 0 1 calc(50% - 1rem);
  }
  .list-card__list--grid-5 .preview-card:nth-child(1) .preview-card__img-wrap, .list-card__list--grid-5 .preview-card:nth-child(2) .preview-card__img-wrap {
    height: 38.1rem;
  }
}
@media (max-width: 480px) {
  .list-card__list--grid-5 .preview-card:nth-child(1), .list-card__list--grid-5 .preview-card:nth-child(2) {
    flex: 0 1 100%;
  }
  .list-card__list--grid-5 .preview-card:nth-child(1) .preview-card__img-wrap, .list-card__list--grid-5 .preview-card:nth-child(2) .preview-card__img-wrap {
    height: 38.8rem;
  }
}
.list-card__list--grid-5 .preview-card:nth-child(3), .list-card__list--grid-5 .preview-card:nth-child(4), .list-card__list--grid-5 .preview-card:nth-child(5) {
  flex: 0 1 calc(33.33% - 2rem);
}
.list-card__list--grid-5 .preview-card:nth-child(3) .preview-card__img-wrap, .list-card__list--grid-5 .preview-card:nth-child(4) .preview-card__img-wrap, .list-card__list--grid-5 .preview-card:nth-child(5) .preview-card__img-wrap {
  height: 60rem;
}
@media (max-width: 1200px) {
  .list-card__list--grid-5 .preview-card:nth-child(3), .list-card__list--grid-5 .preview-card:nth-child(4), .list-card__list--grid-5 .preview-card:nth-child(5) {
    flex: 0 1 calc(33.33% - 1.6rem);
  }
  .list-card__list--grid-5 .preview-card:nth-child(3) .preview-card__img-wrap, .list-card__list--grid-5 .preview-card:nth-child(4) .preview-card__img-wrap, .list-card__list--grid-5 .preview-card:nth-child(5) .preview-card__img-wrap {
    height: 40rem;
  }
}
@media (max-width: 744px) {
  .list-card__list--grid-5 .preview-card:nth-child(3), .list-card__list--grid-5 .preview-card:nth-child(4), .list-card__list--grid-5 .preview-card:nth-child(5) {
    flex: 0 1 calc(50% - 1rem);
  }
  .list-card__list--grid-5 .preview-card:nth-child(3) .preview-card__img-wrap, .list-card__list--grid-5 .preview-card:nth-child(4) .preview-card__img-wrap, .list-card__list--grid-5 .preview-card:nth-child(5) .preview-card__img-wrap {
    height: 38.1rem;
  }
}
@media (max-width: 480px) {
  .list-card__list--grid-5 .preview-card:nth-child(3), .list-card__list--grid-5 .preview-card:nth-child(4), .list-card__list--grid-5 .preview-card:nth-child(5) {
    flex: 0 1 100%;
  }
  .list-card__list--grid-5 .preview-card:nth-child(3) .preview-card__img-wrap, .list-card__list--grid-5 .preview-card:nth-child(4) .preview-card__img-wrap, .list-card__list--grid-5 .preview-card:nth-child(5) .preview-card__img-wrap {
    height: 38.8rem;
  }
}

.list-card__list--grid-7 .preview-card:nth-child(1), .list-card__list--grid-7 .preview-card:nth-child(2), .list-card__list--grid-7 .preview-card:nth-child(3), .list-card__list--grid-7 .preview-card:nth-child(4) {
  flex: 0 1 calc(50% - 2rem);
}
@media (max-width: 1200px) {
  .list-card__list--grid-7 .preview-card:nth-child(1), .list-card__list--grid-7 .preview-card:nth-child(2), .list-card__list--grid-7 .preview-card:nth-child(3), .list-card__list--grid-7 .preview-card:nth-child(4) {
    flex: 0 1 calc(50% - 1.6rem);
  }
  .list-card__list--grid-7 .preview-card:nth-child(1) .preview-card__img-wrap, .list-card__list--grid-7 .preview-card:nth-child(2) .preview-card__img-wrap, .list-card__list--grid-7 .preview-card:nth-child(3) .preview-card__img-wrap, .list-card__list--grid-7 .preview-card:nth-child(4) .preview-card__img-wrap {
    height: 61rem;
  }
}
@media (max-width: 744px) {
  .list-card__list--grid-7 .preview-card:nth-child(1), .list-card__list--grid-7 .preview-card:nth-child(2), .list-card__list--grid-7 .preview-card:nth-child(3), .list-card__list--grid-7 .preview-card:nth-child(4) {
    flex: 0 1 calc(50% - 1rem);
  }
  .list-card__list--grid-7 .preview-card:nth-child(1) .preview-card__img-wrap, .list-card__list--grid-7 .preview-card:nth-child(2) .preview-card__img-wrap, .list-card__list--grid-7 .preview-card:nth-child(3) .preview-card__img-wrap, .list-card__list--grid-7 .preview-card:nth-child(4) .preview-card__img-wrap {
    height: 38.1rem;
  }
}
@media (max-width: 480px) {
  .list-card__list--grid-7 .preview-card:nth-child(1), .list-card__list--grid-7 .preview-card:nth-child(2), .list-card__list--grid-7 .preview-card:nth-child(3), .list-card__list--grid-7 .preview-card:nth-child(4) {
    flex: 0 1 100%;
  }
  .list-card__list--grid-7 .preview-card:nth-child(1) .preview-card__img-wrap, .list-card__list--grid-7 .preview-card:nth-child(2) .preview-card__img-wrap, .list-card__list--grid-7 .preview-card:nth-child(3) .preview-card__img-wrap, .list-card__list--grid-7 .preview-card:nth-child(4) .preview-card__img-wrap {
    height: 38.8rem;
  }
}
.list-card__list--grid-7 .preview-card:nth-child(5), .list-card__list--grid-7 .preview-card:nth-child(6), .list-card__list--grid-7 .preview-card:nth-child(7) {
  flex: 0 1 calc(33.33% - 2rem);
}
.list-card__list--grid-7 .preview-card:nth-child(5) .preview-card__img-wrap, .list-card__list--grid-7 .preview-card:nth-child(6) .preview-card__img-wrap, .list-card__list--grid-7 .preview-card:nth-child(7) .preview-card__img-wrap {
  height: 60rem;
}
@media (max-width: 1200px) {
  .list-card__list--grid-7 .preview-card:nth-child(5), .list-card__list--grid-7 .preview-card:nth-child(6), .list-card__list--grid-7 .preview-card:nth-child(7) {
    flex: 0 1 calc(33.33% - 1.6rem);
  }
  .list-card__list--grid-7 .preview-card:nth-child(5) .preview-card__img-wrap, .list-card__list--grid-7 .preview-card:nth-child(6) .preview-card__img-wrap, .list-card__list--grid-7 .preview-card:nth-child(7) .preview-card__img-wrap {
    height: 40rem;
  }
}
@media (max-width: 744px) {
  .list-card__list--grid-7 .preview-card:nth-child(5), .list-card__list--grid-7 .preview-card:nth-child(6), .list-card__list--grid-7 .preview-card:nth-child(7) {
    flex: 0 1 calc(50% - 1rem);
  }
  .list-card__list--grid-7 .preview-card:nth-child(5) .preview-card__img-wrap, .list-card__list--grid-7 .preview-card:nth-child(6) .preview-card__img-wrap, .list-card__list--grid-7 .preview-card:nth-child(7) .preview-card__img-wrap {
    height: 38.1rem;
  }
}
@media (max-width: 480px) {
  .list-card__list--grid-7 .preview-card:nth-child(5), .list-card__list--grid-7 .preview-card:nth-child(6), .list-card__list--grid-7 .preview-card:nth-child(7) {
    flex: 0 1 100%;
  }
  .list-card__list--grid-7 .preview-card:nth-child(5) .preview-card__img-wrap, .list-card__list--grid-7 .preview-card:nth-child(6) .preview-card__img-wrap, .list-card__list--grid-7 .preview-card:nth-child(7) .preview-card__img-wrap {
    height: 38.8rem;
  }
}

.list-card__list--grid-9 .preview-card {
  flex: 0 1 calc(33.33% - 2rem);
}
.list-card__list--grid-9 .preview-card .preview-card__img-wrap {
  height: 60rem;
}
@media (max-width: 1200px) {
  .list-card__list--grid-9 .preview-card {
    flex: 0 1 calc(33.33% - 1.6rem);
  }
  .list-card__list--grid-9 .preview-card .preview-card__img-wrap {
    height: 40rem;
  }
}
@media (max-width: 744px) {
  .list-card__list--grid-9 .preview-card {
    flex: 0 1 calc(50% - 1rem);
  }
  .list-card__list--grid-9 .preview-card .preview-card__img-wrap {
    height: 38.1rem;
  }
}
@media (max-width: 480px) {
  .list-card__list--grid-9 .preview-card {
    flex: 0 1 100%;
  }
  .list-card__list--grid-9 .preview-card .preview-card__img-wrap {
    height: 38.8rem;
  }
}

.block-page-top + * > .container > .inner {
  margin-top: 6.4rem;
}
@media (max-width: 1200px) {
  .block-page-top + * > .container > .inner {
    margin-top: 4.8rem;
  }
}
@media (max-width: 744px) {
  .block-page-top + * > .container > .inner {
    margin-top: 3.2rem;
  }
}
@media (max-width: 480px) {
  .block-page-top + * > .container > .inner {
    margin-top: 2.4rem;
  }
}
.block-page-top:has(+ .block-banner) + .block-banner .inner {
  margin-top: 0;
  margin-bottom: 0;
}
.block-page-top:has(+ .block-text) .block-page-top__inner {
  margin: 3.2rem 0 6.4rem 0;
}
@media (max-width: 1200px) {
  .block-page-top:has(+ .block-text) .block-page-top__inner {
    margin: 2.4rem 0 4.8rem 0;
  }
}
@media (max-width: 744px) {
  .block-page-top:has(+ .block-text) .block-page-top__inner {
    margin: 2.4rem 0 4.8rem 0;
  }
}
@media (max-width: 480px) {
  .block-page-top:has(+ .block-text) .block-page-top__inner {
    margin: 2.4rem 0 2.4rem 0;
  }
}
.block-page-top:has(+ .block-text_special) .block-page-top__inner {
  margin: 6.4rem 0 4rem 0;
}
@media (max-width: 1200px) {
  .block-page-top:has(+ .block-text_special) .block-page-top__inner {
    margin: 4rem 0 4.8rem 0;
  }
}
@media (max-width: 744px) {
  .block-page-top:has(+ .block-text_special) .block-page-top__inner {
    margin: 4rem 0 3.2rem 0;
  }
}
@media (max-width: 480px) {
  .block-page-top:has(+ .block-text_special) .block-page-top__inner {
    margin: 2.4rem 0 1.6rem 0;
  }
}
.block-page-top:has(+ .block-text_special) + * > .container > .inner {
  margin-top: 0;
}
@media (max-width: 1200px) {
  .block-page-top:has(+ .block-text_special) + * > .container > .inner {
    margin-top: 0;
  }
}
@media (max-width: 744px) {
  .block-page-top:has(+ .block-text_special) + * > .container > .inner {
    margin-top: 0;
  }
}
@media (max-width: 480px) {
  .block-page-top:has(+ .block-text_special) + * > .container > .inner {
    margin-top: 0;
  }
}
.block-page-top__inner {
  margin: 6.4rem 0;
  display: flex;
  flex-direction: column;
  row-gap: 1.6rem;
  align-items: center;
  position: relative;
}
@media (max-width: 1200px) {
  .block-page-top__inner {
    margin: 4rem 0 4.8rem 0;
  }
}
@media (max-width: 744px) {
  .block-page-top__inner {
    margin: 4rem 0 3.2rem 0;
  }
}
@media (max-width: 480px) {
  .block-page-top__inner {
    margin: 2.4rem 0 2.4rem 0;
    align-items: flex-start;
  }
}
.block-page-top__title {
  font-size: 4.8rem;
  max-width: 90rem;
}
@media (max-width: 1200px) {
  .block-page-top__title {
    font-size: 3.2rem;
    max-width: 58rem;
  }
}
@media (max-width: 744px) {
  .block-page-top__title {
    font-size: 2.8rem;
    max-width: 52rem;
  }
}
@media (max-width: 480px) {
  .block-page-top__title {
    font-size: 2.2rem;
    text-align: left;
    max-width: unset;
  }
}
@media (max-width: 480px) {
  .block-page-top__title_short {
    max-width: 22rem;
  }
}
.block-page-top__title-link {
  font-size: 1.4rem;
  color: var(--gray40);
  border-color: var(--gray40);
  text-transform: uppercase;
  position: absolute;
  right: 0;
  bottom: 0.2rem;
  display: none;
}
@media (max-width: 480px) {
  .block-page-top__title-link {
    display: block;
    font-size: 1.2rem;
  }
}
.block-page-top__date {
  font-size: 1.2rem;
  text-align: center;
  color: var(--gray80);
  margin-top: 0.8rem;
}
@media (max-width: 480px) {
  .block-page-top__date {
    font-size: 1rem;
  }
}
.block-page-top__path {
  display: flex;
  flex-wrap: wrap;
  gap: 0.8rem 1.6rem;
}
@media (max-width: 480px) {
  .block-page-top__path {
    gap: 0.4rem 0.8rem;
  }
}
.block-page-top__link {
  color: var(--gray40);
  text-transform: uppercase;
  text-decoration: none;
  white-space: nowrap;
}
.block-page-top__link_active {
  color: var(--black);
}
.block-page-top__category {
  width: 100%;
}
@media (max-width: 480px) {
  .block-page-top__category {
    width: calc(100% + 1.8rem);
    margin-left: -0.9rem;
    padding: 0 0.3rem 0 0.9rem;
  }
}
.block-page-top__category-wrap {
  margin-top: 2.4rem;
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  gap: 1.2rem;
}
@media (max-width: 1200px) {
  .block-page-top__category-wrap {
    margin-top: 1.6rem;
  }
}
@media (max-width: 744px) {
  .block-page-top__category-wrap {
    gap: 1rem;
  }
}
@media (max-width: 480px) {
  .block-page-top__category-wrap {
    margin-top: 0.8rem;
    flex-wrap: unset;
    justify-content: unset;
    gap: unset;
  }
}
.block-page-top__category-card {
  display: flex;
  flex-direction: column;
  row-gap: 1.2rem;
  text-decoration: none;
  width: 21.4rem;
}
@media (max-width: 1200px) {
  .block-page-top__category-card {
    width: 12.8rem;
  }
}
@media (max-width: 744px) {
  .block-page-top__category-card {
    width: 8rem;
  }
}
@media (max-width: 480px) {
  .block-page-top__category-card {
    width: 8.2rem;
    margin-right: 0.6rem;
  }
}
.block-page-top__category-cover {
  width: 21.4rem;
  height: 21.4rem;
  border-radius: 1.6rem;
  overflow: hidden;
}
@media (max-width: 1200px) {
  .block-page-top__category-cover {
    width: 12.8rem;
    height: 12.8rem;
  }
}
@media (max-width: 744px) {
  .block-page-top__category-cover {
    border-radius: 1.2rem;
    width: 8rem;
    height: 8rem;
  }
}
@media (max-width: 480px) {
  .block-page-top__category-cover {
    width: 8.2rem;
    height: 8.2rem;
    border-radius: 0.8rem;
  }
}
.block-page-top__category-cover img {
  width: 100%;
  height: 100%;
}
.block-page-top__category-title {
  text-align: center;
  font-size: 1.4rem;
  text-transform: uppercase;
  line-height: 1.4;
}
@media (max-width: 1200px) {
  .block-page-top__category-title {
    font-size: 1.2rem;
  }
}
@media (max-width: 744px) {
  .block-page-top__category-title {
    font-size: 1rem;
  }
}

.block-product__box {
  z-index: 5;
  display: flex;
  column-gap: 7.8rem;
  position: relative;
}
@media (max-width: 1200px) {
  .block-product__box {
    column-gap: 4.8rem;
  }
}
@media (max-width: 744px) {
  .block-product__box {
    column-gap: 3rem;
  }
}
@media (max-width: 480px) {
  .block-product__box {
    flex-wrap: wrap;
    row-gap: 1.6rem;
  }
}
.block-product__box .swiper-pagination {
  height: 2rem;
  bottom: 1.2rem;
}
.block-product__box .swiper-pagination-bullet {
  width: 7.2rem;
  height: 0.4rem;
  border-radius: 9rem;
  margin: 0.8rem 0.6rem;
  background: var(--black);
  transition-duration: 0.3s;
}
@media (max-width: 744px) {
  .block-product__box .swiper-pagination-bullet {
    width: 3.5rem;
    margin: 0.8rem 0.25rem;
    height: 0.2rem;
  }
}
.block-product__box .swiper-pagination-bullet-active {
  background: var(--white);
}
.block-product__visual {
  display: flex;
  flex-wrap: wrap;
  gap: 2rem;
  align-items: flex-start;
  align-content: baseline;
}
@media (max-width: 1200px) {
  .block-product__visual {
    gap: 1.6rem;
  }
}
@media (max-width: 744px) {
  .block-product__visual {
    gap: 1rem;
  }
}
@media (max-width: 480px) {
  .block-product__visual {
    flex-wrap: unset;
    gap: unset;
  }
}
.block-product__image {
  width: 60.4rem;
  height: 80rem;
  overflow: hidden;
  border-radius: 2.4rem;
}
@media (max-width: 1200px) {
  .block-product__image {
    width: 36.8rem;
    height: 48.8rem;
    border-radius: 2rem;
  }
}
@media (max-width: 744px) {
  .block-product__image {
    width: 35rem;
    height: 46.4rem;
  }
}
@media (max-width: 480px) {
  .block-product__image {
    width: 35.8rem;
    height: 47.4rem;
  }
}
.block-product__image img {
  width: 100%;
  height: 100%;
}
.block-product__modal {
  display: flex;
  flex-direction: column;
}
@media (max-width: 744px) {
  .block-product__modal {
    margin-top: 2.4rem;
  }
}
.block-product__modal-link {
  text-decoration: none;
  padding: 2.5rem 0;
  border-bottom: 0.1rem solid var(--black);
  display: flex;
  align-items: center;
  justify-content: space-between;
}
.block-product__modal-link:nth-of-type(1) {
  border-top: 0.1rem solid var(--black);
}
@media (max-width: 1200px) {
  .block-product__modal-link {
    padding: 1.4rem 0;
    font-size: 1.2rem;
  }
}
@media (max-width: 744px) {
  .block-product__modal-link {
    font-size: 1.2rem;
    padding: 1.4rem 0;
  }
}
@media (max-width: 480px) {
  .block-product__modal-link {
    font-size: 1.2rem;
  }
}
.block-product__info {
  width: 54.6rem;
  display: flex;
  flex-direction: column;
  row-gap: 2.4rem;
  position: sticky;
  top: 12rem;
  height: fit-content;
  z-index: 6;
}
@media (max-width: 1200px) {
  .block-product__info {
    width: 33.6rem;
    top: 11rem;
  }
}
@media (max-width: 744px) {
  .block-product__info {
    width: 33rem;
    top: 10rem;
  }
}
@media (max-width: 480px) {
  .block-product__info {
    width: 100%;
    row-gap: 1.6rem;
  }
}
.block-product__info_gap {
  position: relative;
  top: unset;
  row-gap: 3.2rem;
}
@media (max-width: 1200px) {
  .block-product__info_gap {
    row-gap: 1.6rem;
  }
}
.block-product__favourite {
  width: 2.4rem;
  height: 2.4rem;
  background: url(/img/svg/favourite.svg) no-repeat;
  background-size: cover;
  position: absolute;
  top: 0;
  right: 0;
  cursor: pointer;
}
.block-product__favourite input {
  position: absolute;
  pointer-events: none;
  opacity: 0;
}
.block-product__favourite:has(input:checked) {
  background: url(/img/svg/favourite-fill.svg) no-repeat;
  background-size: cover;
}
@media (max-width: 480px) {
  .block-product__favourite {
    top: 4.6rem;
  }
}
.block-product__tags {
  display: flex;
  flex-wrap: wrap;
  gap: 0.8rem;
}
.block-product__sale {
  border-radius: 0.6rem;
  background: var(--gray00);
  padding: 0.8rem 1.2rem;
  color: var(--gray70);
}
.block-product__title {
  font-size: 2.8rem;
}
@media (max-width: 1200px) {
  .block-product__title {
    font-size: 2rem;
    margin-bottom: -0.8rem;
  }
}
@media (max-width: 744px) {
  .block-product__title {
    font-size: 1.8rem;
  }
}
@media (max-width: 480px) {
  .block-product__title {
    font-size: 2.2rem;
    margin-bottom: 0;
    max-width: 31.2rem;
  }
  .block-product__title br {
    display: none;
  }
}
.block-product__price {
  display: flex;
  align-items: center;
  position: relative;
  column-gap: 1.6rem;
}
@media (max-width: 1200px) {
  .block-product__price {
    column-gap: 0.9rem;
  }
}
@media (max-width: 480px) {
  .block-product__price-current {
    font-size: 1.6rem;
  }
}
@media (max-width: 480px) {
  .block-product__price-old {
    font-size: 1rem;
  }
}
.block-product__gift {
  padding: 1rem 1.2rem;
  margin-left: auto;
  display: flex;
  align-items: center;
  column-gap: 0.4rem;
  background: var(--sageLight);
  border-radius: 1.2rem;
  color: var(--black);
  cursor: pointer;
  transition-duration: 0.3s;
}
@media (max-width: 1200px) {
  .block-product__gift {
    padding: 0.8rem 1rem;
  }
}
@media (max-width: 744px) {
  .block-product__gift {
    padding: 0.6rem 1rem;
    border-radius: 0.8rem;
  }
}
.block-product__gift img {
  width: 2.4rem;
  height: 2.4rem;
  pointer-events: none;
  transition-duration: 0.3s;
}
@media (max-width: 744px) {
  .block-product__gift img {
    width: 2rem;
    height: 2rem;
  }
}
.block-product__gift:hover {
  background: var(--sageDark);
  color: var(--white);
}
.block-product__gift:hover img {
  filter: invert(1);
}
.block-product__gift:active {
  background: var(--sage);
  color: var(--white);
}
.block-product__gift:active img {
  filter: invert(1);
}
.block-product__colors {
  position: relative;
  width: 100%;
}
.block-product__colors-title, .block-product__size-title {
  display: none;
}
@media (max-width: 480px) {
  .block-product__colors-title, .block-product__size-title {
    display: block;
    font-size: 1.6rem;
    margin-top: 1.6rem;
  }
}
.block-product__swiper {
  width: calc(100% - 2.4rem - 0.8rem);
  margin-left: 0;
  position: relative;
  /* @include laptop{
          width: 100%;
      } */
}
@media (max-width: 744px) {
  .block-product__swiper {
    width: calc(100% + 0.9rem);
  }
}
@media (max-width: 480px) {
  .block-product__swiper {
    margin-top: 0.8rem;
  }
}
.block-product__swiper-slide {
  width: 9rem;
  height: 11.8rem;
  margin-right: 1.6rem;
  border-radius: 1.2rem;
  overflow: hidden;
  border: 0.2rem solid transparent;
  transition: border 0.3s;
  cursor: pointer;
  user-select: none;
}
@media (max-width: 1200px) {
  .block-product__swiper-slide {
    width: 5.6rem;
    height: 7.2rem;
    margin-right: 0.8rem;
    border: 0.1rem solid transparent;
  }
}
@media (max-width: 744px) {
  .block-product__swiper-slide {
    margin-right: 0.6rem;
  }
}
.block-product__swiper-slide:nth-last-of-type(1) {
  margin-right: 0;
}
.block-product__swiper-slide input {
  position: absolute;
  pointer-events: none;
  opacity: 0;
}
.block-product__swiper-slide:has(input:checked) {
  border: 0.2rem solid var(--black);
}
@media (max-width: 1200px) {
  .block-product__swiper-slide:has(input:checked) {
    border: 0.1rem solid var(--black);
  }
}
.block-product__cover img {
  width: 100%;
  height: 100%;
  pointer-events: none;
}
.block-product__swiper-prev {
  position: absolute;
  left: -2.4rem;
  top: 50%;
  transform: translateY(-50%);
  cursor: pointer;
}
.block-product__swiper-prev img {
  width: 2.4rem;
  height: 2.4rem;
}
.block-product__swiper-prev.swiper-button-disabled {
  opacity: 0;
}
@media (max-width: 744px) {
  .block-product__swiper-prev {
    display: none;
  }
}
.block-product__swiper-next {
  position: absolute;
  right: 0;
  top: 50%;
  transform: translateY(-50%);
  cursor: pointer;
}
.block-product__swiper-next img {
  width: 2.4rem;
  height: 2.4rem;
}
.block-product__swiper-next.swiper-button-disabled {
  opacity: 0;
}
@media (max-width: 744px) {
  .block-product__swiper-next {
    display: none;
  }
}
.block-product__size {
  display: flex;
  flex-direction: column;
  row-gap: 2.4rem;
  margin-top: 1.6rem;
}
@media (max-width: 1200px) {
  .block-product__size {
    margin-top: 0;
  }
}
@media (max-width: 480px) {
  .block-product__size .select {
    display: none;
  }
}
.block-product__size-swiper {
  display: none;
}
@media (max-width: 480px) {
  .block-product__size-swiper {
    display: block;
    width: calc(100% + 0.9rem);
  }
}
.block-product__size-label {
  cursor: pointer;
  text-align: center;
  width: 7rem;
  height: 3.2rem;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 0.8rem;
  user-select: none;
  border: 0.1rem solid var(--gray00);
  font-weight: 400;
  font-size: 1.2rem;
  margin-right: 0.8rem;
}
.block-product__size-label * {
  color: var(--gray60);
}
.block-product__size-label input {
  position: absolute;
  width: 1.6rem;
  height: 1.6rem;
  opacity: 0;
  pointer-events: none;
}
.block-product__size-label:has(input:checked) {
  background: var(--gray10);
  border: 0.1rem solid transparent;
}
.block-product__size-label:has(input:checked) * {
  color: var(--black);
}
.block-product__link {
  color: var(--gray40);
  border-bottom: 0.1rem solid var(--gray40);
  margin-top: -0.8rem;
  font-size: 1.2rem;
}
@media (max-width: 480px) {
  .block-product__link {
    margin-top: 0;
  }
}
.block-product__cart {
  margin-top: 0.8rem;
  transition-duration: 0.3s;
}
@media (max-width: 1200px) {
  .block-product__cart {
    margin-top: 0;
  }
}
@media (max-width: 1200px) {
  .block-product__cart {
    margin-top: 1.6rem;
  }
}
.block-product__cart_fix {
  transition-duration: 0.3s;
  position: fixed;
  top: calc(100dvh - 7rem);
  z-index: 150;
  width: calc(100% - 1.8rem);
}
.block-product__more {
  margin-top: 4rem;
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
}
@media (max-width: 480px) {
  .block-product__more {
    margin-top: 1.6rem;
  }
}
.block-product__more-title {
  font-size: 2.4rem;
}
@media (max-width: 1200px) {
  .block-product__more-title {
    font-size: 1.6rem;
  }
}
@media (max-width: 744px) {
  .block-product__more-title {
    font-size: 1.8rem;
  }
}
@media (max-width: 480px) {
  .block-product__more-title {
    font-size: 2.2rem;
  }
}
.block-product__more-link {
  margin-right: 0;
  font-size: 1.4rem;
}
@media (max-width: 1200px) {
  .block-product__more-link {
    font-size: 1.2rem;
  }
}
@media (max-width: 744px) {
  .block-product__more-link {
    font-size: 1.2rem;
  }
}
@media (max-width: 480px) {
  .block-product__more-link {
    display: none;
  }
}
.block-product__more-link_mobile {
  display: none;
  position: absolute;
  width: 100%;
  justify-content: flex-end;
}
@media (max-width: 480px) {
  .block-product__more-link_mobile {
    display: flex;
  }
}
.block-product__payment {
  padding: 1.3rem 2.1rem;
  padding-right: 3.7rem;
  background: var(--gray00);
  border-radius: 0.8rem;
  text-decoration: none;
  letter-spacing: 0.05vw;
  font-weight: 500;
  font-size: 1.6rem;
  color: var(--black);
  display: flex;
  align-items: center;
  justify-content: space-between;
  position: relative;
}
.block-product__payment-arrow {
  width: 1.6rem;
  height: 1.6rem;
  position: absolute;
  right: 1.8rem;
  top: 50%;
  transform: translateY(-50%);
}
@media (max-width: 1200px) {
  .block-product__payment-arrow {
    right: 1.2rem;
  }
}
@media (max-width: 1200px) {
  .block-product__payment {
    padding: 1.1rem 1.5rem;
    padding-right: 3.1rem;
  }
}
@media (max-width: 480px) {
  .block-product__payment {
    order: -1;
    margin-top: 1.6rem;
  }
}
.block-product__accordions {
  border-bottom: 0.1rem solid var(--black);
}
@media (max-width: 1200px) {
  .block-product__accordions {
    margin-top: 1.6rem;
  }
}
.block-product__addition {
  display: flex;
  column-gap: 1.6rem;
}
@media (max-width: 1200px) {
  .block-product__addition {
    column-gap: 1.4rem;
    margin-bottom: 2rem;
  }
}
@media (max-width: 480px) {
  .block-product__addition {
    margin-bottom: 0;
    column-gap: 0.8rem;
  }
}
.block-product__card {
  width: 26.5rem;
  display: flex;
  flex-direction: column;
  row-gap: 1rem;
}
@media (max-width: 1200px) {
  .block-product__card {
    width: 16.2rem;
  }
}
@media (max-width: 480px) {
  .block-product__card {
    width: 17.5rem;
  }
}
.block-product__card-cover {
  width: 26.5rem;
  height: 35.2rem;
  border-radius: 1.6rem;
  overflow: hidden;
}
@media (max-width: 1200px) {
  .block-product__card-cover {
    width: 16.2rem;
    height: 20.6rem;
  }
}
@media (max-width: 480px) {
  .block-product__card-cover {
    width: 17.5rem;
    height: 22.4rem;
  }
}
.block-product__card-cover img {
  width: 100%;
  height: 100%;
}
.block-product__card-title {
  margin-top: 1rem;
}
.block-product__card-price {
  display: flex;
  column-gap: 0.8rem;
  align-items: flex-end;
}
.block-product__card-current {
  font-weight: 500;
}
.block-product__card-old {
  color: var(--gray30);
}
.block-product__card-bottom {
  margin-top: 0.6rem;
  display: flex;
  column-gap: 1rem;
  margin-bottom: 0.2rem;
}
@media (max-width: 1200px) {
  .block-product__card-bottom {
    column-gap: 0.6rem;
  }
}
.block-product__card-cart {
  padding: 0;
  width: 5.6rem;
  height: 5.6rem;
  flex-shrink: 0;
}
@media (max-width: 1200px) {
  .block-product__card-cart {
    width: 4.4rem;
    height: 4.4rem;
    border-radius: 0.8rem;
  }
}
.block-product__card-cart img {
  width: 2.4rem;
  height: 2.4rem;
  filter: invert(1);
}

.block-text__box {
  display: flex;
  column-gap: 11rem;
}
@media (max-width: 1200px) {
  .block-text__box {
    column-gap: 11.5rem;
  }
}
@media (max-width: 744px) {
  .block-text__box {
    column-gap: 2rem;
  }
}
.block-text__title {
  font-size: 2.8rem;
  letter-spacing: -0.01vw;
  line-height: 1.1;
  text-align: left;
  margin-bottom: 4rem;
}
@media (max-width: 1200px) {
  .block-text__title {
    font-size: 2rem;
  }
}
@media (max-width: 744px) {
  .block-text__title {
    font-size: 1.8rem;
    margin-bottom: 2.4rem;
  }
}
@media (max-width: 480px) {
  .block-text__title {
    font-size: 1.6rem;
    margin-bottom: 1.6rem;
  }
}
.block-text__title_special {
  font-size: 4.8rem;
  text-align: center;
  max-width: 92rem;
  margin: 0 auto;
  margin-bottom: 6.4rem;
}
@media (max-width: 1200px) {
  .block-text__title_special {
    font-size: 3.2rem;
    margin-bottom: 4rem;
  }
}
@media (max-width: 744px) {
  .block-text__title_special {
    font-size: 2.8rem;
    margin-bottom: 3.2rem;
  }
}
@media (max-width: 480px) {
  .block-text__title_special {
    text-align: left;
    font-size: 2.2rem;
    margin-bottom: 3.2rem;
  }
}
.block-text__select {
  display: none;
}
@media (max-width: 480px) {
  .block-text__select {
    display: block;
    margin-bottom: 2.4rem;
  }
}
.block-text__buttons {
  display: flex;
  column-gap: 2rem;
  margin-top: 4rem;
  margin-bottom: 1.6rem;
}
@media (max-width: 1200px) {
  .block-text__buttons {
    column-gap: 1.6rem;
  }
}
@media (max-width: 744px) {
  .block-text__buttons {
    flex-wrap: wrap;
    row-gap: 1rem;
    margin-top: 3.2rem;
  }
}
@media (max-width: 480px) {
  .block-text__buttons {
    margin-top: 2.4rem;
  }
}
.block-text__link {
  font-size: 1.2rem;
}
@media (max-width: 1200px) {
  .block-text__link {
    font-size: 1.2rem;
  }
}
@media (max-width: 744px) {
  .block-text__link {
    font-size: 1.2rem;
  }
}
@media (max-width: 480px) {
  .block-text__link {
    font-size: 1.2rem;
  }
}
.block-text .content-cms {
  display: flex;
  flex-direction: column;
  row-gap: 2.4rem;
  color: var(--black);
  width: 100%;
  max-width: 93.6rem;
}
@media (max-width: 1200px) {
  .block-text .content-cms {
    row-gap: 1.6rem;
    max-width: 70.5rem;
  }
}
.block-text .content-cms p {
  margin: 0;
  font-size: 1.8rem;
  line-height: 1.4;
  letter-spacing: -0.02vw;
}
@media (max-width: 1200px) {
  .block-text .content-cms p {
    font-size: 1.6rem;
  }
}
@media (max-width: 744px) {
  .block-text .content-cms p {
    font-size: 1.4rem;
  }
}
@media (max-width: 480px) {
  .block-text .content-cms p {
    font-size: 1.2rem;
  }
}
.block-text .content-cms p:has(small) {
  line-height: 1.1;
  max-width: 74.2rem;
}
.block-text .content-cms p small {
  font-size: 1.4rem;
  color: var(--gray80);
}
@media (max-width: 1200px) {
  .block-text .content-cms p small {
    font-size: 1.2rem;
  }
}
@media (max-width: 744px) {
  .block-text .content-cms p small {
    font-size: 1.2rem;
  }
}
@media (max-width: 480px) {
  .block-text .content-cms p small {
    font-size: 1rem;
  }
}
.block-text .content-cms p:has(span) {
  max-width: 62.4rem;
}
.block-text .content-cms p span {
  color: var(--gray60);
}
.block-text .content-cms p a {
  color: var(--black);
}
.block-text .content-cms p b {
  font-weight: 500;
}
.block-text .content-cms h2 {
  font-family: "Stapel Expanded", serif;
  font-weight: 500;
  margin: 0;
  text-decoration: none;
  font-size: 2.4rem;
  letter-spacing: -0.01vw;
  line-height: 1.1;
}
@media (max-width: 1200px) {
  .block-text .content-cms h2 {
    font-size: 1.8rem;
  }
}
@media (max-width: 744px) {
  .block-text .content-cms h2 {
    font-size: 1.6rem;
  }
}
@media (max-width: 480px) {
  .block-text .content-cms h2 {
    font-size: 1.6rem;
  }
}
.block-text .content-cms h3 {
  font-family: "Stapel Expanded", serif;
  font-weight: 500;
  margin: 0;
  text-decoration: none;
  font-size: 2.4rem;
  letter-spacing: -0.01vw;
  line-height: 1.1;
}
@media (max-width: 1200px) {
  .block-text .content-cms h3 {
    font-size: 1.8rem;
  }
}
@media (max-width: 744px) {
  .block-text .content-cms h3 {
    font-size: 1.6rem;
  }
}
@media (max-width: 480px) {
  .block-text .content-cms h3 {
    font-size: 1.6rem;
  }
}
.block-text .content-cms div {
  display: flex;
  flex-direction: column;
}
.block-text .content-cms div:has(img) {
  margin-top: 1.6rem;
  margin-bottom: 1.6rem;
}
@media (max-width: 480px) {
  .block-text .content-cms div:has(img) {
    margin-top: 0.8rem;
    margin-bottom: 0.8rem;
  }
}
.block-text .content-cms div:has(figure) {
  width: 115.1rem;
}
@media (max-width: 1200px) {
  .block-text .content-cms div:has(figure) {
    width: 70.5rem;
  }
}
@media (max-width: 744px) {
  .block-text .content-cms div:has(figure) {
    width: 100%;
  }
}
.block-text .content-cms ul, .block-text .content-cms ol {
  margin: 0;
  padding-left: 2.4rem;
}
.block-text .content-cms ul p, .block-text .content-cms ol p {
  margin-left: -2.4rem;
  margin-bottom: 0.8rem;
}
@media (max-width: 480px) {
  .block-text .content-cms ul p, .block-text .content-cms ol p {
    margin-bottom: 0.4rem;
  }
}
.block-text .content-cms li {
  line-height: 1.4;
  font-size: 1.8rem;
}
@media (max-width: 1200px) {
  .block-text .content-cms li {
    font-size: 1.6rem;
  }
}
@media (max-width: 744px) {
  .block-text .content-cms li {
    font-size: 1.4rem;
  }
}
@media (max-width: 480px) {
  .block-text .content-cms li {
    font-size: 1.2rem;
  }
}
.block-text .content-cms li a {
  color: var(--black);
}
.block-text .content-cms figure {
  margin: 0;
}
.block-text .content-cms figure img,
.block-text .content-cms figure picture {
  width: 100%;
}
.block-text .content-cms figure:has(> img + img), .block-text .content-cms figure:has(> picture + picture) {
  display: flex;
  flex-wrap: wrap;
  gap: 4rem 2rem;
}
@media (max-width: 1200px) {
  .block-text .content-cms figure:has(> img + img), .block-text .content-cms figure:has(> picture + picture) {
    gap: 2.4rem 1.6rem;
  }
}
@media (max-width: 744px) {
  .block-text .content-cms figure:has(> img + img), .block-text .content-cms figure:has(> picture + picture) {
    gap: 1.6rem 1rem;
  }
}
.block-text .content-cms figure:has(> img + img) img,
.block-text .content-cms figure:has(> img + img) picture, .block-text .content-cms figure:has(> picture + picture) img,
.block-text .content-cms figure:has(> picture + picture) picture {
  width: 37rem;
  height: 47.6rem;
  border-radius: 1.2rem;
}
@media (max-width: 1200px) {
  .block-text .content-cms figure:has(> img + img) img,
  .block-text .content-cms figure:has(> img + img) picture, .block-text .content-cms figure:has(> picture + picture) img,
  .block-text .content-cms figure:has(> picture + picture) picture {
    width: 22.4rem;
    height: 30rem;
  }
}
@media (max-width: 744px) {
  .block-text .content-cms figure:has(> img + img) img,
  .block-text .content-cms figure:has(> img + img) picture, .block-text .content-cms figure:has(> picture + picture) img,
  .block-text .content-cms figure:has(> picture + picture) picture {
    width: 14rem;
    height: 20rem;
  }
}
@media (max-width: 480px) {
  .block-text .content-cms figure:has(> img + img) img,
  .block-text .content-cms figure:has(> img + img) picture, .block-text .content-cms figure:has(> picture + picture) img,
  .block-text .content-cms figure:has(> picture + picture) picture {
    width: 35.8rem;
    height: 41.5rem;
  }
}
.block-text .content-cms a.btn {
  margin-top: 1.6rem;
  max-width: 52.6rem;
}
@media (max-width: 480px) {
  .block-text .content-cms a.btn {
    margin-top: 0.8rem;
  }
}
.block-text .content-cms *:has(+ h2), .block-text .content-cms *:has(+ h3) {
  margin-bottom: 1.6rem;
}
@media (max-width: 480px) {
  .block-text .content-cms *:has(+ h2), .block-text .content-cms *:has(+ h3) {
    margin-bottom: 0.8rem;
  }
}
.block-text__signature {
  width: 31.2rem;
  height: 16.9rem;
  margin-left: auto;
  margin-top: 6.4rem;
}
@media (max-width: 1200px) {
  .block-text__signature {
    margin-top: 4.8rem;
  }
}
@media (max-width: 744px) {
  .block-text__signature {
    margin-top: 4rem;
  }
}
@media (max-width: 480px) {
  .block-text__signature {
    margin-top: 3.2rem;
  }
}
.block-text__signature img {
  width: 100%;
  height: 100%;
}
.block-text__content_center {
  width: 100%;
  max-width: 93.8rem;
  margin: 0 auto;
}
@media (max-width: 1200px) {
  .block-text__content_center {
    max-width: 70.5rem;
  }
}
.block-text__content_center .content-cms {
  max-width: 100%;
}
.block-text__content_center .content-cms p:has(span) {
  max-width: unset;
}
.block-text__content_center .content-cms div:has(> div > table) {
  margin-top: 4rem;
}
@media (max-width: 1200px) {
  .block-text__content_center .content-cms div:has(> div > table) {
    margin-top: 3.2rem;
  }
}
@media (max-width: 744px) {
  .block-text__content_center .content-cms div:has(> div > table) {
    margin-top: 2.4rem;
  }
}
@media (max-width: 480px) {
  .block-text__content_center .content-cms div:has(> table) {
    overflow-x: scroll;
    width: 100%;
    padding-bottom: 0.5rem;
  }
}
.block-text__content_center .content-cms ol li {
  list-style-type: "–  ";
}
.block-text__content_center .content-cms table {
  border-collapse: collapse;
  margin-top: 4.4rem;
}
@media (max-width: 1200px) {
  .block-text__content_center .content-cms table {
    margin-top: 2.4rem;
  }
}
@media (max-width: 744px) {
  .block-text__content_center .content-cms table {
    margin-top: 1.6rem;
  }
}
@media (max-width: 480px) {
  .block-text__content_center .content-cms table {
    width: 59rem;
  }
}
.block-text__content_center .content-cms table th {
  font-family: "Stapel Expanded", serif;
  font-weight: 500;
  text-decoration: none;
  letter-spacing: -0.01vw;
  line-height: 1.1;
  color: var(--black);
  font-size: 2.4rem;
  text-align: left;
  padding-bottom: 4rem;
  border-bottom: 0.1rem solid var(--gray20);
}
@media (max-width: 1200px) {
  .block-text__content_center .content-cms table th {
    font-size: 1.8rem;
    padding-bottom: 2.4rem;
  }
}
@media (max-width: 744px) {
  .block-text__content_center .content-cms table th {
    font-size: 1.6rem;
  }
}
.block-text__content_center .content-cms table th:nth-of-type(1) {
  width: 42rem;
}
@media (max-width: 1200px) {
  .block-text__content_center .content-cms table th:nth-of-type(1) {
    width: 32rem;
  }
}
@media (max-width: 480px) {
  .block-text__content_center .content-cms table th:nth-of-type(1) {
    width: 30rem;
  }
}
.block-text__content_center .content-cms table td {
  letter-spacing: -0.03vw;
  line-height: 1.4;
  color: var(--gray80);
  font-size: 1.6rem;
  padding: 2.4rem 0;
  border-bottom: 0.1rem solid var(--gray20);
}
@media (max-width: 1200px) {
  .block-text__content_center .content-cms table td {
    font-size: 1.4rem;
    padding-right: 4rem;
  }
}
@media (max-width: 744px) {
  .block-text__content_center .content-cms table td {
    font-size: 1.2rem;
    padding: 1.6rem 0;
    padding-right: 4rem;
  }
}
.block-text__content_center .content-cms table td[colspan] {
  padding-right: 0;
}

.care-menu {
  width: 51.4rem;
  flex-shrink: 0;
}
@media (max-width: 1200px) {
  .care-menu {
    width: 26.8rem;
  }
}
@media (max-width: 744px) {
  .care-menu {
    width: 22rem;
  }
}
@media (max-width: 480px) {
  .care-menu {
    display: none;
  }
}
.care-menu__sticky {
  position: sticky;
  top: 15rem;
  transition-duration: 0.3s;
}
@media (max-width: 1200px) {
  .care-menu__sticky {
    top: 13rem;
  }
}
@media (max-width: 744px) {
  .care-menu__sticky {
    top: 11rem;
  }
}
.care-menu__nav {
  margin-top: 4rem;
  display: flex;
  flex-direction: column;
  row-gap: 3.2rem;
}
@media (max-width: 1200px) {
  .care-menu__nav {
    row-gap: 1.6rem;
  }
}
@media (max-width: 744px) {
  .care-menu__nav {
    margin-top: 3.2rem;
  }
}
.care-menu__link {
  text-decoration: none;
  display: flex;
  align-items: center;
  column-gap: 1.8rem;
  opacity: 0.5;
  transition-duration: 0.3s;
  font-size: 1.8rem;
}
@media (max-width: 1200px) {
  .care-menu__link {
    font-size: 1.6rem;
  }
}
@media (max-width: 744px) {
  .care-menu__link {
    column-gap: 0.8rem;
    font-size: 1.4rem;
  }
}
.care-menu__link-icon, .care-menu__link-arrow {
  width: 2.4rem;
  height: 2.4rem;
}
@media (max-width: 744px) {
  .care-menu__link-icon, .care-menu__link-arrow {
    width: 2rem;
    height: 2rem;
  }
}
.care-menu__link-arrow {
  transition-duration: 0.3s;
  margin-left: -1rem;
  opacity: 0;
}
@media (max-width: 744px) {
  .care-menu__link-arrow {
    margin-left: -0.8rem;
  }
}
.care-menu__link_active {
  opacity: 1;
}
.care-menu__link_active .care-menu__link-arrow {
  opacity: 1;
}
.care-menu__link:hover {
  opacity: 1;
}
.care-menu__link:hover .care-menu__link-arrow {
  opacity: 1;
}
.care-menu__hello {
  margin-top: 1.2rem;
  font-size: 1.6rem;
  font-style: normal;
  font-weight: 400;
  line-height: 140%;
  color: var(--gray60);
}
@media (max-width: 1200px) {
  .care-menu__hello {
    font-size: 1.4rem;
    margin-top: 1rem;
  }
}
@media (max-width: 744px) {
  .care-menu__hello {
    font-size: 1.2rem;
  }
}
@media (max-width: 480px) {
  .care-menu__hello {
    margin-top: 0.5rem;
    margin-bottom: 1.6rem;
  }
}