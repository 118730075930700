

.shops{

    &__panel{
        margin-bottom: rem(24);
        display: flex;
        column-gap: rem(20);
        @include laptop{
            column-gap: rem(16);
        }
        @include tablet{
            column-gap: rem(10);
            margin-top: rem(48);
        }
        @include mobile{
            flex-wrap: wrap;
            position: relative;
            row-gap: rem(8);
            margin-top: rem(36);
        }
    }

    &__panel-left{
        display: flex;
        align-items: center;
        column-gap: rem(24);
        width: 50%;
        @include tablet{
            flex-wrap: wrap;
            row-gap: rem(8);
        }
        @include mobile{
            width: 100%;
        }
    }

    &__panel-right{
        display: flex;
        align-items: center;
        column-gap: rem(24);
        width: 50%;

        @include tablet{
            align-items: flex-start;
        }
        @include mobile{
            width: 100%;
            padding-bottom: rem(32);
        }
    }

    &__panel-text{
        color: var(--gray60);
        @include mobile{
            position: absolute;
            left: 0;
            bottom: 0;
        }
    }

    &__panel-label{
        display: block;
        color: var(--gray40);
        white-space: nowrap;
        display: flex;
        align-items: center;
        column-gap: rem(8);
        user-select: none;
        font-size: rem(16);
        cursor: pointer;

        input{
            position: absolute;
            opacity: 0;
            pointer-events: none;
        }

        @include laptop{
            font-size: rem(14);
        }
        @include tablet{
            font-size: rem(12);
            display: none;
        }

        &::before{
            content: '';
            width: rem(24);
            height: rem(24);
            background: url(/img/svg/checkbox-empty.svg) no-repeat;
            background-size: cover;
            flex-shrink: 0;
            opacity: 0.4;
        }

        &:has(input:checked){
            color: var(--black);
            &::before{
                background: url(/img/svg/checkbox-full.svg) no-repeat;
                background-size: cover;
                opacity: 1;
            }
        }
    }

    &__panel-select{
        display: none;
        @include tablet{
            display: block;
            width: 100%;
            height: rem(44);
            .select__list_check{
                height: rem(210);
            }
            .select__scroll {
                height: calc(rem(210) - rem(38));
            }
        }
    }

    &__box{
        display: flex;
        column-gap: rem(20);
        @include laptop{
            column-gap: rem(16);
        }
        @include tablet{
            row-gap: rem(16);
            flex-wrap: wrap;
        }
    }

    &__left{
        width: 50%;
        height: rem(800);
        border: rem(1) solid var(--gray20);
        padding: rem(72);
        overflow-y: auto;
        display: flex;
        flex-direction: column;
        row-gap: rem(40);
        @include laptop{
            height: rem(488);
            padding: rem(48);
            row-gap: rem(30);
        }
        @include tablet{
            width: 100%;
        }
        @include mobile{
            padding: rem(24);
            height: rem(340);
            row-gap: rem(24);
        }
    }

    &__right{
        width: 50%;
        height: rem(800);
        background: #ddd;
        @include laptop{
            height: rem(488);
        }
        @include tablet{
            width: 100%;
        }
        @include mobile{
            height: rem(340);
        }
    }

    &__block{
        flex-shrink: 0;
        padding-bottom: rem(40);
        border-bottom: rem(1) solid var(--gray40);
        display: flex;
        flex-direction: column;
        row-gap: rem(16);
        @include laptop{
            padding-bottom: rem(30);
        }
        @include mobile{
            padding-bottom: rem(24);
        }

        &:last-of-type {
            border: none;
            padding-bottom: 0;
        }

        &_hidden{
            display: none;
        }

    }

    &__block-title{
        @include laptop{
            font-size: rem(20);
        }
        @include tablet{
            font-size: rem(18);
        }
        @include mobile{
            font-size: rem(16);
        }
    }

    &__block-text{
        display: flex;
        flex-direction: column;
        row-gap: rem(4);
        
        p{
            margin: 0;
        }

        &_gray *{
            color: var(--gray80);
        }
    }

    &__block-grid{
        display: flex;
        flex-wrap: wrap;
        gap: rem(18);
        margin-top: rem(10);
        @include laptop{
            gap: rem(10);
        }
        @include tablet{
            gap: rem(10) rem(40);
        }

        &_links{
            flex-wrap: nowrap;
        }
    }

    &__block-cell{
        display: flex;
        column-gap: rem(18);
        width: rem(272);

        @include laptop{
            width: rem(220);
            column-gap: rem(12);
        }

        *{
            color: var(--gray80);
        }
    }

    &__block-icon{
        flex-shrink: 0;
        width: rem(24);
        height: rem(24);

        img{
            width: 100%;
            height: 100%;
        }
    }

    &__block-buttons{
        margin-top: rem(10);
        display: flex;
        //column-gap: ;
    }

    &__block-link{
        display: block;

        &_gray{
            color: var(--gray50);
            border-color: var(--gray50);
        }

        @include mobile{
            font-size: rem(12);
        }

    }



    &__map{
        width: 100%;
        height: 100%;
    }


}


.map-baloon-text{
    margin: rem(20) 0;
    p{
        margin: 0;
    }
}