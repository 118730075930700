

.materials-page{

    &__section{
        .container{
            max-width: rem(1608);
        }

        &_wide{
            .container{
                max-width: unset;
            }
        }

        &_sizes{
            .materials-page__right{
                @include tablet{
                    align-content: end;
                }
            }
            .materials-page__image{
                height: rem(550);
                @include laptop{
                    height: rem(431);
                }
                @include tablet{
                    height: rem(192);
                }
            }
        }
    }

    &__box{
        display: flex;
        column-gap: rem(120);
        position: relative;

        @include laptop{
            column-gap: rem(110);
        }
        @include tablet{
            column-gap: rem(50);
        }
        @include mobile{
            flex-wrap: wrap;
            row-gap: rem(16);
        }
        
        &_reverse{
            flex-direction: row-reverse;
            justify-content: flex-end;
            @include mobile{
                flex-direction: row;

                &:has( > .materials-page__right > .materials-page__image_wide ) {
                    flex-direction: row-reverse;
                    flex-wrap: wrap-reverse;
                }

                &:has( > .materials-page__right_single ) {
                    flex-direction: row-reverse;
                    flex-wrap: wrap-reverse;
                }
            }
        }
    }

    &__left{
        padding-top: rem(40);
        display: flex;
        flex-direction: column;
        row-gap: rem(16);
        position: relative;

        @include laptop{
            padding-top: 0;
            max-width: rem(464);
            &:has( > .materials-page__text_item ) {
                padding-bottom: rem(56);
            }
        }
        @include tablet{
            padding-bottom: 0;
            max-width: rem(330);
        }
        @include mobile{
            max-width: unset;
        }

        &_pt{
            padding-top: rem(76);

            @include laptop{
                padding-top: rem(56);
            }
            @include tablet{
                padding-top: rem(24);
            }
            @include mobile{
                padding-top: rem(0);
            }
        }
    }

    &__right{
        display: flex;
        column-gap: rem(20);
        @include laptop{
            gap: rem(16);
            flex-wrap: wrap;
        }
        @include tablet{
            gap: rem(10);
            align-items: flex-start;
            align-content: baseline;
            max-width: rem(330);
        }
        @include mobile{
            gap: rem(9);
            max-width: unset;
            width: 100%;
        }
        

        &_single{
            @include tablet{
                .materials-page__image:nth-of-type(1) {
                    display: none;
                }
                .materials-page__image:nth-of-type(2) {
                    width: rem(290);
                    height: rem(381);
                }
            }
            @include mobile{
                .materials-page__image:nth-of-type(2) {
                    width: 100%;
                    height: rem(377);
                }
            }
        }
    }

    &__image{
        width: rem(448);
        height: rem(600);
        border-radius: rem(20);
        overflow: hidden;

        @include laptop{
            width: rem(272);
            height: rem(364);
        }
        @include tablet{
            width: rem(160);
            height: rem(216);
        }
        @include mobile{
            width: rem(174);
            height: rem(232);
        }

        &_wide{
            width: rem(680);
            height: rem(442);

            @include laptop{
                width: rem(512);
                height: rem(333);
            }
            @include tablet{
                width: rem(320);
                height: rem(208);
            }
            @include mobile{
                width: 100%;
                height: rem(233);
            }
        }
        
        img{
            display: block;
            width: 100%;
            height: 100%;
        }
    }

    &__title{
        margin-bottom: rem(16);

        @include laptop{
            font-size: rem(28);
        }
        @include tablet{
            font-size: rem(26);
            margin-bottom: rem(8);
        }
        @include mobile{
            font-size: rem(20);
            
        }
    }

    &__subtitle{
        margin-top: rem(24);
        margin-bottom: rem(8);

        &_auto{
            margin-top: auto;
            @include laptop{
                margin-top: rem(24);
            }
        }

        @include laptop{
            font-size: rem(20);
            margin-bottom: 0;
        }
        @include tablet{
            font-size: rem(18);
            margin-top: rem(10);
        }
        @include mobile{
            font-size: rem(16);
        }
    }


    &__text{
        max-width: rem(644);

        @include mobile{
            //font-size: rem(14);
        }

        &_item{
            margin-top: auto;
            max-width: rem(210);
            @include laptop{
                display: none;
            }
        }
        &_note{
            @include tablet{
                display: none;
            }
            @include mobile{
                display: block;
                margin-top: rem(-8);
                font-size: rem(12);
                max-width: rem(340);
            }
        }
        &_laptop{
            display: none;
            @include laptop{
                display: block;
                max-width: rem(210);
            }
            @include tablet{
                max-width: rem(160);
            }
        }
        &_tablet{
            display: none;
            @include tablet{
                display: block;
                margin-bottom: rem(24);
            }
            @include mobile{
                display: none;
            }
        }
    }

    &__btn{
        margin-top: rem(44);
        max-width: rem(308);

        @include laptop{
            margin-top: auto;
        }
        @include tablet{
            display: none;
        }

        &_tablet{
            display: none;
            @include tablet{
                display: block;
                width: 100%;
                max-width: unset;
                margin-top: rem(24);
            }
            @include mobile{
                margin-top: rem(16);
            }
        }
    }

    &__list{
        margin: 0;
        padding-left: rem(28);
        display: flex;
        flex-direction: column;
        row-gap: rem(24);

        @include laptop{
            row-gap: rem(16);
        }
        @include mobile{
            row-gap: rem(8);
        }
    }

    &__content-box{
        display: flex;

        @include mobile{
            flex-wrap: wrap;
            row-gap: rem(16);
        }

        &_images{
            justify-content: space-between;

            .materials-page__content-col{
                width: rem(604);

                @include laptop{
                    width: rem(368);
                }
                @include tablet{
                    width: rem(230);
                }
                @include mobile{
                    width: rem(174);
                }
            }
        }
    }

    &__content-col{
        width: 50%;
        display: flex;
        flex-direction: column;
        row-gap: rem(16);

        @include mobile{
            width: 100%;
            &:nth-of-type( 3n + 1 ) {
                display: none;
            }
        }
    }

    &__content-title{
        max-width: rem(600);
        margin-bottom: rem(32);
        
        @include laptop{
            max-width: rem(494);
            font-size: rem(28);
        }
        @include tablet{
            font-size: rem(26);
            margin-bottom: rem(24);
        }
        @include mobile{
            font-size: rem(20);
        }
    }

    &__content-text{
        max-width: rem(600);

        @include laptop{
            max-width: rem(500);
        }
        @include tablet{
            max-width: rem(330);
        }
        @include mobile{
            //font-size: rem(14);
        }

        & + .materials-page__content-subtitle{
            margin-top: rem(16);
            @include tablet{
                margin-top: 0;
            }
        }
    }

    &__content-subtitle{
        @include laptop{
            font-size: rem(18);
        }
        @include tablet{
            font-size: rem(16);
        }
        @include mobile{
            font-size: rem(16);
        }
    }

    &__content-image{
        width: 100%;
        height: rem(680);

        @include laptop{
            height: rem(417);
        }
        @include tablet{
            height: rem(261);
        }
        @include mobile{
            height: rem(198);
        }

        img{
            width: 100%;
            height: 100%;
        }
    }

    &__block-banner{
        .block-banner__box{
            border-radius: rem(20);
            overflow: hidden;

            @include mobile{
                border-radius: 0;
            }
        }

        .container{
            @include mobile{
                padding: 0;
            }
        }
    }

}