.block-product {
  &__inner {
  }

  &__box {
    z-index: 5;
    display: flex;
    column-gap: rem(78);
    position: relative;

    @include laptop {
      column-gap: rem(48);
    }
    @include tablet {
      column-gap: rem(30);
    }
    @include mobile {
      flex-wrap: wrap;
      row-gap: rem(16);
    }

    .swiper-pagination {
      height: rem(20);
      bottom: rem(12);
    }
    .swiper-pagination-bullet {
      width: rem(72);
      height: rem(4);
      border-radius: rem(90);
      margin: rem(8) rem(6);
      background: var(--black);
      transition-duration: 0.3s;
      @include tablet {
        width: rem(35);
        margin: rem(8) rem(2.5);
        height: rem(2);
      }
    }
    .swiper-pagination-bullet-active {
      background: var(--white);
    }
  }

  &__visual {
    display: flex;
    flex-wrap: wrap;
    gap: rem(20);
    align-items: flex-start;
    align-content: baseline;

    @include laptop {
      gap: rem(16);
    }
    @include tablet {
      gap: rem(10);
    }
    @include mobile {
      flex-wrap: unset;
      gap: unset;
    }
  }

  &__image {
    width: rem(604);
    height: rem(800);
    overflow: hidden;
    border-radius: rem(24);

    @include laptop {
      width: rem(368);
      height: rem(488);
      border-radius: rem(20);
    }
    @include tablet {
      width: rem(350);
      height: rem(464);
    }
    @include mobile {
      width: rem(358);
      height: rem(474);
    }

    img {
      width: 100%;
      height: 100%;
    }
  }

  &__modal {
    display: flex;
    flex-direction: column;
    @include tablet {
      margin-top: rem(24);
    }
  }

  &__modal-link {
    text-decoration: none;
    padding: rem(25) 0;
    border-bottom: rem(1) solid var(--black);
    display: flex;
    align-items: center;
    justify-content: space-between;

    &:nth-of-type(1) {
      border-top: rem(1) solid var(--black);
    }

    @include laptop {
      padding: rem(14) 0;
      font-size: rem(12);
    }

    @include tablet {
      font-size: rem(12);
      padding: rem(14) 0;
    }

    @include mobile {
      font-size: rem(12);
    }
  }

  &__info {
    width: rem(546);
    display: flex;
    flex-direction: column;
    row-gap: rem(24);
    position: sticky;
    top: rem(120);
    height: fit-content;
    z-index: 6;

    @include laptop {
      width: rem(336);
      top: rem(110);
    }
    @include tablet {
      width: rem(330);
      top: rem(100);
    }
    @include mobile {
      width: 100%;
      row-gap: rem(16);
    }

    &_gap {
      position: relative;
      top: unset;
      row-gap: rem(32);
      @include laptop {
        row-gap: rem(16);
      }
    }
  }

  &__favourite {
    width: rem(24);
    height: rem(24);
    background: url(/img/svg/favourite.svg) no-repeat;
    background-size: cover;
    position: absolute;
    top: 0;
    right: 0;
    cursor: pointer;

    input {
      position: absolute;
      pointer-events: none;
      opacity: 0;
    }

    &:has(input:checked) {
      background: url(/img/svg/favourite-fill.svg) no-repeat;
      background-size: cover;
    }

    @include mobile {
      top: rem(46);
    }
  }

  &__tags{
      display: flex;
      flex-wrap: wrap;
      gap: rem(8);
  }

  &__sale {
    border-radius: rem(6);
    background: var(--gray00);
    padding: rem(8) rem(12);
    color: var(--gray70);
    //margin-right: auto;
  }

  &__title {
    font-size: rem(28);
    @include laptop {
      font-size: rem(20);
      margin-bottom: rem(-8);
    }
    @include tablet {
      font-size: rem(18);
    }
    @include mobile {
      font-size: rem(22);
      margin-bottom: 0;
      max-width: rem(312);
      br {
        display: none;
      }
    }
  }

  &__price {
    display: flex;
    align-items: center;
    position: relative;
    column-gap: rem(16);
    @include laptop {
      column-gap: rem(9);
    }
  }

  &__price-current {
    @include mobile {
      font-size: rem(16);
    }
  }

  &__price-old {
    
    @include mobile {
      font-size: rem(10);
    }
  }

  &__gift {
    padding: rem(10) rem(12);
    margin-left: auto;
    display: flex;
    align-items: center;
    column-gap: rem(4);
    background: var(--sageLight);
    border-radius: rem(12);
    color: var(--black);
    cursor: pointer;
    transition-duration: 0.3s;

    @include laptop {
      padding: rem(8) rem(10);
    }
    @include tablet {
      padding: rem(6) rem(10);
      border-radius: rem(8);
    }

    img {
      width: rem(24);
      height: rem(24);
      pointer-events: none;
      transition-duration: 0.3s;
      @include tablet {
        width: rem(20);
        height: rem(20);
      }
    }

    &:hover {
      background: var(--sageDark);
      color: var(--white);
      img {
        filter: invert(1);
      }
    }

    &:active {
      background: var(--sage);
      color: var(--white);
      img {
        filter: invert(1);
      }
    }
  }

  &__colors {
    position: relative;
    width: 100%;
  }

  &__colors-title,
  &__size-title {
    display: none;
    @include mobile {
      display: block;
      font-size: rem(16);
      margin-top: rem(16);
    }
  }

  &__swiper {
    width: calc(100% - rem(24) - rem(8));
    margin-left: 0;
    position: relative;
    /* @include laptop{
            width: 100%;
        } */
    @include tablet {
      width: calc(100% + rem(9));
    }
    @include mobile {
      margin-top: rem(8);
    }
  }

  &__swiper-slide {
    width: rem(90);
    height: rem(118);
    margin-right: rem(16);
    border-radius: rem(12);
    overflow: hidden;
    border: rem(2) solid transparent;
    transition: border 0.3s;
    cursor: pointer;
    user-select: none;

    @include laptop {
      width: rem(56);
      height: rem(72);
      margin-right: rem(8);
      border: rem(1) solid transparent;
    }
    @include tablet {
      margin-right: rem(6);
    }

    &:nth-last-of-type(1) {
      margin-right: 0;
    }

    input {
      position: absolute;
      pointer-events: none;
      opacity: 0;
    }

    &:has(input:checked) {
      border: rem(2) solid var(--black);
      @include laptop {
        border: rem(1) solid var(--black);
      }
    }
  }

  &__cover {
    img {
      width: 100%;
      height: 100%;
      pointer-events: none;
    }
  }

  &__swiper-prev {
    position: absolute;
    left: rem(-24);
    top: 50%;
    transform: translateY(-50%);
    cursor: pointer;

    img {
      width: rem(24);
      height: rem(24);
    }

    &.swiper-button-disabled {
      opacity: 0;
    }

    @include tablet {
      display: none;
    }
  }
  &__swiper-next {
    position: absolute;
    right: 0;
    top: 50%;
    transform: translateY(-50%);
    cursor: pointer;

    img {
      width: rem(24);
      height: rem(24);
    }

    &.swiper-button-disabled {
      opacity: 0;
    }

    @include tablet {
      display: none;
    }
  }

  &__size {
    display: flex;
    flex-direction: column;
    row-gap: rem(24);
    margin-top: rem(16);

    @include laptop {
      margin-top: 0;
    }
    @include mobile {
      .select {
        display: none;
      }
    }
  }

  &__size-swiper {
    display: none;
    @include mobile {
      display: block;
      width: calc(100% + rem(9));
    }
  }

  &__size-label {
    cursor: pointer;
    text-align: center;
    width: rem(70);
    height: rem(32);
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: rem(8);
    user-select: none;
    border: rem(1) solid var(--gray00);
    font-weight: 400;
    font-size: rem(12);
    margin-right: rem(8);

    * {
      color: var(--gray60);
    }

    input {
      position: absolute;
      width: rem(16);
      height: rem(16);
      opacity: 0;
      pointer-events: none;
    }

    &:has(input:checked) {
      background: var(--gray10);
      border: rem(1) solid transparent;
      * {
        color: var(--black);
      }
    }
  }

  &__link {
    color: var(--gray40);
    border-bottom: rem(1) solid var(--gray40);
    margin-top: rem(-8);
    font-size: rem(12);
    @include mobile {
      margin-top: 0;
    }
  }

  &__cart {
    margin-top: rem(8);
    transition-duration: 0.3s;
    @include laptop {
      margin-top: 0;
    }
    @include laptop {
      margin-top: rem(16);
    }

    &_fix {
      transition-duration: 0.3s;
      position: fixed;
      top: calc(100dvh - rem(70));
      z-index: 150;
      width: calc(100% - rem(18));
    }
  }

  &__more {
    margin-top: rem(40);
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 100%;

    @include mobile {
      margin-top: rem(16);
    }
  }

  &__more-title {
    font-size: rem(24);
    @include laptop {
      font-size: rem(16);
    }
    @include tablet {
      font-size: rem(18);
    }
    @include mobile {
      font-size: rem(22);
    }
  }

  &__more-link {
    margin-right: 0;
    font-size: rem(14);
    @include laptop {
      font-size: rem(12);
    }
    @include tablet {
      font-size: rem(12);
    }
    @include mobile {
      display: none;
    }
    &_mobile {
      display: none;
      position: absolute;
      width: 100%;
      justify-content: flex-end;
      @include mobile {
        display: flex;
      }
    }
  }

  &__payment {
    padding: rem(13) rem(21);
    padding-right: rem(37);
    background: var(--gray00);
    border-radius: rem(8);
    text-decoration: none;
    letter-spacing: 0.05vw;
    font-weight: 500;
    font-size: rem(16);
    color: var(--black);
    display: flex;
    align-items: center;
    justify-content: space-between;
    position: relative;

    &-arrow {
      width: rem(16);
      height: rem(16);
      position: absolute;
      right: rem(18);
      top: 50%;
      transform: translateY(-50%);
      @include laptop {
        right: rem(12);
      }
    }

    @include laptop {
      padding: rem(11) rem(15);
      padding-right: rem(31);
    }
    @include mobile {
      order: -1;
      margin-top: rem(16);
    }
  }

  &__accordions {
    border-bottom: rem(1) solid var(--black);
    @include laptop {
      margin-top: rem(16);
    }
  }

  &__addition {
    display: flex;
    column-gap: rem(16);
    @include laptop {
      column-gap: rem(14);
      margin-bottom: rem(20);
    }
    @include mobile {
      margin-bottom: 0;
      column-gap: rem(8);
    }
  }

  &__card {
    width: rem(265);
    display: flex;
    flex-direction: column;
    row-gap: rem(10);

    @include laptop {
      width: rem(162);
    }

    @include mobile {
      width: rem(175);
    }
  }

  &__card-cover {
    width: rem(265);
    height: rem(352);
    border-radius: rem(16);
    overflow: hidden;

    @include laptop {
      width: rem(162);
      height: rem(206);
    }
    @include mobile {
      width: rem(175);
      height: rem(224);
    }

    img {
      width: 100%;
      height: 100%;
    }
  }

  &__card-title {
    margin-top: rem(10);
  }

  &__card-price {
    display: flex;
    column-gap: rem(8);
    align-items: flex-end;
  }

  &__card-current {
    font-weight: 500;
  }

  &__card-old {
    color: var(--gray30);
  }

  &__card-bottom {
    margin-top: rem(6);
    display: flex;
    column-gap: rem(10);
    margin-bottom: rem(2);
    @include laptop{
        column-gap: rem(6);
    }
  }

  &__card-cart {
    padding: 0;
    width: rem(56);
    height: rem(56);
    flex-shrink: 0;

    @include laptop {
      width: rem(44);
      height: rem(44);
      border-radius: rem(8);
    }

    img {
      width: rem(24);
      height: rem(24);
      filter: invert(1);
    }
  }
}
