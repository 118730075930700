

.news-page{

    &__section{
        &_certificate{
            
            .news-page__title{
                font-size: rem(36);
                @include laptop{
                    font-size: rem(28);
                }
                @include tablet{
                    font-size: rem(26);
                }
                @include mobile{
                    font-size: rem(20);
                }
            }

            .news-page__title_fix{
                @include tablet{
                    max-width: rem(409);
                }
            }

            .news-page__box_reverse{
                flex-direction: row-reverse;
                @include tablet{
                    flex-direction: row;
                }
            }

            .news-page__left{
                @include tablet{
                    right: 0;
                }
            }
            .news-page__right{
                @include tablet{
                    width: rem(407);
                }
            }



        }

        &_content{

            .news-page__left{
                @include tablet{
                    width: 50%;
                    position: relative;
                    bottom: unset;
                }
                @include mobile{
                    width: 100%;
                }
            }
            .news-page__right{
                @include tablet{
                    width: 50%;
                    position: relative;
                    bottom: unset;
                    padding: 0;
                }
                @include mobile{
                    width: 100%;
                }
            }
            .news-page__box{
                @include mobile{
                    flex-wrap: wrap;
                    row-gap: rem(24);
                }
            }
                
        }
    }

    &__box{
        display: flex;
        position: relative;

        &_reverse{
            flex-direction: row-reverse;

            .news-page__image{
                margin-left: auto;
            }

            .news-page__products{
                @include tablet{
                    padding-left: 0;
                    padding-right: rem(240);
                }
                @include mobile{
                    padding: 0;
                    padding-top: rem(400);
                }
            }
            .news-page__left{
                @include tablet{
                    right: 0;
                }
            }
        }
    }

    &__left{
        position: relative;
        width: 50%;
        @include tablet{
            position: absolute;
            width: rem(230);
            bottom: 0;
        }
        @include mobile{
            width: 100%;
            bottom: rem(350);
        }
    }

    &__right{
        position: relative;
        width: 50%;
        display: flex;
        flex-direction: column;
        row-gap: rem(16);
        @include tablet{
            width: 100%;
        }
    }

    &__image{
        border-radius: rem(20);
        overflow: hidden;
        width: rem(682);
        height: rem(920);

        @include laptop{
            width: rem(464);
            height: rem(628);
        }
        @include tablet{
            width: rem(230);
            height: rem(385);
        }
        @include mobile{
            width: rem(358);
            height: rem(382);
        }

        &_certificate{
            height: rem(480);
            @include laptop{
                height: rem(320);
            }
            @include tablet{
                height: rem(207);
            }
            @include mobile{
                display: none;
            }
        }

        &_height{
            @include laptop{
                height: 100%;

                img{
                    object-position: left;
                }
            }
            @include tablet{
                height: rem(300);
            }
        }

        &_mobile{
            display: none;

            @include mobile{
                display: block;
                position: relative;
                width: 100%;
                height: rem(207);
            }
        }
        
        img{
            display: block;
            width: 100%;
            height: 100%;
        }
    }

    &__title{
        
        &_fix{
            max-width: rem(550);
            @include laptop{
                max-width: rem(360);
            }
            @include tablet{
                max-width: rem(316);
            }
            
        }

        @include laptop{
            font-size: rem(20);
        }
        @include tablet{
            font-size: rem(18);
        }
        @include mobile{
            font-size: rem(16);
        }
    }

    &__text{
        max-width: rem(640);

        &_fix{
            max-width: rem(550);
            @include tablet{
                max-width: rem(428);
            }
        }
    }

    &__products{
        margin-top: rem(16);
        display: flex;
        column-gap: rem(20);
        @include tablet{
            margin-top: rem(4);
            padding-left: rem(240);
            column-gap: rem(8);
        }
        @include mobile{
            padding: 0;
            padding-top: rem(400);

            .block-catalog__card{
                height: rem(330);
                margin-right: 0;
            }
            .block-catalog__card-image{
                height: rem(232);
                width: 100%;
            }
        }
    }

    &__info{
        display: flex;
        flex-wrap: wrap;
        max-width: rem(760);
        justify-content: space-between;
        padding-right: rem(60);
        margin-top: rem(16);

        @include tablet{
            padding-right: 0;
            margin-top: 0;
        }
    }

    &__info-col{
        display: flex;
        flex-direction: column;
        row-gap: rem(8);
        max-width: rem(230);

        @include tablet{
            max-width: rem(170);
        }

        h1,h2,h3,h4,h5 {
            margin-bottom: rem(8);
        }

    }

    &__btn{
        margin-top: rem(44);
        max-width: rem(760);

        @include tablet{
            margin-top: rem(40);
        }
        @include mobile{
            margin-top: 0;
        }
    }

    &__content{
        display: flex;
        flex-direction: column;
        row-gap: rem(16);
    }

    &__content-title{
        max-width: rem(594);
        margin-bottom: rem(32);
        font-size: rem(36);
        @include laptop{
            font-size: rem(28);
            max-width: rem(540);
        }
        @include tablet{
            font-size: rem(26);
            margin-bottom: rem(24);
        }
        @include mobile{
            font-size: rem(20);
        }
    }

    &__content-subtitle{
        max-width: rem(514);
        margin-bottom: rem(12);
        font-size: rem(28);
        @include laptop{
            font-size: rem(20);
        }
        @include tablet{
            font-size: rem(18);
            max-width: rem(244);
            margin-bottom: 0;
        }
        @include mobile{
            font-size: rem(16);
        }
    }

    &__content-text{
        max-width: rem(465);
        @include tablet{
            max-width: rem(318);
        }
    }


}