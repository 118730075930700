



.accordion{
    user-select: none;

    &_open{

    }

    &_footer-mobile{
        border-bottom: rem(1) solid var(--gray10);
        padding-bottom: rem(8);
        .accordion__title{
            min-height: rem(20);
            .t-navigation{
                width: 100%;
                color: var(--gray40);
                &::after{
                    right: 0;
                    transform: translateY(-50%) rotate(-90deg);
                    opacity: 0.3;
                    top: calc(50%);
                }
            }
        }
    }

    &_borders{
        padding: rem(24) 0;
        border-top: rem(1) solid var(--black);

        @include laptop{
            padding: rem(14) rem(0);
        }

        .accordion__content{
            padding-top: rem(16);
            max-width: calc(100% - rem(48));
        }
        .accordion__title div{
            width: 100%;
            &::after{
                content: '';
                right: 0;
            }
            @include laptop{
                font-size: rem(14);
            }
            @include mobile{
                font-size: rem(12);
            }
        }

        &:nth-last-of-type(1){
            border-bottom: rem(1) solid var(--black);
        }
        
    }

    &_vacancy{
        padding: rem(20);
        border: rem(1) solid var(--black);
        border-radius: rem(12);

        .accordion__title div{
            width: 100%;
            &::after{
                content: '';
                right: 0;
                opacity: 0.6;
            }
            @include laptop{
                font-size: rem(14);
            }
            @include mobile{
                font-size: rem(12);
            }
        }

        .accordion__content{
            padding-top: rem(16);
            display: flex;
            flex-direction: column;
            row-gap: rem(16);
        }

        .accordion__content-block{
            display: flex;
            flex-direction: column;
            row-gap: rem(8);
        }

        .accordion__content-ul{
            padding: 0;
            margin: 0;
            padding-left: rem(22);
            
            li{
                color: var(--gray60);
            }
        }

        .accordion__text{
            color: var(--gray80);
        }

        .accordion__text_gray{
            color: var(--gray60);
        }

        .accordion__text_fix{
            max-width: rem(440);
            @include laptop{
                max-width: rem(390);
            }
            @include tablet{
                max-width: rem(340);
            }
        }

        &:has(.accordion__top_open) {
            background: var(--white);
        }

    }

    &__top{
        position: relative;
        transition-duration: 0.3s;

        &_open{
            .accordion__title{
                .t-navigation::after{
                    top: calc(50%);
                    transform: translateY(-50%) scaleY(-1);

                    @include laptop{
                        top: calc(50% + rem(1));
                    }
                }
            }

            .accordion__title div{
                &::after{
                    content: '';
                    transform: translateY(-50%) scaleY(-1);
                }
            }

        }
    }

    &__title{
        position: relative;
        cursor: pointer;

        div{
            position: relative;
            display: inline-block;
        }

        div::after{
            content: '';
            display: block;
            position: absolute;
            width: rem(24);
            height: rem(24);
            right: rem(-30);
            top: calc(50% - rem(2));
            background: url(/img/svg/accordion-arrow.svg) no-repeat;
            background-size: cover;
            transition-duration: 0.3s;
            transform: translateY(-50%);

            @include laptop{
                width: rem(20);
                height: rem(20);
                right: rem(-24);
                top: calc(50% - rem(1));
            }

        }

        &:has(+ .accordion__text){
            margin-bottom: rem(8);
        }
        
    }

    &__hide{
        height: 0;
        overflow: hidden;
        transition-duration: 0.3s;
        
        &_absolute{
            position: absolute;
        }
    }

    &__content{
        padding-top: rem(24);

        @include laptop{
            padding-top: rem(16);
        }
        @include tablet{
            padding-bottom: rem(12);
            padding-top: rem(20);
        }

        .footer__nav-link{
            white-space: unset;
            color: var(--gray80);

            @include tablet{
                color: var(--black);
            }
        }
    }

    &__text{
        &_gray{
            color: var(--gray60);
        }
        &_title{
            margin-bottom: rem(16);
            font-size: rem(18);
        }
    }


}