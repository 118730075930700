.care-menu {
  width: rem(514);
  flex-shrink: 0;

  @include laptop {
    width: rem(268);
  }
  @include tablet {
    width: rem(220);
  }
  @include mobile {
    display: none;
  }

  &__sticky {
    position: sticky;
    top: rem(150);
    transition-duration: 0.3s;
    @include laptop {
      top: rem(130);
    }
    @include tablet {
      top: rem(110);
    }
  }

  &__nav {
    margin-top: rem(40);
    display: flex;
    flex-direction: column;
    row-gap: rem(32);
    @include laptop {
      row-gap: rem(16);
    }
    @include tablet {
      margin-top: rem(32);
    }
  }

  &__link {
    text-decoration: none;
    display: flex;
    align-items: center;
    column-gap: rem(18);
    opacity: 0.5;
    transition-duration: 0.3s;
    font-size: rem(18);

    @include laptop {
      font-size: rem(16);
    }
    @include tablet {
      column-gap: rem(8);
      font-size: rem(14);
    }

    &-icon,
    &-arrow {
      width: rem(24);
      height: rem(24);
      @include tablet {
        width: rem(20);
        height: rem(20);
      }
    }

    &-arrow {
      transition-duration: 0.3s;
      margin-left: rem(-10);
      opacity: 0;
      @include tablet {
        margin-left: rem(-8);
      }
    }

    &_active {
      opacity: 1;
      .care-menu__link-arrow {
        opacity: 1;
      }
    }

    &:hover {
      opacity: 1;
      .care-menu__link-arrow {
        opacity: 1;
      }
    }
  }

  &__hello {
    margin-top: rem(12);
    font-size: rem(16);
    font-style: normal;
    font-weight: 400;
    line-height: 140%;
    color: var(--gray60);

    @include laptop {
      font-size: rem(14);
      margin-top: rem(10);
    }

    @include tablet {
      font-size: rem(12);
    }

    @include mobile {
      margin-top: rem(5);
      margin-bottom: rem(16);
    }
  }
}
