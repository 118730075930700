.main-categories{
   
    &__box{
        display: flex;
        flex-wrap: wrap;
        gap: rem(20);

        @include laptop{
            gap: rem(16);
        }
    }

    &__card{
        text-decoration: none;
        display: flex;
        flex-direction: column;
        gap: rem(32);
        width: rem(448);
        margin-right: rem(20);

        @include laptop{
            width: rem(272);
            gap: rem(16);
            margin-right: rem(16);
        }
        @include tablet{
            width: rem(290);
            margin-right: rem(9);
        }
        @include mobile{
            margin-right: rem(8);
            width: rem(262);
            gap: rem(8);
        }
        &:nth-last-of-type(1){
            margin-right: 0;
        }
    }

    &__card-title{
        color: var(--gray80);
        @include laptop{
            font-size: rem(20);
        }
        @include tablet{
            font-size: rem(18);
        }
        @include mobile{
            font-size: rem(16);
        }
    }

    &__image{
        width: 100%;
        height: rem(460);
        overflow: hidden;

        @include laptop{
            height: rem(272);
        }
        @include tablet{
            height: rem(290);
        }
        @include mobile{
            height: rem(262);
        }

        img{
            width: 100%;
            height: 100%;
            object-position: center;
        }
    }

}