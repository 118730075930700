.franchise-info{

    &__box{
        display: flex;
        justify-content: space-between;
        column-gap: rem(100);

        @include laptop{
            column-gap: rem(10);
        }
        @include tablet{
            flex-wrap: wrap;
        }

        &_reverse{
            flex-direction: row-reverse;

            .franchise-info__content{
                width: 50%;
                @include tablet{
                    width: 100%;
                }
            }
        }
    }

    &__content{
        display: flex;
        flex-direction: column;

        &_row{
            align-items: center;
            flex-direction: row;
            column-gap: rem(140);
        }
    }

    &__title{
        margin-bottom: rem(72);
        max-width: rem(730);
        text-align: left;

        @include laptop{
            margin-bottom: rem(32);
        }
        @include tablet{
            margin-bottom: rem(32);
        }
        @include mobile{
            margin-bottom: rem(24);
            font-size: rem(22);
        }

        &:has(+ .franchise-info__subtitle){
            margin-bottom: rem(56);
            @include laptop{
                margin-bottom: rem(32);
            }
            @include tablet{
                margin-bottom: rem(32);
            }
            @include mobile{
                margin-bottom: rem(24);
            }
        }

        &:has(+ .franchise-info__box){
            margin-bottom: rem(64);
            max-width: rem(950);
            @include laptop{
                margin-bottom: rem(48);
            }
            @include tablet{
                margin-bottom: rem(32);
            }
            @include mobile{
                margin-bottom: rem(24);
            }
        }

        span{
            color: var(--sageDark);
        }

        &_center{
            text-align: center;
            margin: 0 auto;
            max-width: rem(1200);
            @include laptop{
                max-width: rem(800);
            }
        }

    }

    &__description{
        margin: 0 auto;
        margin-top: rem(32);
        margin-bottom: rem(48);
        text-align: center;
        max-width: rem(440);
        @include laptop{
            max-width: rem(360);
            margin-top: rem(24);
            margin-bottom: rem(40);
        }
        @include tablet{
            margin-top: rem(16);
            margin-bottom: rem(48);
        }
        @include mobile{
            margin-top: rem(16);
            margin-bottom: rem(32);
            max-width: rem(310);
        }
    }

    &__subtitle{
        margin-bottom: rem(32);
        max-width: rem(690);
        @include laptop{
            font-size: rem(20);
        }
        @include tablet{
            font-size: rem(18);
        }
        @include mobile{
            font-size: rem(16);
        }
    }

    &__list{
        display: flex;
        flex-direction: column;
        row-gap: rem(24);
        margin: 0;
        padding: 0 0 0 rem(26);

        @include laptop{
            row-gap: rem(12);
        }
        @include mobile{
            row-gap: rem(8);
        }

        &_short{
            row-gap: rem(16);
            @include laptop{
                row-gap: rem(4);
            }
            @include mobile{
                row-gap: rem(4);
            }
        }

        &_grid{
            display: grid;
            grid-template-columns: repeat(2,1fr);
            column-gap: rem(100);
            @include laptop{
                column-gap: rem(80);
            }
            @include tablet{
                grid-template-columns: repeat(3,1fr);
                column-gap: rem(140);
            }
            @include mobile{
                grid-template-columns: repeat(2,1fr);
                column-gap: rem(80);
            }
        }
    }

    &__li{
        color: var(--gray80);
        max-width: rem(470);
    }

    &__image{
        height: rem(600);

        @include laptop{

            max-height: rem(360);
            
        }
        @include tablet{
            max-height: rem(456);
            width: 100%;
            margin-top: rem(40);
        }
        @include mobile{
            
            margin-top: rem(28);
            max-height: rem(230);
        }

        &_map{
            @include laptop{
                margin-top: 0;
            }
            @include tablet{
                margin-top: 0;
                max-height: rem(366);
            }
            @include mobile{
                margin-top: rem(24);
                max-height: rem(185);
            }
        }

        img{
            width: 100%;
            height: 100%;
        }
    }

    &__grid{
        display: flex;
        flex-wrap: wrap;
        margin-top: auto;
        row-gap: rem(100);
        @include laptop{
            row-gap: rem(52);
        }
        @include tablet{
            row-gap: rem(32);
        }
        @include mobile{
            row-gap: rem(20);
        }
    }

    &__block{
        width: 50%;
        @include mobile{
            width: 100%;
        }
    }

    &__block-title{
        font-size: rem(80);
        color: var(--sageDark);
        text-align: left;
        margin-bottom: rem(12);

        @include laptop{
            font-size: rem(56);
        }
        @include tablet{
            font-size: rem(56);
        }
        @include mobile{
            font-size: rem(48);
        }

        span{
            font-size: rem(36);
            margin-left: rem(8);
            @include laptop{
                font-size: rem(28);
            }
            @include tablet{
                font-size: rem(26);
            }
            @include mobile{
                font-size: rem(20);
            }
        }
    }

    &__block-text{
        color: var(--gray80);
    }

}