
.mobile-menu{

    position: absolute;
    width: calc(100% + rem(34));
    height: calc(100dvh - rem(76));
    background: var(--grayBg);
    z-index: 40;
    top: rem(41);
    transform: translateX(-130%);
    transition-duration: 0.3s;
    left: 0;
    margin-left: rem(-17);

    @include min-tablet{
        display: none;
    }

    @include mobile{
        top: rem(55);
    }

    &_open{
        transform: translateX(0);
    }

    &__container{
        position: relative;
        height: calc(100% - rem(68));
        padding: 0 rem(17);
    }

    &__buttons{
        display: flex;
    }

    &__btn{
        display: flex;
        justify-content: center;
        align-items: center;
        padding: rem(17) 0;
        width: 50%;
        border-bottom: rem(1) solid var(--black);
        text-decoration: none;
        background: var(--grayBg);
        font-size: rem(12);
        font-weight: 400;

        @include mobile{
            font-size: rem(12);
        }

        &:nth-of-type(2){
            border-left: rem(1) solid var(--black);
        }
    }

    &__location{
        display: flex;
        justify-content: space-between;
        padding: rem(20) 0;
        margin-top: rem(4);
        margin-bottom: rem(8);
        border-bottom: rem(1) solid var(--gray10);

        @include mobile{
            margin-bottom: unset;
        }
    }

    &__location-current{
        display: flex;
        align-items: center;
        column-gap: rem(8);
        font-size: rem(12);
        @include tablet{
            font-size: rem(12);
        }
        @include mobile{
            font-size: rem(12);
        }

        img{
            width: rem(20);
            height: rem(20);
            @include mobile{
                width: rem(16);
                height: rem(16);
            }
        }
    }

    &__location-change{
        color: var(--gray40);
        text-decoration: none;
        line-height: 1;
        display: flex;
        align-items: center;
        font-size: rem(12);
        @include tablet{
            font-size: rem(12);
        }
        @include mobile{
            font-size: rem(12);
        }
    }

    &__nav{
        position: relative;
        margin: rem(40) 0;
        overflow-y: auto;
        display: flex;
        flex-direction: column;
        row-gap: rem(24);
        max-height: calc(100% - rem(120));

        &_submenu{
            margin-top: rem(32);
            @include mobile{
                margin-top: rem(24);
            }
        }
    }

    &__li{
        display: block;
        font-size: rem(12);
        line-height: rem(15);
    }

    &__bottom{
        margin-top: rem(16);
        padding-top: rem(40);
        border-top: rem(1) solid var(--gray10);
        display: flex;
        flex-direction: column;
        row-gap: rem(40);
    }

    &__ul{
        display: flex;
        flex-direction: column;
        row-gap: rem(24);
    }

    &__link{
        text-decoration: none;
        display: block;
        line-height: rem(15);

        @include mobile{
            font-size: rem(12);
        }

        &_bottom{
            color: var(--gray70);
        }
    }

    &__submenu{
        position: absolute;
        width: 100%;
        height: calc(100% + rem(52));
        background: var(--grayBg);
        top: rem(-52);
        left: 0;
        padding: rem(48) rem(17);
        transform: translateX(-130%);
        transition-duration: 0.3s;

        &_open{
            transform: translateX(0);
        }

        @include mobile{
            height: calc(100% + rem(32));
            padding-top: rem(32) rem(9);
        }
    }

    &__back{
        display: flex;
        align-items: center;
        column-gap: rem(12);
        text-decoration: none;

        img{
            width: rem(20);
            height: rem(20);
        }
    }

    &__back-text{
        font-size: rem(16);
        line-height: 1;
    }
    
    &__city{
        display: flex;
        flex-direction: column;
        row-gap: rem(10);
        position: absolute;
        top: rem(120);
        left: 0;
        width: 100vw;
        z-index: 45;
        padding: rem(16);
        border-radius: rem(6);
        background: var(--white);
        box-shadow: 0px 11px 20px 0px #0510371A;
        display: none;
    }

    &__city-title{
        font-size: rem(12);
        text-align: center;
        font-weight: 400;
        @include tablet{
            font-size: rem(12);
        }
        @include mobile{
            font-size: rem(12);
        }
    }

    &__city-buttons{
        display: flex;
        column-gap: rem(8);
    }

    &__city-btn{
        width: calc(50% - rem(4));
        border: rem(1) solid var(--black);
        border-radius: rem(8);
        line-height: 1;
        padding: rem(13) 0;

        &_black{
            background: var(--black);
            color: var(--white);
        }
    }

}