

.franchise-tabs{

    &__title{
        font-size: rem(36);
        @include laptop{
            font-size: rem(28);
        }
        @include tablet{
            font-size: rem(26);
        }
        @include mobile{
            font-size: rem(20);
        }
    }

    &__panel{
        margin-top: rem(56);
        border-radius: rem(8);
        border: rem(1) solid var(--black);
        display: flex;
        justify-content: space-between;
        column-gap: rem(20);

        @include laptop{
            margin-top: rem(32);
        }
        @include mobile{
            display: none;
        }
    }

    &__select{
        display: none;
        @include mobile{
            display: block;
            margin-top: rem(24);
        }
    }

    &__btn{
        cursor: pointer;
        width: 100%;
        padding: rem(12) rem(16);
        color: var(--gray50);
        text-align: center;

        @include laptop{
            padding: rem(14);
        }
        @include tablet{
            padding: rem(16);
        }

        &.tab-btn-active{
            color: var(--black);
        }
    }

    &__box{
        margin-top: rem(72);
        display: none;
        justify-content: space-between;
        
        &_center{
            align-items: center;
        }

        @include laptop{
            margin-top: rem(48);
        }
        @include tablet{
            margin-top: rem(40);
            flex-wrap: wrap;
        }
        @include mobile{
            margin-top: rem(16);
        }

        &.tab-box-active{
            display: flex;
        }
    }

    &__content{
        
    }

    &__content-title{
        max-width: rem(526);
        margin-bottom: rem(32);
        @include mobile{
            font-size: rem(16);
        }
    }

    &__list{
        display: flex;
        flex-direction: column;
        row-gap: rem(24);
        margin: 0;
        padding: 0 0 0 rem(26);

        @include laptop{
            row-gap: rem(12);
        }
        @include tablet{
            row-gap: rem(8);
        }
        @include mobile{
            row-gap: rem(8);
        }
    }

    &__li{
        color: var(--gray80);
        max-width: rem(470);
    }

    &__image{
        @include laptop{
            max-width: rem(560);
        }
        @include tablet{
            width: 100%;
            max-width: unset;
            max-height: rem(456);
            margin-top: rem(40);
        }
        @include mobile{
            margin-top: rem(28);
        }
    }

}