.vacancy {

    .inner{
        margin-top: rem(64);
        @include laptop{
            margin-top: rem(40);
        }
        @include tablet{
            margin-top: rem(40);
        }
        @include mobile{
            margin-top: rem(32);
        }
    }

    &__box {
        display: flex;
        flex-wrap: wrap;
        justify-content: space-between;
        column-gap: rem(300);
        row-gap: rem(72);

        @include laptop{
            column-gap: rem(100);
            row-gap: rem(56);
        }
        @include tablet{
            flex-wrap: wrap;
            row-gap: rem(48);
        }
        @include mobile{
            row-gap: rem(40);
        }
    }

    &__left {
        width: 100%;
        max-width: rem(916);

        @include laptop{
            max-width: rem(560);
        }
        @include tablet{
            width: 100%;
            max-width: unset;
        }

        &_col {
            display: flex;
            flex-direction: column;
            row-gap: rem(24);
        }
    }


    &__top {
        width: 100%;
        display: flex;
        column-gap: rem(20);

        @include laptop{
            column-gap: rem(16);
        }
        @include tablet{
            column-gap: rem(10);
        }
        @include mobile{
            flex-wrap: wrap;
            row-gap: rem(8);
        }
    }

    &__title {
        text-align: left;

        @include laptop{
            font-size: rem(28);
        }
        @include tablet{
            font-size: rem(26);
        }
        @include mobile{
            font-size: rem(20);
        }

    }

    &__accordions{
        display: flex;
        flex-direction: column;
        row-gap: rem(8);
    }

}






.vacancy-form {

    width: rem(606);
    margin-top: rem(60);

    @include laptop{
        width: rem(368);
        margin-top: rem(52);
    }
    @include tablet{
        width: 100%;
        margin-top: rem(0);
    }



    &:has(.error-field) {
        .vacancy-form__btn {
            user-select: none;
            pointer-events: none;
            opacity: 0.3;
        }
    }


    &__title {
        text-align: left;
        max-width: rem(465);
        margin-bottom: rem(24);

        @include laptop {
            font-size: rem(18);
            max-width: rem(350);
        }

        @include tablet {
            font-size: rem(16);
            max-width: rem(496);
        }

        @include mobile {
            font-size: rem(16);
            max-width: rem(305);
        }
    }

    /* &__inner {
    display: grid;
    grid-template-columns: rem(916) rem(606);
    justify-content: space-between;

    @include laptop {
      grid-template-columns: rem(560) rem(368);
    }

    @include tablet {
     display: flex;
     flex-direction: column;
     gap: rem(40);
    }
  } */

    &__policy {
        margin-top: rem(10);
        color: var(--gray50);

        a {
            color: inherit;
        }

        @include laptop {
            max-width: rem(367);
        }

        @include tablet {
            max-width: none;
        }
    }

    &__btn {
        width: 100%;
        margin-block: rem(24) rem(10);
    }

    &__inputs {
        display: flex;
        flex-direction: column;
        gap: rem(8);
        margin-bottom: rem(24);

        @include laptop {
            margin-bottom: rem(16);
        }

        @include mobile {
            margin-bottom: rem(24);
        }
    }
}