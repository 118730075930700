


.loyalty{


    &__box{
        display: flex;
        justify-content: space-between;
        margin-top: rem(80);

        @include laptop{
            margin-top: rem(64);
        }
        @include tablet{
            margin-top: rem(32);
            flex-wrap: wrap;
            row-gap: rem(56);
        }
        @include mobile{
            row-gap: rem(32);
        }
    }

    &__title{
        max-width: rem(600);
        text-align: left;
        @include laptop{
            max-width: rem(440);
            font-size: rem(28);
        }
        @include tablet{
            font-size: rem(26);
        }
        @include mobile{
            font-size: rem(20);
        }
    }
    
    &__info{
        width: 100%;
        max-width: rem(604);
        border: rem(1) solid var(--gray20);
        border-radius: rem(24);
        padding: rem(76) rem(78);
        display: flex;
        flex-direction: column;
        row-gap: rem(32);

        @include laptop{
            max-width: rem(416);
            padding: rem(48);
        }
        @include tablet{
            max-width: 100%;
            padding: rem(30);
        }
        @include mobile{
            padding: 0;
            border: none;
            border-radius: 0;
        }
    }

    &__info-block{
        display: flex;
        flex-direction: column;
        row-gap: rem(16);
    }

    hr{
        background: var(--gray20);
        border: none;
        height: rem(1);
        width: 100%;
        margin: 0;
        @include tablet{
            display: none;
        }
    }

    &__info-text{
        color: var(--gray70);
    }

    &__info-title{
        text-align: left;
        @include laptop{
            font-size: rem(18);
        }
        @include tablet{
            font-size: rem(16);
        }
        @include mobile{
            font-size: rem(16);
        }
    }

    &__info-flex{
        display: flex;
        justify-content: space-between;
    }

    &__info-buttons{
        display: flex;
        column-gap: rem(20);
        margin-top: rem(8);

        .btn{
            padding-left: rem(5);
            padding-right: rem(5);
        }

        @include laptop{
            column-gap: rem(10);
        }
        @include tablet{
            margin-top: 0;
        }
        @include mobile{
            flex-wrap: wrap;
            row-gap: rem(8);
        }
    }


    &__sale{
        width: 100%;
        max-width: rem(1072);

        @include laptop{
            max-width: rem(660);
        }
        @include tablet{
            
        }
    }

    &__sale-slider-wrap{
        margin-top: rem(40);
        margin-bottom: rem(72);
        @include laptop{
            margin-top: rem(32);
            margin-bottom: rem(32);
        }
        @include tablet{
            margin-top: rem(24);
        }
    }

    &__sale-scale{
        display: flex;
        justify-content: space-between;
    }

    &__sale-step{
        color: var(--gray40);
        &_active{
            color: var(--black);
            font-weight: 500;
        }
    }

    &__sale-slider-track{
        margin-top: rem(17);
        height: rem(8);
        background: var(--gray20);
        position: relative;
        border-radius: rem(100);
    }

    &__sale-slider{
        top: 0;
        left: 0;
        position: absolute;
        height: rem(8);
        width: 100%;
        background: transparent;
        -webkit-appearance: none;
        border-radius: rem(100);

        &::-webkit-slider-thumb {
            -webkit-appearance: none;
            background: var(--sageDark);
            width: rem(20);
            height: rem(20);
            border-radius: rem(100);
            border: rem(5) solid var(--sage);
            margin-top: rem(-6);
            cursor: pointer;
        }

        &::-moz-range-thumb{
            box-sizing: border-box;
            background: var(--sageDark);
            width: rem(20);
            height: rem(20);
            border-radius: rem(100);
            border: rem(5) solid var(--sage);
            margin-top: rem(-6);
            cursor: pointer;
        }
        
        &::-webkit-slider-runnable-track {
            height: rem(8);
            border-radius: rem(100);
            //-webkit-appearance: none;
            //background: #ffffff01;
            /* border: none;
            border-radius: rem(100);
            cursor: pointer; */
        }

        
    }

    &__sale-results{
        display: flex;
        justify-content: space-between;
        max-width: rem(990);
        @include laptop{
            column-gap: rem(16);
        }
        @include mobile{
            flex-wrap: wrap;
            row-gap: rem(32);
        }
    }

    &__sale-col{
        display: flex;
        flex-direction: column;
        row-gap: rem(24);
        width: rem(440);

        @include tablet{
            width: rem(272);
        }
        @include mobile{
            width: 100%;
        }

        &_border{
            @include mobile{
                padding-top: rem(32);
                border-top: rem(1) solid var(--gray20);
            }
        }
    }

    &__sale-pictures{
        display: flex;
    }

    &__sale-img{
        width: rem(214);
        height: rem(214);
        border-radius: rem(200);
        display: block;
        z-index: 2;
        flex-shrink: 0;

        @include laptop{
            width: rem(113);
            height: rem(113);
        }

        &:nth-of-type(2){
            margin-left: rem(-58);
            z-index: 1;
            @include laptop{
                margin-left: rem(-30);
            }
        }
    }

    &__sale-col-title{
        flex-grow: 1;
        max-width: rem(310);
        @include laptop{
            font-size: rem(16);
        }
        @include tablet{
            font-size: rem(14);
        }
    }

    &__sale-block{
        display: flex;
        flex-direction: column;
        row-gap: rem(8);
        flex-grow: 1;
        @include laptop{
            row-gap: rem(6);
        }
    }

    &__sale-line{
        display: flex;
        justify-content: space-between;
    }

    &__sale-text{
        &_gray{
            color: var(--gray50);
        }
        &_sage{
            color: var(--sageDark);
        }
    }



    &__conditions{
        width: 100%;
        max-width: rem(1072);
        @include laptop{
            max-width: rem(656);
        }
    }

    &__conditions-box{
        display: flex;
        column-gap: rem(80);
        margin-top: rem(56);
        @include laptop{
            column-gap: rem(16);
            margin-top: rem(30);
        }
        @include tablet{
            flex-wrap: wrap;
            row-gap: rem(40);
            margin-top: rem(24);
        }
        @include mobile{
            row-gap: rem(24);
        }
    }

    &__conditions-col{
        width: 100%;
        display: flex;
        flex-direction: column;
        row-gap: rem(16);
        @include laptop{
            row-gap: rem(12);
        }
        @include tablet{
            width: rem(550);
        }
        @include mobile{
            width: 100%;
        }
    }

    &__conditions-title{
        margin-bottom: rem(8);
        max-width: rem(400);
        color: var(--gray70);

        @include laptop{
            max-width: rem(270);
        }
        @include tablet{
            max-width: 100%;
            font-size: rem(18);
        }
        @include mobile{
            font-size: rem(16);
        }
    }

    &__conditions-text{
        color: var(--gray60);
    }




}