.profile-config {
  &__heading {
    text-align: left;
    margin-bottom: rem(32);

    @include laptop {
      max-width: rem(416);
      font-size: rem(18);
    }

    @include tablet {
      font-size: rem(16);
      margin-bottom: rem(24);
      max-width: rem(300);
    }

    @include mobile {
      font-size: rem(16);
      max-width: rem(250);
      margin-bottom: rem(16);
    }
  }

  &__body {
    display: flex;
    flex-direction: column;
    gap: rem(20);

    @include tablet {
      gap: rem(12);
    }
  }
}
