
.certificate{

    &__box{
        margin-top: rem(64);
        display: flex;
        justify-content: space-between;
        max-width: rem(1460);

        @include laptop{
            margin-top: rem(40);
        }
        @include tablet{
            margin-top: rem(32);
        }
        @include mobile{
            flex-wrap: wrap-reverse;
            row-gap: rem(56);
        }

    }

    &__tab{
        &:not(.tab-box-active){
            display: none;
        }
    }

    &__accordions{
        width: 100%;
        max-width: rem(546);
        @include laptop{
            max-width: rem(464);
        }
        @include tablet{
            max-width: rem(320);
        }
        @include mobile{
            max-width: 100%;
        }
    }

    &__form{
        width: 100%;
        max-width: rem(584);
        display: flex;
        flex-direction: column;
        row-gap: rem(32);
        @include laptop{
            max-width: rem(512);
        }
        @include tablet{
            max-width: rem(340);
            row-gap: rem(24);
        }
        @include mobile{
            max-width: 100%;
        }
    }

    &__check{
        width: 100%;
        max-width: rem(624);
        display: flex;
        flex-direction: column;
        row-gap: rem(32);
        @include laptop{
            max-width: rem(472);
        }
        @include tablet{
            max-width: rem(320);
            row-gap: rem(24);
        }
        @include mobile{
            max-width: 100%;
        }
    }

    &__poly{
        color: var(--gray50);
        margin-top: rem(-8);
    }

    &__explain{
        color: var(--gray50);
        margin-top: rem(-8);
        max-width: rem(310);

        @include laptop{
            max-width: rem(260);
        }

        @include tablet{
            margin-top: rem(-2);
        }

        @include mobile{
            margin-top: rem(-6);
        }

        a{
            color: var(--gray50);
        }
    }

    &__block{
        display: flex;
        flex-direction: column;
        row-gap: rem(16);

        @include tablet{
            row-gap: rem(10);
        }

        .variants{
            @include tablet{
                gap: rem(5);
                margin-top: rem(5);
                margin-bottom: rem(5);
            }
        }

        textarea{
            min-height: rem(56);
            @include laptop{
                min-height: rem(40);
            }
            @include tablet{
                min-height: rem(44);
            }
        }
    }

    &__image{
        width: rem(604);
        height: rem(340);
        border-radius: rem(20);
        overflow: hidden;
        margin-bottom: rem(8);

        @include laptop{
            border-radius: rem(14);
            width: rem(480);
            height: rem(269);
        }
        @include tablet{
            width: rem(320);
            height: rem(196);
            margin-bottom: 0;
        }
        @include mobile{
            width: 100%;
        }

        img{
            width: 100%;
            height: 100%;
        }
    }

}