

.block-text{

    

    &__box{
        display: flex;
        column-gap: rem(110);
        @include laptop{
            column-gap: rem(115);
        }
        @include tablet{
            column-gap: rem(20);
        }
    }

    &__title{
        font-size: rem(28);
        letter-spacing: -0.01vw;
        line-height: 1.1;
        text-align: left;
        margin-bottom: rem(40);
        @include laptop{
            font-size: rem(20);
        }
        @include tablet{
            font-size: rem(18);
            margin-bottom: rem(24);
        }
        @include mobile{
            font-size: rem(16);
            margin-bottom: rem(16);
        }

        &_special{
            font-size: rem(48);
            text-align: center;
            max-width: rem(920);
            margin: 0 auto;
            margin-bottom: rem(64);
            @include laptop{
                font-size: rem(32);
                margin-bottom: rem(40);
            }
            @include tablet{
                font-size: rem(28);
                margin-bottom: rem(32);
            }
            @include mobile{
                text-align: left;
                font-size: rem(22);
                margin-bottom: rem(32);
            }
        }

    }

    &__select{
        display: none;
        @include mobile{
            display: block;
            margin-bottom: rem(24);
        }
    }

    &__buttons{
        display: flex;
        column-gap: rem(20);
        margin-top: rem(40);
        margin-bottom: rem(16);

        @include laptop{
            column-gap: rem(16);
        }
        @include tablet{
            flex-wrap: wrap;
            row-gap: rem(10);
            margin-top: rem(32);
        }
        @include mobile{
            margin-top: rem(24);
        }

    }

    &__link{
        font-size: rem(12);
        @include laptop{
            font-size: rem(12);
        }
        @include tablet{
            font-size: rem(12);
        }
        @include mobile{
            font-size: rem(12);
        }
    }

    .content-cms{
        display: flex;
        flex-direction: column;
        row-gap: rem(24);
        color: var(--black);
        width: 100%;
        max-width: rem(936);

        @include laptop{
            row-gap: rem(16);
            max-width: rem(705);
        }
        @include mobile{
            //row-gap: rem(24);
        }


        p{
            margin: 0;
            font-size: rem(18);
            line-height: 1.4;
            letter-spacing: -0.02vw;

            @include laptop{
                font-size: rem(16);
            }
            @include tablet{
                font-size: rem(14);
            }
            @include mobile{
                font-size: rem(12);
            }

            &:has(small){
                line-height: 1.1;
                max-width: rem(742);
            }

            small{
                font-size: rem(14);
                color: var(--gray80);
                
                @include laptop{
                    font-size: rem(12);
                }
                @include tablet{
                    font-size: rem(12);
                }
                @include mobile{
                    font-size: rem(10);
                }
            }

            &:has(span){
                max-width: rem(624);
            }

            span{
                color: var(--gray60);
            }

            a{
                color: var(--black);
            }

            b{
                font-weight: 500;
            }
        }
        

        h2{
            font-family: "Stapel Expanded", serif;
            font-weight: 500;
            margin: 0;
            text-decoration: none;
            font-size: rem(24);
            letter-spacing: -0.01vw;
            line-height: 1.1;
            @include laptop{
                font-size: rem(18);
            }
            @include tablet{
                font-size: rem(16);
            }
            @include mobile{
                font-size: rem(16);
            }
        }
        h3{
            font-family: "Stapel Expanded", serif;
            font-weight: 500;
            margin: 0;
            text-decoration: none;
            font-size: rem(24);
            letter-spacing: -0.01vw;
            line-height: 1.1;
            //margin-bottom: rem(8);
            @include laptop{
                font-size: rem(18);
            }
            @include tablet{
                font-size: rem(16);
                //margin-bottom: 0;
            }
            @include mobile{
                font-size: rem(16);
            }
        }

        div{
            display: flex;
            flex-direction: column;
            //row-gap: rem(16);

            &:has(img){
                margin-top: rem(16);
                margin-bottom: rem(16);
                @include mobile{
                    margin-top: rem(8);
                    margin-bottom: rem(8);
                }
            }

            &:has(figure){
                width: rem(1151);
                @include laptop{
                    width: rem(705);
                }
                @include tablet{
                    width: 100%;
                }
            }
        }

        ul, ol{
            margin: 0;
            padding-left: rem(24);

            p{
                margin-left: rem(-24);
                margin-bottom: rem(8);
                @include mobile{
                    margin-bottom: rem(4);
                }
            }
        }

        li{
            line-height: 1.4;
            font-size: rem(18);
            @include laptop{
                font-size: rem(16);
            }
            @include tablet{
                font-size: rem(14);
            }
            @include mobile{
                font-size: rem(12);
            }

            a{
                color: var(--black);
            }
        }

        figure{
            margin: 0;

            img,
            picture{
                width: 100%;
            }

            &:has(> img + img),
            &:has(> picture + picture ) {
                display: flex;
                flex-wrap: wrap;
                gap: rem(40) rem(20);
                @include laptop{
                    gap: rem(24) rem(16);
                }
                @include tablet{
                    gap: rem(16) rem(10);
                }

                img,
                picture{
                    width: rem(370);
                    height: rem(476);
                    border-radius: rem(12);
                    @include laptop{
                        width: rem(224);
                        height: rem(300);
                    }
                    @include tablet{
                        width: rem(140);
                        height: rem(200);
                    }
                    @include mobile{
                        width: rem(358);
                        height: rem(415);
                    }
                }
            }

        }

        a.btn{
            margin-top: rem(16);
            max-width: rem(526);
            @include mobile{
                margin-top: rem(8);
            }
        }

        *{
            &:has(+ h2),
            &:has(+ h3) {
                margin-bottom: rem(16);
                @include mobile{
                    margin-bottom: rem(8);
                }
            }
        }

    }

    &__signature{
        width: rem(312);
        height: rem(169);
        margin-left: auto;
        margin-top: rem(64);
        
        @include laptop{
            margin-top: rem(48);
        }
        @include tablet{
            margin-top: rem(40);
        }
        @include mobile{
            margin-top: rem(32);
        }

        img{
            width: 100%;
            height: 100%;
        }
    }

    &__content{

        &_center{
            width: 100%;
            max-width: rem(938);
            margin: 0 auto;

            @include laptop{
                max-width: rem(705);
            }

            .content-cms{
                max-width: 100%;
                
                p{
                    &:has(span){
                        max-width: unset;
                    }
                }


                div{
                    

                    &:has(> div > table) {
                        margin-top: rem(40);
                        @include laptop{
                            margin-top: rem(32);
                        }
                        @include tablet{
                            margin-top: rem(24);
                        }
                        
                    }
                    &:has(> table){
                        @include mobile{
                            overflow-x: scroll;
                            width: 100%;
                            padding-bottom: rem(5);
                        }
                    }
                }

                ol{
                    li{
                        list-style-type:"–  ";
                    }
                }

                table{
                    border-collapse: collapse;
                    margin-top: rem(44);
                    @include laptop{
                        margin-top: rem(24);
                    }
                    @include tablet{
                        margin-top: rem(16);
                    }
                    @include mobile{
                        width: rem(590);
                    }

                    tr{
                        
                    }

                    th{
                        font-family: "Stapel Expanded", serif;
                        font-weight: 500;
                        text-decoration: none;
                        letter-spacing: -0.01vw;
                        line-height: 1.1;
                        color: var(--black);
                        font-size: rem(24);
                        text-align: left;
                        padding-bottom: rem(40);
                        border-bottom: rem(1) solid var(--gray20);
                        @include laptop{
                            font-size: rem(18);
                            padding-bottom: rem(24);
                        }
                        @include tablet{
                            font-size: rem(16);
                        }

                        &:nth-of-type(1){
                            width: rem(420);
                            @include laptop{
                                width: rem(320);
                            }
                            @include mobile{
                                width: rem(300);
                            }
                        }

                    }

                    td{
                        letter-spacing: -0.03vw;
                        line-height: 1.4;
                        color: var(--gray80);
                        font-size: rem(16);
                        padding: rem(24) 0;
                        border-bottom: rem(1) solid var(--gray20);
                        @include laptop{
                            font-size: rem(14);
                            padding-right: rem(40);
                        }
                        @include tablet{
                            font-size: rem(12);
                            padding: rem(16) 0;
                            padding-right: rem(40);
                        }

                        &[colspan]{
                            padding-right: 0;
                        }

                    }

                }

            }
        }


    }

}