html {
  box-sizing: border-box;
  background: var(--grayBg);
}

@media screen and (min-width: 960px) {
  html {
    /* margin-left: calc(100vw - 100%);
    margin-right: 0; */
    width: 100vw;
    overflow-x: clip;
    margin-left: calc((100% - 100vw) / 2);
  }
}

*,
*::before,
*::after {
  box-sizing: inherit;
}

.page {
  height: 100%;
  font-family: var(--font-family, sans-serif);
  -webkit-text-size-adjust: 100%;
}

.page__body {
  margin: 0;
  min-width: 320px;
  min-height: 100%;
  font-size: 16px;

  &::-webkit-scrollbar {
    width: 12px;             
  }
  &::-webkit-scrollbar-track {
    background: var(--grayBg);      
  }
  &::-webkit-scrollbar-thumb {
    background-color: var(--gray50);  
    border-radius: 20px;     
    border: 3px solid var(--white);
  }


}

@media screen and (min-width: 960px) {
  .page__body {
      //margin-left: calc(100% - 100vw);
  }
}

img {
  height: auto;
  max-width: 100%;
  object-fit: cover;
}

a {
  text-decoration: none;
}

.site-container {
  overflow: clip; // если используете на сайте position: sticky - уберите эту настройку

  &.dis-scroll {
    @media screen and (min-width: 1025px) {
      left: rem(-6);
    }
  }

}

.is-hidden {
  display: none !important; /* stylelint-disable-line declaration-no-important */
}

.btn-reset {
  border: none;
  padding: 0;
  background: transparent;
  cursor: pointer;
}

.list-reset {
  list-style: none;
  margin: 0;
  padding: 0;
}

.input-reset {
  -webkit-appearance: none;
  appearance: none;
  border: none;
  border-radius: 0;
  background-color: #fff;
  -moz-appearance: textfield;

  &::-webkit-search-decoration,
  &::-webkit-search-cancel-button,
  &::-webkit-search-results-button,
  &::-webkit-search-results-decoration {
    display: none;
  }

  &::-webkit-outer-spin-button,
  &::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
  }

}

.visually-hidden {
  position: absolute;
  overflow: hidden;
  margin: -1px;
  border: 0;
  padding: 0;
  width: 1px;
  height: 1px;
  clip: rect(0 0 0 0);
}

.container {
  margin: 0 auto;
  //padding: 0 var(--container-offset);
  //max-width: var(--container-width);
  padding: 0 rem(34);
  width: 100%;

  @include laptop{
      padding: 0 rem(32);
  }
  @include tablet{
      padding: 0 rem(17);
  }
  @include mobile{
      padding: 0 rem(9);
  }
}

.js-focus-visible :focus:not(.focus-visible) {
  outline: none;
}

.centered {
  text-align: center;
}

.dis-scroll {
  position: fixed;
  left: 0;
  top: 0;
  overflow: hidden;
  width: 100%;
  height: 100vh;
  overscroll-behavior: none;
}

.page--ios .dis-scroll {
  position: relative;
}

.border-radius{
  border-radius: rem(20);
  overflow: hidden;
  @include laptop{
      border-radius: rem(14);
  }
  @include tablet{
      border-radius: rem(12);
  }
}

.custom-scroll{
  &::-webkit-scrollbar {
    width: rem(11);
    border-radius: rem(30);
  }
  &::-webkit-scrollbar-track {
    background: transparent;
    margin-top: rem(10);
    margin-bottom: rem(10);
  }
  &::-webkit-scrollbar-thumb {
    background-color: var(--black);  
    border-radius: rem(30);    
    border: rem(4) solid var(--white); 
  }
}


.js-modal-open *{
  pointer-events: none;
}


.swiper-wrapper-mobile{
  @media (min-width: 480px) {
    transform: translate3d(0px, 0px, 0px) !important;
  }
}

.display-none{
  display: none !important;
}

*{
  &:has(.select_open){
    z-index: 13;
  }
}