.profile-history {
  &__tabs {
    display: flex;
    gap: rem(16);
    margin-bottom: rem(40);

    @include laptop {
      gap: rem(10);
      margin-bottom: rem(32);
    }

    @include tablet {
      justify-content: space-between;
      overflow-x: auto;
      padding-bottom: rem(10);
      margin-bottom: rem(12);
      width: rem(487);
      margin-right: rem(-17);
      padding-right: rem(17);
      gap: rem(8);
    }

    @include mobile {
      width: 100vw;
      gap: rem(6);
      margin-bottom: rem(22);
    }
  }

  &__tab {
    user-select: none;
    color: var(--gray50);
    font-size: rem(14);
    font-style: normal;
    font-weight: 400;
    line-height: 140%;
    cursor: pointer;

    padding: rem(8) rem(12);
    border-radius: rem(8);
    border: rem(1) solid var(--gray00);
    transition: all 0.3s ease;

    &:has(.profile-history__tab-radio:checked) {
      background: var(--gray10);
      border-color: transparent;
      color: var(--black);
    }

    @include tablet {
      padding: rem(6) rem(10);
      flex: 0 0 auto;
      font-size: rem(12);
    }
  }

  &__tab-radio {
    display: none;
  }
}

.profile-history-item {
  display: flex;

  height: rem(257);
  padding-block: rem(40);
  border-bottom: rem(1) solid var(--gray20);

  @include laptop {
    height: rem(211);
    padding-block: rem(32);
  }

  @include tablet {
    height: rem(222);
    padding-block: rem(28);
    position: relative;
  }

  @include mobile {
    flex-direction: column;
    height: rem(211);
    padding-block: rem(20);
  }

  &:first-child {
    border-top: rem(1) solid var(--gray20);
  }

  &__text {
    font-size: rem(16);
    font-style: normal;
    font-weight: 400;
    line-height: 140%;
    color: var(--black);

    @include laptop {
      font-size: rem(14);
    }

    @include tablet {
      font-size: rem(12);
    }
  }

  &__subtitle {
    font-size: rem(16);
    font-style: normal;
    font-weight: 400;
    line-height: 140%;
    color: var(--gray50);

    @include laptop {
      font-size: rem(14);
    }

    @include tablet {
      font-size: rem(12);
    }
  }

  &__order {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    gap: rem(20);
    width: rem(266);
    margin-right: rem(46);

    @include laptop {
      width: rem(176);
      margin-right: rem(16);
    }

    @include tablet {
      width: rem(168);
      margin-right: rem(13);
    }

    @include mobile {
      position: absolute;
      inset: rem(20) 0;
      width: 100%;
      margin-right: 0;
    }

    &-top {
      @include mobile {
        display: flex;
        flex-direction: row-reverse;
        justify-content: space-between;
        align-items: center;
      }
    }

    &-num {
      font-size: rem(16);
      font-style: normal;
      font-weight: 400;
      line-height: 140%;
      color: var(--white);

      padding: rem(4) rem(16);
      border-radius: rem(90);
      background: var(--sageDark);
      max-width: max-content;
      margin-bottom: rem(16);

      @include laptop {
        font-size: rem(14);
        margin-bottom: rem(8);
      }

      @include tablet {
        font-size: rem(12);
      }

      @include mobile {
        margin-bottom: 0;
      }
    }

    &-date {
      font-size: rem(16);
      font-style: normal;
      font-weight: 400;
      line-height: 140%;
      color: var(--black);

      @include laptop {
        font-size: rem(14);
      }

      @include tablet {
        font-size: rem(12);
      }
    }

    &-price {
      margin-bottom: rem(3);

      @include mobile {
        margin-bottom: 0;
      }
    }

    &-price-sum {
      font-size: rem(18);
      font-style: normal;
      font-weight: 500;
      line-height: 140%;
      color: var(--black);

      @include laptop {
        font-size: rem(16);
      }

      @include tablet {
        font-size: rem(14);
      }

      @include mobile {
        font-size: rem(12);
      }
    }
  }

  &__status {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    gap: rem(20);

    width: rem(120);
    margin-right: rem(192);

    @include laptop {
      width: rem(105);
      margin-right: auto;
    }

    @include tablet {
      width: rem(89);
    }

    @include mobile {
      margin-right: 0;
      margin-left: auto;
      text-align: right;
      padding-top: rem(40);
      gap: rem(12);
    }
  }

  &__products {
    width: rem(396);

    display: flex;
    flex-direction: column;
    justify-content: space-between;

    @include laptop {
      width: rem(244);
    }

    @include tablet {
      position: absolute;
      right: rem(45);
      height: rem(109);
      text-align: right;
    }

    @include mobile {
      top: rem(60);
      right: auto;
      left: 0;
      text-align: left;
      width: rem(186);
      gap: rem(12);
      height: rem(82);
    }
  }

  &__more {
    width: rem(119);
    margin-left: auto;
    align-self: end;

    display: flex;
    align-items: center;
    gap: rem(8);

    font-size: rem(14);
    font-style: normal;
    font-weight: 400;
    line-height: 110%;
    text-transform: uppercase;
    text-decoration: none;
    color: var(--black);

    @include laptop {
      width: rem(107);
      font-size: rem(12);
    }

    @include mobile {
      position: absolute;
      z-index: 10;
      right: 0;
      bottom: rem(20);
    }

    &-img {
      flex: 0 0 auto;
      width: rem(24);
      height: rem(24);
    }
  }

  &__products-imgs {
    display: flex;
    align-items: center;
    justify-content: space-between;
    gap: rem(12);

    @include laptop {
      gap: rem(6);
    }
  }

  &__products-img {
    width: rem(90);
    height: rem(118);
    border-radius: rem(12);
    flex: 0 0 auto;

    @include laptop {
      width: rem(56);
      height: rem(72);
    }

    @include mobile {
      width: rem(42);
      height: rem(54);
      border-radius: rem(4);
    }
  }

  &__products-link {
    flex: 0 0 auto;
    width: rem(90);
    height: rem(118);
    padding: rem(10);
    border-radius: rem(12);

    display: grid;
    place-content: center;
    border: rem(1) solid var(--gray20);

    color: var(--gray30);
    text-align: center;
    font-size: rem(16);
    font-style: normal;
    font-weight: 400;
    line-height: 140%;
    letter-spacing: -0.48px;
    text-decoration: none;

    @include laptop {
      width: rem(56);
      height: rem(72);
      font-size: rem(14);
    }

    @include mobile {
      width: rem(42);
      height: rem(54);
      border-radius: rem(4);
      font-size: rem(12);
    }
  }
}

.profile-history-empty {
  &__text {
    margin: 0;
    margin-bottom: rem(40);

    @include laptop {
      margin-bottom: rem(24);
    }

    @include tablet {
      font-size: rem(14);
    }

    @include mobile {
      font-size: rem(12);
      margin-bottom: rem(16);
    }
  }

  &__link {
    display: grid;
    width: rem(300);
    height: rem(56);
    text-decoration: none;
    place-content: center;
    border-radius: rem(12);
    text-transform: uppercase;

    @include laptop {
      height: rem(44);
    }

    @include tablet {
      width: rem(230);
    }

    @include mobile {
      width: rem(220);
    }
  }
}
