@use "sass:math";

@function rem($pixels) {
  @return calc($pixels / 10) + rem;
}

html {
  --refRes: 1920;
  font-size: calc(100vw / var(--refRes) * 10) !important;

  @media (max-width: 1200px) {
    --refRes: 1200;
    font-size: calc(100vw / var(--refRes) * 10) !important;
  }
  @media (max-width: 744px) {
    --refRes: 744;
    font-size: calc(100vw / var(--refRes) * 10) !important;
  }
  @media (max-width: 480px) {
    --refRes: 375;
    font-size: calc(100vw / var(--refRes) * 10) !important;
  }

}